import * as CorrespondenceSearchApiActionConstants from "./../actions/api/correspondence/CorrespondenceSearchApiActionConstants";

const initialState = {
  correspondenceSearchResults: [],
};

export default function correspondenceSearch(state = initialState, action) {
  switch (action.type) {
    case CorrespondenceSearchApiActionConstants.CORRESPONDENCE_SEARCH_REQUEST:
      return {
        ...state,
      };
    case CorrespondenceSearchApiActionConstants.CORRESPONDENCE_SEARCH_SUCCESS: {
      return {
        ...state,
        correspondenceSearchResults: action.data,
      };
    }
    case CorrespondenceSearchApiActionConstants.CORRESPONDENCE_SEARCH_FAILED:
      return {
        ...state,
      };
    case CorrespondenceSearchApiActionConstants.CORRESPONDENCE_SEARCH_CLEAR:
      return {
        ...state,
        correspondenceSearchResults: [],
      };
    default:
      return state;
  }
}
