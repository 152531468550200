import * as AddUpdateAuthorizationEligibilityApiActionConstants from '../../redux/actions/api/authorization/AddUpdateAuthorizationEligibilityApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdateAuthorizationEligibilityReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateAuthorizationEligibilityApiActionConstants.ADD_UPDATE_AUTHORIZATION_ELIGIBILITY_REQUEST:
            return {
                ...state,
            };
        case AddUpdateAuthorizationEligibilityApiActionConstants.ADD_UPDATE_AUTHORIZATION_ELIGIBILITY_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateAuthorizationEligibilityApiActionConstants.ADD_UPDATE_AUTHORIZATION_ELIGIBILITY_FAILED:
            return {
                ...state,
                data: ''
            };
        case AddUpdateAuthorizationEligibilityApiActionConstants.ADD_UPDATE_AUTHORIZATION_ELIGIBILITY_SELECTED:
            return {
                ...state,
                data: action.val
            };
        default:
            return state
    }
}
