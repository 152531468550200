import React, { useState, Component } from "react";
import "../../App.css";
import "./Organization.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Table, Modal, Tooltip } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LeftPanel from "../common/leftPanel/LeftPanel";
import RightPanel from "../common/leftPanel/RightPanel";
import LeftPanelMobile from "../common/leftPanel/LeftPanelMobile";
import Search from "../common/search/Search";
import {
  addUpdateOrganization,
  addUpdateOrganizationSelected,
} from "../../redux/actions/api/organization/AddUpdateOrganizationApiActions";
import { addUpdatePhoneEmail } from "../../redux/actions/api/phoneEmail/AddUpdatePhoneEmailApiActions";
import { addUpdateAddress } from "../../redux/actions/api/address/AddUpdateAddressApiActions";
import {
  getPatientPhoneEmail,
  patientPhoneEmailClear,
} from "../../redux/actions/api/phoneEmail/GetPatientPhoneEmailApiActions";
import {
  getPatientAddress,
  patientAddressClear,
} from "../../redux/actions/api/address/GetPatientAddressApiActions";
import { addUpdateProvider } from "../../redux/actions/api/provider/AddUpdateProviderApiActions";
import { addUpdateFacility } from "../../redux/actions/api/facility/AddUpdateFacilityApiActions";
import {
  getProvider,
  providerClear,
} from "../../redux/actions/api/provider/GetProviderApiActions";
import { addUpdateEmployee } from "../../redux/actions/api/employee/AddUpdateEmployeeApiActions";
import {
  getClientEmployees,
  clientEmployeesClear,
} from "../../redux/actions/api/employee/GetClientEmployeesApiActions";
import { getClients } from "../../redux/actions/api/client/GetClientsApiActions";
import { getClient } from "../../redux/actions/api/client/GetClientApiActions";
import getFacilities from "../../redux/actions/api/facility/GetFacilitiesApiActions";
import facilitiesClear from "../../redux/actions/api/facility/GetFacilitiesApiActions";
import getOrganization from "../../redux/actions/api/organization/GetOrganizationApiActions";
import getAllOrganization from "../../redux/actions/api/organization/GetAllOrganizationApiActions";
import getClientsByOrgId from "../../redux/actions/api/client/GetClientsByOrgIdApiActions";
import { OverlayTrigger } from "react-bootstrap";
import { WithRouter } from "../common/WithRouter";

import { Routes, Route } from "react-router-dom";
import USStates from "../common/USStates";
import getUser from "../../redux/actions/api/authentication/GetUserApiActions";

class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      id: "",
      orgId: "",

      name: "",
      type: "",
      executiveSponsorName: "",
      executiveSponsorPhone: "",
      accountsPayablePhoneNumber: "",
      contactInvoicing: "",
      contactInvoicingEmail: "",
      taxId: "",
      npi: "",
      runEligibility: "",
      runEligibilityIsChecked: ["N"],
      fullService: "",
      fullServiceIsChecked: ["N"],
      outpatientRate: "",
      outpatientRateIsChecked: [],
      inpatientRate: "",
      inpatientRateIsChecked: [],
      contactCaseManager: "",
      contactCaseRepresentative: "",
      contactCaseRepresentativePhone: "",
      status: "ACTIVE",

      subtype: "MOBILE",
      numberEmail: "",
      isPrimary: "Y",
      sms: "Y",
      statusPhoneEmail: "ACTIVE",

      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "US",
      statusAddress: "ACTIVE",

      isNew: "Y",

      //Facility
      outpatientRateFacility: "",
      outpatientRateIsCheckedFacility: [],
      inpatientRateFacility: "",
      inpatientRateIsCheckedFacility: [],
      statusFacility: "ACTIVE",
      nameFacility: "",
      executiveSponsorNameFacility: "",
      executiveSponsorPhoneFacility: "",
      accountsPayablePhoneNumberFacility: "",
      contactInvoicingFacility: "",
      contactInvoicingEmailFacility: "",
      taxIdFacility: "",
      npiFacility: "",
      runEligibilityFacility: "",
      runEligibilityFacilityIsChecked: ["N"],
      fullServiceFacility: "",
      fullServiceFacilityIsChecked: ["N"],
      contactCaseManagerFacility: "",
      contactCaseRepresentativeFacility: "",
      contactCaseRepresentativePhoneFacility: "",

      physicalAddress: "",
      physicalAddress1: "",
      physicalAddress2: "",
      physicalCity: "",
      physicalState: "",
      physicalZip: "",
      mailingAddress: "",
      mailingAddress1: "",
      mailingAddress2: "",
      mailingCity: "",
      mailingState: "",
      mailingZip: "",

      //Provider
      statusProvider: "ACTIVE",
      nameProvider: "",
      taxIdProvider: "",
      npiProvider: "",
      fax: "",
      caseManager: "",
      caseManagerEmail: "",
      concurrentReviewNurse: "",
      concurrentReviewNurseEmail: "",

      parentId: "",

      middleName: "",
      lastName: "",
      userRole: "USER",
      userId: "",
      userPassword: "",
      userPasswordEncrypted: "",
      statusEmployee: "ACTIVE",

      saveToTable: "",
      editAddressModal: false,
      editAddressModalName: "",

      hideOrganizationInfo: true,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleRunEligibility = this.handleRunEligibility.bind(this);
    this.handleFullService = this.handleFullService.bind(this);
    this.handleRunEligibilityFacility =
      this.handleRunEligibilityFacility.bind(this);
    this.handleFullServiceFacility = this.handleFullServiceFacility.bind(this);
    this.handleInpatientRate = this.handleInpatientRate.bind(this);
    this.handleOutpatientRate = this.handleOutpatientRate.bind(this);
    this.handleInpatientRateFacility =
      this.handleInpatientRateFacility.bind(this);
    this.handleOutpatientRateFacility =
      this.handleOutpatientRateFacility.bind(this);
    this.showEditAddressModal = this.showEditAddressModal.bind(this);
  }

  organizationDefault = {
    id: "",
    name: "",
    type: "",
    executiveSponsorName: "",
    executiveSponsorPhone: "",
    accountsPayablePhoneNumber: "",
    contactInvoicing: "",
    contactInvoicingEmail: "",
    taxId: "",
    npi: "",
    runEligibility: "",
    runEligibilityIsChecked: ["N"],
    fullService: "",
    fullServiceIsChecked: ["N"],
    outpatientRate: "",
    outpatientRateIsChecked: [],
    inpatientRate: "",
    inpatientRateIsChecked: [],
    contactCaseManager: "",
    contactCaseRepresentative: "",
    contactCaseRepresentativePhone: "",
    status: "ACTIVE",
  };

  phoneEmailDefault = {
    id: "",
    type: "HOME",
    subtype: "MOBILE",
    numberEmail: "",
    isPrimary: "Y",
    sms: "Y",
    statusPhoneEmail: "ACTIVE",
  };

  addressDefault = {
    id: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
    isPrimary: "Y",
    statusAddress: "ACTIVE",
  };

  facilityDefault = {
    id: "",
    outpatientRateFacility: "",
    outpatientRateIsCheckedFacility: [],
    inpatientRateFacility: "",
    inpatientRateIsCheckedFacility: [],
    contactCaseManagerFacility: "",
    contactCaseRepresentativeFacility: "",
    contactCaseRepresentativePhoneFacility: "",
    statusFacility: "ACTIVE",
    nameFacility: "",
    executiveSponsorNameFacility: "",
    executiveSponsorPhoneFacility: "",
    accountsPayablePhoneNumberFacility: "",
    contactInvoicingFacility: "",
    contactInvoicingEmailFacility: "",
    taxIdFacility: "",
    npiFacility: "",
    runEligibilityFacility: "",
    runEligibilityFacilityIsChecked: ["N"],
    fullServiceFacility: "",
    fullServiceFacilityIsChecked: ["N"],

    physicalAddress: "",
    physicalAddress1: "",
    physicalAddress2: "",
    physicalCity: "",
    physicalState: "",
    physicalZip: "",
    mailingAddress: "",
    mailingAddress1: "",
    mailingAddress2: "",
    mailingCity: "",
    mailingState: "",
    mailingZip: "",
  };

  providerDefault = {
    id: "",
    statusProvider: "ACTIVE",
    nameProvider: "",
    taxIdProvider: "",
    npiProvider: "",
    fax: "",
    caseManager: "",
    caseManagerEmail: "",
    concurrentReviewNurse: "",
    concurrentReviewNurseEmail: "",
  };

  employeeDefault = {
    id: "",
    type: "",
    firstName: "",
    middleName: "",
    lastName: "",
    userRole: "USER",
    userId: "",
    userPassword: "",
    userPasswordEncrypted: "",
    statusEmployee: "ACTIVE",
  };

  handleSelect(key) {
    console.log("selected" + key);
    this.setState({ key: key });
  }
  /*
    handleOrgId(e) {
        const state = this.state;

        var orgId = e.target.value;
        this.setState({orgId:orgId});
        this.props.getClientsByOrgId(orgId);
        this.clearClientAndList();
        console.log("this.state.orgId", orgId);
    };
    */

  submitEditAddressModal = () => {
    var address = "";
    const { editAddressModalName } = this.state;
    if (editAddressModalName == "PHYSICAL") {
      address =
        this.state.physicalAddress1 +
        ", " +
        this.state.physicalCity +
        ", " +
        this.state.physicalState +
        ", " +
        this.state.physicalZip;
      this.setState({ physicalAddress: address });
    } else {
      address =
        this.state.mailingAddress1 +
        ", " +
        this.state.mailingCity +
        ", " +
        this.state.mailingState +
        ", " +
        this.state.mailingZip;
      this.setState({ mailingAddress: address });
    }

    this.setState({ editAddressModal: false });
  };

  showEditAddressModal = (modalName) => {
    console.log("hereeeeeeeeeeee");
    this.setState({ editAddressModal: true, editAddressModalName: modalName });
    console.log(this.state.editAddressModal, "CICK");
  };

  setRunEligibility = (record) => {
    var runEligibility = "N";
    var runEligibilityIsChecked = [];
    if (record.runEligibility && record.runEligibility != null) {
      console.log("record.runEligibility", record.runEligibility);
      if (record.runEligibility == "N") {
        runEligibility = "N";
      } else {
        runEligibilityIsChecked = ["Y"];
        runEligibility = "Y";
      }
    }
    this.setState({
      runEligibility: runEligibility,
      runEligibilityIsChecked: runEligibilityIsChecked,
    });
  };

  handleRunEligibility(event) {
    var runEligibility = "N";
    var runEligibilityIsChecked = [];
    if (event.target.checked) {
      runEligibility = "Y";
      runEligibilityIsChecked = ["Y"];
    }
    this.setState({
      runEligibility: runEligibility,
      runEligibilityIsChecked: runEligibilityIsChecked,
    });
  }

  setRunEligibilityFacility = (record) => {
    var runEligibilityFacility = "N";
    var runEligibilityFacilityIsChecked = [];
    if (
      record.runEligibilityFacility &&
      record.runEligibilityFacility != null
    ) {
      if (record.runEligibilityFacility == "N") {
        runEligibilityFacility = "N";
      } else {
        runEligibilityFacilityIsChecked = ["Y"];
        runEligibilityFacility = "Y";
      }
    }
    this.setState({
      runEligibilityFacility: runEligibilityFacility,
      runEligibilityFacilityIsChecked: runEligibilityFacilityIsChecked,
    });
  };

  handleRunEligibilityFacility(event) {
    var runEligibilityFacility = "N";
    var runEligibilityFacilityIsChecked = [];
    if (event.target.checked) {
      runEligibilityFacility = "Y";
      runEligibilityFacilityIsChecked = ["Y"];
    }
    this.setState({
      runEligibilityFacility: runEligibilityFacility,
      runEligibilityFacilityIsChecked: runEligibilityFacilityIsChecked,
    });
  }

  setFullService = (record) => {
    var fullService = "N";
    var fullServiceIsChecked = [];
    if (record.fullService && record.fullService != null) {
      if (record.fullService == "N") {
        fullService = "N";
      } else {
        fullServiceIsChecked = ["Y"];
        fullService = "Y";
      }
    }
    this.setState({
      fullService: fullService,
      fullServiceIsChecked: fullServiceIsChecked,
    });
  };

  handleFullService(event) {
    var fullService = "N";
    var fullServiceIsChecked = [];
    if (event.target.checked) {
      fullService = "Y";
      fullServiceIsChecked = ["Y"];
    }
    this.setState({
      fullService: fullService,
      fullServiceIsChecked: fullServiceIsChecked,
    });
  }

  setFullServiceFacility = (record) => {
    var fullServiceFacility = "N";
    var fullServiceFacilityIsChecked = [];
    if (record.fullServiceFacility && record.fullServiceFacility != null) {
      if (record.fullServiceFacility == "N") {
        fullServiceFacility = "N";
      } else {
        fullServiceFacilityIsChecked = ["Y"];
        fullServiceFacility = "Y";
      }
    }
    this.setState({
      fullServiceFacility: fullServiceFacility,
      fullServiceFacilityIsChecked: fullServiceFacilityIsChecked,
    });
  };

  handleFullServiceFacility(event) {
    var fullServiceFacility = "N";
    var fullServiceFacilityIsChecked = [];
    if (event.target.checked) {
      fullServiceFacility = "Y";
      fullServiceFacilityIsChecked = ["Y"];
    }
    this.setState({
      fullServiceFacility: fullServiceFacility,
      fullServiceFacilityIsChecked: fullServiceFacilityIsChecked,
    });
  }

  clearOrganizationAndList = async () => {
    this.props.addUpdateOrganizationSelected({});
    this.props.patientPhoneEmailClear();
    this.props.patientAddressClear();
    this.props.facilitiesClear();
    //this.props.providerClear();
    this.props.clientEmployeesClear();
    this.setState(this.organizationDefault);
    console.log("I am here 5...");
  };

  saveOrganization = async () => {
    const { addedOrganization } = this.props;
    var organization = { ...this.state, id: addedOrganization.id };
    await this.props.addUpdateOrganization(organization);
    this.props.getAllOrganization();
  };

  savePhoneEmail = async () => {
    const { isNew } = this.state;
    const state = this.state;
    const { addedOrganization } = this.props;

    var phoneEmail = { ...this.state, parentId: addedOrganization.id };
    phoneEmail.status = state.statusPhoneEmail;
    await this.props.addUpdatePhoneEmail(phoneEmail);
    await this.props.getPatientPhoneEmail(addedOrganization.id);

    if (isNew == "Y") {
      this.setState(this.phoneEmailDefault);
    }
  };

  saveAddress = async () => {
    const { isNew } = this.state;
    const state = this.state;
    const { addedOrganization } = this.props;

    var address = { ...this.state, parentId: addedOrganization.id };
    address.status = state.statusAddress;
    await this.props.addUpdateAddress(address);
    await this.props.getPatientAddress(addedOrganization.id);
    if (isNew == "Y") {
      this.setState(this.addressDefault);
    }
  };

  saveProvider = async () => {
    const { isNew } = this.state;
    const state = this.state;
    const { addedOrganization } = this.props;

    var provider = { ...this.state, clientId: addedOrganization.id };
    provider.status = state.statusProvider;
    provider.name = state.nameProvider;
    provider.taxId = state.taxIdProvider;
    provider.npi = state.npiProvider;

    await this.props.addUpdateProvider(provider);
    await this.props.getProvider(addedOrganization.id);
    if (isNew == "Y") {
      this.setState(this.providerDefault);
    }
  };

  saveFacility = async () => {
    const { isNew } = this.state;
    const state = this.state;
    const { addedOrganization } = this.props;

    var facility = { ...this.state, clientId: addedOrganization.id };
    facility.inpatientRate = state.inpatientRateFacility;
    facility.outpatientRate = state.outpatientRateFacility;
    facility.status = state.statusFacility;
    facility.name = state.nameFacility;
    facility.executiveSponsorName = state.executiveSponsorNameFacility;
    facility.executiveSponsorPhone = state.executiveSponsorPhoneFacility;
    facility.accountsPayablePhoneNumber =
      state.accountsPayablePhoneNumberFacility;
    facility.contactInvoicing = state.contactInvoicingFacility;
    facility.contactInvoicingEmail = state.contactInvoicingEmailFacility;
    facility.taxId = state.taxIdFacility;
    facility.npi = state.npiFacility;
    facility.runEligibility = state.runEligibilityFacility;
    facility.fullService = state.fullServiceFacility;
    facility.contactCaseManager = state.contactCaseManagerFacility;
    facility.contactCaseRepresentative =
      state.contactCaseRepresentativeFacility;
    facility.contactCaseRepresentativePhone =
      state.contactCaseRepresentativePhoneFacility;

    await this.props.addUpdateFacility(facility);
    await this.props.getFacilities(addedOrganization.id);
    if (isNew == "Y") {
      this.setState(this.FacilityDefault);
    }
  };

  saveEmployee = async () => {
    const { isNew } = this.state;
    const state = this.state;
    const { addedOrganization } = this.props;

    var employee = { ...this.state, parentId: addedOrganization.id };
    var re =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
    employee.status = state.statusEmployee;
    if (employee.userPassword.match(re)) {
      await this.props.addUpdateEmployee(employee);
      await this.props.getClientEmployees(addedOrganization.id);
      if (isNew == "Y") {
        this.setState(this.employeeDefault);
      }
    } else {
      alert(
        "Password did not satisfy the rules:\n" +
          "1. Password must be between 8 to 30 characters.\n" +
          "2. Password must contain at least one uppercase or capital letter.\n" +
          "3. Password must contain at least one lowercase letter.\n" +
          "4. Password must contain at least one number.\n" +
          "5. Password must contain at least one special character ($, #, @, !, &, *, (,))."
      );
    }
  };

  onClickRow = (record) => {
    this.setInOutPatientRate(record);
    this.setRunEligibility(record);
    this.setFullService(record);
    this.setRunEligibilityFacility(record);
    this.setFullServiceFacility(record);

    this.props.addUpdateOrganizationSelected(record);
    const { addedOrganization } = this.props;

    console.log("addedOrganization", addedOrganization);

    this.props.getPatientPhoneEmail(record.id);
    this.props.getPatientAddress(record.id);
    this.props.getFacilities(record.id);
    this.props.getClientEmployees(record.id);
    //this.props.getProvider(record.id);

    this.setState(record);
  };

  ninetyDaysChangePassword = async () => {
    let userInfo = this.props.user;
    if (!userInfo.passwordChanged) {
      userInfo.passwordChanged = new Date();
      await this.props.addUpdateEmployee(userInfo);
      await this.props.getUser(userInfo.userId);
    } else {
      let passwordChanged = new Date(userInfo.passwordChanged);
      let ninetyDaysFromNow = new Date();
      ninetyDaysFromNow.setDate(ninetyDaysFromNow.getDate() - 90);
      if (passwordChanged < ninetyDaysFromNow) {
        alert("Your password has expired. Please create a new password.");
        this.props.navigate("/change-password");
      }
    }
  };

  async componentDidMount() {
    if (!this.props.user) {
      let userId = sessionStorage.getItem("userId");
      await this.props.getUser(userId);
    }

    //this.props.getClientsByOrgId(0);
    await this.ninetyDaysChangePassword();
    await this.props.getAllOrganization();
    this.clearOrganizationAndList();

    this.setState({ hideOrganizationInfo: true });
  }

  setInOutPatientRate = (record) => {
    var inpatientRate = 0;
    var inpatientRateIsChecked = [];
    if (record.inpatientRate && record.inpatientRate != null) {
      console.log("record.inpatientRate", record.inpatientRate);
      if (record.inpatientRate == 0) {
        inpatientRate = 0;
      } else {
        inpatientRateIsChecked = [1];
        inpatientRate = 1;
      }
    }

    this.setState({
      inpatientRate: inpatientRate,
      inpatientRateIsChecked: inpatientRateIsChecked,
    });

    var outpatientRate = 0;
    var outpatientRateIsChecked = [];
    if (record.outpatientRate && record.outpatientRate != null) {
      console.log("record.outpatientRate", record.outpatientRate);
      if (record.outpatientRate == 0) {
        outpatientRate = 0;
      } else {
        outpatientRateIsChecked = [1];
        outpatientRate = 1;
      }
    }

    this.setState({
      outpatientRate: outpatientRate,
      outpatientRateIsChecked: outpatientRateIsChecked,
    });
  };

  handleOutpatientRate(event) {
    var outpatientRate = 0;
    var outpatientRateIsChecked = [];
    if (event.target.checked) {
      outpatientRate = 1;
      outpatientRateIsChecked = [1];
    }
    this.setState({
      outpatientRate: outpatientRate,
      outpatientRateIsChecked: outpatientRateIsChecked,
    });

    console.log("I am here 2", outpatientRate);
    console.log("I am here 3", outpatientRateIsChecked);
  }

  handleInpatientRate(event) {
    var inpatientRate = 0;
    var inpatientRateIsChecked = [];
    if (event.target.checked) {
      inpatientRate = 1;
      inpatientRateIsChecked = [1];
    }
    this.setState({
      inpatientRate: inpatientRate,
      inpatientRateIsChecked: inpatientRateIsChecked,
    });

    console.log("I am here 2", inpatientRate);
  }

  setInOutPatientRateFacility = (record) => {
    var inpatientRateFacility = 0;
    var inpatientRateIsCheckedFacility = [];
    if (record.inpatientRateFacility && record.inpatientRateFacility != null) {
      console.log("record.inpatientRate", record.inpatientRate);
      if (record.inpatientRateFacility == 0) {
        inpatientRateFacility = 0;
      } else {
        inpatientRateIsCheckedFacility = [1];
        inpatientRateFacility = 1;
      }
    }

    this.setState({
      inpatientRateFacility: inpatientRateFacility,
      inpatientRateIsCheckedFacility: inpatientRateIsCheckedFacility,
    });

    var outpatientRateFacility = 0;
    var outpatientRateIsCheckedFacility = [];
    if (
      record.outpatientRateFacility &&
      record.outpatientRateFacility != null
    ) {
      console.log("record.outpatientRate", record.outpatientRateFacility);
      if (record.outpatientRateFacility == 0) {
        outpatientRateFacility = 0;
      } else {
        outpatientRateIsCheckedFacility = [1];
        outpatientRateFacility = 1;
      }
    }

    this.setState({
      outpatientRateFacility: outpatientRateFacility,
      outpatientRateIsCheckedFacility: outpatientRateIsCheckedFacility,
    });
  };

  handleOutpatientRateFacility(event) {
    var outpatientRateFacility = 0;
    var outpatientRateIsCheckedFacility = [];
    if (event.target.checked) {
      outpatientRateFacility = 1;
      outpatientRateIsCheckedFacility = [1];
    }
    this.setState({
      outpatientRateFacility: outpatientRateFacility,
      outpatientRateIsCheckedFacility: outpatientRateIsCheckedFacility,
    });

    console.log("I am here 2", outpatientRateFacility);
    console.log("I am here 3", outpatientRateIsCheckedFacility);
  }

  handleInpatientRateFacility(event) {
    var inpatientRateFacility = 0;
    var inpatientRateIsCheckedFacility = [];
    if (event.target.checked) {
      inpatientRateFacility = 1;
      inpatientRateIsCheckedFacility = [1];
    }
    this.setState({
      inpatientRateFacility: inpatientRateFacility,
      inpatientRateIsCheckedFacility: inpatientRateIsCheckedFacility,
    });

    console.log("I am here 2", inpatientRateFacility);
  }

  onClickHandler(record, e) {
    console.log("record", record);
    const state = this.state;

    if (record.saveToTable) {
      if (record.saveToTable == "Facility") {
        if (record.isNew == "N") {
          record.statusFacility = record.status;
          record.nameFacility = record.name;
          record.executiveSponsorNameFacility = record.executiveSponsorName;
          record.accountsPayablePhoneNumberFacility =
            record.accountsPayablePhoneNumber;
          record.contactInvoicingFacility = record.contactInvoicing;
          record.contactInvoicingEmailFacility = record.contactInvoicingEmail;
          record.taxIdFacility = record.taxId;
          record.npiFacility = record.npi;
          record.runEligibilityFacility = record.runEligibility;
          record.fullServiceFacility = record.fullService;
          record.contactCaseManagerFacility = record.contactCaseManager;
          record.contactCaseRepresentativeFacility =
            record.contactCaseRepresentative;
          record.contactCaseRepresentativePhoneFacility =
            record.contactCaseRepresentativePhone;
          record.inpatientRateFacility = record.inpatientRate;
          record.outpatientRateFacility = record.outpatientRate;
        }

        console.log("inpatientRate", state.inpatientRate);
        console.log(" inpatientRateFacility", state.inpatientRateFacility);
        record.inpatientRate = state.inpatientRate;
        record.outpatientRate = state.outpatientRate;
        record.status = state.status;
        record.name = state.name;
        record.executiveSponsorName = state.executiveSponsorName;
        record.executiveSponsorPhone = state.executiveSponsorPhone;
        record.accountsPayablePhoneNumber = state.accountsPayablePhoneNumber;
        record.contactInvoicing = state.contactInvoicing;
        record.contactInvoicingEmail = state.contactInvoicingEmail;
        record.taxId = state.taxId;
        record.npi = state.npi;
        record.runEligibility = state.runEligibility;
        record.fullService = state.fullService;
        record.contactCaseManager = state.contactCaseManager;
        record.contactCaseRepresentative = state.contactCaseRepresentative;
        record.contactCaseRepresentativePhone =
          state.contactCaseRepresentativePhone;

        this.setInOutPatientRateFacility(record);
        this.setRunEligibilityFacility(record);
        this.setFullServiceFacility(record);
      } else if (record.saveToTable == "PhoneEmail") {
        if (record.isNew == "N") {
          record.statusPhoneEmail = record.status;
        }
        record.status = state.status;
      } else if (record.saveToTable == "Address") {
        if (record.isNew == "N") {
          record.statusAddress = record.status;
        }
        record.status = state.status;
      } else if (record.saveToTable == "Provider") {
        if (record.isNew == "N") {
          record.statusProvider = record.status;
          record.nameProvider = record.name;
          record.taxIdProvider = record.taxId;
          record.npiProvider = record.npi;
        }

        record.status = state.status;
        record.name = state.name;
        record.taxId = state.taxId;
        record.npi = state.npi;
      } else if (record.saveToTable == "Employee") {
        if (record.isNew == "N") {
          record.statusEmployee = record.status;
        }
        record.status = state.status;
      }
    }
    console.log(record);
    this.setState(record);
    const elementsShow = document.querySelectorAll("tr.show");
    const hiddenElement = e.currentTarget.nextSibling;

    /*Reset all show details*/
    elementsShow.forEach((element) => {
      if (hiddenElement != element) {
        element.classList.remove("show");
      }
    });

    /*Set selected row*/
    const selected = e.currentTarget;
    const elementsSelected = document.querySelectorAll("tr.selected-table-row");

    elementsSelected.forEach((element) => {
      if (selected != element) {
        element.classList.remove("selected-table-row");
      }
    });

    selected.classList.add("selected-table-row");

    /*Reset all up arrow*/
    const elementsArrow = document.querySelectorAll(".fa-chevron-up");
    elementsArrow.forEach((element) => {
      element.classList.remove("fa-chevron-up");
      element.classList.add("fa-chevron-down");
    });

    /*set up and show details on selected row*/
    const arrowSelected = document.querySelectorAll(".selected-table-row .fa");

    if (hiddenElement.className.indexOf("collapse show") > -1) {
      hiddenElement.classList.remove("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });
    } else {
      hiddenElement.classList.add("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-down");
        element.classList.add("fa-chevron-up");
      });
    }
    //}

    //this.setState({btnClick:'N'});
  }

  addDashes = (phone) => {
    let x = phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    phone = !x[2] ? x[1] : +x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    return phone;
  };

  render() {
    var {
      addedOrganization,
      phoneEmailList,
      addressList,
      providerList,
      employeesList,
      organizationList,
      facilityList,
      organizationList,
    } = this.props;

    var { editAddressModal, hideOrganizationInfo } = this.state;

    if (!phoneEmailList) {
      phoneEmailList = [];
    }

    if (!addressList) {
      addressList = [];
    }

    if (!providerList) {
      providerList = [];
    }

    if (!employeesList) {
      employeesList = [];
    }

    if (!organizationList) {
      organizationList = [];
    }

    if (!facilityList) {
      facilityList = [];
    }

    if (!organizationList) {
      organizationList = [];
    }

    var hideList = { display: "none" };
    if (addedOrganization) {
      if (addedOrganization.id) {
        hideList = { display: "block" };
      }
    }

    var hideOrganization = { display: "none" };
    var hideOrganizationList = { display: "none" };
    if (hideOrganizationInfo == true) {
      hideOrganization = { display: "none" };
      hideOrganizationList = { display: "block" };
    } else {
      hideOrganization = { display: "block" };
      hideOrganizationList = { display: "none" };
    }

    const tooltipNote = () => (
      <Tooltip id="button-tooltip">
        <div>
          <label>
            1. Password must be between 8 to 30 characters. <br />
            2. Password must contain at least one uppercase or capital letter{" "}
            <br />
            3. Password must contain at least one lowercase letter <br />
            4. Password must contain at least one number <br />
            5. Password must contain at least one special character ($, #, @, !,
            &, *, (,)).")
          </label>
        </div>
      </Tooltip>
    );

    console.log("organizationList", organizationList);
    console.log(
      "this.state.outpatientRateIsCheckedFacilit",
      this.state.outpatientRateIsCheckedFacility
    );
    return (
      <div className="w-100">
        <div className="auth-content-header d-flex justify-content-between">
          <div className="auth-logo-cont">
            <svg
              width="118"
              height="24"
              viewBox="0 0 118 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.28 0C0.413213 0 0 8.54562 0 8.54562V23.7477C0 23.8803 0.108893 23.988 0.243042 23.988H2.55173C2.68588 23.988 2.79478 23.8803 2.79478 23.7477V13.6394C2.79478 13.5068 2.90367 13.3991 3.03782 13.3991H17.522C17.6562 13.3991 17.7651 13.5068 17.7651 13.6394V23.7477C17.7651 23.8803 17.8739 23.988 18.0081 23.988H20.3168C20.4509 23.988 20.5598 23.8803 20.5598 23.7477V8.54562C20.5598 8.54562 20.1466 0 10.28 0ZM17.7653 10.8523C17.7653 10.9849 17.6564 11.0926 17.5222 11.0926H3.03782C2.90367 11.0926 2.79478 10.9849 2.79478 10.8523V9.0262C2.79478 5.49636 5.68892 2.63499 9.2592 2.63499H11.3006C14.8709 2.63499 17.7651 5.49636 17.7651 9.0262V10.8523H17.7653Z"
                fill="#2DC0D0"
              />
              <path
                d="M37.6368 5.4519V15.287C37.6368 18.7372 34.8078 21.5341 31.3181 21.5341C27.8283 21.5341 24.9994 18.7372 24.9994 15.287V5.4519H22.3423V15.0796C22.3423 19.9806 26.3608 23.9536 31.3179 23.9536C36.275 23.9536 40.2934 19.9806 40.2934 15.0796V5.4519H37.6363H37.6368Z"
                fill="#2DC0D0"
              />
              <path
                d="M49.3089 21.4402C46.6133 21.4402 44.4282 19.2798 44.4282 16.6148V8.38945C44.4282 8.27217 44.5242 8.1772 44.6429 8.1772H50.4349C50.5535 8.1772 50.6496 8.08223 50.6496 7.96495V5.95463C50.6496 5.83735 50.5535 5.74238 50.4349 5.74238H44.6429C44.5242 5.74238 44.4282 5.64741 44.4282 5.53013V0.212249C44.4282 0.0949695 44.3321 0 44.2135 0H42.245C42.1263 0 42.0303 0.0949695 42.0303 0.212249V17.6719C42.0303 20.9915 44.7522 23.6826 48.1098 23.6826H52.379C52.4976 23.6826 52.5937 23.5876 52.5937 23.4704V21.6522C52.5937 21.535 52.4976 21.44 52.379 21.44H49.3089V21.4402Z"
                fill="#2DC0D0"
              />
              <path
                d="M63.3938 5.48613C60.9294 5.48613 58.6971 6.46816 57.0751 8.05767V0H54.418V23.988H57.0751V14.1529C57.0751 10.7027 59.904 7.9058 63.3938 7.9058C66.8835 7.9058 69.7124 10.7027 69.7124 14.1529V23.988H72.3695V14.3602C72.3695 9.45929 68.3511 5.48633 63.394 5.48633L63.3938 5.48613Z"
                fill="#2DC0D0"
              />
              <path
                d="M83.7622 4.90674C78.4294 4.90674 74.106 9.18098 74.106 14.4534C74.106 19.7259 78.4292 24.0001 83.7622 24.0001C89.0953 24.0001 93.4183 19.7259 93.4183 14.4534C93.4183 9.18098 89.0951 4.90674 83.7622 4.90674ZM83.7622 21.5503C79.7978 21.5503 76.584 18.373 76.584 14.4534C76.584 10.5339 79.7978 7.3565 83.7622 7.3565C87.7267 7.3565 90.9404 10.5339 90.9404 14.4534C90.9404 18.373 87.7267 21.5503 83.7622 21.5503Z"
                fill="#2DC0D0"
              />
              <path
                d="M112.778 3.39188V0.212249C112.778 0.0949695 112.682 0 112.563 0H95.2317V23.7757C95.2317 23.893 95.3277 23.988 95.4464 23.988H98.6624C98.781 23.988 98.8771 23.893 98.8771 23.7757V12.8506C98.8771 12.7333 98.9732 12.6383 99.0918 12.6383H109.89C110.009 12.6383 110.105 12.5433 110.105 12.4261V9.24643C110.105 9.12915 110.009 9.03418 109.89 9.03418H99.0918C98.9732 9.03418 98.8771 8.93921 98.8771 8.82193V3.81638C98.8771 3.6991 98.9732 3.60413 99.0918 3.60413H112.563C112.682 3.60413 112.778 3.50916 112.778 3.39188Z"
                fill="#22449C"
              />
              <path
                d="M117.805 5.99707H114.783C114.675 5.99707 114.588 6.08348 114.588 6.19008V9.88059C114.588 9.98718 114.675 10.0736 114.783 10.0736H117.805C117.913 10.0736 118 9.98718 118 9.88059V6.19008C118 6.08348 117.913 5.99707 117.805 5.99707Z"
                fill="#22449C"
              />
              <path
                d="M117.805 11.6594H114.783C114.675 11.6594 114.588 11.7458 114.588 11.8524V23.7852C114.588 23.8918 114.675 23.9782 114.783 23.9782H117.805C117.913 23.9782 118 23.8918 118 23.7852V11.8524C118 11.7458 117.913 11.6594 117.805 11.6594Z"
                fill="#22449C"
              />
            </svg>
          </div>
          <div className="d-flex justify-content-end">
            <Search />
            <RightPanel />
          </div>
        </div>
        <Modal show={editAddressModal} size="lg" centered>
          <Modal.Body className="p-5">
            <div class="form-title-label">
              <label className="border-bottom">Edit Address:</label>
            </div>
            <Form className="mt-4">
              <Row>
                <div class="col-lg-12 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Address 1
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      value={
                        this.state.editAddressModalName == "PHYSICAL"
                          ? this.state.physicalAddress1
                          : this.state.mailingAddress1
                      }
                      onChange={(e) =>
                        this.setState(
                          this.state.editAddressModalName == "PHYSICAL"
                            ? { physicalAddress1: e.target.value }
                            : { mailingAddress1: e.target.value }
                        )
                      }
                    />
                  </Form.Group>
                </div>
              </Row>
              <Row>
                <div class="col-lg-12 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Address 2
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      value={
                        this.state.editAddressModalName == "PHYSICAL"
                          ? this.state.physicalAddress2
                          : this.state.mailingAddress2
                      }
                      onChange={(e) =>
                        this.setState(
                          this.state.editAddressModalName == "PHYSICAL"
                            ? { physicalAddress2: e.target.value }
                            : { mailingAddress2: e.target.value }
                        )
                      }
                    />
                  </Form.Group>
                </div>
              </Row>
              <Row>
                <div class="col-lg-5 col-6 mt-1">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      City
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      value={
                        this.state.editAddressModalName == "PHYSICAL"
                          ? this.state.physicalCity
                          : this.state.mailingCity
                      }
                      onChange={(e) =>
                        this.setState(
                          this.state.editAddressModalName == "PHYSICAL"
                            ? { physicalCity: e.target.value }
                            : { mailingCity: e.target.value }
                        )
                      }
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-5 col-6 mt-1">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      State
                    </Form.Label>
                    <Form.Select
                      className="grid-form-control"
                      value={
                        this.state.editAddressModalName == "PHYSICAL"
                          ? this.state.physicalState
                          : this.state.mailingState
                      }
                      onChange={(e) =>
                        this.setState(
                          this.state.editAddressModalName == "PHYSICAL"
                            ? { physicalState: e.target.value }
                            : { mailingState: e.target.value }
                        )
                      }
                    >
                      <option selected disabled value="">
                        {" "}
                        -- Select State --{" "}
                      </option>
                      {USStates.map((USState, key) => {
                        return (
                          <option key={key} value={USState.abbreviation}>
                            {USState.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6 mt-1">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Zip
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      value={
                        this.state.editAddressModalName == "PHYSICAL"
                          ? this.state.physicalZip
                          : this.state.mailingZip
                      }
                      onChange={(e) =>
                        this.setState(
                          this.state.editAddressModalName == "PHYSICAL"
                            ? { physicalZip: e.target.value }
                            : { mailingZip: e.target.value }
                        )
                      }
                    />
                  </Form.Group>
                </div>
              </Row>
              <Row className="mt-4">
                <div className="col-6 p-0">
                  <Button
                    className="btn btn-success grid-btn submit-modal"
                    type="button"
                    onClick={this.submitEditAddressModal.bind(this)}
                  >
                    SUBMIT
                  </Button>
                </div>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
        <div className="auth-content-main d-flex align-items-center justify-content-center">
          <div className="auth-content-content">
            <div class="row">
              <div className="col-md-8">
                <label className="patient-title">ORGANIZATIONS</label>
              </div>
              <div className="col-md-4">
                <Button
                  className="add-patients-btn btn-primary mb-1 float-end m-0"
                  variant="primary"
                  type="button"
                  onClick={() => {
                    this.clearOrganizationAndList();
                    this.setState({ hideOrganizationInfo: false });
                  }}
                >
                  <img
                    src={require("../../images/add-icon.png")}
                    className="add-icon"
                  />
                  Add New Organization
                </Button>
                <Button
                  style={hideOrganization}
                  className="btn back-btn mb-1 float-end mr-1"
                  type="button"
                  onClick={() => {
                    this.clearOrganizationAndList();
                    this.setState({ hideOrganizationInfo: true });
                  }}
                >
                  <img
                    src={require("../../images/back-icon.png")}
                    className="add-icon"
                  />
                  Back
                </Button>
              </div>
            </div>
            <div style={hideOrganization} className="main-panel">
              <Row className="col-lg-12 mb-1">
                <div class="col-lg-2 col-6 mt-2">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Name
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={40}
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6 mt-2">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Executive Sponsor Name
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={40}
                      value={this.state.executiveSponsorName}
                      onChange={(e) =>
                        this.setState({ executiveSponsorName: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6 mt-2">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Executive Sponsor Phone
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={15}
                      value={this.state.executiveSponsorPhone}
                      onChange={(e) => {
                        let phone = this.addDashes(e.target.value);
                        this.setState({ executiveSponsorPhone: phone });
                      }}
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6 mt-2">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Accounts Payable Phone
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={15}
                      value={this.state.accountsPayablePhoneNumber}
                      onChange={(e) => {
                        let phone = this.addDashes(e.target.value);
                        this.setState({ accountsPayablePhoneNumber: phone });
                      }}
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6 mt-2">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Invoicing
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={40}
                      value={this.state.contactInvoicing}
                      onChange={(e) =>
                        this.setState({ contactInvoicing: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6 mt-2">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Invoicing Email
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={40}
                      value={this.state.contactInvoicingEmail}
                      onChange={(e) =>
                        this.setState({ contactInvoicingEmail: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
              </Row>
              <Row className="col-lg-12 mb-1">
                <div class="col-lg-2 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Tax Id
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={40}
                      value={this.state.taxId}
                      onChange={(e) => this.setState({ taxId: e.target.value })}
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Npi
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={40}
                      value={this.state.npi}
                      onChange={(e) => this.setState({ npi: e.target.value })}
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-2 mt-1 text-center">
                  <Form.Label className="table-form-label mb-0">
                    Out Patient Rate
                  </Form.Label>
                  <Form.Group
                    className="client-rate-form"
                    as={Col}
                    controlId=""
                  >
                    <Form.Control
                      className="client-rate-control"
                      value={this.state.outpatientRate}
                      onChange={(e) =>
                        this.setState({ outpatientRate: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-2 mt-1 text-center">
                  <Form.Label className="table-form-label mb-0">
                    In Patient Rate
                  </Form.Label>
                  <Form.Group
                    className="client-rate-form"
                    as={Col}
                    controlId=""
                  >
                    <Form.Control
                      className="client-rate-control"
                      value={this.state.inpatientRate}
                      onChange={(e) =>
                        this.setState({ inpatientRate: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-4 col-6 mt-4 d-flex">
                  <Form.Check
                    className="grid-form-control org-checkbox mt-1"
                    label="Run Eligibility"
                    onChange={this.handleRunEligibility}
                    checked={this.state.runEligibilityIsChecked.includes("Y")}
                  />
                  <Form.Check
                    className="grid-form-control org-checkbox mt-1"
                    label="Full Service"
                    onChange={this.handleFullService}
                    checked={this.state.fullServiceIsChecked.includes("Y")}
                  />
                </div>
              </Row>
              <Row className="col-lg-12 mb-1">
                <div class="col-lg-2 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Contact Case Manager
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={40}
                      value={this.state.contactCaseManager}
                      onChange={(e) =>
                        this.setState({ contactCaseManager: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Contact Case Representative
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={40}
                      value={this.state.contactCaseRepresentative}
                      onChange={(e) =>
                        this.setState({
                          contactCaseRepresentative: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Contact Case Rep. Phone
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={15}
                      value={this.state.contactCaseRepresentativePhone}
                      onChange={(e) => {
                        let phone = this.addDashes(e.target.value);
                        this.setState({
                          contactCaseRepresentativePhone: phone,
                        });
                      }}
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Status
                    </Form.Label>
                    <Form.Select
                      className="grid-form-control"
                      value={this.state.status}
                      onChange={(e) =>
                        this.setState({ status: e.target.value })
                      }
                    >
                      <option value="ACTIVE">ACTIVE</option>
                      <option value="NOT ACTIVE">NOT ACTIVE</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div class="col-lg-4 col-6">
                  <Button
                    className="form-save-btn float-end mt-4"
                    variant="primary"
                    type="button"
                    onClick={() => {
                      this.saveOrganization();
                    }}
                  >
                    <img
                      src={require("../../images/save-btn-icon.png")}
                      className="add-icon"
                    />
                    Save
                  </Button>
                </div>
              </Row>
            </div>
            <div style={hideList}>
              <div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <label className="form-main-title">Phone and Email</label>
                    </div>
                    <div>
                      <Table
                        bordered
                        className="table-portal table table-bordered"
                      >
                        <thead>
                          <tr>
                            <th className="td-first"></th>
                            <th>Type</th>
                            <th>Number/ Email</th>
                            <th className="td-last">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {phoneEmailList.map((record, key) => {
                            return (
                              <>
                                <tr
                                  onClick={(event) =>
                                    this.onClickHandler(
                                      {
                                        ...record,
                                        isNew: "N",
                                        saveToTable: "PhoneEmail",
                                      },
                                      event
                                    )
                                  }
                                >
                                  <td className="td-first">
                                    <i className="fa fa-chevron-down"></i>
                                  </td>
                                  <td>{record.type}</td>
                                  <td>{record.numberEmail}</td>
                                  <td className="td-last">{record.status}</td>
                                </tr>
                                <tr className="sub-table collapse">
                                  <td className="td-first td-last" colSpan="4">
                                    <Form>
                                      <Row className="mb-1">
                                        <div class="col-lg-3 col-6">
                                          <Form.Group
                                            as={Col}
                                            controlId="patientFullName"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              Type
                                            </Form.Label>
                                            <Form.Select
                                              className="grid-form-control"
                                              value={this.state.type}
                                              onChange={(e) =>
                                                this.setState({
                                                  type: e.target.value,
                                                })
                                              }
                                            >
                                              <option value="HOME">HOME</option>
                                              <option value="WORK">WORK</option>
                                              <option value="MOBILE">
                                                MOBILE
                                              </option>
                                              <option value="LANDLINE">
                                                LANDLINE
                                              </option>
                                              <option value="EMAIL">
                                                EMAIL
                                              </option>
                                            </Form.Select>
                                          </Form.Group>
                                        </div>
                                        <div class="col-lg-6 col-6">
                                          <Form.Group
                                            as={Col}
                                            controlId="formGridPassword"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              Number/ Email
                                            </Form.Label>
                                            <Form.Control
                                              className="grid-form-control"
                                              maxLength={40}
                                              value={this.state.numberEmail}
                                              onChange={(e) =>
                                                this.setState({
                                                  numberEmail: e.target.value,
                                                })
                                              }
                                            />
                                          </Form.Group>
                                        </div>
                                        <div class="col-lg-3 col-6">
                                          <Form.Group
                                            as={Col}
                                            controlId="patientFullName"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              Status
                                            </Form.Label>
                                            <Form.Select
                                              className="grid-form-control"
                                              value={
                                                this.state.statusPhoneEmail
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  statusPhoneEmail:
                                                    e.target.value,
                                                })
                                              }
                                            >
                                              <option value="ACTIVE">
                                                ACTIVE
                                              </option>
                                              <option value="INACTIVE">
                                                INACTIVE
                                              </option>
                                            </Form.Select>
                                          </Form.Group>
                                        </div>
                                        <div class="col-lg-3 col-6 mt-2">
                                          <Form.Group
                                            as={Col}
                                            controlId="patientFullName"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              Primary
                                            </Form.Label>
                                            <Form.Select
                                              className="grid-form-control"
                                              value={this.state.isPrimary}
                                              onChange={(e) =>
                                                this.setState({
                                                  isPrimary: e.target.value,
                                                })
                                              }
                                            >
                                              <option value="Y">Yes</option>
                                              <option value="N">No</option>
                                            </Form.Select>
                                          </Form.Group>
                                        </div>
                                        <div class="col-lg-3 col-6 mt-2">
                                          <Form.Group
                                            as={Col}
                                            controlId="patientFullName"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              SMS
                                            </Form.Label>
                                            <Form.Select
                                              className="grid-form-control"
                                              value={this.state.sms}
                                              onChange={(e) =>
                                                this.setState({
                                                  sms: e.target.value,
                                                })
                                              }
                                            >
                                              <option value="Y">Yes</option>
                                              <option value="N">No</option>
                                            </Form.Select>
                                          </Form.Group>
                                        </div>
                                      </Row>
                                      <Row>
                                        <div>
                                          <Button
                                            className="form-save-btn btn-primary mt-1 float-end"
                                            type="button"
                                            onClick={() => {
                                              this.savePhoneEmail();
                                            }}
                                          >
                                            <img
                                              src={require("../../images/save-btn-icon.png")}
                                              className="add-icon"
                                            />
                                            Save
                                          </Button>
                                        </div>
                                      </Row>
                                    </Form>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                          <tr
                            onClick={(event) =>
                              this.onClickHandler(
                                {
                                  ...this.phoneEmailDefault,
                                  isNew: "Y",
                                  saveToTable: "PhoneEmail",
                                },
                                event
                              )
                            }
                          >
                            <td className="td-first">
                              <i className="fa fa-chevron-down"></i>
                            </td>
                            <td></td>
                            <td></td>
                            <td className="td-last"></td>
                          </tr>
                          <tr className="sub-table collapse">
                            <td className="td-first td-last" colSpan="4">
                              <Form>
                                <Row className="mb-1">
                                  <div class="col-lg-3 col-6">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Type
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.type}
                                        onChange={(e) =>
                                          this.setState({
                                            type: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="HOME">HOME</option>
                                        <option value="WORK">WORK</option>
                                        <option value="MOBILE">MOBILE</option>
                                        <option value="LANDLINE">
                                          LANDLINE
                                        </option>
                                        <option value="EMAIL">EMAIL</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-6 col-6">
                                    <Form.Group
                                      as={Col}
                                      controlId="formGridPassword"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Number/ Email
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        maxLength={40}
                                        value={this.state.numberEmail}
                                        onChange={(e) =>
                                          this.setState({
                                            numberEmail: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Status
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.statusPhoneEmail}
                                        onChange={(e) =>
                                          this.setState({
                                            statusPhoneEmail: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="ACTIVE">ACTIVE</option>
                                        <option value="INACTIVE">
                                          INACTIVE
                                        </option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-2">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Primary
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.isPrimary}
                                        onChange={(e) =>
                                          this.setState({
                                            isPrimary: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-2">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        SMS
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.sms}
                                        onChange={(e) =>
                                          this.setState({ sms: e.target.value })
                                        }
                                      >
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row>
                                  <div>
                                    <Button
                                      className="form-save-btn btn-primary mt-1 float-end"
                                      type="button"
                                      onClick={() => {
                                        this.savePhoneEmail();
                                      }}
                                    >
                                      <img
                                        src={require("../../images/save-btn-icon.png")}
                                        className="add-icon"
                                      />
                                      Save
                                    </Button>
                                  </div>
                                </Row>
                              </Form>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <label className="form-main-title">Address</label>
                    </div>
                    <div>
                      <Table
                        bordered
                        className="table-portal table table-bordered"
                      >
                        <thead>
                          <tr>
                            <th className="td-first"></th>
                            <th>Type</th>
                            <th>Address</th>
                            <th>City</th>
                            <th className="td-last">State</th>
                          </tr>
                        </thead>
                        <tbody>
                          {addressList.map((record, key) => {
                            return (
                              <>
                                <tr
                                  onClick={(event) =>
                                    this.onClickHandler(
                                      {
                                        ...record,
                                        isNew: "N",
                                        saveToTable: "Address",
                                      },
                                      event
                                    )
                                  }
                                >
                                  <td className="td-first">
                                    <i className="fa fa-chevron-down"></i>
                                  </td>
                                  <td>{record.type}</td>
                                  <td>{record.address1}</td>
                                  <td>{record.city}</td>
                                  <td className="td-last">{record.state}</td>
                                </tr>
                                <tr className="sub-table collapse">
                                  <td className="td-first td-last" colSpan="6">
                                    <Form>
                                      <Row className="mb-1">
                                        <div class="col-lg-3 col-6">
                                          <Form.Group
                                            as={Col}
                                            controlId="patientFullName"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              TYPE
                                            </Form.Label>
                                            <Form.Select
                                              className="grid-form-control"
                                              value={this.state.type}
                                              onChange={(e) =>
                                                this.setState({
                                                  type: e.target.value,
                                                })
                                              }
                                            >
                                              <option value="HOME">HOME</option>
                                              <option value="WORK">WORK</option>
                                            </Form.Select>
                                          </Form.Group>
                                        </div>
                                      </Row>
                                      <Row className="mb-1">
                                        <div class="col-lg-6 col-6">
                                          <Form.Group as={Col} controlId="">
                                            <Form.Label className="table-form-label mb-0">
                                              Address 1
                                            </Form.Label>
                                            <Form.Control
                                              className="grid-form-control"
                                              maxLength={40}
                                              value={this.state.address1}
                                              onChange={(e) =>
                                                this.setState({
                                                  address1: e.target.value,
                                                })
                                              }
                                            />
                                          </Form.Group>
                                        </div>
                                        <div class="col-lg-6 col-6">
                                          <Form.Group as={Col} controlId="">
                                            <Form.Label className="table-form-label mb-0">
                                              Address 2
                                            </Form.Label>
                                            <Form.Control
                                              className="grid-form-control"
                                              maxLength={40}
                                              value={this.state.address2}
                                              onChange={(e) =>
                                                this.setState({
                                                  address2: e.target.value,
                                                })
                                              }
                                            />
                                          </Form.Group>
                                        </div>
                                      </Row>
                                      <Row className="mb-1">
                                        <div class="col-lg-3 col-6 mt-1">
                                          <Form.Group
                                            as={Col}
                                            controlId="patientFullName"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              City
                                            </Form.Label>
                                            <Form.Control
                                              className="grid-form-control"
                                              maxLength={40}
                                              value={this.state.city}
                                              onChange={(e) =>
                                                this.setState({
                                                  city: e.target.value,
                                                })
                                              }
                                            />
                                          </Form.Group>
                                        </div>
                                        <div class="col-lg-3 col-6 mt-1">
                                          <Form.Group
                                            as={Col}
                                            controlId="patientFullName"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              State
                                            </Form.Label>
                                            <Form.Select
                                              className="grid-form-control"
                                              value={this.state.state}
                                              onChange={(e) =>
                                                this.setState({
                                                  state: e.target.value,
                                                })
                                              }
                                            >
                                              <option
                                                selected
                                                disabled
                                                value=""
                                              >
                                                {" "}
                                                -- Select State --{" "}
                                              </option>
                                              {USStates.map((USState, key) => {
                                                return (
                                                  <option
                                                    key={key}
                                                    value={USState.abbreviation}
                                                  >
                                                    {USState.name}
                                                  </option>
                                                );
                                              })}
                                            </Form.Select>
                                          </Form.Group>
                                        </div>
                                        <div class="col-lg-3 col-6 mt-1">
                                          <Form.Group as={Col} controlId="">
                                            <Form.Label className="table-form-label mb-0">
                                              Country
                                            </Form.Label>
                                            <Form.Select
                                              className="grid-form-control"
                                              value={this.state.country}
                                              onChange={(e) =>
                                                this.setState({
                                                  country: e.target.value,
                                                })
                                              }
                                            >
                                              <option value="US">US</option>
                                            </Form.Select>
                                          </Form.Group>
                                        </div>
                                        <div class="col-lg-3 col-6 mt-1">
                                          <Form.Group as={Col} controlId="">
                                            <Form.Label className="table-form-label mb-0">
                                              Zip
                                            </Form.Label>
                                            <Form.Control
                                              className="grid-form-control"
                                              maxLength={10}
                                              value={this.state.zip}
                                              onChange={(e) =>
                                                this.setState({
                                                  zip: e.target.value,
                                                })
                                              }
                                            />
                                          </Form.Group>
                                        </div>
                                      </Row>
                                      <Row className="mb-1">
                                        <div class="col-lg-3 col-6 mt-1">
                                          <Form.Group
                                            as={Col}
                                            controlId="patientFullName"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              Status
                                            </Form.Label>
                                            <Form.Select
                                              className="grid-form-control"
                                              value={this.state.statusAddress}
                                              onChange={(e) =>
                                                this.setState({
                                                  statusAddress: e.target.value,
                                                })
                                              }
                                            >
                                              <option value="ACTIVE">
                                                ACTIVE
                                              </option>
                                              <option value="INACTIVE">
                                                INACTIVE
                                              </option>
                                            </Form.Select>
                                          </Form.Group>
                                        </div>
                                        <div class="col-lg-3 col-6 mt-1">
                                          <Form.Group
                                            as={Col}
                                            controlId="patientFullName"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              Primary
                                            </Form.Label>
                                            <Form.Select
                                              className="grid-form-control"
                                              value={this.state.isPrimary}
                                              onChange={(e) =>
                                                this.setState({
                                                  isPrimary: e.target.value,
                                                })
                                              }
                                            >
                                              <option value="Y">Yes</option>
                                              <option value="N">No</option>
                                            </Form.Select>
                                          </Form.Group>
                                        </div>
                                      </Row>
                                      <Row>
                                        <div>
                                          <Button
                                            className="form-save-btn btn-primary mt-1 flaot-end"
                                            type="button"
                                            onClick={() => {
                                              this.saveAddress();
                                            }}
                                          >
                                            <img
                                              src={require("../../images/save-btn-icon.png")}
                                              className="add-icon"
                                            />
                                            Save
                                          </Button>
                                        </div>
                                      </Row>
                                    </Form>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                          <tr
                            onClick={(event) =>
                              this.onClickHandler(
                                {
                                  ...this.addressDefault,
                                  isNew: "Y",
                                  saveToTable: "Address",
                                },
                                event
                              )
                            }
                          >
                            <td className="td-first">
                              <i className="fa fa-chevron-down"></i>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="td-last"></td>
                          </tr>
                          <tr className="sub-table collapse">
                            <td className="td-first td-last" colSpan="6">
                              <Form>
                                <Row className="mb-1">
                                  <div class="col-lg-3 col-6">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        TYPE
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.type}
                                        onChange={(e) =>
                                          this.setState({
                                            type: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="HOME">HOME</option>
                                        <option value="WORK">WORK</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row className="mb-1">
                                  <div class="col-lg-6 col-6">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Address 1
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        maxLength={40}
                                        value={this.state.address1}
                                        onChange={(e) =>
                                          this.setState({
                                            address1: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-6 col-6">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Address 2
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        maxLength={40}
                                        value={this.state.address2}
                                        onChange={(e) =>
                                          this.setState({
                                            address2: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row className="mb-1">
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        City
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        maxLength={40}
                                        value={this.state.city}
                                        onChange={(e) =>
                                          this.setState({
                                            city: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        State
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.state}
                                        onChange={(e) =>
                                          this.setState({
                                            state: e.target.value,
                                          })
                                        }
                                      >
                                        <option selected disabled value="">
                                          {" "}
                                          -- Select State --{" "}
                                        </option>
                                        {USStates.map((USState, key) => {
                                          return (
                                            <option
                                              key={key}
                                              value={USState.abbreviation}
                                            >
                                              {USState.name}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Country
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.country}
                                        onChange={(e) =>
                                          this.setState({
                                            country: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="US">US</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Zip
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        maxLength={10}
                                        value={this.state.zip}
                                        onChange={(e) =>
                                          this.setState({ zip: e.target.value })
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row className="mb-1">
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Status
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.statusAddress}
                                        onChange={(e) =>
                                          this.setState({
                                            statusAddress: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="ACTIVE">ACTIVE</option>
                                        <option value="INACTIVE">
                                          INACTIVE
                                        </option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Primary
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.isPrimary}
                                        onChange={(e) =>
                                          this.setState({
                                            isPrimary: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row>
                                  <div>
                                    <Button
                                      className="form-save-btn btn-primary mt-1 flaot-end"
                                      type="button"
                                      onClick={() => {
                                        this.saveAddress();
                                      }}
                                    >
                                      <img
                                        src={require("../../images/save-btn-icon.png")}
                                        className="add-icon"
                                      />
                                      Save
                                    </Button>
                                  </div>
                                </Row>
                              </Form>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={hideOrganizationList} className="form-main-title mt-2">
              <label>Organizations List</label>
            </div>
            <div style={hideOrganizationList} className="mb-2">
              <Table
                bordered
                hover
                className="table-portal table table-bordered"
              >
                <thead>
                  <tr>
                    <th className="td-first-none-collapse">Name</th>
                    <th>Executive Sponsor Name</th>
                    <th>Executive Sponsor Phone</th>
                    <th className="td-last">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {organizationList.map((record, key) => {
                    return (
                      <>
                        <tr
                          key={key}
                          className={key % 2 == 1 ? "even-row" : ""}
                          onClick={() => {
                            this.onClickRow(record);
                            this.setState({ hideOrganizationInfo: false });
                          }}
                        >
                          <td className="td-right-border">{record.name}</td>
                          <td>{record.executiveSponsorName}</td>
                          <td>{record.executiveSponsorPhone}</td>
                          <td className="td-last">{record.status}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUpdateOrganization: bindActionCreators(addUpdateOrganization, dispatch),
    addUpdateOrganizationSelected: bindActionCreators(
      addUpdateOrganizationSelected,
      dispatch
    ),
    addUpdatePhoneEmail: bindActionCreators(addUpdatePhoneEmail, dispatch),
    addUpdateAddress: bindActionCreators(addUpdateAddress, dispatch),
    addUpdateProvider: bindActionCreators(addUpdateProvider, dispatch),
    addUpdateEmployee: bindActionCreators(addUpdateEmployee, dispatch),
    addUpdateFacility: bindActionCreators(addUpdateFacility, dispatch),
    getPatientPhoneEmail: bindActionCreators(getPatientPhoneEmail, dispatch),
    getPatientAddress: bindActionCreators(getPatientAddress, dispatch),
    getProvider: bindActionCreators(getProvider, dispatch),
    getClientEmployees: bindActionCreators(getClientEmployees, dispatch),
    getAllOrganization: bindActionCreators(getAllOrganization, dispatch),
    getClient: bindActionCreators(getClient, dispatch),
    getFacilities: bindActionCreators(getFacilities, dispatch),
    getOrganization: bindActionCreators(getOrganization, dispatch),
    getClientsByOrgId: bindActionCreators(getClientsByOrgId, dispatch),
    patientAddressClear: bindActionCreators(patientAddressClear, dispatch),
    patientPhoneEmailClear: bindActionCreators(
      patientPhoneEmailClear,
      dispatch
    ),
    facilitiesClear: bindActionCreators(facilitiesClear, dispatch),
    providerClear: bindActionCreators(providerClear, dispatch),
    clientEmployeesClear: bindActionCreators(providerClear, dispatch),
    getUser: bindActionCreators(getUser, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    addedOrganization: state.addUpdateOrganization.data,
    addedPhoneEmail: state.addUpdatePhoneEmail.data,
    phoneEmailList: state.getPatientPhoneEmail.data,
    addressList: state.getPatientAddress.data,
    providerList: state.getProvider.data,
    employeesList: state.getClientEmployees.data,
    organizationList: state.getAllOrganization.data,
    facilityList: state.getFacilities.data,
    user: state.currentUser.data,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter(Organization));
