import React, { useState, Component } from "react";
import { Table, Tab, Tabs, Modal } from "react-bootstrap";
import "../../App.css";
import "./Encounter.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import InputGroup from "react-bootstrap/InputGroup";
import { bindActionCreators } from "redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import LeftPanel from "../common/leftPanel/LeftPanel";
import EncounterInfo from "./encounterInfo/EncounterInfo";
import RightPanel from "../common/leftPanel/RightPanel";
import LeftPanelMobile from "../common/leftPanel/LeftPanelMobile";
import PatientInfo from "./common/PatientInfo";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedTextInput from "react-text-mask";

import { Routes, Route } from "react-router-dom";

import { validateIcdCpt } from "../../redux/actions/api/authorization/ValidateIcdCptApiActions";

import {
  addUpdateEncounter,
  addUpdateEncounterSelected,
} from "../../redux/actions/api/encounter/AddUpdateEncounterApiActions";
import {
  addUpdateAuthorization,
  addUpdateAuthorizationSelected,
} from "../../redux/actions/api/authorization/AddUpdateAuthorizationApiActions";
import {
  addUpdateAuthorizationEligibility,
  getAuthorizationEligibility,
  addUpdateAuthorizationEligibilitySelected,
} from "../../redux/actions/api/authorization/AddUpdateAuthorizationEligibilityApiActions";
import {
  addUpdateAuthorization278,
  getAuthorization278,
  addUpdateAuthorization278Selected,
} from "../../redux/actions/api/authorization/AddUpdateAuthorization278ApiActions";
import {
  addUpdateAuthorizationDetail,
  addUpdateAuthorizationDetailSelected,
} from "../../redux/actions/api/authorization/AddUpdateAuthorizationDetailApiActions";
import { addUpdateAuthorizationProvider } from "../../redux/actions/api/authorization/AddUpdateAuthorizationProviderApiActions";
import { addUpdateAuthorizationNote } from "../../redux/actions/api/authorization/AddUpdateAuthorizationNoteApiActions";
import { addUpdateAuthorizationDiagnosis } from "../../redux/actions/api/authorization/AddUpdateAuthorizationDiagnosisApiActions";
import { addUpdateAuthorizationProcedure } from "../../redux/actions/api/authorization/AddUpdateAuthorizationProcedureApiActions";

import {
  addUpdateAuthorizationRequestingProvider,
  addUpdateAuthorizationRequestingProviderSelected,
} from "../../redux/actions/api/authorization/AddUpdateAuthorizationRequestingProviderApiActions";

import {
  getAuthorizationById,
  authorizationByIdSelected,
} from "../../redux/actions/api/authorization/GetAuthorizationByIdApiActions";

import { getCptDescription } from "../../redux/actions/api/authorization/GetCptDescriptionApiActions";

import {
  getAuthorizationDetail,
  getAuthorizationDetailSelected,
} from "../../redux/actions/api/authorization/GetAuthorizationDetailApiActions";
import {
  getAuthorizationProvider,
  getAuthorizationProviderSelected,
} from "../../redux/actions/api/authorization/GetAuthorizationProviderApiActions";
import {
  getAuthorizationNote,
  getAuthorizationNoteSelected,
} from "../../redux/actions/api/authorization/GetAuthorizationNoteApiActions";
import {
  getAuthorizationDiagnosis,
  getAuthorizationDiagnosisSelected,
} from "../../redux/actions/api/authorization/GetAuthorizationDiagnosisApiActions";
import {
  getAuthorizationProcedure,
  getAuthorizationProcedureSelected,
} from "../../redux/actions/api/authorization/GetAuthorizationProcedureApiActions";
import {
  getAuthorizationHistory,
  getAuthorizationHistorySelected,
} from "../../redux/actions/api/authorization/GetAuthorizationHistoryApiActions";

import { getAuthorizationDuplicates } from "../../redux/actions/api/authorization/GetAuthorizationDuplicatesApiActions";

import getPayersFindAll from "../../redux/actions/api/payer/GetPayersApiActions";

import { getSystemsCode } from "../../redux/actions/api/systemsCode/GetSystemsCodeApiActions";

import getFacilities from "../../redux/actions/api/facility/GetFacilitiesApiActions";
import { addUpdateFacility } from "../../redux/actions/api/facility/AddUpdateFacilityApiActions";
import { addUpdateProvider } from "../../redux/actions/api/provider/AddUpdateProviderApiActions";

import {
  getProvider,
  providerClear,
} from "../../redux/actions/api/provider/GetProviderApiActions";

import { getDocumentationUploadFileUriPath } from "../../redux/actions/api/EndpointResolver";
import {
  getPatientDocumentation,
  patientDocumentationClear,
} from "../../redux/actions/api/documentation/GetPatientDocumentationApiActions";
import {
  getAuthorizationDocumentation,
  authorizationDocumentationClear,
} from "../../redux/actions/api/authorizationDocumentation/GetAuthorizationDocumentationApiActions";
import { getAddUpdateAuthorizationDocumentationByStatus } from "../../redux/actions/api/authorizationDocumentation/AddUpdateAuthorizationDocumentationByStatusApiActions";
import { addUpdateDocumentation } from "../../redux/actions/api/documentation/AddUpdateDocumentationApiActions";
import {
  getDocumentationDownloadFile,
  documentationDownloadFileClear,
} from "../../redux/actions/api/documentation/DocumentationDownloadFileApiActions";

import {
  getMedicalNecessity,
  medicalNecessityClear,
} from "../../redux/actions/api/encounter/MedicalNecessityApiActions";

import { getServiceTypes } from "../../redux/actions/api/serviceType/GetServiceTypesApiActions";
import validateIcd10cmCode from "../../redux/actions/api/icd10cm/ValidateIcd10cmCodeApiActions";

import { getPatientInsurance } from "../../redux/actions/api/insurance/GetPatientInsuranceApiActions";
import { patientInsuranceClear } from "../../redux/actions/api/insurance/GetPatientInsuranceApiActions";
import { addUpdateInsurance } from "../../redux/actions/api/insurance/AddUpdateInsuranceApiActions";

import { getMedicalReq } from "../../redux/actions/api/encounter/GetMedicalReqApiActions";

import axios from "axios";
import USStates from "../common/USStates";
import getPayerDto from "../../redux/actions/api/payer/GetPayerDtoApiActions";
import MedicalNecessityRequirementsModal from "./medical-necessity-requirements-modal/MedicalNecessityRequirementsModal";
import MedicalDocumentationRequirementsModal from "./medical-documentation-requirements-modal/MedicalDocumentationRequirementsModal";
import { WithRouter } from "../common/WithRouter";

import { addUpdatePatientSelected } from "../../redux/actions/api/patient/AddUpdatePatientApiActions";
import {
  formatDateToCustomISO,
  formatDateToCustomISO2,
} from "../common/UtilsDate";

import Search from "../common/search/Search";

import JsonFormatter from "react-json-formatter";
import getCignaFax from "../../redux/actions/api/fax/GetFaxApiActions";

class Encounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      orgId: "",
      clientId: "",

      accountNumber: "",
      accountNumberErr: "",
      visitDate: "",
      visitDateErr: "",
      visitTime: "",
      visitTimeErr: "",
      approvalDate: "",
      // approvalDateErr: "",

      orderOrderNumber: "",
      orderOrderNumberErr: "",
      orderRequestedDate: "",
      orderRequestedDateErr: "",
      orderPreAuthStatus: "NEW",
      orderPreAuthStatusErr: "",
      orderDispositionCode: "JUST PLACED",
      orderDispositionCodeErr: "",
      orderReasonCode: "",
      orderReasonCodeErr: "",
      orderPayerId: "",
      orderPayerIdErr: "",
      orderMemberId: "",
      orderMemberIdErr: "",
      orderRelationshipToSubscriber: "",
      orderRelationshipToSubscriberErr: "",
      orderRequestType: "",
      orderRequestTypeErr: "",
      orderSubmissionType: "",
      orderSubmissionTypeErr: "",
      orderFollowUpDate: "",
      orderFollowUpDateErr: "",
      orderPlaceOfService: "",
      orderPlaceOfServiceErr: "",
      orderServiceType: "",
      orderServiceTypeErr: "",

      orderReqRole: "REFERRING",
      orderReqRoleErr: "",
      orderReqTypeErr: "",
      orderReqType: "AT",
      orderReqProNpi: "",
      orderReqProNpiErr: "",
      orderReqProSpecialtyTaxonomy: "",
      orderReqProSpecialtyTaxonomyErr: "",
      orderReqProFirstName: "",
      orderReqProFirstNameErr: "",
      orderReqProLastName: "",
      orderReqProLastNameErr: "",
      orderReqContactPhone: "",
      orderReqContactPhoneErr: "",
      orderReqContactPhoneExtension: "",
      orderReqContactFax: "",
      orderReqContactFaxErr: "",
      orderReqProviderFacilityId: "",
      orderReqProviderFacilityIdErr: "",
      orderReqProviderFacility: [],
      orderReqAddress1: "",
      orderReqAddress1Err: "",
      orderReqCity: "",
      orderReqCityErr: "",
      orderReqState: "",
      orderReqStateErr: "",
      orderReqZip: "",
      orderReqZipErr: "",

      orderDetailId: "",
      orderNumber: "",
      orderNumberErr: "",
      requestedDate: "",
      requestedDateErr: "",
      preAuthStatus: "NEW",
      preAuthStatusErr: "",
      dispositionCode: "",
      dispositionCodeErr: "",
      rejectedReasonCode: "",
      reasonCodeErr: "",
      payerId: "",
      payerIdErr: "",
      memberId: "",
      memberIdErr: "",
      relationshipToSubscriber: "",
      relationshipToSubscriberErr: "",
      requestType: "",
      requestTypeErr: "",
      submissionType: "",
      submissionTypeErr: "",
      note: "",
      noteErr: "",

      role: "RENDERING",
      roleErr: "",
      providerFacilityId: "",
      providerFacilityIdErr: "",
      firstName: "",
      firstNameErr: "",
      lastName: "",
      lastNameErr: "",
      npi: "",
      npiErr: "",
      specialtyTaxonomy: "",
      specialtyTaxonomyErr: "",
      contactPhone: "",
      contactPhoneErr: "",
      contactFax: "",
      contactFaxErr: "",
      name: "",
      nameErr: "",
      address1: "",
      address1Err: "",
      city: "",
      cityErr: "",
      state: "",
      stateErr: "",
      zip: "",
      zipErr: "",

      serviceType: "",
      serviceTypeErr: "",
      placeOfService: "",
      placeOfServiceErr: "",
      fromDate: "",
      fromDateErr: "",
      toDate: "",
      toDateErr: "",
      quantity: "",
      quantityErr: "",
      unitMeasure: "",
      unitMeasureErr: "",
      levelOfService: "",
      levelOfServiceErr: "",
      frequency: "",
      frequencyErr: "",
      reasonForExam: "",
      reasonForExamErr: "",
      trackingNumber: "",
      trackingNumberErr: "",
      procedureCode: "",
      procedureCodeErr: "",
      procedureDescription: "",
      procedureDescriptionErr: "",
      isAuthorizationRequired: "N",
      isAuthorizationRequiredChecked: [],
      authNumber: "",
      authNumberErr: "",
      approvedUnits: "",
      approvedUnitsErr: "",
      approvedProcedureCodes: "",
      approvedProcedureCodesErr: "",
      approvalFromDate: "",
      approvalFromDateErr: "",
      approvalToDate: "",
      approvalToDateErr: "",
      procedureNotes: "",
      procedureNotesErr: "",

      isNew: "Y",
      providerFacility: [],

      diagnosisCode: "",
      diagnosisCodeErr: "",
      description: "",
      descriptionErr: "",

      documentIsChecked: [],

      disabledSaveEncounter: false,
      disabledSaveOrder: false,
      disabledSaveOrderDetail: false,
      disabledSaveAttendingProvider: false,
      disabledSaveNote: false,
      disabledSaveDiagnosis: false,
      disabledSaveProcedure: false,
      disabledSubmit278: false,

      showInsuranceModal: false,
      showFacilityModal: false,
      showProviderModal: false,
      editAddressModal: false,

      type: "PRIMARY",
      typeErr: "",
      relationInsurance: "",
      relationInsuranceErr: "",
      payerIdInsurance: "",
      payerIdInsuranceErr: "",
      address1Insurance: "",
      address1InsuranceErr: "",
      address2Insurance: "",
      cityInsurance: "",
      cityInsuranceErr: "",
      stateInsurance: "",
      stateInsuranceErr: "",
      zipInsurance: "",
      zipInsuranceErr: "",
      phoneNumber: "",
      phoneNumberErr: "",
      groupName: "",
      groupNameErr: "",
      groupId: "",
      groupIdErr: "",
      policyNumber: "",
      policyNumberErr: "",
      plan: "",
      planErr: "",

      insBegin: "",
      insBeginErr: "",

      insEnd: "",
      insEndErr: "",

      medDocReqModal: false,
      medNecReqModal: false,

      isValidating: false,
      validating: "FREE",

      orderDuplicateFoundModal: false,

      outpatientRateFacility: "",
      outpatientRateIsCheckedFacility: [],
      inpatientRateFacility: "",
      inpatientRateIsCheckedFacility: [],
      contactCaseManagerFacility: "",
      contactCaseRepresentativeFacility: "",
      contactCaseRepresentativePhoneFacility: "",
      statusFacility: "ACTIVE",
      nameFacility: "",
      executiveSponsorNameFacility: "",
      executiveSponsorPhoneFacility: "",
      accountsPayablePhoneNumberFacility: "",
      contactInvoicingFacility: "",
      contactInvoicingEmailFacility: "",
      taxIdFacility: "",
      npiFacility: "",
      specialtyTaxonomyFacility: "",
      runEligibilityFacility: "",
      runEligibilityFacilityIsChecked: ["N"],
      fullServiceFacility: "",
      fullServiceFacilityIsChecked: ["N"],
      physicalAddress: "",
      physicalAddress1: "",
      physicalAddress2: "",
      physicalCity: "",
      physicalState: "",
      physicalZip: "",
      mailingAddress: "",
      mailingAddress1: "",
      mailingAddress2: "",
      mailingCity: "",
      mailingState: "",
      mailingZip: "",
      mainPhoneNumber: "",

      nameFacilityErr: "",
      specialtyTaxonomyFacilityErr: "",
      npiFacilityErr: "",
      taxIdFacilityErr: "",
      mainPhoneNumberErr: "",
      executiveSponsorNameFacilityErr: "",
      executiveSponsorPhoneFacilityErr: "",
      accountsPayablePhoneNumberFacilityErr: "",
      contactInvoicingFacilityErr: "",
      contactInvoicingEmailFacilityErr: "",
      outpatientRateFacilityErr: "",
      inpatientRateFacilityErr: "",
      contactCaseManagerFacilityErr: "",
      contactCaseRepresentativeFacilityErr: "",
      contactCaseRepresentativePhoneFacilityErr: "",

      type: "",

      nameProvider: "",
      nameProviderErr: "",
      statusProvider: "ACTIVE",
      specialtyTaxonomyProvider: "",
      specialtyTaxonomyProviderErr: "",
      npiProvider: "",
      npiProviderErr: "",
      taxIdProvider: "",
      taxIdProviderErr: "",
      physicalAddress: "",
      phoneNumber: "",
      phoneNumberErr: "",
      fax: "",
      faxErr: "",
      email: "",
      emailErr: "",
      mailingAddress: "",
      caseManager: "",
      caseManagerErr: "",
      caseManagerEmail: "",
      caseManagerEmailErr: "",
      concurrentReviewNurse: "",
      concurrentReviewNurseErr: "",
      concurrentReviewNurseEmail: "",
      concurrentReviewNurseEmailErr: "",
      contactName: "",
      contactNameErr: "",
      contactPhone: "",
      contactPhoneErr: "",
      contactFax: "",
      contactFaxErr: "",

      editAddressModal: false,
      eligibilityResultDetail: false,
      eligibilityResultJson: "",
      resultDetail278: false,
      resultJson278: "",
      editAddressModalName: "",

      contactPhoneAttendingProvider: "",
      contactPhoneAttendingProviderErr: "",
      contactFaxAttendingProvider: "",
      contactFaxAttendingProviderErr: "",
      contactPhoneExtensionAttendingProvider: "",
      contactPhoneExtensionAttendingProviderErr:"",
    };

    this.handleIsAuthorizationRequired =
      this.handleIsAuthorizationRequired.bind(this);

    this.handleShowCloseDocumentation =
      this.handleShowCloseDocumentation.bind(this);
    this.handleShowCloseUploadFile = this.handleShowCloseUploadFile.bind(this);
    this.handleShowCloseInsurance = this.handleShowCloseInsurance.bind(this);
    this.handleShowCloseFacility = this.handleShowCloseFacility.bind(this);
    this.handleShowCloseProvider = this.handleShowCloseProvider.bind(this);
    this.handleRunEligibilityFacility =
      this.handleRunEligibilityFacility.bind(this);
    this.handleFullServiceFacility = this.handleFullServiceFacility.bind(this);

    this.showEditAddressModal = this.showEditAddressModal.bind(this);

    this.isValidating = this.isValidating.bind(this);
  }

  async componentDidMount() {
    this.props.getOrderDetailsSelected([]);
    this.props.addUpdateAuthorizationEligibilitySelected([]);

    const {
      selectedEncounter,
      selectedOrder,
      selectedOrderRequestingProvider,
      addedPatient,
    } = this.props;

    if (selectedEncounter) {
      this.setState({ ...selectedEncounter });
    }

    if (selectedOrder) {
      if (selectedOrder.id) {
        this.setState({
          orderOrderNumber: selectedOrder.orderNumber,
          orderRequestedDate: selectedOrder.requestedDate,
          orderFollowUpDate: selectedOrder.followUpDate,
          orderPreAuthStatus: selectedOrder.preAuthStatus,
          orderDispositionCode: selectedOrder.dispositionCode,
          orderReasonCode: selectedOrder.rejectedReasonCode,
          orderPayerId: selectedOrder.payerId,
          orderMemberId: selectedOrder.memberId,
          orderRelationshipToSubscriber: selectedOrder.relationshipToSubscriber,
          orderRequestType: selectedOrder.requestType,
          orderSubmissionType: selectedOrder.submissionType,
          orderPlaceOfService: selectedOrder.placeOfService,
          orderServiceType: selectedOrder.serviceType,
        });
        await this.props.getOrderDetails(selectedOrder.id);
        await this.props.getAuthorizationEligibility(selectedOrder.id);
      }
    }

    if (selectedOrderRequestingProvider) {
      if (selectedOrderRequestingProvider.id) {
        this.setState({
          orderReqRole: selectedOrderRequestingProvider.role,
          orderReqProviderFacilityId:
            selectedOrderRequestingProvider.providerFacilityId,
          orderReqProNpi: selectedOrderRequestingProvider.npi,
          orderReqProSpecialtyTaxonomy:
            selectedOrderRequestingProvider.specialtyTaxonomy,
          orderReqProFirstName: selectedOrderRequestingProvider.firstName,
          orderReqProLastName: selectedOrderRequestingProvider.lastName,
          orderReqContactPhone: selectedOrderRequestingProvider.contactPhone,
          orderReqContactPhoneExtension:
            selectedOrderRequestingProvider.contactPhoneExtension,
          orderReqContactFax: selectedOrderRequestingProvider.contactFax,
          orderReqProviderFacilityId:
            selectedOrderRequestingProvider.providerFacilityId,
          orderReqAddress1: selectedOrderRequestingProvider.address1,
          orderReqCity: selectedOrderRequestingProvider.city,
          orderReqState: selectedOrderRequestingProvider.state,
          orderReqZip: selectedOrderRequestingProvider.zip,
          orderReqType: selectedOrderRequestingProvider.type,
        });
        await this.checkEligibility("ComponentDidMount");
        console.log("Are you here...");
      }
    }
    await this.props.getPatientInsurance(addedPatient.id);
    await this.props.getProvider(addedPatient.clientId);
    await this.props.getFacilities(addedPatient.clientId);
    await this.props.getPayersFindAll();
    await this.props.getSystemsCode();
    await this.props.getServiceTypes();

    this.setState({
      providerFacility: this.props.providers,
      orderReqProviderFacility: this.props.providers,
    });
  }

  encounterDefault = {
    id: "",
    orgId: "",
    clientId: "",

    accountNumber: "",
    accountNumberErr: "",
    visitDate: "",
    visitDateErr: "",
    visitTime: "",
    visitTimeErr: "",
    approvalDate: "",
    approvalDateErr: "",
  };

  orderDefault = {
    orderOrderNumber: "",
    orderOrderNumberErr: "",
    orderRequestedDate: "",
    orderRequestedDateErr: "",
    orderFollowUpDate: "",
    orderFollowUpDateErr: "",
    orderPreAuthStatus: "NEW",
    orderPreAuthStatusErr: "",
    orderDispositionCode: "JUST PLACED",
    orderDispositionCodeErr: "",
    orderReasonCode: "",
    orderReasonCodeErr: "",
    orderPayerId: "",
    orderPayerIdErr: "",
    orderMemberId: "",
    orderMemberIdErr: "",
    orderRelationshipToSubscriber: "",
    orderRelationshipToSubscriberErr: "",
    orderRequestType: "",
    orderRequestTypeErr: "",
    orderSubmissionType: "",
    orderSubmissionTypeErr: "",
    orderPlaceOfService: "",
    orderPlaceOfServiceErr: "",
    orderServiceType: "",
    orderServiceTypeErr: "",
  };

  orderDetailDefault = {
    orderDetailId: "",
    orderNumber: "",
    orderNumberErr: "",
    requestedDate: "",
    requestedDateErr: "",
    preAuthStatus: "NEW",
    preAuthStatusErr: "",
    dispositionCode: "JUST PLACED",
    dispositionCodeErr: "",
    rejectedReasonCode: "",
    reasonCodeErr: "",
    payerId: "",
    payerIdErr: "",
    memberId: "",
    memberIdErr: "",
    relationshipToSubscriber: "",
    relationshipToSubscriberErr: "",
    requestType: "",
    requestTypeErr: "",
    submissionType: "",
    submissionTypeErr: "",
  };

  orderRequestingProviderDefault = {
    orderReqRole: "REFERRING",
    orderReqRoleErr: "",
    orderReqType: "AT",
    orderReqTypeErr: "",
    orderReqProNpi: "",
    orderReqProNpiErr: "",
    orderReqProSpecialtyTaxonomy: "",
    orderReqProSpecialtyTaxonomyErr: "",
    orderReqProFirstName: "",
    orderReqProFirstNameErr: "",
    orderReqProLastName: "",
    orderReqProLastNameErr: "",
    orderReqContactPhone: "",
    orderReqContactPhoneErr: "",
    orderReqContactPhoneExtension: "",
    orderReqContactFax: "",
    orderReqContactFaxErr: "",
    orderReqProviderFacilityId: "",
    orderReqProviderFacilityIdErr: "",
    orderReqAddress1: "",
    orderReqAddress1Err: "",
    orderReqCity: "",
    orderReqCityErr: "",
    orderReqState: "",
    orderReqStateErr: "",
    orderReqZip: "",
    orderReqZipErr: "",
  };

  orderDetailAttendingProviderDefault = {
    id: "",
    role: "RENDERING",
    providerFacilityId: "",
    providerFacilityIdErr: "",
    firstName: "",
    firstNameErr: "",
    lastName: "",
    lastNameErr: "",
    npi: "",
    npiErr: "",
    specialtyTaxonomy: "",
    specialtyTaxonomyErr: "",
    contactPhoneAttendingProvider: "",
    contactPhoneAttendingProviderErr: "",
    contactFaxAttendingProvider: "",
    contactFaxAttendingProviderErr: "",
    contactPhoneExtensionAttendingProvider: "",
    contactPhoneExtensionAttendingProviderErr:"",
    name: "",
    nameErr: "",
    address1: "",
    address1Err: "",
    city: "",
    cityErr: "",
    state: "",
    stateErr: "",
    zip: "",
    zipErr: "",
  };

  orderDetailNotesDefault = {
    id: "",
    note: "",
    noteErr: "",
  };

  orderDetailDiagnosisDefault = {
    id: "",
    diagnosisCode: "",
    diagnosisCodeErr: "",
    description: "",
    descriptionErr: "",
  };

  orderDetailProcedureDefault = {
    id: "",
    serviceType: "",
    serviceTypeErr: "",
    placeOfService: "",
    placeOfServiceErr: "",
    fromDate: "",
    fromDateErr: "",
    toDate: "",
    toDateErr: "",
    quantity: "",
    quantityErr: "",
    unitMeasure: "",
    unitMeasureErr: "",
    levelOfService: "",
    levelOfServiceErr: "",
    frequency: "",
    frequencyErr: "",
    reasonForExam: "",
    reasonForExamErr: "",
    trackingNumber: "",
    trackingNumberErr: "",
    procedureCode: "",
    procedureCodeErr: "",
    procedureDescription: "",
    procedureDescriptionErr: "",
    isAuthorizationRequired: "N",
    authNumber: "",
    authNumberErr: "",
    approvedUnits: "",
    approvedUnitsErr: "",
    approvedProcedureCodes: "",
    approvedProcedureCodesErr: "",
    approvalFromDate: "",
    approvalFromDateErr: "",
    approvalToDate: "",
    approvalToDateErr: "",
    procedureNotes: "",
    procedureNotesErr: "",
  };

  insuranceDefault = {
    id: "",
    patientId: "",
    type: "PRIMARY",
    typeErr: "",
    relation: "",
    relationInsuranceErr: "",
    payerId: "",
    payerIdInsuranceErr: "",
    address1: "",
    address1InsuranceErr: "",
    address2: "",
    city: "",
    cityInsuranceErr: "",
    state: "",
    stateInsuranceErr: "",
    zip: "",
    zipInsuranceErr: "",
    phoneNumber: "",
    phoneNumberErr: "",
    groupName: "",
    groupNameErr: "",
    groupId: "",
    groupIdErr: "",
    policyNumber: "",
    policyNumberErr: "",
    plan: "",
    planErr: "",
    insBegin: "",
    insBeginErr: "",

    insEnd: "",
    insEndErr: "",
  };

  clearEncounterList = () => {
    this.setState(this.encounterDefault);
    this.setState(this.orderDefault);
    this.setState(this.orderRequestingProviderDefault);
    this.setState(this.orderDetailDefault);
    this.setState(this.orderDetailAttendingProviderDefault);
    this.setState(this.orderDetailDiagnosisDefault);
    this.setState(this.orderDetailProcedureDefault);
  };

  addNewEncounterClick = () => {
    this.props.addUpdateEncounterSelected({});
    this.props.addUpdateOrderSelected({});
    this.props.getOrderDetailsSelected([]);
    this.props.addUpdateOrderRequestingProviderSelected({});
    this.clearEncounterList();
  };

  clearInsuranceErrors = () => {
    this.setState({
      typeErr: "",
      relationInsuranceErr: "",
      payerIdInsuranceErr: "",
      address1InsuranceErr: "",
      cityInsuranceErr: "",
      stateInsuranceErr: "",
      zipInsuranceErr: "",
      phoneNumberErr: "",
      groupNameErr: "",
      groupIdErr: "",
      policyNumberErr: "",
      planErr: "",
      insBeginErr: "",
      insEndErr: "",
    });
  };

  saveInsurance = async () => {
    var { isNew, id, type } = this.state;
    const { addedPatient, insuranceList } = this.props;
    var error = false;
    if (!id) {
      id = 0;
    }

    console.log(
      "id:" + id + "addedPatient.id:" + addedPatient.id + "type:" + type
    );

    var insuranceTypeCheck = insuranceList.filter(
      (a) => a.patientId == addedPatient.id && a.id != id && a.type == type
    );

    var errorDesc = "";
    if (insuranceTypeCheck.length != 0) {
      errorDesc = type + " already exist!";
      this.setState({ typeErr: errorDesc });
      error = true;
      console.log("test test" + type);
    } else {
      this.setState({ typeErr: "" });
    }

    if (!this.state.address1Insurance) {
      this.setState({ address1InsuranceErr: "Address1 is required." });
      error = true;
    } else {
      this.setState({ address1InsuranceErr: "" });
    }

    if (!this.state.cityInsurance) {
      this.setState({ cityInsuranceErr: "City is required." });
      error = true;
    } else {
      this.setState({ cityInsuranceErr: "" });
    }

    if (!this.state.stateInsurance) {
      this.setState({ stateInsuranceErr: "State is required." });
      error = true;
    } else {
      this.setState({ stateInsuranceErr: "" });
    }

    if (!this.state.zipInsurance) {
      this.setState({ zipInsuranceErr: "Zip is required." });
      error = true;
    } else {
      this.setState({ zipInsuranceErr: "" });
    }

    if (!this.state.relationInsurance) {
      this.setState({ relationInsuranceErr: "Relation is required." });
      error = true;
    } else {
      this.setState({ relationInsuranceErr: "" });
    }

    if (!this.state.policyNumber) {
      this.setState({ policyNumberErr: "Policy Number is required." });
      error = true;
    } else {
      this.setState({ policyNumberErr: "" });
    }

    if (!this.state.insBegin) {
      this.setState({ insBeginErr: "Begin is required." });
      error = true;
    } else {
      this.setState({ insBeginErr: "" });
    }

    if (!this.state.payerIdInsurance) {
      this.setState({ payerIdInsuranceErr: "Payer is required." });
      error = true;
    } else {
      this.setState({ payerIdInsuranceErr: "" });
    }

    if (error == false) {
      var insurance = {
        ...this.state,
        patientId: addedPatient.id,
        relation: this.state.relationInsurance,
        payerId: this.state.payerIdInsurance,
        address1: this.state.address1Insurance,
        address2: this.state.address2Insurance,
        city: this.state.cityInsurance,
        state: this.state.stateInsurance,
        zip: this.state.zipInsurance,
      };
      this.clearInsuranceErrors();
      await this.props.addUpdateInsurance(insurance);
      await this.props.getPatientInsurance(addedPatient.id);
      if (isNew == "Y") {
        this.setState(this.insuranceDefault);
      }
    }
  };

  clearEncounterErrors = () => {
    this.setState({
      accountNumberErr: "",
      visitDateErr: "",
      visitTimeErr: "",
    });
  };

  saveEncounter = async () => {
    this.setState({ disabledSaveEncounter: true });
    var error = false;

    if (!this.state.accountNumber) {
      this.setState({ accountNumberErr: "Please enter Account Number." });
      error = true;
    } else {
      this.setState({ accountNumberErr: "" });
    }

    if (!this.state.visitDate) {
      this.setState({ visitDateErr: "Please enter Visit Date." });
      error = true;
    } else {
      this.setState({ visitDateErr: "" });
    }

    if (!this.state.visitTime) {
      this.setState({ visitTimeErr: "Please enter Visit Time." });
      error = true;
    } else {
      this.setState({ visitTimeErr: "" });
    }

    if (error == false) {
      const { addedPatient, selectedEncounter } = this.props;
      var encounterId = "";
      if (selectedEncounter) {
        encounterId = selectedEncounter.id;
      }

      var encounter = {
        ...this.state,
        orgId: addedPatient.orgId,
        clientId: addedPatient.clientId,
        id: encounterId,
        patientId: addedPatient.id,
      };
      this.clearEncounterErrors();
      console.log(encounter);
      await this.props.addUpdateEncounter(encounter);
    }

    this.setState({ disabledSaveEncounter: false });
  };

  clearOrderErrors = () => {
    this.setState({
      orderOrderNumberErr: "",
      orderRequestedDateErr: "",
      orderFollowUpDateErr: "",
      orderPreAuthStatusErr: "",
      orderDispositionCodeErr: "",
      orderReasonCodeErr: "",
      orderPayerIdErr: "",
      orderMemberIdErr: "",
      orderRelationshipToSubscriberErr: "",
      orderRequestTypeErr: "",
      orderSubmissionTypeErr: "",
      orderPlaceOfServiceErr: "",
      orderServiceTypeErr: "",

      orderReqProNpiErr: "",
      orderReqProSpecialtyTaxonomyErr: "",
      orderReqProFirstNameErr: "",
      orderReqProLastNameErr: "",
      orderReqContactPhoneErr: "",
      orderReqContactFaxErr: "",
      orderReqProviderFacilityIdErr: "",
      orderReqAddress1Err: "",
      orderReqCityErr: "",
      orderReqStateErr: "",
      orderReqZipErr: "",
    });
  };

  checkOrderDuplicates = async () => {
    var { getOrderDuplicates, selectedOrder, addedPatient } = this.props;

    if (!selectedOrder || selectedOrder.id == undefined) {
      selectedOrder = {};
      selectedOrder.id = 0;
    }

    if (this.state.orderOrderNumber) {
      await getOrderDuplicates(
        addedPatient.clientId,
        "ORDER",
        selectedOrder.id,
        this.state.orderOrderNumber
      );

      if (this.props.orderDuplicates.length != 0) {
        this.setState({
          orderOrderNumberErr: "Order Number already exist!",
          orderDuplicateFoundModal: true,
        });
      } else {
        this.setState({ orderOrderNumberErr: "" });
      }
    }
  };

  handleShowCloseDuplicatesModal = (val) => {
    this.setState({ orderDuplicateFoundModal: val });
  };

  onClickEncounter(record) {
    if (!record) {
      this.props.addUpdateEncounterSelected({});
      this.props.addUpdateOrderSelected({});
      this.props.addUpdateOrderRequestingProviderSelected({});
    } else {
      this.props.addUpdateEncounterSelected(record.encounter);
      if (record.authorization) {
        this.props.addUpdateOrderSelected(record.authorization);
      } else {
        this.props.addUpdateOrderSelected({});
      }

      if (record.authRequestingProvider) {
        this.props.addUpdateOrderRequestingProviderSelected(
          record.authRequestingProvider[0]
        );
      } else {
        this.props.addUpdateOrderRequestingProviderSelected({});
      }

      if (record.guarantorPatients) {
        this.props.addUpdatePatientSelected(record.guarantorPatients);
        console.log("Im here at IF");
      } else {
        this.props.addUpdatePatientSelected({});
        console.log("Im here at else");
      }
    }

    this.setState({ orderDuplicateFoundModal: false });
    this.props.navigate("/refreshencounter");
  }

  saveOrder = async () => {
    this.setState({ disabledSaveOrder: true });

    const elementsShow = document.querySelectorAll("tr.main-table");

    elementsShow.forEach((element) => {
      element.classList.remove("show");
    });

    var error = false;

    if (!this.state.orderOrderNumber) {
      this.setState({ orderOrderNumberErr: "Order Number is required." });
      error = true;
    } else {
      if (this.state.orderOrderNumberErr == "Order Number already exist!") {
        error = true;
      } else {
        this.setState({ orderOrderNumberErr: "" });
      }
    }

    if (!this.state.orderRequestedDate) {
      this.setState({ orderRequestedDateErr: "Requested Date is required." });
      error = true;
    } else {
      this.setState({ orderRequestedDateErr: "" });
    }

    if (!this.state.orderFollowUpDate) {
      this.setState({ orderFollowUpDateErr: "Follow Up Date is required." });
      error = true;
    } else {
      this.setState({ orderFollowUpDateErr: "" });
    }

    if (!this.state.orderPayerId) {
      this.setState({ orderPayerIdErr: "Payer is required." });
      error = true;
    } else {
      this.setState({ orderPayerIdErr: "" });
    }

    if (!this.state.orderMemberId) {
      this.setState({ orderMemberIdErr: "Member ID is required." });
      error = true;
    } else {
      this.setState({ orderMemberIdErr: "" });
    }
    console.log(
      "this.state.orderRelationshipToSubscriber" +
        this.state.orderRelationshipToSubscriber +
        "this.state.orderRelationshipToSubscriber"
    );
    if (!this.state.orderRelationshipToSubscriber) {
      this.setState({
        orderRelationshipToSubscriberErr:
          "Relationship to Subscriber is required.",
      });
      error = true;
    } else {
      this.setState({ orderRelationshipToSubscriberErr: "" });
    }

    if (!this.state.orderRequestType) {
      this.setState({ orderRequestTypeErr: "Request Type is required." });
      error = true;
    } else {
      this.setState({ orderRequestTypeErr: "" });
    }

    if (!this.state.orderSubmissionType) {
      this.setState({ orderSubmissionTypeErr: "Submission Type is required." });
      error = true;
    } else {
      this.setState({ orderSubmissionTypeErr: "" });
    }

    if (!this.state.orderReqProviderFacilityId) {
      this.setState({
        orderReqProviderFacilityIdErr: "Requesting Provider is required.",
      });
      error = true;
    } else {
      this.setState({ orderReqProviderFacilityIdErr: "" });
    }

    if (!this.state.orderReqProNpi) {
      this.setState({ orderReqProNpiErr: "NPI is required." });
      error = true;
    } else {
      this.setState({ orderReqProNpiErr: "" });
    }

    if (!this.state.orderReqProSpecialtyTaxonomy) {
      this.setState({
        orderReqProSpecialtyTaxonomyErr: "Speciality/Taxonomy is required.",
      });
      error = true;
    } else {
      this.setState({ orderReqProSpecialtyTaxonomyErr: "" });
    }

    if (!this.state.orderReqAddress1) {
      this.setState({ orderReqAddress1Err: "Address 1 is required." });
      error = true;
    } else {
      this.setState({ orderReqAddress1Err: "" });
    }

    if (!this.state.orderReqCity) {
      this.setState({ orderReqCityErr: "City is required." });
      error = true;
    } else {
      this.setState({ orderReqCityErr: "" });
    }

    if (!this.state.orderReqState) {
      this.setState({ orderReqStateErr: "State is required." });
      error = true;
    } else {
      this.setState({ orderReqStateErr: "" });
    }

    if (!this.state.orderReqZip) {
      this.setState({ orderReqZipErr: "Zip is required." });
      error = true;
    } else {
      this.setState({ orderReqZipErr: "" });
    }

    if (!this.state.orderReqProFirstName) {
      this.setState({ orderReqProFirstNameErr: "First Name is required." });
      error = true;
    } else {
      this.setState({ orderReqProFirstNameErr: "" });
    }

    if (!this.state.orderReqProLastName) {
      this.setState({ orderReqProLastNameErr: "Last Name is required." });
      error = true;
    } else {
      this.setState({ orderReqProLastNameErr: "" });
    }

    if (!this.state.orderReqContactPhone) {
      this.setState({ orderReqContactPhoneErr: "Phone is required." });
      error = true;
    } else {
      this.setState({ orderReqContactPhoneErr: "" });
    }

    if (!this.state.orderReqContactFax) {
      this.setState({ orderReqContactFaxErr: "Fax is required." });
      error = true;
    } else {
      this.setState({ orderReqContactFaxErr: "" });
    }
    console.log("required");
    console.log(typeof this.state.orderFollowUpDate);
    console.log(this.state.orderFollowUpDate);

    if (error == false) {
      var { addedPatient, selectedEncounter, selectedOrder } = this.props;

      var encounterId = "";
      console.log("selectedEncounter", selectedEncounter);
      if (!selectedEncounter || !selectedEncounter.id) {
        var accountNumber = "";
        if (!this.state.accountNumber) {
          accountNumber = "A-" + this.state.orderOrderNumber;
        }
        this.setState({ accountNumber: accountNumber });
        var encounter = {
          ...this.state,
          orgId: addedPatient.orgId,
          clientId: addedPatient.clientId,
          facilityId: addedPatient.facilityId,
          id: encounterId,
          patientId: addedPatient.id,
          accountNumber: accountNumber,
        };
        await this.props.addUpdateEncounter(encounter);

        this.saveOrder();
      } else {
        this.props.getPatientInsurance(addedPatient.id);

        if (!selectedOrder) {
          selectedOrder = {};
        }
        var order = {
          orderNumber: this.state.orderOrderNumber,
          requestedDate: this.state.orderRequestedDate,
          followUpDate: this.state.orderFollowUpDate,
          preAuthStatus: this.state.orderPreAuthStatus,
          dispositionCode: this.state.orderDispositionCode,
          rejectedReasonCode: this.state.orderReasonCode,
          payerId: this.state.orderPayerId,
          memberId: this.state.orderMemberId,
          relationshipToSubscriber: this.state.orderRelationshipToSubscriber,
          requestType: this.state.orderRequestType,
          submissionType: this.state.orderSubmissionType,
          placeOfService: this.state.orderPlaceOfService,
          serviceType: this.state.orderServiceType,
          orgId: addedPatient.orgId,
          clientId: addedPatient.clientId,
          facilityId: addedPatient.facilityId,
          id: selectedOrder.id,
          encounterId: selectedEncounter.id,
          patientId: addedPatient.id,
          type: "ORDER",
        };
        console.log("order", order);
        this.clearOrderErrors();
        await this.props.addUpdateOrder(order);

        this.saveOrderDetail(order);
      }
    } else {
      this.setState({ disabledSaveOrder: false });
    }
  };

  setCPTCrossWalk = (procedureCode) => {
    //this.setState({procedureDescription:''});
    this.setState({ serviceType: "" });

    if (!procedureCode || procedureCode == "") {
      return "";
    }

    var { cptServiceTypes } = this.props;

    const cptServiceType = cptServiceTypes.filter(
      (a) =>
        a.from.toUpperCase() <= procedureCode.toUpperCase() &&
        a.to.toUpperCase() >= procedureCode.toUpperCase()
    );

    if (cptServiceType.length != 0) {
      //if(cptServiceType[0].procedureRange != null){
      //    this.setState({procedureDescription:cptServiceType[0].procedureRange});
      //}

      if (!cptServiceType[0].numberCode != null) {
        this.setState({ serviceType: cptServiceType[0].numberCode });
      }
    }
  };

  getPlaceOfService = (code) => {
    if (!code || code == "") {
      return "";
    }

    var { placeOfServices } = this.props;
    var desc = "";
    placeOfServices.map((payer, key) => {
      const placeOfService = placeOfServices.filter((a) => a.code == code);

      if (placeOfService.length != 0) {
        desc = placeOfService[0].value;
      }
    });

    return desc;
  };

  selectOrderPayer = (payerId) => {
    var { payers, insuranceList } = this.props;

    if (!payers) {
      payers = [];
    }

    if (!insuranceList) {
      insuranceList = [];
    }

    payers.map((payer, key) => {
      const insurancePayer = insuranceList.filter((a) => a.payerId == payerId);

      if (insurancePayer.length != 0) {
        this.setState({
          orderMemberId: insurancePayer[0].policyNumber,
          orderRelationshipToSubscriber: insurancePayer[0].relation,
        });
      }
    });
  };

  checkEligibility = async (from) => {
    var {
      selectedOrder,
      selectedOrderRequestingProvider,
      addedPatient,
      payers,
      insuranceList,
    } = this.props;

    var dob = "";
    if (addedPatient.dob) {
      dob = formatDateToCustomISO(moment(addedPatient.dob).toDate());
      dob = moment(dob).format("YYYY-MM-DD");
    }

    var eligibilities = [];
    console.log("insuranceList", insuranceList);
    for (let i = 0; i < insuranceList.length; i++) {
      const payer = payers.filter((a) => a.id == insuranceList[i].payerId);
      var payerId = "";
      if (payer.length != 0) {
        payerId = payer[0].payerId;
      }

      var memberId = "";
      var relationshipToSubscriber = "";

      if (selectedOrder.payerId == insuranceList[i].payerId) {
        memberId = selectedOrder.memberId;
        relationshipToSubscriber = selectedOrder.relationshipToSubscriber;
      } else {
        memberId = insuranceList[i].policyNumber;
        relationshipToSubscriber = insuranceList[i].relation;
      }

      var eligibility = {
        orderId: selectedOrder.id,
        payerId: payerId,
        insurancePayerId: insuranceList[i].payerId,
        providerNpi: selectedOrderRequestingProvider.npi,
        providerType: selectedOrderRequestingProvider.type,
        providerLastName: selectedOrderRequestingProvider.lastName,
        patientSSN: addedPatient.ssn,
        providerCity: selectedOrderRequestingProvider.city,
        providerState: selectedOrderRequestingProvider.state,
        providerZipCode: selectedOrderRequestingProvider.zip,
        memberId: memberId,
        patientLastName: addedPatient.lastName,
        patientFirstName: addedPatient.firstName,
        patientBirthDate: dob,
        serviceType: "30", //this.state.orderServiceType
        placeOfService: selectedOrder.placeOfService,
        providerSpecialty: selectedOrderRequestingProvider.specialtyTaxonomy,
        patientGender: addedPatient.gender,
        patientState: "",
        subscriberRelationship: relationshipToSubscriber,
      };
      eligibilities.push(eligibility);
    }
    if (from == "ComponentDidMount") {
      if (this.checkEligibilityStatus() == "In Progress") {
        await this.props.addUpdateAuthorizationEligibility(eligibilities);
        this.props.getOrderDetails(selectedOrder.id);
        this.checkEligibility(from);
      }
    } else {
      await this.props.addUpdateAuthorizationEligibility(eligibilities);
      this.props.getOrderDetails(selectedOrder.id);
    }

    if (this.checkEligibilityStatus() == "In Progress") {
      this.checkEligibility(from);
    }

    this.setState({ disabledSaveOrder: false });
  };

  checkEligibilityStatus = () => {
    const { selectedOrderEligibility } = this.props;

    var status = "";

    for (let i = 0; i < selectedOrderEligibility.length; i++) {
      if (selectedOrderEligibility[i].responseCode) {
        var jsonResponse = JSON.parse(selectedOrderEligibility[i].responseJson);

        if (
          selectedOrderEligibility[i].responseCode >= 200 &&
          selectedOrderEligibility[i].responseCode <= 299
        ) {
          var coverages = jsonResponse.coverages;
          coverages.forEach((coverage) => {
            status = coverage.status;
          });
        }

        if (status == "In Progress") {
          break;
        }
      }
    }

    return status;
  };


  check278 = async () => {
   this.setState({disabledSubmit278: true});

   const elementsShow = document.querySelectorAll("tr.sub-table");

   elementsShow.forEach((element) => {
     element.classList.remove("show");
   });

   var {orderDetailId} = this.state;

   var {
      addedPatient,
      selectedOrder,
   } = this.props;

   var dob = '';
   if(addedPatient.dob){
       dob = formatDateToCustomISO(moment(addedPatient.dob).toDate());
       dob = moment(dob).format("YYYY-MM-DD");
   }

   var availity278 = {
     orderId: selectedOrder.id,
     orderDetailId: orderDetailId,
     patientLastName: addedPatient.lastName,
     patientFirstName: addedPatient.firstName,
     patientBirthDate: dob,
   };

   console.log("availity278" , availity278);

   await this.props.addUpdateAuthorization278(availity278);

   await this.props.getOrderProcedure(orderDetailId);

   this.setState({disabledSubmit278: false});

  };

  saveOrderDetail = async (order) => {
    var {
      selectedOrder,
      orderDetails,
      selectedOrderRequestingProvider,
      authentication,
      payers,
      addedPatient,
    } = this.props;

    if (!selectedOrder.id || selectedOrder.id == undefined) {
      this.saveOrderDetail(order);
    }

    if (orderDetails.length == 1) {
      if (orderDetails[0].orderDetailId == "") {
        var orderDetail = {
          ...order,
          id: "",
          orderNumber: selectedOrder.orderNumber + "_1",
          parentId: selectedOrder.id,
          type: "ORDER_DETAIL",
          modifiedBy: authentication.username,
        };

        await this.props.addUpdateOrderDetail(orderDetail);
        await this.props.getOrderDetails(selectedOrder.id);
      }
    }

    var requestingProvider = {
      id: selectedOrderRequestingProvider.id,
      authorizationId: selectedOrder.id,
      role: this.state.orderReqRole,
      npi: this.state.orderReqProNpi,
      specialtyTaxonomy: this.state.orderReqProSpecialtyTaxonomy,
      firstName: this.state.orderReqProFirstName,
      lastName: this.state.orderReqProLastName,
      contactPhone: this.state.orderReqContactPhone,
      contactPhoneExtension: this.state.orderReqContactPhoneExtension,
      contactFax: this.state.orderReqContactFax,
      providerFacilityId: this.state.orderReqProviderFacilityId,
      address1: this.state.orderReqAddress1,
      city: this.state.orderReqCity,
      state: this.state.orderReqState,
      zip: this.state.orderReqZip,
      type: this.state.orderReqType,
    };

    await this.props.addUpdateOrderRequestingProvider(requestingProvider);
    await this.checkEligibility("");
  };

  selectOrderDetailPayer = (payerId) => {
    var { payers, insuranceList } = this.props;

    if (!payers) {
      payers = [];
    }

    if (!insuranceList) {
      insuranceList = [];
    }

    payers.map((payer, key) => {
      const insurancePayer = insuranceList.filter((a) => a.payerId == payerId);

      if (insurancePayer.length != 0) {
        this.setState({
          memberId: insurancePayer[0].policyNumber,
          relationshipToSubscriber: insurancePayer[0].relation,
        });
      }
    });
  };

  clearEncounterOrderDetailErrors = () => {
    this.setState({
      orderNumberErr: "",
      requestedDateErr: "",
      orderFollowUpDateErr: "",
      preAuthStatusErr: "",
      dispositionCodeErr: "",
      reasonCodeErr: "",
      payerIdErr: "",
      memberIdErr: "",
      relationshipToSubscriberErr: "",
      requestTypeErr: "",
      submissionTypeErr: "",
    });
  };

  saveEncounterOrderDetail = async () => {
    this.setState({ disabledSaveOrderDetail: true });

    //Render condition for Reason Code Field
    const isNegativeDisposition =
      this.state.dispositionCode === "NOT CERTIFIED" ||
      this.state.dispositionCode === "NOT CERTIFIED - APPEALED" ||
      this.state.dispositionCode === "PARTIALLY AFFIRMED" ||
      this.state.dispositionCode === "REJECTED";
    const isReasonCodeFieldVisible =
      this.state.preAuthStatus === "AFFIRMED DECISION" && isNegativeDisposition;

    // const duplicateOrderNumber =
    //   this.state.orderNumber === this.state.orderOrderNumber;

    const duplicateOrderDetailNumber = !!this.props.orderDetails.find(
      (orderDetail) =>
        orderDetail.orderNumber === this.state.orderNumber &&
        orderDetail.id !== this.state.orderDetailId
    );

    //check if new order detail number is equal to the base order number or a order detail number without "_"
    // if (duplicateOrderNumber || !this.state.orderNumber.includes("_")) {
    //   const newOrderNumber = `${this.state.orderNumber}_${this.props.orderDetails.length}`;

    //   alert(
    //     `Duplicate Order Number detected, new Order Number value changed to "${newOrderNumber}". Please review new order details.`
    //   );

    //   this.setState({
    //     orderNumber: newOrderNumber,
    //     disabledSaveOrderDetail: false,
    //   });
    //   return;
    // }

    //check if new order detail number is equal to any created order detail number
    if (duplicateOrderDetailNumber) {
      const newOrderDetailNumber = this.state.orderNumber.replace(
        /(_\d+)/,
        (match) => {
          let number = parseInt(match.slice(1), 10);
          return "_" + (number + 1);
        }
      );

      alert(
        `Duplicate Order Number detected, Order Detail Number value changed to "${newOrderDetailNumber}". Please review new order details.`
      );

      this.setState({
        orderNumber: newOrderDetailNumber,
        disabledSaveOrderDetail: false,
      });
      return;
    }

    var error = false;

    if (!this.state.orderNumber) {
      this.setState({ orderNumberErr: "Order Number is required." });
      error = true;
    } else {
      this.setState({ orderNumberErr: "" });
    }

    if (!this.state.requestedDate) {
      this.setState({ requestedDateErr: "Requested Date is required." });
      error = true;
    } else {
      this.setState({ requestedDateErr: "" });
    }

    if (!this.state.preAuthStatus) {
      this.setState({ preAuthStatusErr: "Pre-Auth Status is required." });
      error = true;
    } else {
      this.setState({ preAuthStatusErr: "" });
    }

    if (!this.state.dispositionCode) {
      this.setState({ dispositionCodeErr: "Disposition Code is required." });
      error = true;
    } else {
      this.setState({ dispositionCodeErr: "" });
    }

    if (!this.state.rejectedReasonCode && isReasonCodeFieldVisible) {
      this.setState({ reasonCodeErr: "Reason Code is required." });
      error = true;
    } else {
      this.setState({ reasonCodeErr: "" });
    }

    if (!this.state.payerId) {
      this.setState({ payerIdErr: "Payer Id is required." });
      error = true;
    } else {
      this.setState({ payerIdErr: "" });
    }

    if (!this.state.memberId) {
      this.setState({ memberIdErr: "Member Id is required." });
      error = true;
    } else {
      this.setState({ memberIdErr: "" });
    }

    if (!this.state.relationshipToSubscriber) {
      this.setState({
        relationshipToSubscriberErr: "Relationship To Subscriber is required.",
      });
      error = true;
    } else {
      this.setState({ relationshipToSubscriberErr: "" });
    }

    if (!this.state.requestType) {
      this.setState({ requestTypeErr: "Request Type is required." });
      error = true;
    } else {
      this.setState({ requestTypeErr: "" });
    }

    if (!this.state.submissionType) {
      this.setState({ submissionTypeErr: "Submission Type is required." });
      error = true;
    } else {
      this.setState({ submissionTypeErr: "" });
    }
    /*
        if(!this.state.notes){
            this.setState({notesErr:'Note is required.'});
            error = true;
        }else{
            this.setState({notesErr:''});
        }
        */

    if (error == false) {
      var { isNew, orderDetailId } = this.state;
      const { selectedOrder, addedPatient, authentication, getOrderHistory } =
        this.props;
      var orderDetail = {
        ...this.state,
        id: orderDetailId,
        parentId: selectedOrder.id,
        type: "ORDER_DETAIL",
        orgId: addedPatient.orgId,
        clientId: addedPatient.clientId,
        modifiedBy: authentication.username,
      };

      await this.props.addUpdateOrderDetail(orderDetail);
      await this.props.getOrderDetails(selectedOrder.id);

      const { selectedOrderDetail } = this.props;
      getOrderHistory(selectedOrderDetail.id);
      //await this.props.addUpdateOrderDetailSelected({});
      this.setState({ orderDetailId: selectedOrderDetail.id });
      this.clearEncounterOrderDetailErrors();

      console.log("selectedOrderDetail.id", selectedOrderDetail.id);
    }
    this.setState({ disabledSaveOrderDetail: false });
  };

  clearAttendingProviderErrors = () => {
    this.setState({
      roleErr: "",
      providerFacilityIdErr: "",
      firstNameErr: "",
      lastNameErr: "",
      npiErr: "",
      specialtyTaxonomyErr: "",
      contactPhoneErr: "",
      contactFaxErr: "",
      nameErr: "",
      address1Err: "",
      cityErr: "",
      stateErr: "",
      zipErr: "",
    });
  };

  saveAttendingProvider = async () => {
    this.setState({ disabledSaveAttendingProvider: true });
    var error = false;

    if (this.state.role == "FACILITY") {
      if (!this.state.name) {
        this.setState({ nameErr: "Name is required." });
        error = true;
      } else {
        this.setState({ nameErr: "" });
      }
    } else {
      if (!this.state.firstName) {
        this.setState({ firstNameErr: "First Name is required." });
        error = true;
      } else {
        this.setState({ firstNameErr: "" });
      }

      if (!this.state.lastName) {
        this.setState({ lastNameErr: "Last Name is required." });
        error = true;
      } else {
        this.setState({ lastNameErr: "" });
      }
    }

    if (!this.state.providerFacilityId) {
      this.setState({ providerFacilityIdErr: "Provider is required." });
      error = true;
    } else {
      this.setState({ providerFacilityIdErr: "" });
    }

    if (!this.state.npi) {
      this.setState({ npiErr: "NPI is required." });
      error = true;
    } else {
      this.setState({ npiErr: "" });
    }

    if (!this.state.specialtyTaxonomy) {
      this.setState({
        specialtyTaxonomyErr: "Specialty Taxonomy is required.",
      });
      error = true;
    } else {
      this.setState({ specialtyTaxonomyErr: "" });
    }

    if (!this.state.address1) {
      this.setState({ address1Err: "Address 1 is required." });
      error = true;
    } else {
      this.setState({ address1Err: "" });
    }

    if (!this.state.city) {
      this.setState({ cityErr: "City is required." });
      error = true;
    } else {
      this.setState({ cityErr: "" });
    }

    if (!this.state.state) {
      this.setState({ stateErr: "State is required." });
      error = true;
    } else {
      this.setState({ stateErr: "" });
    }

    if (!this.state.zip) {
      this.setState({ zipErr: "Zip is required." });
      error = true;
    } else {
      this.setState({ zipErr: "" });
    }

    if (!this.state.contactPhoneAttendingProvider) {
      this.setState({ contactPhoneAttendingProviderErr: "Phone is required." });
      error = true;
    } else {
      this.setState({ contactPhoneAttendingProviderErr: "" });
    }

    /*
    if (!this.state.contactFaxAttendingProvider) {
      this.setState({ contactFaxAttendingProviderErr: "Fax is required." });
      error = true;
    } else {
      this.setState({ contactFaxAttendingProviderErr: "" });
    }
    */

    if (error == false) {
      const { isNew, orderDetailId, contactPhoneAttendingProvider, contactFaxAttendingProvider, contactPhoneExtensionAttendingProvider} = this.state;

      var attendingProvider = { ...this.state,
                                authorizationId: orderDetailId,
                                contactPhone: contactPhoneAttendingProvider,
                                contactPhoneExt: contactPhoneExtensionAttendingProvider,
                                contactFax: contactFaxAttendingProvider,
                                };

      if (this.state.role == "FACILITY") {
        attendingProvider = {
          ...this.state,
          firstName: "",
          lastName: "",
          authorizationId: orderDetailId,
          contactPhone: contactPhoneAttendingProvider,
          contactPhoneExt: contactPhoneExtensionAttendingProvider,
          contactFax: contactFaxAttendingProvider,
        };
      }

      console.log("attendingProvider", attendingProvider);
      await this.props.addUpdateOrderProvider(attendingProvider);
      await this.props.getOrderProvider(orderDetailId);
      if (isNew == "Y") {
        this.setState(this.orderDetailAttendingProviderDefault);
      }

      this.clearAttendingProviderErrors();
    }
    this.setState({ disabledSaveAttendingProvider: false });
  };

  saveNote = async () => {
    this.setState({ disabledSaveNote: true });
    var error = false;

    if (!this.state.note) {
      this.setState({ noteErr: "Note is required." });
      error = true;
    } else {
      this.setState({ nameErr: "" });
    }

    if (error == false) {
      const { isNew, orderDetailId } = this.state;
      const { authentication } = this.props;

      var notes = {
        ...this.state,
        authorizationId: orderDetailId,
        modifiedBy: authentication.username,
      };

      console.log("notes", notes);
      await this.props.addUpdateOrderNote(notes);
      await this.props.getOrderNote(orderDetailId);
      if (isNew == "Y") {
        this.setState(this.orderDetailNotesDefault);
      }

      this.clearNotesErrors();
      console.log("notes", notes);
    }
    this.setState({ disabledSaveNote: false });
  };

  clearNotesErrors = () => {
    this.setState({
      noteErr: "",
    });
  };

  clearDiagnosisErrors = () => {
    this.setState({
      diagnosisCodeErr: "",
      descriptionErr: "",
    });
  };

  saveDiagnosis = async () => {
    this.setState({ disabledSaveDiagnosis: true });
    var error = false;

    if (!this.state.diagnosisCode) {
      this.setState({ diagnosisCodeErr: "Diagnosis Code is required." });
      error = true;
    } else {
      this.setState({ diagnosisCodeErr: "" });
    }

    if (!this.state.description) {
      this.setState({ descriptionErr: "Description is required." });
      error = true;
    } else {
      this.setState({ descriptionErr: "" });
    }

    if (this.state.diagnosisCode) {
      await this.props.validateIcd10cmCode(this.state.diagnosisCode);
      console.log(this.props.validateIcd10cmCodeData);
      if (this.props.validateIcd10cmCodeData.valid === "INVALID") {
        this.setState({ diagnosisCodeErr: "Diagnosis Code not found." });
      } else {
        this.setState({ diagnosisCodeErr: "" });
      }
    }

    if (error == false) {
      const { isNew, orderDetailId } = this.state;
      var {
        addedPatient,
        selectedOrder,
        getOrderHistory,
        orderDetailsProcedure,
      } = this.props;

      if (!orderDetailsProcedure) {
        orderDetailsProcedure = [];
      }

      var diagnosis = { ...this.state, authorizationId: orderDetailId };

      await this.props.addUpdateOrderDiagnosis(
        diagnosis,
        addedPatient.clientId
      );
      await this.props.getOrderDiagnosis(orderDetailId);

      const { savedDiagnosis } = this.props;

      if (isNew == "Y") {
        this.setState(this.orderDetailDiagnosisDefault);
      }

      if (savedDiagnosis.orderDetailStatus == "VALIDATED") {
        //this.setState({preAuthStatus:savedDiagnosis.orderDetailStatus});
        //getOrderHistory(orderDetailId);
      } else {
        if (this.state.preAuthStatus == "NEW") {
          if (orderDetailsProcedure.length != 0) {
            this.validateICDCPT(addedPatient.clientId, orderDetailId);
          }
        }
      }

      await this.props.getAuthorizationById(orderDetailId);
      var { authorizationById } = this.props;
      this.props.addUpdateOrderDetailSelected(authorizationById);

      this.clearDiagnosisErrors();
    }
    this.setState({ disabledSaveDiagnosis: false });
  };

  clearProcedureErrors = () => {
    this.setState({
      serviceTypeErr: "",
      placeOfServiceErr: "",
      fromDateErr: "",
      toDateErr: "",
      quantityErr: "",
      unitMeasureErr: "",
      levelOfServiceErr: "",
      frequencyErr: "",
      reasonForExamErr: "",
      trackingNumberErr: "",
      procedureCodeErr: "",
      procedureDescriptionErr: "",
      authNumberErr: "",
      approvedUnitsErr: "",
      approvedProcedureCodesErr: "",
      approvalFromDateErr: "",
      approvalToDateErr: "",
      procedureNotesErr: "",
    });
  };

  isValidating = async () => {
    var { orderDetailId } = this.state;

    if (orderDetailId) {
      const {
        getAuthorizationById,
        getOrderDiagnosis,
        getOrderProcedure,
        getOrderHistory,
        getOrderNote,
      } = this.props;
      await getAuthorizationById(orderDetailId);
      console.log("orderDetailId", orderDetailId);

      var { authorizationById } = this.props;

      this.setState({ validating: authorizationById.validating });

      if (authorizationById.validating) {
        if (authorizationById.validating == "VALIDATING") {
          this.setState({ isValidating: true });
          this.props.authorizationByIdSelected({});
          setTimeout(this.isValidating, 10);
        } else if (authorizationById.validating == "FREE") {
          getOrderDiagnosis(orderDetailId);
          getOrderProcedure(orderDetailId);
          getOrderHistory(orderDetailId);
          getOrderNote(orderDetailId);
          this.setState({
            isValidating: false,
            preAuthStatus: authorizationById.preAuthStatus,
            dispositionCode: authorizationById.dispositionCode,
          });
          this.setState({ isValidating: false });
        } else {
          this.setState({ isValidating: false });
        }
      } else {
        this.setState({ isValidating: false });
      }
    } else {
      this.setState({ isValidating: false });
    }
  };

  runIsValidating = async () => {
    this.setState({ isValidating: false });
    await clearTimeout(this.isValidating);
    await setTimeout(this.isValidating, 10);
  };

  runValidateICDCPT = async () => {
    await clearTimeout(this.validateICDCPT);
    await setTimeout(this.validateICDCPT, 10);
  };

  validateICDCPT = async () => {
    const {
      addedPatient,
      validateIcdCpt,
      getAuthorizationById,
      addUpdateOrderDetail,
      authorizationByIdSelected,
    } = this.props;
    const { orderDetailId } = this.state;

    await getAuthorizationById(orderDetailId);

    var { authorizationById } = this.props;

    if (!authorizationById.validating) {
      authorizationById.validating = "VALIDATING";
      await addUpdateOrderDetail(authorizationById);

      this.runIsValidating();
      validateIcdCpt(addedPatient.clientId, orderDetailId);
    } else {
      if (authorizationById.validating == "VALIDATING") {
        authorizationById.validating = "STOP";
        await addUpdateOrderDetail(authorizationById);

        this.runIsValidating();
        this.runValidateICDCPT();
      } else if (authorizationById.validating == "FREE") {
        authorizationById.validating = "VALIDATING";
        await addUpdateOrderDetail(authorizationById);

        this.runIsValidating();
        validateIcdCpt(addedPatient.clientId, orderDetailId);
      } else if (authorizationById.validating == "STOP") {
        this.runIsValidating();
        validateIcdCpt(addedPatient.clientId, orderDetailId);
      }
    }
  };

  saveProcedure = async () => {
    this.setState({ disabledSaveProcedure: true });
    var error = false;

    if (!this.state.serviceType) {
      this.setState({ serviceTypeErr: "ServiceType is required." });
      error = true;
    } else {
      this.setState({ serviceTypeErr: "" });
    }

    if (this.state.requestType == "OUT-PATIENT") {
      if (!this.state.procedureCode) {
        this.setState({ procedureCodeErr: "Procedure Code is required." });
        error = true;
      } else {
        this.setState({ procedureCodeErr: "" });
      }

      //if(!this.state.procedureDescription){
      //    this.setState({procedureDescriptionErr:'Procedure Description is required.'});
      //    error = true;
      //}else{
      //    this.setState({procedureDescriptionErr:''});
      //}

      if (!this.state.frequency) {
        this.setState({ frequencyErr: "Frequency is required." });
        error = true;
      } else {
        this.setState({ frequencyErr: "" });
      }

      if (!this.state.reasonForExam) {
        this.setState({ reasonForExamErr: "Reason For Exam is required." });
        error = true;
      } else {
        this.setState({ reasonForExamErr: "" });
      }
      /*
                if(!this.state.trackingNumber){
                    this.setState({trackingNumberErr:'Tracking Number is required.'});
                    error = true;
                }else{
                    this.setState({trackingNumberErr:''});
                }
                */
    } else {
      this.setState({ procedureCodeErr: "" });
      this.setState({ procedureDescriptionErr: "" });
      this.setState({ frequencyErr: "" });
      this.setState({ reasonForExamErr: "" });
      this.setState({ trackingNumberErr: "" });
    }

    if (!this.state.placeOfService) {
      this.setState({ placeOfServiceErr: "Place Of Service is required." });
      error = true;
    } else {
      this.setState({ placeOfServiceErr: "" });
    }

    if (!this.state.fromDate) {
      this.setState({ fromDateErr: "From Date is required." });
      error = true;
    } else {
      this.setState({ fromDateErr: "" });
    }

    if (!this.state.toDate) {
      this.setState({ toDateErr: "To Date is required." });
      error = true;
    } else {
      this.setState({ toDateErr: "" });
    }

    if (!this.state.quantity) {
      this.setState({ quantityErr: "Quantity is required." });
      error = true;
    } else {
      this.setState({ quantityErr: "" });
    }

    if (!this.state.unitMeasure) {
      this.setState({ unitMeasureErr: "Unit Measure is required." });
      error = true;
    } else {
      this.setState({ unitMeasureErr: "" });
    }

    if (!this.state.levelOfService) {
      this.setState({ levelOfServiceErr: "Level Of Service is required." });
      error = true;
    } else {
      this.setState({ levelOfServiceErr: "" });
    }
    /*
          if(!this.state.authNumber){
              this.setState({authNumberErr:'Auth Number is required.'});
              error = true;
          }else{
              this.setState({authNumberErr:''});
          }

          if(!this.state.approvedUnits){
              this.setState({approvedUnitsErr:'Approved Units is required.'});
              error = true;
          }else{
              this.setState({approvedUnitsErr:''});
          }

          if(!this.state.approvedProcedureCodes){
              this.setState({approvedProcedureCodesErr:'Approved Procedure Codes is required.'});
              error = true;
          }else{
              this.setState({approvedProcedureCodesErr:''});
          }

          if(!this.state.approvalFromDate){
              this.setState({approvalFromDateErr:'From Date is required.'});
              error = true;
          }else{
              this.setState({approvalFromDateErr:''});
          }

          if(!this.state.approvalToDate){
              this.setState({approvalToDateErr:'To Date is required.'});
              error = true;
          }else{
              this.setState({approvalToDateErr:''});
          }
          */

    if (error == false) {
      const { isNew, orderDetailId } = this.state;
      var {
        addedPatient,
        selectedOrder,
        getOrderHistory,
        orderDetailsDiagnosis,
      } = this.props;
      var procedure = { ...this.state, authorizationId: orderDetailId };

      if (!orderDetailsDiagnosis) {
        orderDetailsDiagnosis = [];
      }

      await this.props.addUpdateOrderProcedure(
        procedure,
        addedPatient.clientId
      );
      await this.props.getOrderProcedure(orderDetailId);
      //await this.props.getOrderDiagnosis(orderDetailId);
      //await this.props.getOrderDetails(orderDetailId);
      //await this.props.getOrderDetails(selectedOrder.id);

      const { savedProcedure } = this.props;

      if (isNew == "Y") {
        this.setState(this.orderDetailProcedureDefault);
      } else {
        this.setIsAuthorizationRequired(savedProcedure);
        this.setState({
          procedureDescription: savedProcedure.procedureDescription,
        });
      }

      if (savedProcedure.orderDetailDisposition == "CODING REVIEW") {
        this.setState({ dispositionCode: "CODING REVIEW" });
        getOrderHistory(orderDetailId);
        this.props.getOrderDetails(selectedOrder.id);
      }

      if (savedProcedure.orderDetailStatus == "VALIDATED") {
        //this.setState({preAuthStatus:savedProcedure.orderDetailStatus});
        //getOrderHistory(orderDetailId);
      } else {
        if (this.state.preAuthStatus == "NEW") {
          if (orderDetailsDiagnosis.length != 0) {
            this.validateICDCPT(addedPatient.clientId, orderDetailId);
          }
        }
      }

      await this.props.getAuthorizationById(orderDetailId);
      var { authorizationById } = this.props;
      this.props.addUpdateOrderDetailSelected(authorizationById);

      this.clearProcedureErrors();
    }
    this.setState({ disabledSaveProcedure: false });
  };

  selectOrderReqRole = (e) => {
    this.setState({ orderReqRole: e.target.value });
    if (e.target.value === "FACILITY") {
      this.setState({ orderReqProviderFacility: this.props.facilities });
    } else {
      this.setState({ orderReqProviderFacility: this.props.providers });
    }

    this.selectProvider(e.target.value, this.state.orderReqProviderFacilityId);
  };

  selectRole = (e) => {
    this.setState({ role: e.target.value });
    if (e.target.value === "FACILITY") {
      this.setState({ providerFacility: this.props.facilities });
      this.setState({ orderReqType: "H" });
    } else {
      this.setState({ providerFacility: this.props.providers });
      this.setState({ orderReqType: "AT" });
    }

    this.selectProvider(e.target.value, this.state.providerFacilityId);

    this.clearAttendingProviderErrors();
  };

  selectProvider = (type, providerId) => {
    var { providers, facilities } = this.props;

    var providerList = [];

    if (!providers) {
      providers = [];
    }

    if (!facilities) {
      facilities = [];
    }

    //console.log("Im here 1");
    if (type == "REFERRING" || type == "RENDERING") {
      providers.map((record, key) => {
        const provider = providers.filter((a) => a.id == providerId);
        console.log("Im here 1", provider);
        console.log("Im here 4" + record.id);
        if (provider.length != 0) {
          if (type == "REFERRING") {
            this.setState({
              orderReqProNpi: provider[0].npi ? provider[0].npi : "",
              orderReqProSpecialtyTaxonomy: provider[0].specialtyTaxonomy
                ? provider[0].specialtyTaxonomy
                : "",
              orderReqContactPhone: provider[0].phoneNumber
                ? provider[0].phoneNumber
                : "",
              orderReqContactFax: provider[0].fax ? provider[0].fax : "",
              orderReqAddress1: provider[0].physicalAddress1
                ? provider[0].physicalAddress1
                : "",
              orderReqCity: provider[0].physicalCity
                ? provider[0].physicalCity
                : "",
              orderReqState: provider[0].physicalState
                ? provider[0].physicalState
                : "",
              orderReqZip: provider[0].physicalZip
                ? provider[0].physicalZip
                : "",
              orderReqProFirstName: provider[0].firstName
                ? provider[0].firstName
                : "",
              orderReqProLastName: provider[0].lastName
                ? provider[0].lastName
                : "",
            });
          } else {
            this.setState({
              npi: provider[0].npi ? provider[0].npi : "",
              specialtyTaxonomy: provider[0].specialtyTaxonomy
                ? provider[0].specialtyTaxonomy
                : "",
              contactPhone: provider[0].phoneNumber
                ? provider[0].phoneNumber
                : "",
              contactFax: provider[0].fax ? provider[0].fax : "",
              address1: provider[0].physicalAddress1
                ? provider[0].physicalAddress1
                : "",
              city: provider[0].physicalCity ? provider[0].physicalCity : "",
              state: provider[0].physicalState ? provider[0].physicalState : "",
              zip: provider[0].physicalZip ? provider[0].physicalZip : "",
              firstName: provider[0].firstName ? provider[0].firstName : "",
              lastName: provider[0].lastName ? provider[0].lastName : "",
            });
          }
        }
      });
    } else if (type == "FACILITY") {
      facilities.map((record, key) => {
        const facility = facilities.filter((a) => a.id == providerId);
        console.log("Im here 1", facility);
        console.log("Im here 4" + record.id);
        if (facility.length != 0) {
          this.setState({
            name: facility[0].name ? facility[0].name : "",
            npi: facility[0].npi ? facility[0].npi : "",
            specialtyTaxonomy: facility[0].specialtyTaxonomy
              ? facility[0].specialtyTaxonomy
              : "",
            address1: facility[0].physicalAddress1
              ? facility[0].physicalAddress1
              : "",
            city: facility[0].physicalCity ? facility[0].physicalCity : "",
            state: facility[0].physicalState ? facility[0].physicalState : "",
            zip: facility[0].physicalZip ? facility[0].physicalZip : "",
            contactPhoneAttendingProvider: facility[0].mainPhoneNumber ? facility[0].mainPhoneNumber : "",
          });
        }
      });
    }
  };

  onClickHandler(record, e) {
    this.setState({ isValidating: false });

    var {
      disabledSaveProcedure,
      disabledSaveDiagnosis,
      disabledSaveAttendingProvider,
      disabledSaveNote,
      disabledSaveOrderDetail,
      disabledSaveOrder,
    } = this.state;

    if (
      disabledSaveOrder == false &&
      disabledSaveProcedure == false &&
      disabledSaveDiagnosis == false &&
      disabledSaveNote == false &&
      disabledSaveAttendingProvider == false &&
      disabledSaveOrderDetail == false
    ) {
      var { btnClick } = this.state;

      this.setState(record);
      if (record.id) {
        this.setState({ orderDetailId: record.id });
        this.props.getAuthorization278(record.id);
        this.props.addUpdateOrderDetailSelected(record);
      } else {
        this.setState({ orderDetailId: "" });
      }

      this.runIsValidating();

      this.props.getOrderProviderSelected([]);
      this.props.getOrderNoteSelected([]);
      this.props.getOrderDiagnosisSelected([]);
      this.props.getOrderProcedureSelected([]);
      this.props.getOrderHistorySelected([]);

      if (record.id) {
        this.props.getOrderProvider(record.id);
        this.props.getOrderNote(record.id);
        this.props.getOrderDiagnosis(record.id);
        this.props.getOrderProcedure(record.id);
        this.props.getOrderHistory(record.id);
      }

      var { orderDetails } = this.props;
      var { orderOrderNumber, orderRequestedDate } = this.state;
      var newOrderDetailNumber = "";
      if (!orderDetails) {
        orderDetails = [];
      }
      newOrderDetailNumber = orderOrderNumber + "_" + orderDetails.length;

      if (!record.id) {
        this.setState({
          orderNumber: newOrderDetailNumber,
          requestedDate: orderRequestedDate,
          dispositionCode: "JUST PLACED",
        });
      }

      if (!record.payer) {
        this.setState({ submissionType: "Phone Call" });
      }

      const elementsShow = document.querySelectorAll("tr.main-table");
      const hiddenElement = e.currentTarget.nextSibling;

      /*Reset all show details*/
      elementsShow.forEach((element) => {
        if (hiddenElement != element) {
          element.classList.remove("show");
        }
      });

      /*Set selected row*/
      const selected = e.currentTarget;
      const elementsSelected = document.querySelectorAll(
        "tr.selected-table-row"
      );

      elementsSelected.forEach((element) => {
        if (selected != element) {
          element.classList.remove("selected-table-row");
        }
      });

      selected.classList.add("selected-table-row");

      /*Reset all up arrow*/
      const elementsArrow = document.querySelectorAll(".fa-chevron-up");
      elementsArrow.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });

      /*set up and show details on selected row*/
      const arrowSelected = document.querySelectorAll(
        ".selected-table-row .fa"
      );

      if (hiddenElement.className.indexOf("collapse show") > -1) {
        hiddenElement.classList.remove("show");

        arrowSelected.forEach((element) => {
          element.classList.remove("fa-chevron-up");
          element.classList.add("fa-chevron-down");
        });
      } else {
        hiddenElement.classList.add("show");

        arrowSelected.forEach((element) => {
          element.classList.remove("fa-chevron-down");
          element.classList.add("fa-chevron-up");
        });
      }

      this.clearEncounterOrderDetailErrors();
    }
  }

  onClickHandlerInsurance(record, e) {
    console.log("record", record);

    this.setState({
      id: record.id,
      patientId: record.patientId,
      type: record.type,
      relationInsurance: record.relation,
      payerIdInsurance: record.payerId,
      address1Insurance: record.address1,
      address2Insurance: record.address2,
      cityInsurance: record.city,
      stateInsurance: record.state,
      zipInsurance: record.zip,
      phoneNumber: record.phoneNumber,
      groupName: record.groupName,
      groupId: record.groupId,
      policyNumber: record.policyNumber,
      plan: record.plan,
      insBegin: record.insBegin,
      insEnd: record.insEnd,
      isNew: record.isNew,
    });

    this.clearInsuranceErrors();

    const elementsShow = document.querySelectorAll("tr.main-table");
    const hiddenElement = e.currentTarget.nextSibling;

    /*Reset all show details*/
    elementsShow.forEach((element) => {
      if (hiddenElement != element) {
        element.classList.remove("show");
      }
    });

    /*Set selected row*/
    const selected = e.currentTarget;
    const elementsSelected = document.querySelectorAll("tr.selected-table-row");

    elementsSelected.forEach((element) => {
      if (selected != element) {
        element.classList.remove("selected-table-row");
      }
    });

    selected.classList.add("selected-table-row");

    /*Reset all up arrow*/
    const elementsArrow = document.querySelectorAll(".fa-chevron-up");
    elementsArrow.forEach((element) => {
      element.classList.remove("fa-chevron-up");
      element.classList.add("fa-chevron-down");
    });

    /*set up and show details on selected row*/
    const arrowSelected = document.querySelectorAll(".selected-table-row .fa");

    if (hiddenElement.className.indexOf("collapse show") > -1) {
      hiddenElement.classList.remove("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });
    } else {
      hiddenElement.classList.add("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-down");
        element.classList.add("fa-chevron-up");
      });
    }

    this.clearEncounterOrderDetailErrors();
  }

  handleIsAuthorizationRequired(event) {
    var isAuthorizationRequired = "N";
    var isAuthorizationRequiredChecked = [];
    if (event.target.checked) {
      isAuthorizationRequired = "Y";
      isAuthorizationRequiredChecked = ["Y"];
    }
    this.setState({
      isAuthorizationRequired: isAuthorizationRequired,
      isAuthorizationRequiredChecked: isAuthorizationRequiredChecked,
    });
  }

  setIsAuthorizationRequired = (record) => {
    var isAuthorizationRequired = "N";
    var isAuthorizationRequiredChecked = [];
    if (
      record.isAuthorizationRequired &&
      record.isAuthorizationRequired != null
    ) {
      if (record.isAuthorizationRequired == "N") {
        isAuthorizationRequired = "N";
      } else {
        isAuthorizationRequiredChecked = ["Y"];
        isAuthorizationRequired = "Y";
      }
    }

    this.setState({
      isAuthorizationRequired: isAuthorizationRequired,
      isAuthorizationRequiredChecked: isAuthorizationRequiredChecked,
    });
  };

  //Documentation
  saveDocumentation = async () => {
    var {
      isNew,
      documentType,
      documentName,
      selectedFile,
      selectedAuthorization,
    } = this.state;
    var { authentication } = this.props;
    var filename = "";

    console.log(selectedFile);

    if (selectedFile != null && selectedFile != "") {
      var name = selectedFile.name;
      var fileType = name.substring(name.length - 3);
      filename =
        selectedAuthorization.clientId +
        "_" +
        selectedAuthorization.patientId +
        "_" +
        documentType +
        "_" +
        documentName +
        "." +
        fileType;
      filename = filename.replace(" ", "_");
      var response = await this.uploadFile(
        selectedFile,
        filename,
        selectedAuthorization.clientId
      );
      if (response.success) {
      } else {
        return;
      }
    }

    document.getElementById("myfile").value = null;
    var dateUploaded = "";
    dateUploaded = new Date();
    console.log(dateUploaded);
    var documentation = {
      ...this.state,
      patientId: selectedAuthorization.patientId,
      clientId: selectedAuthorization.clientId,
      orgId: selectedAuthorization.orgId,
      filename: filename,
      username: authentication.username,
      dateUploaded: dateUploaded,
    };

    await this.props.addUpdateDocumentation(documentation);
    await this.props.getPatientDocumentation(selectedAuthorization.patientId);
    this.setState({ documentType: "", documentName: "" });
  };

  uploadFile = async (file, fileName, clientName) => {
    //var axios = require('axios');
    var FormData = require("form-data");
    //var fs = require('fs');
    var data = new FormData();
    data.append("file", file);

    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

    var config = {
      method: "post",
      url:
        getDocumentationUploadFileUriPath() +
        "?name=" +
        fileName +
        "&clientName=" +
        clientName,
      headers: {
        Authorization: localStorage.getItem("id_token") || null,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
    var retData = {};
    retData.success = false;
    await axios(config)
      .then(function (response) {
        retData.success = true;
        retData.response = response.data;
      })
      .catch(function (error) {
        retData.success = false;
        retData.error = error;
      });
    return retData;
  };

  handleShowCloseDocumentation = (val) => {
    this.setState({ showDocumentation: val });
  };

  setDefaultCheckedDocuments = async () => {
    var { activeRecords, documentIsChecked } = this.state;
    documentIsChecked = [];
    var { documentationList, authorizationDocumentationList } = this.props;

    console.log(
      "documentationList" +
        documentationList +
        "authorizationDocumentationList" +
        authorizationDocumentationList
    );

    if (
      documentationList == "CLEARED" ||
      authorizationDocumentationList == "CLEARED"
    ) {
      this.setDefaultCheckedDocuments();
    }

    if (!documentationList) {
      documentationList = [];
    }

    documentationList.map((record, key) => {
      if (authorizationDocumentationList.length != 0) {
        const authorizationDocument = authorizationDocumentationList.filter(
          (a) => a.documentationId == record.id
        );

        if (authorizationDocument.length != 0) {
          if (authorizationDocument[0].status == "ACTIVE") {
            console.log("authorizationDocument", authorizationDocument);
            documentIsChecked.push(record.id);
          }
        }
      }
    });

    this.setState({ documentIsChecked: documentIsChecked });
  };

  handleClickDocument = (e) => {
    var { id, checked } = e.target;
    var { documentIsChecked, selectedAuthorization } = this.state;

    var status = "ACTIVE";

    id = Number(id);
    this.setState({ documentIsChecked: [...documentIsChecked, id] });
    if (!checked) {
      status = "INACTIVE";
      this.setState({
        documentIsChecked: documentIsChecked.filter((item) => item !== id),
      });
    }

    this.props.getAddUpdateAuthorizationDocumentationByStatus(
      selectedAuthorization.clientId,
      selectedAuthorization.patientId,
      selectedAuthorization.id,
      id,
      status
    );
  };

  getPayerName = (payerId) => {
    var name = "";
    const { payers } = this.props;
    var payer = payers.filter((a) => a.id == payerId);
    if (payer.length != 0) {
      name = payer[0].name;
    }

    return name;
  };

  handleShowCloseInsurance = (val) => {
    this.setState({ showInsuranceModal: val });
  };

  addDashes = (phone) => {
    let x = phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    phone = !x[2] ? x[1] : +x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    return phone;
  };

  handleShowCloseFacility = (val) => {
    this.setState({
      outpatientRateFacility: "",
      outpatientRateIsCheckedFacility: [],
      inpatientRateFacility: "",
      inpatientRateIsCheckedFacility: [],
      contactCaseManagerFacility: "",
      contactCaseRepresentativeFacility: "",
      contactCaseRepresentativePhoneFacility: "",
      statusFacility: "ACTIVE",
      nameFacility: "",
      executiveSponsorNameFacility: "",
      executiveSponsorPhoneFacility: "",
      accountsPayablePhoneNumberFacility: "",
      contactInvoicingFacility: "",
      contactInvoicingEmailFacility: "",
      taxIdFacility: "",
      npiFacility: "",
      specialtyTaxonomyFacility: "",
      runEligibilityFacility: "",
      runEligibilityFacilityIsChecked: ["N"],
      fullServiceFacility: "",
      fullServiceFacilityIsChecked: ["N"],
      physicalAddress: "",
      physicalAddress1: "",
      physicalAddress2: "",
      physicalCity: "",
      physicalState: "",
      physicalZip: "",
      mailingAddress: "",
      mailingAddress1: "",
      mailingAddress2: "",
      mailingCity: "",
      mailingState: "",
      mailingZip: "",
      mainPhoneNumber: "",

      physicalAddressErr: "",
      physicalAddress1Err: "",
      physicalAddress2Err: "",
      physicalCityErr: "",
      physicalStateErr: "",
      physicalZipErr: "",
      mailingAddressErr: "",
      mailingAddress1Err: "",
      mailingAddress2Err: "",
      mailingCityErr: "",
      mailingStateErr: "",
      mailingZipErr: "",

      nameFacilityErr: "",
      specialtyTaxonomyFacilityErr: "",
      npiFacilityErr: "",
      taxIdFacilityErr: "",
      mainPhoneNumberErr: "",
      executiveSponsorNameFacilityErr: "",
      executiveSponsorPhoneFacilityErr: "",
      accountsPayablePhoneNumberFacilityErr: "",
      contactInvoicingFacilityErr: "",
      contactInvoicingEmailFacilityErr: "",
      outpatientRateFacilityErr: "",
      inpatientRateFacilityErr: "",
      contactCaseManagerFacilityErr: "",
      contactCaseRepresentativeFacilityErr: "",
      contactCaseRepresentativePhoneFacilityErr: "",
      type: "Institutional",
    });

    this.setState({ showFacilityModal: val });
  };

  handleRunEligibilityFacility(event) {
    var runEligibilityFacility = "N";
    var runEligibilityFacilityIsChecked = [];
    if (event.target.checked) {
      runEligibilityFacility = "Y";
      runEligibilityFacilityIsChecked = ["Y"];
    }
    this.setState({
      runEligibilityFacility: runEligibilityFacility,
      runEligibilityFacilityIsChecked: runEligibilityFacilityIsChecked,
    });
  }

  handleFullServiceFacility(event) {
    var fullServiceFacility = "N";
    var fullServiceFacilityIsChecked = [];
    if (event.target.checked) {
      fullServiceFacility = "Y";
      fullServiceFacilityIsChecked = ["Y"];
    }
    this.setState({
      fullServiceFacility: fullServiceFacility,
      fullServiceFacilityIsChecked: fullServiceFacilityIsChecked,
    });
  }

  saveFacility = async () => {
    var error = false;

    if (!this.state.nameFacility) {
      this.setState({ nameFacilityErr: "Name is required." });
      error = true;
    } else {
      this.setState({ nameFacilityErr: "" });
    }

    /*
        if(!this.state.specialtyTaxonomyFacility){
                    this.setState({specialtyTaxonomyFacilityErr:'Specialty Taxonomy is required.'});
                    error = true;
        }else{
            this.setState({specialtyTaxonomyFacilityErr:''});
        }
        */
    if (!this.state.npiFacility) {
      this.setState({ npiFacilityErr: "NPI is required." });
      error = true;
    } else {
      this.setState({ npiFacilityErr: "" });
    }

    if (!this.state.physicalAddress) {
      this.setState({ physicalAddressErr: "Physical Address is required." });
      error = true;
    } else {
      this.setState({ physicalAddressErr: "" });
    }

    if (!this.state.mailingAddress) {
      this.setState({ mailingAddressErr: "Mailing Address is required." });
      error = true;
    } else {
      this.setState({ mailingAddressErr: "" });
    }

    /*
        if(!this.state.taxIdFacility){
                    this.setState({taxIdFacilityErr:'Tax Id is required.'});
                    error = true;
        }else{
            this.setState({taxIdFacilityErr:''});
        }
        if(!this.state.mainPhoneNumber){
                    this.setState({mainPhoneNumberErr:'Main Phone Number is required.'});
                    error = true;
        }else{
            this.setState({mainPhoneNumberErr:''});
        }
        if(!this.state.executiveSponsorNameFacility){
                    this.setState({executiveSponsorNameFacilityErr:'Exe. Sponsor Name is required.'});
                    error = true;
        }else{
            this.setState({executiveSponsorNameFacilityErr:''});
        }
        if(!this.state.executiveSponsorPhoneFacility){
                    this.setState({executiveSponsorPhoneFacilityErr:'Exe. Sponsor Phone is required.'});
                    error = true;
        }else{
            this.setState({accountsPayablePhoneNumberFacilityErr:''});
        }
        if(!this.state.accountsPayablePhoneNumberFacility){
                    this.setState({accountsPayablePhoneNumberFacilityErr:'Accnt. Payable Phone is required.'});
                    error = true;
        }else{
            this.setState({accountsPayablePhoneNumberFacilityErr:''});
        }
        if(!this.state.contactInvoicingFacility){
                    this.setState({contactInvoicingFacilityErr:'Contact Invoicing is required.'});
                    error = true;
        }else{
            this.setState({contactInvoicingFacilityErr:''});
        }
        if(!this.state.contactInvoicingEmailFacility){
                    this.setState({contactInvoicingEmailFacilityErr:'Cont. Invoicing Email is required.'});
                    error = true;
        }else{
            this.setState({contactInvoicingEmailFacilityErr:''});
        }
        if(!this.state.outpatientRateFacility){
                    this.setState({outpatientRateFacilityErr:'Outpatient Rate is required.'});
                    error = true;
        }else{
            this.setState({outpatientRateFacilityErr:''});
        }
        if(!this.state.inpatientRateFacility){
                    this.setState({inpatientRateFacilityErr:'Inpatient Rate is required.'});
                    error = true;
        }else{
            this.setState({inpatientRateFacilityErr:''});
        }
        if(!this.state.contactCaseManagerFacility){
                    this.setState({contactCaseManagerFacilityErr:'Cont. Case Manager is required.'});
                    error = true;
        }else{
            this.setState({contactCaseManagerFacilityErr:''});
        }
        if(!this.state.contactCaseRepresentativeFacility){
                    this.setState({contactCaseRepresentativeFacilityErr:'Cont. Case Representative is required.'});
                    error = true;
        }else{
            this.setState({contactCaseRepresentativeFacilityErr:''});
        }
        if(!this.state.contactCaseRepresentativePhoneFacility){
                    this.setState({contactCaseRepresentativePhoneFacilityErr:'Cont. Case Representative Phone is required.'});
                    error = true;
        }else{
            this.setState({contactCaseRepresentativePhoneFacilityErr:''});
        }

        */

    console.log("I am here..." + error);
    if (error == false) {
      const { isNew } = this.state;
      const state = this.state;
      const { addedPatient } = this.props;

      var facility = { ...this.state, clientId: addedPatient.clientId };
      facility.id = "";
      facility.inpatientRate = state.inpatientRateFacility;
      facility.outpatientRate = state.outpatientRateFacility;
      facility.status = state.statusFacility;
      facility.name = state.nameFacility;
      facility.executiveSponsorName = state.executiveSponsorNameFacility;
      facility.executiveSponsorPhone = state.executiveSponsorPhoneFacility;
      facility.accountsPayablePhoneNumber =
        state.accountsPayablePhoneNumberFacility;
      facility.contactInvoicing = state.contactInvoicingFacility;
      facility.contactInvoicingEmail = state.contactInvoicingEmailFacility;
      facility.taxId = state.taxIdFacility;
      facility.npi = state.npiFacility;
      facility.specialtyTaxonomy = state.specialtyTaxonomyFacility;
      facility.runEligibility = state.runEligibilityFacility;
      facility.fullService = state.fullServiceFacility;
      facility.contactCaseManager = state.contactCaseManagerFacility;
      facility.contactCaseRepresentative =
        state.contactCaseRepresentativeFacility;
      facility.contactCaseRepresentativePhone =
        state.contactCaseRepresentativePhoneFacility;
      facility.type = state.type;

      await this.props.addUpdateFacility(facility);
      await this.props.getFacilities(addedPatient.clientId);

      this.setState({ showFacilityModal: false });
    }
  };

  handleShowCloseProvider = (val) => {
    this.setState({
      nameProvider: "",
      nameProviderErr: "",
      statusProvider: "ACTIVE",
      firstName: "",
      firstNameErr: "",
      lastName: "",
      lastNameErr: "",
      specialtyTaxonomyProvider: "",
      specialtyTaxonomyProviderErr: "",
      npiProvider: "",
      npiProviderErr: "",
      taxIdProvider: "",
      taxIdProviderErr: "",
      physicalAddress: "",
      phoneNumber: "",
      phoneNumberErr: "",
      fax: "",
      faxErr: "",
      email: "",
      emailErr: "",
      mailingAddress: "",
      caseManager: "",
      caseManagerErr: "",
      caseManagerEmail: "",
      caseManagerEmailErr: "",
      concurrentReviewNurse: "",
      concurrentReviewNurseErr: "",
      concurrentReviewNurseEmail: "",
      concurrentReviewNurseEmailErr: "",
      contactName: "",
      contactNameErr: "",
      contactPhone: "",
      contactPhoneErr: "",
      contactFax: "",
      contactFaxErr: "",
      type: "Professional",
      physicalAddressErr: "",
      physicalAddress1Err: "",
      physicalAddress2Err: "",
      physicalCityErr: "",
      physicalStateErr: "",
      physicalZipErr: "",
      mailingAddressErr: "",
      mailingAddress1Err: "",
      mailingAddress2Err: "",
      mailingCityErr: "",
      mailingStateErr: "",
      mailingZipErr: "",
    });

    this.setState({ showProviderModal: val });
  };

  setFullName = () => {
    var name = "";
    name = this.state.lastName + ", " + this.state.firstName;
    this.setState({ nameProvider: name });
  };

  saveProvider = async () => {
    var error = false;
    if (!this.state.nameProvider) {
      this.setState({ nameProviderErr: "Name is required." });
      error = true;
    } else {
      this.setState({ nameProviderErr: "" });
    }
    if (!this.state.firstName) {
      this.setState({ firstNameErr: "First Name is required." });
      error = true;
    } else {
      this.setState({ firstNameErr: "" });
    }
    if (!this.state.lastName) {
      this.setState({ lastNameErr: "LastName is required." });
      error = true;
    } else {
      this.setState({ lastNameErr: "" });
    }

    if (!this.state.physicalAddress) {
      this.setState({ physicalAddressErr: "Physical Address is required." });
      error = true;
    } else {
      this.setState({ physicalAddressErr: "" });
    }

    if (!this.state.mailingAddress) {
      this.setState({ mailingAddressErr: "Mailing Address is required." });
      error = true;
    } else {
      this.setState({ mailingAddressErr: "" });
    }

    if (!this.state.npiProvider) {
      this.setState({ npiProviderErr: "NPI is required." });
      error = true;
    } else {
      this.setState({ npiProviderErr: "" });
    }

    /*
        if(!this.state.specialtyTaxonomyProvider){
                    this.setState({specialtyTaxonomyProviderErr:'Specialty/ Taxonomy is required.'});
                    error = true;
        }else{
            this.setState({specialtyTaxonomyProviderErr:''});
        }

        if(!this.state.taxIdProvider){
                    this.setState({taxIdProviderErr:'Tax ID is required.'});
                    error = true;
        }else{
            this.setState({taxIdProviderErr:''});
        }
        if(!this.state.caseManager){
                    this.setState({caseManagerErr:'Case Manager is required.'});
                    error = true;
        }else{
            this.setState({caseManagerErr:''});
        }
        if(!this.state.caseManagerEmail){
                    this.setState({caseManagerEmailErr:'Case Manager Email is required.'});
                    error = true;
        }else{
            this.setState({caseManagerEmailErr:''});
        }
        if(!this.state.concurrentReviewNurse){
                    this.setState({concurrentReviewNurseErr:'Concurrent Review Nurse is required.'});
                    error = true;
        }else{
            this.setState({concurrentReviewNurseErr:''});
        }
        if(!this.state.concurrentReviewNurseEmail){
                    this.setState({concurrentReviewNurseEmailErr:'Concurrent Review Nurse Email is required.'});
                    error = true;
        }else{
            this.setState({concurrentReviewNurseEmailErr:''});
        }
        if(!this.state.contactName){
                    this.setState({contactNameErr:'Contact Name is required.'});
                    error = true;
        }else{
            this.setState({contactNameErr:''});
        }
        if(!this.state.contactPhone){
                    this.setState({contactPhoneErr:'Contact Phone is required.'});
                    error = true;
        }else{
            this.setState({contactPhoneErr:''});
        }
        if(!this.state.contactFax){
                    this.setState({contactFaxErr:'Contact Fax is required.'});
                    error = true;
        }else{
            this.setState({contactFaxErr:''});
        }
        if(!this.state.email){
                    this.setState({emailErr:'Email is required.'});
                    error = true;
        }else{
            this.setState({emailErr:''});
        }
        if(!this.state.fax){
                    this.setState({faxErr:'Fax is required.'});
                    error = true;
        }else{
            this.setState({faxErr:''});
        }
        if(!this.state.phoneNumber){
                    this.setState({phoneNumberErr:'Phone Number is required.'});
                    error = true;
        }else{
            this.setState({phoneNumberErr:''});
        }
        */

    if (error == false) {
      const { isNew } = this.state;
      const state = this.state;
      const { addedPatient } = this.props;

      var provider = { ...this.state, clientId: addedPatient.clientId };
      provider.id = "";
      provider.status = state.statusProvider;
      provider.name = state.nameProvider;
      provider.taxId = state.taxIdProvider;
      provider.npi = state.npiProvider;
      provider.specialtyTaxonomy = state.specialtyTaxonomyProvider;
      provider.type = state.type;
      console.log("provider 1", provider);
      await this.props.addUpdateProvider(provider);
      await this.props.getProvider(addedPatient.clientId);

      this.setState({ showProviderModal: false });
    }
  };

  submitEditAddressModal = () => {
    var address = "";
    const { editAddressModalName } = this.state;
    var error = false;

    if (editAddressModalName == "PHYSICAL") {
      if (!this.state.physicalAddress1) {
        this.setState({ physicalAddress1Err: "Address1 is required." });
        error = true;
      } else {
        this.setState({ physicalAddress1Err: "" });
      }

      if (!this.state.physicalCity) {
        this.setState({ physicalCityErr: "City is required." });
        error = true;
      } else {
        this.setState({ physicalCityErr: "" });
      }

      if (!this.state.physicalState) {
        this.setState({ physicalStateErr: "State is required." });
        error = true;
      } else {
        this.setState({ physicalStateErr: "" });
      }

      if (!this.state.physicalZip) {
        this.setState({ physicalZipErr: "Zip is required." });
        error = true;
      } else {
        this.setState({ physicalZipErr: "" });
      }
    } else {
      if (!this.state.mailingAddress1) {
        this.setState({ mailingAddress1Err: "Address1 is required." });
        error = true;
      } else {
        this.setState({ mailingAddress1Err: "" });
      }

      if (!this.state.mailingCity) {
        this.setState({ mailingCityErr: "City is required." });
        error = true;
      } else {
        this.setState({ mailingCityErr: "" });
      }

      if (!this.state.mailingState) {
        this.setState({ mailingStateErr: "State is required." });
        error = true;
      } else {
        this.setState({ mailingStateErr: "" });
      }

      if (!this.state.mailingZip) {
        this.setState({ mailingZipErr: "Zip is required." });
        error = true;
      } else {
        this.setState({ mailingZipErr: "" });
      }
    }

    if (error == false) {
      if (editAddressModalName == "PHYSICAL") {
        address =
          this.state.physicalAddress1 +
          ", " +
          this.state.physicalCity +
          ", " +
          this.state.physicalState +
          ", " +
          this.state.physicalZip;
        this.setState({ physicalAddress: address });
      } else {
        address =
          this.state.mailingAddress1 +
          ", " +
          this.state.mailingCity +
          ", " +
          this.state.mailingState +
          ", " +
          this.state.mailingZip;
        this.setState({ mailingAddress: address });
      }

      this.setState({ editAddressModal: false });
    }
  };

  showEditAddressModal = (modalName) => {
    console.log(modalName);
    this.setState({ editAddressModal: true, editAddressModalName: modalName });
  };

  handleShowCloseUploadFile = (val) => {
    this.setState({ showUploadFile: val });
  };
  //End Documentation

  onClickHandlerSubTable(record, e) {
    var {
      disabledSaveProcedure,
      disabledSaveDiagnosis,
      disabledSaveAttendingProvider,
      disabledSaveNote,
      disabledSaveOrderDetail,
      disabledSubmit278,
    } = this.state;

    if (
      disabledSaveProcedure == false &&
      disabledSaveDiagnosis == false &&
      disabledSaveAttendingProvider == false &&
      disabledSaveNote == false &&
      disabledSaveOrderDetail == false &&
      disabledSubmit278 == false
    ) {
      var { btnClick, orderDetailId } = this.state;

      this.setState(record);

      this.setIsAuthorizationRequired(record);
      const elementsShow = document.querySelectorAll("tr.sub-table");
      const hiddenElement = e.currentTarget.nextSibling;

      /*Reset all show details*/
      elementsShow.forEach((element) => {
        if (hiddenElement != element) {
          element.classList.remove("show");
        }
      });

      /*Set selected row*/
      const selected = e.currentTarget;
      const elementsSelected = document.querySelectorAll(
        "tr.selected-table-row"
      );

      elementsSelected.forEach((element) => {
        if (selected != element) {
          element.classList.remove("selected-table-row");
        }
      });

      selected.classList.add("selected-table-row");

      /*Reset all up arrow*/
      const elementsArrow = document.querySelectorAll(".fa-chevron-up");
      elementsArrow.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });

      /*set up and show details on selected row*/
      const arrowSelected = document.querySelectorAll(
        ".selected-table-row .fa"
      );

      if (hiddenElement.className.indexOf("collapse show") > -1) {
        hiddenElement.classList.remove("show");

        arrowSelected.forEach((element) => {
          element.classList.remove("fa-chevron-up");
          element.classList.add("fa-chevron-down");
        });
      } else {
        hiddenElement.classList.add("show");

        arrowSelected.forEach((element) => {
          element.classList.remove("fa-chevron-down");
          element.classList.add("fa-chevron-up");
        });
      }

      this.clearAttendingProviderErrors();
      this.clearDiagnosisErrors();
      this.clearProcedureErrors();
    }
  }

  addDashes = (phone) => {
    let x = phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    phone = !x[2] ? x[1] : +x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    return phone;
  };

  openDocumentationReq = async () => {
    this.setState({ medDocReqModal: true });

    const { orderDetailsProcedure } = this.props;

    var procedureCodes = [];
    orderDetailsProcedure.forEach((procedureCode) => {
      procedureCodes.push(procedureCode.procedureCode);
    });

    this.props.getMedicalReq(procedureCodes);
  };

  openMedicalNecessity = async (authId) => {
    const tab = window.open("about:blank");

    const { orderDetailsProcedure } = this.props;

    var count = 0,
      concat = "";
    var procedureCodes = "";
    orderDetailsProcedure.forEach((procedureCode) => {
      if (count == 0) {
        concat = "";
      } else {
        concat = "TEARS";
      }

      count++;

      procedureCodes += concat + procedureCode.procedureCode;
    });

    console.log(procedureCodes);
    var fileName = "IMAGING_GUIDELINES_" + authId;
    var type = "application/pdf";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      this.props.getMedicalNecessity(authId, procedureCodes);
    } else {
      console.log("I am here...");
      await this.props.getMedicalNecessity(authId, procedureCodes);

      if (this.props.medicalNecessity instanceof Blob) {
        console.log("I am here 2...");
        const file = new Blob([this.props.medicalNecessity], { type: type });

        const fileURL = URL.createObjectURL(file);
        console.log(fileURL);
        this.props.medicalNecessityClear();

        tab.location = fileURL;
      }
    }
  };

  handleDispositionCode = (e) => {
    var { rejectedReasonCode } = this.state;

    const isNegativeDisposition =
      this.state.dispositionCode === "NOT CERTIFIED" ||
      this.state.dispositionCode === "NOT CERTIFIED - APPEALED" ||
      this.state.dispositionCode === "PARTIALLY AFFIRMED" ||
      this.state.dispositionCode === "REJECTED";

    if (isNegativeDisposition && !!rejectedReasonCode) {
      this.setState({ rejectedReasonCode: null });
    }

    this.setState({
      dispositionCode: e.target.value,
    });
  };

  handlePreAuthStatus = (e) => {
    if (
      this.state.preAuthStatus === "AFFIRMED DECISION" &&
      !!this.state.rejectedReasonCode
    ) {
      this.setState({ rejectedReasonCode: null });
    }

    this.setState({
      preAuthStatus: e.target.value,
    });
  };

  render() {
    var {
      orderDetails,
      selectedEncounter,
      selectedOrder,
      selectedOrderEligibility,
      selectedOrderDetail278,
      providers,
      orderDetailsProviders,
      orderDetailsNote,
      orderDetailsDiagnosis,
      orderDetailsProcedure,
      orderDetailsHistory,
      documentationList,
      payers,
      insuranceList,
      dispositionCodes,
      reasonCodes,
      placeOfServices,
      insuranceList,
      addedPatient,
      orderDuplicates,
      menu,
      selectedOrderDetail,
    } = this.props;

    var {
      orderDetailId,
      providerFacility,
      orderReqProviderFacility,
      role,
      orderReqRole,
      documentIsChecked,
      showInsuranceModal,
      showFacilityModal,
      showProviderModal,
      editAddressModal,
      orderDuplicateFoundModal,
      medDocReqModal,
      medNecReqModal,
      isValidating,
      eligibilityResultDetail,
      eligibilityResultJson,
      resultDetail278,
      resultJson278,
    } = this.state;
    if (!orderDetails) {
      orderDetails = [];
    }

    var hideOtherAttendingProvider = { display: "block" };
    var hideOtherFacilityProvider = { display: "block" };
    if (role === "FACILITY") {
      providerFacility = this.props.facilities;
      hideOtherAttendingProvider = { display: "none" };
    } else {
      providerFacility = this.props.providers;
      hideOtherFacilityProvider = { display: "none" };
    }

    if (!providerFacility) {
      providerFacility = [];
    }

    if (orderReqRole === "FACILITY") {
      orderReqProviderFacility = this.props.facilities;
    } else {
      orderReqProviderFacility = this.props.providers;
    }

    if (!orderReqProviderFacility) {
      orderReqProviderFacility = [];
    }

    orderDetails.sort((a, b) => {
      return a.id - b.id;
    });

    let newOrdDetailExist = orderDetails.filter(
      (orderDetail) => orderDetail.orderDetailId == ""
    );

    if (newOrdDetailExist.length == 0) {
      orderDetails.push(this.orderDetailDefault);
    }

    if (!providers) {
      providers = [];
    }

    if (!orderDetailsNote) {
      orderDetailsNote = [];
    }

    if (!orderDetailsProviders) {
      orderDetailsProviders = [];
    }

    if (!orderDetailsDiagnosis) {
      orderDetailsDiagnosis = [];
    }

    if (!orderDetailsProcedure) {
      orderDetailsProcedure = [];
    }

    if (!orderDetailsHistory) {
      orderDetailsHistory = [];
    }

    if (!dispositionCodes) {
      dispositionCodes = [];
    }

    if (!reasonCodes) {
      reasonCodes = [];
    }

    dispositionCodes.sort((a, b) => {
      const nameA = a.value.toUpperCase(); // ignore upper and lowercase
      const nameB = b.value.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    if (!placeOfServices) {
      placeOfServices = [];
    }

    if (!insuranceList) {
      insuranceList = [];
    }

    if (!selectedOrderEligibility) {
      selectedOrderEligibility = [];
    }

    insuranceList.sort((a, b) => {
      return a.id - b.id;
    });

    placeOfServices.sort((a, b) => {
      const nameA = Number(a.code); // ignore upper and lowercase
      const nameB = Number(b.code); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    orderDetailsProviders.sort((a, b) => {
      return a.id - b.id;
    });

    orderDetailsDiagnosis.sort((a, b) => {
      return a.id - b.id;
    });

    orderDetailsProcedure.sort((a, b) => {
      return a.id - b.id;
    });

    orderDetailsHistory.sort((a, b) => {
      return b.id - a.id;
    });

    if (!payers) {
      payers = [];
    }

    var payerList = [];
    this.props.payers.map((payer, key) => {
      const insurancePayer = insuranceList.filter((a) => a.payerId == payer.id);

      if (insurancePayer.length != 0) {
        payerList.push(payer);
      }
    });

    var hideOrderDetail = { display: "block" };
    if (!selectedOrder || selectedOrder.id == undefined) {
      hideOrderDetail = { display: "none" };
    }

    var hideOrderDetailOthers = { display: "block" };
    if (!orderDetailId) {
      hideOrderDetailOthers = { display: "none" };
    }

    if (!documentationList) {
      documentationList = [];
    } else {
      if (documentationList == "CLEARED") {
        documentationList = [];
      }
    }

    var spinner = <div></div>;
    if (isValidating === true) {
      spinner = (
        <div class="spinner-border ms-1 mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      );
    }
    var back = "/portal";
    if (menu) {
      if (menu == "Portal") {
        back = "/portal";
      } else if (menu == "Patients") {
        back = "/accounts/add-patients";
      }
    }

    var MaskDateInput = [
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    //Render condition for Reason Code Field
    const isNegativeDisposition =
      this.state.dispositionCode === "NOT CERTIFIED" ||
      this.state.dispositionCode === "NOT CERTIFIED - APPEALED" ||
      this.state.dispositionCode === "PARTIALLY AFFIRMED" ||
      this.state.dispositionCode === "REJECTED";
    const isReasonCodeFieldVisible =
      this.state.preAuthStatus === "AFFIRMED DECISION" && isNegativeDisposition;

    console.log("1selectedOrderDetail1", selectedOrderDetail);

    var show278Result = {display:"none"};

    var isValidCPTICD = true;
    /*
    if(selectedOrderDetail){
        if(selectedOrderDetail.valid == "VALID"){
            isValidCPTICD = true;
        }
    }
    */

    var eligibilityResult = [];
    var isInsEligible = true;

    selectedOrderEligibility.forEach((eligResult) => {

      /*
      if(eligResult.statusCode == "4"){
        if(eligResult.payerId == selectedOrderDetail.payerId){
            isInsEligible = true;
        }
      }
      */

      const payer = payers.filter((a) => a.id == eligResult.payerId);
      var payerName = "";
      if (payer.length != 0) {
        payerName = payer[0].name;
      }

      console.log("eligResult.payerId " + eligResult.payerId);
      if (eligResult.responseCode) {
        var jsonResponse = JSON.parse(eligResult.responseJson);

        let status;
        let errorHeader;
        let errors = [];
        var viewDetails;

        if (eligResult.responseCode >= 200 && eligResult.responseCode <= 299) {
          var coverages = jsonResponse.coverages;
          coverages.forEach((coverage) => {
            status = coverage.status;
            if (status.indexOf("Complete") > -1) {
              var plans = coverage.plans;
              if (plans.length > 0) {
                status = plans[0].status;
              }

              viewDetails = (
                <a
                  className="patient-view"
                  onClick={() => {
                    this.setState({
                      eligibilityResultDetail: true,
                      eligibilityResultJson: eligResult.responseJson,
                    });
                  }}
                >
                  DETAILS
                </a>
              );
            }
            if (coverage.statusCode == "19") {
              errorHeader = (
                <div>
                  <label className="elig-info elig-info-error">
                    The following error(s) occured :
                  </label>
                </div>
              );
              errors = coverage.validationMessages;
            }
          });
        } else {
          status = jsonResponse.userMessage;
          errors = jsonResponse.errors;

          errorHeader = (
            <div>
              <label className="elig-info elig-info-error">
                The following error(s) occured :
              </label>
            </div>
          );
        }

        eligibilityResult.push(
          <div>
            <div>
              <label className="elig-info ">{payerName}&nbsp;:&nbsp;</label>
              <label className="elig-info">{status}</label>
              {viewDetails}
            </div>
            {errorHeader}
            {errors.map((error, key) => {
              return (
                <>
                  <li className="elig-info-error">{error.errorMessage}</li>
                </>
              );
            })}
          </div>
        );
      }
    });

    if(isInsEligible == true && isValidCPTICD == true){
        show278Result = {display:"block"};
    }

    var result278 = [];
    var statusCode = "";
    if(selectedOrderDetail278){

      const payer = payers.filter((a) => a.id == selectedOrderDetail278.payerId);
      var payerName = "";
      if (payer.length != 0) {
        payerName = payer[0].name;
      }

      console.log("selectedOrderDetail278.payerId " + selectedOrderDetail278.payerId);
      if (selectedOrderDetail278.responseCode) {
        var jsonResponse = JSON.parse(selectedOrderDetail278.responseJson);

        let status;
        let errorHeader;
        let errors = [];
        var viewDetails;

        if (selectedOrderDetail278.responseCode >= 200 && selectedOrderDetail278.responseCode <= 299) {

          status = jsonResponse.status;
          statusCode = jsonResponse.statusCode;
          viewDetails = (
            <a
              className="patient-view"
              onClick={() => {
                this.setState({
                  resultDetail278: true,
                  resultJson278: selectedOrderDetail278.responseJson,
                });
              }}
            >
              DETAILS
            </a>
          );

        } else {
          status = jsonResponse.userMessage;
          errors = jsonResponse.errors;

          errorHeader = (
            <div>
              <label className="elig-info elig-info-error">
                The following error(s) occured :
              </label>
            </div>
          );
        }

        result278.push(
          <div>
            <div>
              <label className="elig-info ">{payerName}&nbsp;:&nbsp;</label>
              <label className="elig-info">{status}</label>
              {viewDetails}
            </div>
            {errorHeader}
            {errors.map((error, key) => {
              return (
                <>
                  <li className="elig-info-error">{error.errorMessage}</li>
                </>
              );
            })}
          </div>
        );
      }
    }

    var submit278Btn;

    if(statusCode != "A1"){
        submit278Btn = <Button
            className="form-save-btn save-order btn-primary mt-1 float-end"
            type="button"
            disabled={
                this.state
                .disabledSubmit278
            }
            onClick={() => {
              this.check278();
            }}
          >
            <img
              src={require("../../images/save-btn-icon.png")}
              className="add-icon"
            />
            Submit 278
      </Button>
    }

    const jsonStyle = {
      propertyStyle: { color: "red" },
      stringStyle: { color: "green" },
      numberStyle: { color: "darkorange" },
    };

    return (
      <div className="w-100">
        <div className="auth-content-header d-flex justify-content-between">
          <div className="auth-logo-cont">
            <svg
              width="118"
              height="24"
              viewBox="0 0 118 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.28 0C0.413213 0 0 8.54562 0 8.54562V23.7477C0 23.8803 0.108893 23.988 0.243042 23.988H2.55173C2.68588 23.988 2.79478 23.8803 2.79478 23.7477V13.6394C2.79478 13.5068 2.90367 13.3991 3.03782 13.3991H17.522C17.6562 13.3991 17.7651 13.5068 17.7651 13.6394V23.7477C17.7651 23.8803 17.8739 23.988 18.0081 23.988H20.3168C20.4509 23.988 20.5598 23.8803 20.5598 23.7477V8.54562C20.5598 8.54562 20.1466 0 10.28 0ZM17.7653 10.8523C17.7653 10.9849 17.6564 11.0926 17.5222 11.0926H3.03782C2.90367 11.0926 2.79478 10.9849 2.79478 10.8523V9.0262C2.79478 5.49636 5.68892 2.63499 9.2592 2.63499H11.3006C14.8709 2.63499 17.7651 5.49636 17.7651 9.0262V10.8523H17.7653Z"
                fill="#2DC0D0"
              />
              <path
                d="M37.6368 5.4519V15.287C37.6368 18.7372 34.8078 21.5341 31.3181 21.5341C27.8283 21.5341 24.9994 18.7372 24.9994 15.287V5.4519H22.3423V15.0796C22.3423 19.9806 26.3608 23.9536 31.3179 23.9536C36.275 23.9536 40.2934 19.9806 40.2934 15.0796V5.4519H37.6363H37.6368Z"
                fill="#2DC0D0"
              />
              <path
                d="M49.3089 21.4402C46.6133 21.4402 44.4282 19.2798 44.4282 16.6148V8.38945C44.4282 8.27217 44.5242 8.1772 44.6429 8.1772H50.4349C50.5535 8.1772 50.6496 8.08223 50.6496 7.96495V5.95463C50.6496 5.83735 50.5535 5.74238 50.4349 5.74238H44.6429C44.5242 5.74238 44.4282 5.64741 44.4282 5.53013V0.212249C44.4282 0.0949695 44.3321 0 44.2135 0H42.245C42.1263 0 42.0303 0.0949695 42.0303 0.212249V17.6719C42.0303 20.9915 44.7522 23.6826 48.1098 23.6826H52.379C52.4976 23.6826 52.5937 23.5876 52.5937 23.4704V21.6522C52.5937 21.535 52.4976 21.44 52.379 21.44H49.3089V21.4402Z"
                fill="#2DC0D0"
              />
              <path
                d="M63.3938 5.48613C60.9294 5.48613 58.6971 6.46816 57.0751 8.05767V0H54.418V23.988H57.0751V14.1529C57.0751 10.7027 59.904 7.9058 63.3938 7.9058C66.8835 7.9058 69.7124 10.7027 69.7124 14.1529V23.988H72.3695V14.3602C72.3695 9.45929 68.3511 5.48633 63.394 5.48633L63.3938 5.48613Z"
                fill="#2DC0D0"
              />
              <path
                d="M83.7622 4.90674C78.4294 4.90674 74.106 9.18098 74.106 14.4534C74.106 19.7259 78.4292 24.0001 83.7622 24.0001C89.0953 24.0001 93.4183 19.7259 93.4183 14.4534C93.4183 9.18098 89.0951 4.90674 83.7622 4.90674ZM83.7622 21.5503C79.7978 21.5503 76.584 18.373 76.584 14.4534C76.584 10.5339 79.7978 7.3565 83.7622 7.3565C87.7267 7.3565 90.9404 10.5339 90.9404 14.4534C90.9404 18.373 87.7267 21.5503 83.7622 21.5503Z"
                fill="#2DC0D0"
              />
              <path
                d="M112.778 3.39188V0.212249C112.778 0.0949695 112.682 0 112.563 0H95.2317V23.7757C95.2317 23.893 95.3277 23.988 95.4464 23.988H98.6624C98.781 23.988 98.8771 23.893 98.8771 23.7757V12.8506C98.8771 12.7333 98.9732 12.6383 99.0918 12.6383H109.89C110.009 12.6383 110.105 12.5433 110.105 12.4261V9.24643C110.105 9.12915 110.009 9.03418 109.89 9.03418H99.0918C98.9732 9.03418 98.8771 8.93921 98.8771 8.82193V3.81638C98.8771 3.6991 98.9732 3.60413 99.0918 3.60413H112.563C112.682 3.60413 112.778 3.50916 112.778 3.39188Z"
                fill="#22449C"
              />
              <path
                d="M117.805 5.99707H114.783C114.675 5.99707 114.588 6.08348 114.588 6.19008V9.88059C114.588 9.98718 114.675 10.0736 114.783 10.0736H117.805C117.913 10.0736 118 9.98718 118 9.88059V6.19008C118 6.08348 117.913 5.99707 117.805 5.99707Z"
                fill="#22449C"
              />
              <path
                d="M117.805 11.6594H114.783C114.675 11.6594 114.588 11.7458 114.588 11.8524V23.7852C114.588 23.8918 114.675 23.9782 114.783 23.9782H117.805C117.913 23.9782 118 23.8918 118 23.7852V11.8524C118 11.7458 117.913 11.6594 117.805 11.6594Z"
                fill="#22449C"
              />
            </svg>
          </div>
          <div className="d-flex justify-content-end">
            <Search />
            <RightPanel />
          </div>
        </div>

        <div className="auth-content-main d-flex align-items-center justify-content-center">
          <MedicalNecessityRequirementsModal
            data={{ isOpen: medNecReqModal }}
            result={(result) => {
              this.setState({ medNecReqModal: result.isOpen });
            }}
          />
          <MedicalDocumentationRequirementsModal
            data={{ isOpen: medDocReqModal }}
            result={(result) => {
              this.setState({ medDocReqModal: result.isOpen });
            }}
          />
          <Modal show={showInsuranceModal} size="xl" centered>
            <Modal.Body className="p-1">
              <div class="form-main-title my-2">
                <label>Insurance/Payer</label>
              </div>
              <Form className="mt-1">
                <Table bordered className="table-portal">
                  <thead>
                    <tr>
                      <th className="td-first"></th>
                      <th>Type</th>
                      <th>Payer</th>
                      <th>Group Name</th>
                      <th className="td-last">Group Id</th>
                    </tr>
                  </thead>
                  <tbody>
                    {insuranceList.map((record, key) => {
                      return (
                        <>
                          <tr
                            onClick={(event) =>
                              this.onClickHandlerInsurance(
                                { ...record, isNew: "N", btnClick: "N" },
                                event
                              )
                            }
                          >
                            <td className="td-first">
                              <i className="fa fa-chevron-down"></i>
                            </td>
                            <td>{record.type}</td>
                            <td>{this.getPayerName(record.payerId)}</td>
                            <td>{record.groupName}</td>
                            <td className="td-last">{record.groupId}</td>
                          </tr>
                          <tr className="main-table collapse">
                            <td className="td-first td-last" colSpan="5">
                              <Form>
                                <Row>
                                  <div class="col-lg-2 col-6">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Type
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.typeInsurance}
                                        onChange={(e) =>
                                          this.setState({
                                            typeInsurance: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="PRIMARY">PRIMARY</option>
                                        <option value="SECONDARY">
                                          SECONDARY
                                        </option>
                                        <option value="TERTIARY">
                                          TERTIARY
                                        </option>
                                      </Form.Select>
                                      <span className="table-form-label-err mb-0">
                                        {this.state.typeInsuranceErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-2 col-6">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Relation
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.relationInsurance}
                                        onChange={(e) =>
                                          this.setState({
                                            relationInsurance: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="">
                                          -- Select Relation --
                                        </option>
                                        <option value="SELF">SELF</option>
                                        <option value="SPOUSE">SPOUSE</option>
                                        <option value="MOTHER">MOTHER</option>
                                        <option value="FATHER">FATHER</option>
                                        <option value="DAUGHTER">
                                          DAUGHTER
                                        </option>
                                        <option value="SON">SON</option>
                                        <option value="OTHER">OTHER</option>
                                      </Form.Select>
                                      <span className="table-form-label-err mb-0">
                                        {this.state.relationInsuranceErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-2 col-6">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Payer
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.payerIdInsurance}
                                        onChange={(e) =>
                                          this.setState({
                                            payerIdInsurance: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="">
                                          {" "}
                                          -- Select Payer --{" "}
                                        </option>
                                        {this.props.payers.map((payer, key) => {
                                          return (
                                            <option key={key} value={payer.id}>
                                              {payer.name}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                      <span className="table-form-label-err mb-0">
                                        {this.state.payerIdInsuranceErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Address 1
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        value={this.state.address1Insurance}
                                        onChange={(e) =>
                                          this.setState({
                                            address1Insurance: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                    <span className="table-form-label-err mb-0">
                                      {this.state.address1InsuranceErr}
                                    </span>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Address 2
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        value={this.state.address2Insurance}
                                        onChange={(e) =>
                                          this.setState({
                                            address2Insurance: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-2 col-6 mt-1">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        City
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        value={this.state.cityInsurance}
                                        onChange={(e) =>
                                          this.setState({
                                            cityInsurance: e.target.value,
                                          })
                                        }
                                      />
                                      <span className="table-form-label-err mb-0">
                                        {this.state.cityInsuranceErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-2 col-6 mt-1">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        State
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.stateInsurance}
                                        onChange={(e) =>
                                          this.setState({
                                            stateInsurance: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="">
                                          {" "}
                                          -- Select State --{" "}
                                        </option>
                                        {USStates.map((USState, key) => {
                                          return (
                                            <option
                                              key={key}
                                              value={USState.abbreviation}
                                            >
                                              {USState.name}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                      <span className="table-form-label-err mb-0">
                                        {this.state.stateInsuranceErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-2 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Zip
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        type="number"
                                        value={this.state.zipInsurance}
                                        onChange={(e) =>
                                          this.setState({
                                            zipInsurance: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                    <span className="table-form-label-err mb-0">
                                      {this.state.zipInsuranceErr}
                                    </span>
                                  </div>
                                </Row>
                                <Row>
                                  <div class="col-lg-2 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Phone Number
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        type="number"
                                        value={this.state.phoneNumber}
                                        onChange={(e) =>
                                          this.setState({
                                            phoneNumber: e.target.value,
                                          })
                                        }
                                      />
                                      <span className="table-form-label-err mb-0">
                                        {this.state.phoneNumberErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-2 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Group Name
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        value={this.state.groupName}
                                        onChange={(e) =>
                                          this.setState({
                                            groupName: e.target.value,
                                          })
                                        }
                                      />
                                      <span className="table-form-label-err mb-0">
                                        {this.state.groupNameErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-2 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Group Id
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        value={this.state.groupId}
                                        onChange={(e) =>
                                          this.setState({
                                            groupId: e.target.value,
                                          })
                                        }
                                      />
                                      <span className="table-form-label-err mb-0">
                                        {this.state.groupIdErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-2 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Policy Number
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        value={this.state.policyNumber}
                                        onChange={(e) =>
                                          this.setState({
                                            policyNumber: e.target.value,
                                          })
                                        }
                                      />
                                      <span className="table-form-label-err mb-0">
                                        {this.state.policyNumberErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row>
                                  <div class="col-lg-2 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Plan
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        value={this.state.plan}
                                        onChange={(e) =>
                                          this.setState({
                                            plan: e.target.value,
                                          })
                                        }
                                      />
                                      <span className="table-form-label-err mb-0">
                                        {this.state.planErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div className="col-lg-2 col-6 mt-1 d-flex flex-column">
                                    <label className="table-form-label mb-0">
                                      Begin
                                    </label>
                                    <DatePicker
                                      className="date-picker"
                                      showIcon
                                      placeholderText="MM/DD/YYYY"
                                      customInput={
                                        <MaskedTextInput
                                          type="text"
                                          mask={MaskDateInput}
                                        />
                                      }
                                      onChange={(date) =>
                                        this.setState({
                                          insBegin: formatDateToCustomISO(date),
                                        })
                                      }
                                      selected={
                                        this.state.insBegin
                                          ? formatDateToCustomISO(
                                              moment(
                                                this.state.insBegin
                                              ).toDate()
                                            )
                                          : null
                                      }
                                    />
                                    <span className="table-form-label-err mb-0 mb-0">
                                      {this.state.insBeginErr}
                                    </span>
                                  </div>
                                  <div className="col-lg-2 col-6 mt-1 d-flex flex-column">
                                    <label className="table-form-label mb-0">
                                      End
                                    </label>
                                    <DatePicker
                                      className="date-picker"
                                      showIcon
                                      placeholderText="MM/DD/YYYY"
                                      customInput={
                                        <MaskedTextInput
                                          type="text"
                                          mask={MaskDateInput}
                                        />
                                      }
                                      onChange={(date) =>
                                        this.setState({
                                          insEnd: formatDateToCustomISO(date),
                                        })
                                      }
                                      selected={
                                        this.state.insEnd
                                          ? formatDateToCustomISO(
                                              moment(this.state.insEnd).toDate()
                                            )
                                          : null
                                      }
                                    />
                                    <span className="table-form-label-err mb-0 mb-0">
                                      {this.state.insEndErr}
                                    </span>
                                  </div>
                                </Row>
                                <Row>
                                  <div>
                                    <Button
                                      className="form-save-btn btn-primary mt-1 float-end"
                                      type="button"
                                      onClick={() => {
                                        this.saveInsurance();
                                      }}
                                    >
                                      <img
                                        src={require("../../images/save-btn-icon.png")}
                                        className="add-icon"
                                      />
                                      Save
                                    </Button>
                                  </div>
                                </Row>
                              </Form>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    <tr
                      onClick={(event) =>
                        this.onClickHandlerInsurance(
                          {
                            ...this.insuranceDefault,
                            isNew: "Y",
                            btnClick: "N",
                          },
                          event
                        )
                      }
                    >
                      <td className="td-first">
                        <i className="fa fa-chevron-down"></i>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr className="main-table collapse">
                      <td className="td-first td-last" colSpan="5">
                        <Form>
                          <Row>
                            <div class="col-lg-2 col-6">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  Type
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.type}
                                  onChange={(e) =>
                                    this.setState({ type: e.target.value })
                                  }
                                >
                                  <option value="PRIMARY">PRIMARY</option>
                                  <option value="SECONDARY">SECONDARY</option>
                                  <option value="TERTIARY">TERTIARY</option>
                                </Form.Select>
                              </Form.Group>
                              <span className="table-form-label-err mb-0">
                                {this.state.typeErr}
                              </span>
                            </div>
                            <div class="col-lg-2 col-6">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Relation
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.relationInsurance}
                                  onChange={(e) =>
                                    this.setState({
                                      relationInsurance: e.target.value,
                                    })
                                  }
                                >
                                  <option value="">
                                    -- Select Relation --
                                  </option>
                                  <option value="SELF">SELF</option>
                                  <option value="SPOUSE">SPOUSE</option>
                                  <option value="MOTHER">MOTHER</option>
                                  <option value="FATHER">FATHER</option>
                                  <option value="DAUGHTER">DAUGHTER</option>
                                  <option value="SON">SON</option>
                                  <option value="OTHER">OTHER</option>
                                </Form.Select>
                                <span className="table-form-label-err mb-0">
                                  {this.state.relationInsuranceErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-2 col-6">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  Payer
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.payerIdInsurance}
                                  onChange={(e) =>
                                    this.setState({
                                      payerIdInsurance: e.target.value,
                                    })
                                  }
                                >
                                  <option value=""> -- Select Payer -- </option>
                                  {this.props.payers.map((payer, key) => {
                                    return (
                                      <option key={key} value={payer.id}>
                                        {payer.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                                <span className="table-form-label-err mb-0">
                                  {this.state.payerIdInsuranceErr}
                                </span>
                              </Form.Group>
                            </div>
                          </Row>
                          <Row>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Address 1
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  value={this.state.address1Insurance}
                                  onChange={(e) =>
                                    this.setState({
                                      address1Insurance: e.target.value,
                                    })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.address1InsuranceErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Address 2
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  value={this.state.address2Insurance}
                                  onChange={(e) =>
                                    this.setState({
                                      address2Insurance: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  City
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  value={this.state.cityInsurance}
                                  onChange={(e) =>
                                    this.setState({
                                      cityInsurance: e.target.value,
                                    })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.cityInsuranceErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  State
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.stateInsurance}
                                  onChange={(e) =>
                                    this.setState({
                                      stateInsurance: e.target.value,
                                    })
                                  }
                                >
                                  <option value=""> -- Select State -- </option>
                                  {USStates.map((USState, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={USState.abbreviation}
                                      >
                                        {USState.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                                <span className="table-form-label-err mb-0">
                                  {this.state.stateInsuranceErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Zip
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  value={this.state.zipInsurance}
                                  onChange={(e) =>
                                    this.setState({
                                      zipInsurance: e.target.value,
                                    })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.zipInsuranceErr}
                                </span>
                              </Form.Group>
                            </div>
                          </Row>
                          <Row>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Phone Number
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  type="number"
                                  value={this.state.phoneNumber}
                                  onChange={(e) =>
                                    this.setState({
                                      phoneNumber: e.target.value,
                                    })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.phoneNumberErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Group Name
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  value={this.state.groupName}
                                  onChange={(e) =>
                                    this.setState({ groupName: e.target.value })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.groupNameErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Group Id
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  value={this.state.groupId}
                                  onChange={(e) =>
                                    this.setState({ groupId: e.target.value })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.groupIdErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Policy Number
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  value={this.state.policyNumber}
                                  onChange={(e) =>
                                    this.setState({
                                      policyNumber: e.target.value,
                                    })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.policyNumberErr}
                                </span>
                              </Form.Group>
                            </div>
                          </Row>
                          <Row>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Plan
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  value={this.state.plan}
                                  onChange={(e) =>
                                    this.setState({ plan: e.target.value })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.planErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-lg-2 col-6 mt-1 d-flex flex-column">
                              <label className="table-form-label mb-0">
                                Begin
                              </label>
                              <DatePicker
                                className="date-picker"
                                showIcon
                                placeholderText="MM/DD/YYYY"
                                customInput={
                                  <MaskedTextInput
                                    type="text"
                                    mask={MaskDateInput}
                                  />
                                }
                                onChange={(date) =>
                                  this.setState({
                                    insBegin: formatDateToCustomISO(date),
                                  })
                                }
                                selected={
                                  this.state.insBegin
                                    ? formatDateToCustomISO(
                                        moment(this.state.insBegin).toDate()
                                      )
                                    : null
                                }
                              />
                              <span className="table-form-label-err mb-0 mb-0">
                                {this.state.insBeginErr}
                              </span>
                            </div>
                            <div className="col-lg-2 col-6 mt-1 d-flex flex-column">
                              <label className="table-form-label mb-0">
                                End
                              </label>
                              <DatePicker
                                className="date-picker"
                                showIcon
                                placeholderText="MM/DD/YYYY"
                                customInput={
                                  <MaskedTextInput
                                    type="text"
                                    mask={MaskDateInput}
                                  />
                                }
                                onChange={(date) =>
                                  this.setState({
                                    insEnd: formatDateToCustomISO(date),
                                  })
                                }
                                selected={
                                  this.state.insEnd
                                    ? formatDateToCustomISO(
                                        moment(this.state.insEnd).toDate()
                                      )
                                    : null
                                }
                              />
                              <span className="table-form-label-err mb-0 mb-0">
                                {this.state.insEndErr}
                              </span>
                            </div>
                          </Row>
                          <Row>
                            <div>
                              <Button
                                className="form-save-btn btn-primary mt-1 float-end"
                                type="button"
                                onClick={() => {
                                  this.saveInsurance();
                                }}
                              >
                                <img
                                  src={require("../../images/save-btn-icon.png")}
                                  className="add-icon"
                                />
                                Save
                              </Button>
                            </div>
                          </Row>
                        </Form>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn grid-btn"
                variant="secondary"
                onClick={() => {
                  this.handleShowCloseInsurance(false);
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={eligibilityResultDetail} size="lg" centered>
            <Modal.Body className="pb-0 pt-4 pl-4 pr-4">
              <div class="form-title-label">
                <label className="border-bottom">Eligibility Result:</label>
              </div>
              <Form className="mt-4">
                <Row>
                  <div class="col-lg-12 col-6 eligResult">
                    <JsonFormatter
                      json={eligibilityResultJson}
                      tabWith={2}
                      jsonStyle={jsonStyle}
                    />
                  </div>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn grid-btn submit-modal"
                variant="secondary"
                type="button"
                onClick={() => {
                  this.setState({ eligibilityResultDetail: false });
                }}
              >
                CLOSE
              </Button>
            </Modal.Footer>
          </Modal>


          <Modal show={resultDetail278} size="lg" centered>
            <Modal.Body className="pb-0 pt-4 pl-4 pr-4">
              <div class="form-title-label">
                <label className="border-bottom">278 Result:</label>
              </div>
              <Form className="mt-4">
                <Row>
                  <div class="col-lg-12 col-6 eligResult">
                    <JsonFormatter
                      json={resultJson278}
                      tabWith={2}
                      jsonStyle={jsonStyle}
                    />
                  </div>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn grid-btn submit-modal"
                variant="secondary"
                type="button"
                onClick={() => {
                  this.setState({ resultDetail278: false });
                }}
              >
                CLOSE
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={editAddressModal} size="lg" centered>
            <Modal.Body className="p-5">
              <div class="form-title-label">
                <label className="border-bottom">Edit Address:</label>
              </div>
              <Form className="mt-4">
                <Row>
                  <div class="col-lg-12 col-6">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Address 1
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        value={
                          this.state.editAddressModalName == "PHYSICAL"
                            ? this.state.physicalAddress1
                            : this.state.mailingAddress1
                        }
                        onChange={(e) =>
                          this.setState(
                            this.state.editAddressModalName == "PHYSICAL"
                              ? { physicalAddress1: e.target.value }
                              : { mailingAddress1: e.target.value }
                          )
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.editAddressModalName == "PHYSICAL"
                        ? this.state.physicalAddress1Err
                        : this.state.mailingAddress1Err}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-12 col-6">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Address 2
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        value={
                          this.state.editAddressModalName == "PHYSICAL"
                            ? this.state.physicalAddress2
                            : this.state.mailingAddress2
                        }
                        onChange={(e) =>
                          this.setState(
                            this.state.editAddressModalName == "PHYSICAL"
                              ? { physicalAddress2: e.target.value }
                              : { mailingAddress2: e.target.value }
                          )
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.editAddressModalName == "PHYSICAL"
                        ? this.state.physicalAddress2Err
                        : this.state.mailingAddress2Err}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-5 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        City
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        value={
                          this.state.editAddressModalName == "PHYSICAL"
                            ? this.state.physicalCity
                            : this.state.mailingCity
                        }
                        onChange={(e) =>
                          this.setState(
                            this.state.editAddressModalName == "PHYSICAL"
                              ? { physicalCity: e.target.value }
                              : { mailingCity: e.target.value }
                          )
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.editAddressModalName == "PHYSICAL"
                        ? this.state.physicalCityErr
                        : this.state.mailingCityErr}
                    </span>
                  </div>
                  <div class="col-lg-5 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        State
                      </Form.Label>
                      <Form.Select
                        className="grid-form-control"
                        value={
                          this.state.editAddressModalName == "PHYSICAL"
                            ? this.state.physicalState
                            : this.state.mailingState
                        }
                        onChange={(e) =>
                          this.setState(
                            this.state.editAddressModalName == "PHYSICAL"
                              ? { physicalState: e.target.value }
                              : { mailingState: e.target.value }
                          )
                        }
                      >
                        <option selected disabled value="">
                          {" "}
                          -- Select State --{" "}
                        </option>
                        {USStates.map((USState, key) => {
                          return (
                            <option key={key} value={USState.abbreviation}>
                              {USState.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.editAddressModalName == "PHYSICAL"
                        ? this.state.physicalStateErr
                        : this.state.mailingStateErr}
                    </span>
                  </div>
                  <div class="col-lg-2 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Zip
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        value={
                          this.state.editAddressModalName == "PHYSICAL"
                            ? this.state.physicalZip
                            : this.state.mailingZip
                        }
                        onChange={(e) =>
                          this.setState(
                            this.state.editAddressModalName == "PHYSICAL"
                              ? { physicalZip: e.target.value }
                              : { mailingZip: e.target.value }
                          )
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.editAddressModalName == "PHYSICAL"
                        ? this.state.physicalZipErr
                        : this.state.mailingZipErr}
                    </span>
                  </div>
                </Row>
                <Row className="mt-4">
                  <div className="col-6 p-0">
                    <Button
                      className="btn btn-success grid-btn submit-modal"
                      type="button"
                      onClick={this.submitEditAddressModal.bind(this)}
                    >
                      SUBMIT
                    </Button>
                    <Button
                      className="btn grid-btn submit-modal"
                      variant="secondary"
                      type="button"
                      onClick={() => {
                        this.setState({ editAddressModal: false });
                      }}
                    >
                      CLOSE
                    </Button>
                  </div>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal show={showFacilityModal} size="xl" centered>
            <Modal.Body className="p-1">
              <div class="form-main-title my-2 px-2">
                <label>Add Facility</label>
              </div>
              <Form className="mt-1 px-2">
                <Row>
                  <div class="col-lg-3 col-6 d-flex">
                    <label className="encounter-form-label">Name</label>
                    <Form.Group as={Col} controlId="">
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.nameFacility}
                        onChange={(e) =>
                          this.setState({ nameFacility: e.target.value })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.nameFacilityErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6"></div>
                  <div class="col-lg-3 col-6 d-flex">
                    <label className="encounter-form-label">Status</label>
                    <Form.Group as={Col} controlId="patientFullName">
                      <Form.Select
                        className="grid-form-control"
                        value={this.state.statusFacility}
                        onChange={(e) =>
                          this.setState({ statusFacility: e.target.value })
                        }
                      >
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="NOT ACTIVE">NOT ACTIVE</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Speciality/Taxonomy
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.specialtyTaxonomyFacility}
                        onChange={(e) =>
                          this.setState({
                            specialtyTaxonomyFacility: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.specialtyTaxonomyFacilityErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Npi
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.npiFacility}
                        onChange={(e) =>
                          this.setState({ npiFacility: e.target.value })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.npiFacilityErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Tax Id
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.taxIdFacility}
                        onChange={(e) =>
                          this.setState({ taxIdFacility: e.target.value })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.taxIdFacilityErr}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Physical Address
                      </Form.Label>
                      <InputGroup className="d-flex flex-row">
                        <Form.Control
                          className="grid-form-control"
                          readOnly
                          value={this.state.physicalAddress}
                        />
                        <InputGroup.Text className="input-group-edit">
                          <a
                            onClick={() => {
                              this.showEditAddressModal("PHYSICAL");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fill-rule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                              />
                            </svg>
                          </a>
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.physicalAddressErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Mailing Address
                      </Form.Label>
                      <InputGroup className="d-flex flex-row">
                        <Form.Control
                          className="grid-form-control"
                          readOnly
                          value={this.state.mailingAddress}
                        />
                        <InputGroup.Text className="input-group-edit">
                          <a
                            onClick={() => {
                              this.showEditAddressModal("MAILING");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fill-rule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                              />
                            </svg>
                          </a>
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.mailingAddressErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Main Phone Number
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={15}
                        value={this.state.mainPhoneNumber}
                        onChange={(e) => {
                          let phone = this.addDashes(e.target.value);
                          this.setState({ mainPhoneNumber: phone });
                        }}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.mainPhoneNumberErr}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Executive Sponsor Name
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.executiveSponsorNameFacility}
                        onChange={(e) =>
                          this.setState({
                            executiveSponsorNameFacility: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.executiveSponsorNameFacilityErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Executive Sponsor Phone
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={15}
                        value={this.state.executiveSponsorPhoneFacility}
                        onChange={(e) => {
                          let phone = this.addDashes(e.target.value);
                          this.setState({
                            executiveSponsorPhoneFacility: phone,
                          });
                        }}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.executiveSponsorPhoneFacilityErr}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Accounts Payable Phone
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={15}
                        value={this.state.accountsPayablePhoneNumberFacility}
                        onChange={(e) => {
                          let phone = this.addDashes(e.target.value);
                          this.setState({
                            accountsPayablePhoneNumberFacility: phone,
                          });
                        }}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.accountsPayablePhoneNumberFacilityErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact Invoicing
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.contactInvoicingFacility}
                        onChange={(e) =>
                          this.setState({
                            contactInvoicingFacility: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.contactInvoicingFacilityErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact Invoicing Email
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.contactInvoicingEmailFacility}
                        onChange={(e) =>
                          this.setState({
                            contactInvoicingEmailFacility: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.contactInvoicingEmailFacilityErr}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-3 mt-1 text-center">
                    <Form.Label className="table-form-label mb-0">
                      Out Patient Rate
                    </Form.Label>
                    <Form.Group
                      className="client-rate-form"
                      as={Col}
                      controlId=""
                    >
                      <Form.Control
                        className="client-rate-control"
                        value={this.state.outpatientRateFacility}
                        onChange={(e) =>
                          this.setState({
                            outpatientRateFacility: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                  <div class="col-lg-3 mt-1 text-center">
                    <Form.Label className="table-form-label mb-0">
                      In Patient Rate
                    </Form.Label>
                    <Form.Group
                      className="client-rate-form"
                      as={Col}
                      controlId=""
                    >
                      <Form.Control
                        className="client-rate-control"
                        value={this.state.inpatientRateFacility}
                        onChange={(e) =>
                          this.setState({
                            inpatientRateFacility: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                  <div class="col-lg-3 col-6 mt-1 d-flex">
                    <Form.Check
                      className="grid-form-control client-checkbox mt-3"
                      label="Run Eligibility"
                      onChange={this.handleRunEligibilityFacility}
                      checked={this.state.runEligibilityFacilityIsChecked.includes(
                        "Y"
                      )}
                    />
                    <Form.Check
                      className="grid-form-control client-checkbox mt-3"
                      label="Full Service"
                      onChange={this.handleFullServiceFacility}
                      checked={this.state.fullServiceFacilityIsChecked.includes(
                        "Y"
                      )}
                    />
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-3 col-6">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact Case Manager
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.contactCaseManagerFacility}
                        onChange={(e) =>
                          this.setState({
                            contactCaseManagerFacility: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.contactCaseManagerFacilityErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact Case Representative
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.contactCaseRepresentativeFacility}
                        onChange={(e) =>
                          this.setState({
                            contactCaseRepresentativeFacility: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.contactCaseRepresentativeFacilityErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact Case Representative Phone
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={15}
                        value={
                          this.state.contactCaseRepresentativePhoneFacility
                        }
                        onChange={(e) => {
                          let phone = this.addDashes(e.target.value);
                          this.setState({
                            contactCaseRepresentativePhoneFacility: phone,
                          });
                        }}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.contactCaseRepresentativePhoneFacilityErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Type
                      </Form.Label>
                      <Form.Select
                        className="grid-form-control"
                        value={this.state.type}
                        onChange={(e) =>
                          this.setState({ type: e.target.value })
                        }
                      >
                        <option value="Institutional">Institutional</option>
                      </Form.Select>
                    </Form.Group>
                    <span className="table-form-label-err mb-0"></span>
                  </div>
                </Row>
                <Row>
                  <div>
                    <Button
                      className="form-save-btn btn-primary mt-1 float-end"
                      type="button"
                      onClick={() => {
                        this.saveFacility();
                      }}
                    >
                      <img
                        src={require("../../images/save-btn-icon.png")}
                        className="add-icon"
                      />
                      Save
                    </Button>
                  </div>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn grid-btn"
                variant="secondary"
                onClick={() => {
                  this.handleShowCloseFacility(false);
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showProviderModal} size="xl" centered>
            <Modal.Body className="p-1">
              <div class="form-main-title my-2 px-2">
                <label>Add Provider</label>
              </div>
              <Form className="mt-1 px-2">
                <Row>
                  <div class="col-lg-3 col-6 d-flex">
                    <label className="encounter-form-label">Name</label>
                    <Form.Group as={Col} controlId="">
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        readOnly
                        value={this.state.nameProvider}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.nameProviderErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6"></div>
                  <div class="col-lg-3 col-6 d-flex">
                    <label className="encounter-form-label">Status</label>
                    <Form.Group as={Col} controlId="">
                      <Form.Select
                        className="grid-form-control"
                        value={this.state.statusProvider}
                        onChange={(e) =>
                          this.setState({ statusProvider: e.target.value })
                        }
                      >
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="NOT ACTIVE">NOT ACTIVE</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        First Name
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.firstName}
                        onChange={(e) => {
                          this.setState({ firstName: e.target.value });
                        }}
                        onBlur={(e) => {
                          this.setFullName();
                        }}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.firstNameErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Last Name
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.lastName}
                        onChange={(e) => {
                          this.setState({ lastName: e.target.value });
                        }}
                        onBlur={(e) => {
                          this.setFullName();
                        }}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.lastNameErr}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Speciality/Taxonomy
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.specialtyTaxonomyProvider}
                        onChange={(e) =>
                          this.setState({
                            specialtyTaxonomyProvider: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.specialtyTaxonomyProviderErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Npi
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.npiProvider}
                        onChange={(e) =>
                          this.setState({ npiProvider: e.target.value })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.npiProviderErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Tax Id
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.taxIdProvider}
                        onChange={(e) =>
                          this.setState({ taxIdProvider: e.target.value })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.taxIdProviderErr}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Physical Address
                      </Form.Label>
                      <InputGroup className="d-flex flex-row">
                        <Form.Control
                          className="grid-form-control"
                          readOnly
                          value={this.state.physicalAddress}
                        />
                        <InputGroup.Text className="input-group-edit">
                          <a
                            onClick={() => {
                              this.showEditAddressModal("PHYSICAL");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fill-rule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                              />
                            </svg>
                          </a>
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.physicalAddressErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Phone Number
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={15}
                        value={this.state.phoneNumber}
                        onChange={(e) => {
                          let phone = this.addDashes(e.target.value);
                          this.setState({ phoneNumber: phone });
                        }}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.phoneNumberErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Fax
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={15}
                        value={this.state.fax}
                        onChange={(e) => {
                          let fax = this.addDashes(e.target.value);
                          this.setState({ fax: fax });
                        }}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.faxErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Email
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.emailErr}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Mailing Address
                      </Form.Label>
                      <InputGroup className="d-flex flex-row">
                        <Form.Control
                          className="grid-form-control"
                          readOnly
                          value={this.state.mailingAddress}
                        />
                        <InputGroup.Text className="input-group-edit">
                          <a
                            onClick={() => {
                              this.showEditAddressModal("MAILING");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fill-rule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                              />
                            </svg>
                          </a>
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.mailingAddressErr}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Case Manager
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.caseManager}
                        onChange={(e) =>
                          this.setState({ caseManager: e.target.value })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.caseManagerErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Case Manager Email
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.caseManagerEmail}
                        onChange={(e) =>
                          this.setState({ caseManagerEmail: e.target.value })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.caseManagerEmailErr}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Concurrent Review Nurse
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.concurrentReviewNurse}
                        onChange={(e) =>
                          this.setState({
                            concurrentReviewNurse: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.concurrentReviewNurseErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Concurrent Review Nurse Email
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.concurrentReviewNurseEmail}
                        onChange={(e) =>
                          this.setState({
                            concurrentReviewNurseEmail: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.concurrentReviewNurseEmailErr}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact Name
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.contactName}
                        onChange={(e) =>
                          this.setState({ contactName: e.target.value })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.contactNameErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact Phone
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={12}
                        value={this.state.contactPhone}
                        onChange={(e) => {
                          let phone = this.addDashes(e.target.value);
                          this.setState({ contactPhone: phone });
                        }}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.contactPhoneErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact Fax
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={12}
                        value={this.state.contactFax}
                        onChange={(e) => {
                          let fax = this.addDashes(e.target.value);
                          this.setState({ contactFax: fax });
                        }}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.contactFaxErr}
                    </span>
                  </div>
                  <div class="col-lg-3 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Type
                      </Form.Label>
                      <Form.Select
                        className="grid-form-control"
                        value={this.state.type}
                        onChange={(e) =>
                          this.setState({ type: e.target.value })
                        }
                      >
                        <option value="Professional">Professional</option>
                      </Form.Select>
                    </Form.Group>
                    <span className="table-form-label-err mb-0"></span>
                  </div>
                </Row>
                <Row>
                  <div>
                    <Button
                      className="form-save-btn btn-primary mt-2 float-end"
                      type="button"
                      onClick={() => {
                        this.saveProvider();
                      }}
                    >
                      <img
                        src={require("../../images/save-btn-icon.png")}
                        className="add-icon"
                      />
                      Save
                    </Button>
                  </div>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn grid-btn"
                variant="secondary"
                onClick={() => {
                  this.handleShowCloseProvider(false);
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={orderDuplicateFoundModal} size="xl" centered>
            <Modal.Body className="p-1">
              <div class="form-main-title my-2">
                <label>Order Duplicate Found!</label>
              </div>
              <Form className="mt-1">
                <Table bordered className="table-portal">
                  <thead>
                    <tr>
                      <th className="td-first-none-collapse">Account Number</th>
                      <th>Order Number</th>
                      <th>Visit Date</th>
                      <th className="td-last">Visit Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderDuplicates.map((record, key) => {
                      return (
                        <>
                          <tr
                            key={key}
                            className={key % 2 == 1 ? "even-row" : ""}
                            onClick={(event) => this.onClickEncounter(record)}
                          >
                            <td className="td-first-none-collapse">
                              {record.encounter.accountNumber}
                            </td>
                            <td>
                              {record.authorization
                                ? record.authorization.orderNumber
                                : ""}
                            </td>
                            <td>
                              {record.encounter.visitDate
                                ? moment(record.encounter.visitDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </td>
                            <td className="td-last">
                              {record.encounter.visitTime}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn grid-btn"
                variant="secondary"
                onClick={() => {
                  this.handleShowCloseDuplicatesModal(false);
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.showDocumentation}
            size="xl"
            className="portal-dialog"
          >
            <Modal.Body className="auth-modal-body">
              <label className="auth-modal-title">DOCUMENTATION</label>
              <Table bordered className="table-portal">
                <thead>
                  <tr>
                    <th></th>
                    <th>Document Type</th>
                    <th>Document Name</th>
                    <th>Filename</th>
                    <th>Date Uploaded</th>
                    <th>User</th>
                    <th>ATTACHMENT</th>
                    <th className="td-last">Submitted</th>
                  </tr>
                </thead>
                <tbody>
                  {documentationList.map((record, key) => {
                    return (
                      <>
                        <tr key={key}>
                          <td className="td-first">
                            <Form.Check
                              onChange={this.handleClickDocument}
                              id={record.id}
                              checked={documentIsChecked.includes(record.id)}
                            />
                          </td>
                          <td>{record.documentType}</td>
                          <td>{record.documentName}</td>
                          <td>{record.filename}</td>
                          <td>
                            {moment(record.dateUploaded).format("MM/DD/YYYY")}
                          </td>
                          <td>{record.username}</td>
                          <td>
                            <a
                              className="btn-view-document-attach"
                              onClick={async () => {
                                var fileName = record.filename;
                                var filetype = fileName.substring(
                                  fileName.length - 3
                                );
                                var type = "image/tif";
                                if (filetype.toUpperCase() == "PNG") {
                                  localStorage.setItem(
                                    "tickler_sent_attachment_filetype",
                                    "image/png"
                                  );
                                  type = "image/png";
                                } else if (filetype.toUpperCase() == "PDF") {
                                  localStorage.setItem(
                                    "tickler_sent_attachment_filetype",
                                    "application/pdf"
                                  );
                                  type = "application/pdf";
                                } else if (filetype.toUpperCase() == "TIF") {
                                  localStorage.setItem(
                                    "tickler_sent_attachment_filetype",
                                    "image/tif"
                                  );
                                  type = "image/tif";
                                } else if (filetype.toUpperCase() == "JPG") {
                                  localStorage.setItem(
                                    "tickler_sent_attachment_filetype",
                                    "image/jpg"
                                  );
                                  type = "image/jpg";
                                } else {
                                  localStorage.setItem(
                                    "tickler_sent_attachment_filetype",
                                    "application/octet-stream"
                                  );
                                  type = "application/octet-stream";
                                  console.log("I am here documentation");
                                  // download etc files...
                                  if (
                                    window.navigator &&
                                    window.navigator.msSaveOrOpenBlob
                                  ) {
                                    //window.navigator.msOpenBlob(file, "Statement.pdf");
                                    this.props.getDocumentationDownloadFile(
                                      fileName,
                                      ""
                                    );
                                  } else {
                                    await this.props.getDocumentationDownloadFile(
                                      fileName,
                                      record.clientId
                                    );

                                    const file = new Blob(
                                      [this.props.documentationDownloadFile],
                                      { type: type }
                                    );

                                    const fileURL = URL.createObjectURL(file);
                                    const a = document.createElement("a");
                                    a.href = fileURL;
                                    a.download = fileName;
                                    const clickHandler = () => {
                                      setTimeout(() => {
                                        URL.revokeObjectURL(fileURL);
                                        a.removeEventListener(
                                          "click",
                                          clickHandler
                                        );
                                      }, 150);
                                    };
                                    a.addEventListener(
                                      "click",
                                      clickHandler,
                                      false
                                    );
                                    a.click();
                                    return;
                                  }
                                }
                                if (
                                  window.navigator &&
                                  window.navigator.msSaveOrOpenBlob
                                ) {
                                  //window.navigator.msOpenBlob(file, "Statement.pdf");
                                  this.props.getDocumentationDownloadFile(
                                    fileName,
                                    ""
                                  );
                                } else {
                                  //localStorage.setItem("tickler_sent_attachment_filename", data.attachmentFilename);
                                  //localStorage.setItem("tickler_sent_parent_id", data.parentId);
                                  //window.open('../attachment');
                                  await this.props.getDocumentationDownloadFile(
                                    fileName,
                                    record.clientId
                                  );

                                  if (
                                    this.props
                                      .documentationDownloadFile instanceof Blob
                                  ) {
                                    const file = new Blob(
                                      [this.props.documentationDownloadFile],
                                      { type: type }
                                    );

                                    const fileURL = URL.createObjectURL(file);

                                    this.props.documentationDownloadFileClear();
                                    window.open(fileURL, "_blank");
                                  }
                                }
                              }}
                            >
                              VIEW
                            </a>
                          </td>
                          <td className="td-last">{record.submitted}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="form-cancel-btn"
                onClick={() => {
                  this.handleShowCloseDocumentation(false);
                }}
              >
                <img
                  src={require("../../images/cancel-btn-icon.png")}
                  className="add-icon"
                />
                Close
              </Button>
              <Button
                className="view-doc-btn"
                variant="primary"
                onClick={() => {
                  this.handleShowCloseUploadFile(true);
                }}
              >
                <img
                  src={require("../../images/new-file-icon.png")}
                  className="add-icon"
                />
                New File
              </Button>
              <Button
                className="form-save-btn"
                variant="primary"
                onClick={() => {
                  this.handleShowCloseDocumentation(false);
                }}
              >
                <img
                  src={require("../../images/submit-icon.png")}
                  className="add-icon"
                />
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showUploadFile} size="lg" centered>
            <Modal.Header>
              <label>New File</label>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Col} controlId="">
                <Form.Label className="table-form-label mb-0">
                  Document Type
                </Form.Label>
                <Form.Select
                  className="grid-form-control-select"
                  value={this.state.documentType}
                  onChange={(e) =>
                    this.setState({ documentType: e.target.value })
                  }
                >
                  <option value="">Select Type</option>
                  <option value="MEDICAL RECORD">MEDICAL RECORD</option>
                  <option value="INSURANCE CARD">INSURANCE CARD</option>
                  <option value="DRIVERS LICENSE">DRIVERS LICENSE</option>
                  <option value="PAYER DOCUMENTATION">
                    PAYER DOCUMENTATION
                  </option>
                  <option value="PATIENT CORRESPONDENCE">
                    PATIENT CORRESPONDENCE
                  </option>
                  <option value="APPOINTMENT INFORMATION">
                    APPOINTMENT INFORMATION
                  </option>
                  <option value="ELIGIBILITY VERIFICATION">
                    ELIGIBILITY VERIFICATION
                  </option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} controlId="">
                <Form.Label className="table-form-label mb-0">
                  Document Name
                </Form.Label>
                <Form.Control
                  className="grid-form-control"
                  placeholder="Document Name"
                  value={this.state.documentName}
                  onChange={(e) =>
                    this.setState({ documentName: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group
                as={Col}
                controlId=""
                className="table-form-label mb-0 mt-2"
              >
                <form id="attachform" name="attachform">
                  <Form.Control
                    type="file"
                    id="myfile"
                    accept="application/pdf"
                    name="myfile"
                    onChange={(e) =>
                      this.setState({ selectedFile: e.target.files[0] })
                    }
                  />
                </form>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn grid-btn"
                variant="secondary"
                onClick={() => {
                  this.handleShowCloseUploadFile(false);
                }}
              >
                Close
              </Button>
              <Button
                className="btn grid-btn"
                variant="primary"
                onClick={() => {
                  this.saveDocumentation();
                  //this.handleShowCloseUploadFile(false);
                }}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="auth-content-content">
            <Button
              className="btn back-btn"
              variant="secondary"
              type="button"
              className="btn back-btn mb-1 float-end mb-1"
              onClick={() => {
                this.props.navigate(back);
              }}
            >
              <img
                src={require("../../images/back-icon.png")}
                className="add-icon"
              />
              Back
            </Button>
            <div className="row">
              <div className="col-md-10">
                <PatientInfo />
              </div>
            </div>
            <div className="line-sep"></div>
            <div className="d-flex justify-content-between">
              <div>
                <label className="form-main-title">ENCOUNTER</label>
              </div>
            </div>
            <div className="main-panel">
              <Form>
                <Row className="mb-1 mt-2">
                  <div className="col-3">
                    <Form.Group as={Col} controlId="accountNumber">
                      <Form.Label className="table-form-label mb-0">
                        Account Number
                      </Form.Label>
                      <Form.Control
                        maxLength={20}
                        className="grid-form-control"
                        placeholder="Account Number"
                        value={this.state.accountNumber}
                        onChange={(e) =>
                          this.setState({ accountNumber: e.target.value })
                        }
                      />
                      <span className="table-form-label-err mb-0">
                        {this.state.accountNumberErr}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-3 d-flex flex-column">
                    <label className="date-picker-label mb-0">Visit Date</label>
                    <DatePicker
                      className="date-picker"
                      placeholderText="MM/DD/YYYY"
                      showIcon
                      onChange={(date) =>
                        this.setState({
                          visitDate: formatDateToCustomISO(date),
                        })
                      }
                      selected={
                        this.state.visitDate
                          ? formatDateToCustomISO(
                              moment(this.state.visitDate).toDate()
                            )
                          : null
                      }
                      customInput={
                        <MaskedTextInput type="text" mask={MaskDateInput} />
                      }
                    />
                    <span className="table-form-label-err mb-0 mt-2">
                      {this.state.visitDateErr}
                    </span>
                  </div>
                  <div className="col-3">
                    <Form.Group as={Col} controlId="authStatus">
                      <Form.Label className="table-form-label mb-0">
                        Visit Time
                      </Form.Label>
                      <Form.Control
                        maxLength={40}
                        type="time"
                        className="grid-form-control"
                        placeholder="Visit Time"
                        value={this.state.visitTime}
                        onChange={(e) =>
                          this.setState({ visitTime: e.target.value })
                        }
                      />
                      <span className="table-form-label-err mb-0">
                        {this.state.visitTimeErr}
                      </span>
                    </Form.Group>
                  </div>

                  <div className="col-3 d-flex flex-column">
                    <label className="date-picker-label mb-0">
                      Approval Date
                    </label>
                    <DatePicker
                      className="date-picker"
                      placeholderText="MM/DD/YYYY"
                      showIcon
                      onChange={(date) =>
                        this.setState({
                          approvalDate: formatDateToCustomISO(date),
                        })
                      }
                      selected={
                        this.state.approvalDate
                          ? formatDateToCustomISO(
                              moment(this.state.approvalDate).toDate()
                            )
                          : null
                      }
                      customInput={
                        <MaskedTextInput type="text" mask={MaskDateInput} />
                      }
                    />
                  </div>
                </Row>

                <Row>
                  <div>
                    <Button
                      className="form-save-btn btn-primary mt-1 mb-2 float-end"
                      type="button"
                      disabled={this.state.disabledSaveEncounter}
                      onClick={() => {
                        this.saveEncounter();
                      }}
                    >
                      <img
                        src={require("../../images/save-btn-icon.png")}
                        className="add-icon"
                      />
                      Save
                    </Button>
                  </div>
                </Row>
              </Form>
            </div>
            <div className="line-sep"></div>
            <div>
              <label className="form-main-title">ORDER INFORMATION</label>
            </div>
            <div class="row">
              <div class="order-info">
                <Form>
                  <Row>
                    <div className="col-lg-4 col-6">
                      <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">
                          Order Number
                        </Form.Label>
                        <Form.Control
                          maxLength={40}
                          className="grid-form-control"
                          value={this.state.orderOrderNumber}
                          onChange={(e) =>
                            this.setState({ orderOrderNumber: e.target.value })
                          }
                          onBlur={() => {
                            this.checkOrderDuplicates();
                          }}
                        />
                        <span className="table-form-label-err mb-0">
                          {this.state.orderOrderNumberErr}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4 col-6 d-flex flex-column">
                      <label className="table-form-label mb-0">
                        Requested Date
                      </label>
                      <DatePicker
                        className="date-picker"
                        showIcon
                        placeholderText="MM/DD/YYYY"
                        customInput={
                          <MaskedTextInput type="text" mask={MaskDateInput} />
                        }
                        onChange={(date) =>
                          this.setState({
                            orderRequestedDate: formatDateToCustomISO(date),
                          })
                        }
                        selected={
                          this.state.orderRequestedDate
                            ? formatDateToCustomISO(
                                moment(this.state.orderRequestedDate).toDate()
                              )
                            : null
                        }
                      />
                      <span className="table-form-label-err mb-0 mb-0">
                        {this.state.orderRequestedDateErr}
                      </span>
                    </div>
                    <div className="col-lg-4 col-6 d-flex flex-column">
                      <label className="table-form-label mb-0">
                        Follow-up Date
                      </label>
                      <DatePicker
                        className="date-picker"
                        showIcon
                        placeholderText="MM/DD/YYYY"
                        customInput={
                          <MaskedTextInput type="text" mask={MaskDateInput} />
                        }
                        onChange={(date) =>
                          this.setState({
                            orderFollowUpDate: formatDateToCustomISO(date),
                          })
                        }
                        selected={
                          this.state.orderFollowUpDate
                            ? formatDateToCustomISO(
                                moment(this.state.orderFollowUpDate).toDate()
                              )
                            : null
                        }
                      />
                      <span className="table-form-label-err mb-0 mb-0">
                        {this.state.orderFollowUpDateErr}
                      </span>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-lg-4 col-6">
                      <Form.Group as={Col} controlId="accountNumber">
                        <Form.Label className="table-form-label mb-0">
                          Payer
                        </Form.Label>
                        <div className="d-flex">
                          <Form.Select
                            className="grid-form-control"
                            value={this.state.orderPayerId}
                            onChange={async (e) => {
                              this.setState({ orderPayerId: e.target.value });
                              this.selectOrderPayer(e.target.value);
                              //TODO auto-populate submission type
                              await this.props.getPayerDto(e.target.value);
                              console.log(this.props.payerDto, "payerDto");
                              let trx = {};
                              for (let trxTypes of this.props.payerDto
                                .transactionTypes) {
                                if (
                                  trxTypes.transactionType ===
                                  "HCSR DETAILED AUTH/REFERRAL (278)"
                                ) {
                                  console.log("I am here");

                                  trx = trxTypes;
                                }
                              }
                              if (trx !== {}) {
                                switch (trx.priority1Type) {
                                  case "REST":
                                    this.setState({
                                      orderSubmissionType: "API-REST",
                                    });
                                    break;
                                  case "SOAP":
                                    this.setState({
                                      orderSubmissionType: "API-Soap",
                                    });
                                    break;
                                  case "EDI":
                                    this.setState({
                                      orderSubmissionType: "278_EDI",
                                    });
                                    break;
                                  case "PORTAL":
                                    if (trx.priority1_rpa === "Y") {
                                      this.setState({
                                        orderSubmissionType: "RPA",
                                      });
                                    } else {
                                      this.setState({
                                        orderSubmissionType: "Portal",
                                      });
                                    }
                                    break;
                                  case "E-Fax":
                                    this.setState({
                                      orderSubmissionType: "E-Fax",
                                    });
                                    break;
                                  case "Phone Call":
                                    this.setState({
                                      orderSubmissionType: "Phone Call",
                                    });
                                    break;
                                  default:
                                    this.setState({ orderSubmissionType: "" });
                                }
                              }
                            }}
                          >
                            <option value=""> -- Select Payer -- </option>
                            {payerList.map((payer, key) => {
                              return (
                                <option key={key} value={payer.id}>
                                  {payer.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <a
                            className="add-payer-btn"
                            onClick={() => {
                              this.handleShowCloseInsurance(true);
                            }}
                          >
                            <i className="fa fa-plus"></i>
                          </a>
                        </div>
                        <span className="table-form-label-err mb-0">
                          {this.state.orderPayerIdErr}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4 col-6">
                      <Form.Group as={Col} controlId="authStatus">
                        <Form.Label className="table-form-label mb-0">
                          Member ID
                        </Form.Label>
                        <Form.Control
                          className="grid-form-control"
                          maxLength={40}
                          value={this.state.orderMemberId}
                          onChange={(e) =>
                            this.setState({ orderMemberId: e.target.value })
                          }
                        />
                      </Form.Group>
                      <span className="table-form-label-err mb-0">
                        {this.state.orderMemberIdErr}
                      </span>
                    </div>
                    <div className="col-lg-4 col-6">
                      <Form.Group as={Col} controlId="authStatus">
                        <Form.Label className="table-form-label mb-0">
                          Relationship to Subscriber
                        </Form.Label>
                        <Form.Select
                          className="grid-form-control"
                          value={this.state.orderRelationshipToSubscriber}
                          onChange={(e) =>
                            this.setState({
                              orderRelationshipToSubscriber: e.target.value,
                            })
                          }
                        >
                          <option value=""> -- Select Relation -- </option>
                          {this.props.relation.map((rel, key) => {
                            return (
                              <option key={key} value={rel.value}>
                                {rel.value}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <span className="table-form-label-err mb-0">
                          {this.state.orderRelationshipToSubscriberErr}
                        </span>
                      </Form.Group>
                    </div>
                  </Row>
                  <Row className="my-3">
                    <div class="col-lg-4 col-6 d-flex">
                      <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">
                          Request Type
                        </Form.Label>
                        <Form.Select
                          className="grid-form-control"
                          value={this.state.orderRequestType}
                          onChange={(e) =>
                            this.setState({ orderRequestType: e.target.value })
                          }
                        >
                          <option value=""> -- Select Type -- </option>
                          <option value="IN-PATIENT">IN-PATIENT</option>
                          <option value="OUT-PATIENT">OUT-PATIENT</option>
                        </Form.Select>
                        <span className="table-form-label-err mb-0">
                          {this.state.orderRequestTypeErr}
                        </span>
                      </Form.Group>
                    </div>
                    <div class="col-lg-4 col-6 d-flex">
                      <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">
                          Submission Type
                        </Form.Label>
                        <Form.Select
                          className="grid-form-control"
                          value={this.state.orderSubmissionType}
                          onChange={(e) =>
                            this.setState({
                              orderSubmissionType: e.target.value,
                            })
                          }
                        >
                          <option value="">
                            {" "}
                            -- Select Submission Type --{" "}
                          </option>
                          <option value="API-REST">API-REST</option>
                          <option value="API-Soap">API-Soap</option>
                          <option value="278_EDI">278_EDI</option>
                          <option value="RPA">RPA</option>
                          <option value="Portal">Portal</option>
                          <option value="E-Fax">E-Fax</option>
                          <option value="Phone Call">Phone Call</option>
                        </Form.Select>
                        <span className="table-form-label-err mb-0">
                          {this.state.orderSubmissionTypeErr}
                        </span>
                      </Form.Group>
                    </div>
                    <div class="col-lg-4" style={{ display: "none" }}>
                      <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">
                          Place of Service
                        </Form.Label>
                        <Form.Select
                          className="grid-form-control"
                          value={this.state.orderPlaceOfService}
                          onChange={(e) =>
                            this.setState({
                              orderPlaceOfService: e.target.value,
                            })
                          }
                        >
                          <option value="">
                            {" "}
                            -- Select Place Of Service --{" "}
                          </option>
                          {placeOfServices.map((placeOfService, key) => {
                            return (
                              <option key={key} value={placeOfService.code}>
                                {placeOfService.code +
                                  " - " +
                                  placeOfService.value}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      <span className="table-form-label-err mb-0">
                        {this.state.orderPlaceOfServiceErr}
                      </span>
                    </div>
                  </Row>
                </Form>
              </div>
              <div class="eligibility-result">
                <div>
                  <label className="elig-info">Eligibility Result:</label>
                </div>
                {eligibilityResult}
              </div>
            </div>
            <div className="line-sep"></div>
            <div className="main-panel">
              <Row>
                <div class="col-lg-3 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Role
                    </Form.Label>
                    <Form.Select
                      className="grid-form-control"
                      value={this.state.orderReqRole}
                      onChange={this.selectOrderReqRole.bind(this)}
                    >
                      <option value="REFERRING">REFERRING</option>
                      <option value="FACILITY">FACILITY</option>
                      <option value="REQUESTING">REQUESTING</option>
                    </Form.Select>
                  </Form.Group>
                  <span className="table-form-label-err mb-0">
                    {this.state.orderReqRoleErr}
                  </span>
                </div>
                <div className="col-lg-3 col-6">
                  <Form.Group as={Col} controlId="accountNumber">
                    <Form.Label className="table-form-label mb-0">
                      Select a Provider
                    </Form.Label>
                    <div className="d-flex">
                      <Form.Select
                        className="grid-form-control"
                        value={this.state.orderReqProviderFacilityId}
                        onChange={(e) => {
                          this.setState({
                            orderReqProviderFacilityId: e.target.value,
                          });
                          this.selectProvider("REFERRING", e.target.value);
                        }}
                      >
                        <option value="">
                          {" "}
                          -- Select Provider/Facility --{" "}
                        </option>
                        {orderReqProviderFacility.map((provider, key) => {
                          return (
                            <option key={key} value={provider.id}>
                              {provider.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <a
                        className="add-payer-btn"
                        onClick={() => {
                          if (this.state.orderReqRole == "FACILITY") {
                            this.handleShowCloseFacility(true);
                          } else {
                            this.handleShowCloseProvider(true);
                          }
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </a>
                    </div>
                    <span className="table-form-label-err mb-0">
                      {this.state.orderReqProviderFacilityIdErr}
                    </span>
                  </Form.Group>
                </div>

                <div className="col-lg-3">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      NPI
                    </Form.Label>
                    <Form.Control
                      maxLength={40}
                      className="grid-form-control"
                      value={this.state.orderReqProNpi}
                      onChange={(e) =>
                        this.setState({ orderReqProNpi: e.target.value })
                      }
                    />
                  </Form.Group>
                  <span className="table-form-label-err mb-0">
                    {this.state.orderReqProNpiErr}
                  </span>
                </div>
                <div className="col-lg-3 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Speciality/ Taxonomy
                    </Form.Label>
                    <Form.Control
                      maxLength={40}
                      className="grid-form-control"
                      value={this.state.orderReqProSpecialtyTaxonomy}
                      onChange={(e) =>
                        this.setState({
                          orderReqProSpecialtyTaxonomy: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <span className="table-form-label-err mb-0">
                    {this.state.orderReqProSpecialtyTaxonomyErr}
                  </span>
                </div>
              </Row>
              <Row className="mt-1">
                <div className="col-lg-6 border-right-sep">
                  <Row>
                    <div className="col-lg-12">
                      <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">
                          Address Line 1
                        </Form.Label>
                        <Form.Control
                          className="grid-form-control"
                          maxLength={40}
                          value={this.state.orderReqAddress1}
                          onChange={(e) =>
                            this.setState({ orderReqAddress1: e.target.value })
                          }
                        />
                      </Form.Group>
                      <span className="table-form-label-err mb-0">
                        {this.state.orderReqAddress1Err}
                      </span>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-lg-5">
                      <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">
                          City
                        </Form.Label>
                        <Form.Control
                          className="grid-form-control"
                          maxLength={40}
                          value={this.state.orderReqCity}
                          onChange={(e) =>
                            this.setState({ orderReqCity: e.target.value })
                          }
                        />
                      </Form.Group>
                      <span className="table-form-label-err mb-0">
                        {this.state.orderReqCityErr}
                      </span>
                    </div>
                    <div className="col-lg-5">
                      <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">
                          State
                        </Form.Label>
                        <Form.Select
                          className="grid-form-control"
                          value={this.state.orderReqState}
                          onChange={(e) =>
                            this.setState({ orderReqState: e.target.value })
                          }
                        >
                          <option value=""> -- Select State -- </option>
                          {USStates.map((USState, key) => {
                            return (
                              <option key={key} value={USState.abbreviation}>
                                {USState.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <span className="table-form-label-err mb-0">
                          {this.state.orderReqStateErr}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col-lg-2">
                      <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">
                          Zip
                        </Form.Label>
                        <Form.Control
                          className="grid-form-control"
                          maxLength={10}
                          value={this.state.orderReqZip}
                          onChange={(e) =>
                            this.setState({ orderReqZip: e.target.value })
                          }
                        />
                      </Form.Group>
                      <span className="table-form-label-err mb-0">
                        {this.state.orderReqZipErr}
                      </span>
                    </div>
                  </Row>
                </div>
                <div className="col-lg-6">
                  <Row>
                    <div className="col-lg-6">
                      <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">
                          Contact First Name
                        </Form.Label>
                        <Form.Control
                          className="grid-form-control"
                          maxLength={40}
                          value={this.state.orderReqProFirstName}
                          onChange={(e) =>
                            this.setState({
                              orderReqProFirstName: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <span className="table-form-label-err mb-0">
                        {this.state.orderReqProFirstNameErr}
                      </span>
                    </div>
                    <div className="col-lg-6">
                      <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">
                          Contact Last Name
                        </Form.Label>
                        <Form.Control
                          className="grid-form-control"
                          maxLength={40}
                          value={this.state.orderReqProLastName}
                          onChange={(e) =>
                            this.setState({
                              orderReqProLastName: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <span className="table-form-label-err mb-0">
                        {this.state.orderReqProLastNameErr}
                      </span>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-lg-6">
                      <div className="d-flex">
                        <div className="me-1 col-6">
                          <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">
                              Contact Phone
                            </Form.Label>
                            <Form.Control
                              className="grid-form-control"
                              maxLength={15}
                              value={this.state.orderReqContactPhone}
                              onChange={(e) => {
                                let phone = this.addDashes(e.target.value);
                                this.setState({ orderReqContactPhone: phone });
                              }}
                            />
                          </Form.Group>
                          <span className="table-form-label-err mb-0">
                            {this.state.orderReqContactPhoneErr}
                          </span>
                        </div>
                        <div className="ms-1 col-6">
                          <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">
                              Extension
                            </Form.Label>
                            <Form.Control
                              className="grid-form-control"
                              maxLength={12}
                              value={this.state.orderReqContactPhoneExtension}
                              onChange={(e) =>
                                this.setState({
                                  orderReqContactPhoneExtension: e.target.value,
                                })
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">
                          Contact Fax
                        </Form.Label>
                        <Form.Control
                          className="grid-form-control"
                          maxLength={12}
                          value={this.state.orderReqContactFax}
                          onChange={(e) => {
                            let fax = this.addDashes(e.target.value);
                            this.setState({ orderReqContactFax: fax });
                          }}
                        />
                      </Form.Group>
                      <span className="table-form-label-err mb-0">
                        {this.state.orderReqContactFaxErr}
                      </span>
                    </div>
                  </Row>
                </div>
              </Row>
              <Row>
                <div className="mb-2">
                  <Button
                    className="form-save-btn save-order btn-primary mt-2 float-end"
                    type="button"
                    disabled={this.state.disabledSaveOrder}
                    onClick={() => {
                      this.saveOrder();
                    }}
                  >
                    <img
                      src={require("../../images/save-btn-icon.png")}
                      className="add-icon"
                    />
                    Save/Check Eligibility
                  </Button>
                </div>
              </Row>
            </div>
            <div className="line-sep"></div>
            <div style={hideOrderDetail}>
              <Table bordered className="table-portal">
                <thead>
                  <tr>
                    <th className="td-first"></th>
                    <th>Order Number</th>
                    <th>Requested Date</th>
                    <th>Pre-Auth Status</th>
                    <th>Disposition Code</th>
                    <th className="td-last">Request Type</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails.map((record, key) => {
                    return (
                      <>
                        <tr
                          onClick={(event) =>
                            this.onClickHandler(
                              { ...record, isNew: "N", btnClick: "N" },
                              event
                            )
                          }
                        >
                          <td className="td-first">
                            <i className="fa fa-chevron-down"></i>
                          </td>
                          <td>{record.orderNumber}</td>
                          <td>
                            {record.requestedDate
                              ? moment(
                                  formatDateToCustomISO(
                                    moment(record.requestedDate).toDate()
                                  )
                                ).format("MM/DD/YYYY")
                              : ""}
                          </td>

                          <td>{record.id ? record.preAuthStatus : ""}</td>
                          <td>{record.id ? record.dispositionCode : ""}</td>
                          <td className="td-last">{record.requestType}</td>
                        </tr>
                        <tr className="main-table collapse">
                          <td className="td-first td-last" colSpan="6">
                            <Form>
                              <Row>
                                <div className="col-lg-6">
                                  <Row>
                                    <div className="col-lg-6">
                                      <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">
                                          Order Number
                                        </Form.Label>
                                        <Form.Control
                                          maxLength={40}
                                          className="grid-form-control"
                                          value={this.state.orderNumber}
                                          onChange={(e) =>
                                            this.setState({
                                              orderNumber: e.target.value,
                                            })
                                          }
                                        />
                                      </Form.Group>
                                      <span className="table-form-label-err mb-0">
                                        {this.state.orderNumberErr}
                                      </span>
                                    </div>
                                    <div class="col-lg-6 d-flex items-end">
                                      <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">
                                          Submission Type
                                        </Form.Label>
                                        <Form.Select
                                          className="grid-form-control"
                                          value={this.state.submissionType}
                                          onChange={(e) =>
                                            this.setState({
                                              submissionType: e.target.value,
                                            })
                                          }
                                        >
                                          <option value="">
                                            {" "}
                                            -- Select Submission Type --{" "}
                                          </option>
                                          <option value="API-REST">
                                            API-REST
                                          </option>
                                          <option value="API-Soap">
                                            API-Soap
                                          </option>
                                          <option value="278_EDI">
                                            278_EDI
                                          </option>
                                          <option value="RPA">RPA</option>
                                          <option value="Portal">Portal</option>
                                          <option value="E-Fax">E-Fax</option>
                                          <option value="Phone Call">
                                            Phone Call
                                          </option>
                                        </Form.Select>
                                        <span className="table-form-label-err mb-0">
                                          {this.state.submissionTypeErr}
                                        </span>
                                      </Form.Group>
                                      <Button
                                        className={`view-fax-btn ${
                                          this.state.submissionType === "E-Fax"
                                            ? ""
                                            : "hidden"
                                        }`}
                                        variant="primary"
                                        type="button"
                                        onClick={async (e) => {
                                          console.log("I am here!!", record);
                                          localStorage.setItem(
                                            "fax_attachment_filetype",
                                            "application/pdf"
                                          );
                                          await this.props.getCignaFax(
                                            record.clientId,
                                            record.id
                                          );
                                          console.log(this.props.cignaFax);
                                          const file = new Blob(
                                            [this.props.cignaFax],
                                            { type: "application/pdf" }
                                          );
                                          if (
                                            window.navigator &&
                                            window.navigator.msSaveOrOpenBlob
                                          ) {
                                            window.navigator.msSaveOrOpenBlob(
                                              file,
                                              "application/pdf"
                                            );
                                            console.log(
                                              "I am here ---------------"
                                            );
                                          } else {
                                            console.log("I am here");
                                            const fileURL =
                                              URL.createObjectURL(file);
                                            window.open(fileURL, "_blank");
                                          }
                                        }}
                                      >
                                        View Fax
                                      </Button>
                                    </div>
                                  </Row>
                                  <Row>
                                    <div className="col-lg-6">
                                      <Form.Group
                                        as={Col}
                                        controlId="accountNumber"
                                      >
                                        <Form.Label className="table-form-label mb-0">
                                          Payer
                                        </Form.Label>
                                        <div className="d-flex">
                                          <Form.Select
                                            className="grid-form-control"
                                            value={this.state.payerId}
                                            onChange={(e) => {
                                              this.setState({
                                                payerId: e.target.value,
                                              });
                                              this.selectOrderDetailPayer(
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <option value="">
                                              {" "}
                                              -- Select Payer --{" "}
                                            </option>
                                            {payerList.map((payer, key) => {
                                              return (
                                                <option
                                                  key={key}
                                                  value={payer.id}
                                                >
                                                  {payer.name}
                                                </option>
                                              );
                                            })}
                                          </Form.Select>
                                          <a
                                            className="add-payer-btn"
                                            onClick={() => {
                                              this.handleShowCloseInsurance(
                                                true
                                              );
                                            }}
                                          >
                                            <i className="fa fa-plus"></i>
                                          </a>
                                        </div>
                                        <span className="table-form-label-err mb-0">
                                          {this.state.payerIdErr}
                                        </span>
                                      </Form.Group>
                                    </div>

                                    <div class="col-lg-6">
                                      <div class="ps-0">
                                        <div class="row px-0">
                                          <Form.Group as={Col} controlId="">
                                            <Form.Label className="table-form-label mb-0">
                                              Pre-Auth Status
                                            </Form.Label>

                                            <Form.Select
                                              className="grid-form-control"
                                              value={this.state.preAuthStatus}
                                              onChange={
                                                this.handlePreAuthStatus
                                              }
                                            >
                                              <option value="">
                                                {" "}
                                                -- Select Status --{" "}
                                              </option>
                                              {this.props.statusCodes.map(
                                                (statusCode, key) => {
                                                  return (
                                                    <option
                                                      key={key}
                                                      value={statusCode.code}
                                                    >
                                                      {statusCode.value}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </Form.Select>
                                          </Form.Group>
                                          {spinner}
                                        </div>
                                      </div>
                                      <span className="table-form-label-err mb-0">
                                        {this.state.preAuthStatusErr}
                                      </span>
                                    </div>
                                  </Row>

                                  <Row>
                                    <div className="col-lg-6">
                                      <Form.Group
                                        as={Col}
                                        controlId="authStatus"
                                      >
                                        <Form.Label className="table-form-label mb-0">
                                          Member ID
                                        </Form.Label>
                                        <Form.Control
                                          maxLength={40}
                                          className="grid-form-control"
                                          value={this.state.memberId}
                                          onChange={(e) =>
                                            this.setState({
                                              memberId: e.target.value,
                                            })
                                          }
                                        />
                                      </Form.Group>
                                      <span className="table-form-label-err mb-0">
                                        {this.state.memberIdErr}
                                      </span>
                                    </div>
                                    <div className="col-lg-6">
                                      <Form.Group
                                        as={Col}
                                        controlId="authStatus"
                                      >
                                        <Form.Label className="table-form-label mb-0">
                                          Disposition Code
                                        </Form.Label>
                                        <Form.Select
                                          className="grid-form-control"
                                          value={this.state.dispositionCode}
                                          onChange={this.handleDispositionCode}
                                        >
                                          <option value="">
                                            {" "}
                                            -- Select Disposition Code --{" "}
                                          </option>
                                          {dispositionCodes.map(
                                            (dispositionCode, key) => {
                                              return (
                                                <option
                                                  key={key}
                                                  value={dispositionCode.code}
                                                >
                                                  {dispositionCode.value}
                                                </option>
                                              );
                                            }
                                          )}
                                        </Form.Select>
                                      </Form.Group>
                                      <span className="table-form-label-err mb-0">
                                        {this.state.dispositionCodeErr}
                                      </span>
                                    </div>
                                  </Row>
                                  <Row>
                                    <div class="col-lg-6 d-flex">
                                      <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">
                                          Request Type
                                        </Form.Label>
                                        <Form.Select
                                          className="grid-form-control"
                                          value={this.state.requestType}
                                          onChange={(e) =>
                                            this.setState({
                                              requestType: e.target.value,
                                            })
                                          }
                                        >
                                          <option value="">
                                            {" "}
                                            -- Select Type --{" "}
                                          </option>
                                          <option value="IN-PATIENT">
                                            IN-PATIENT
                                          </option>
                                          <option value="OUT-PATIENT">
                                            OUT-PATIENT
                                          </option>
                                        </Form.Select>
                                        <span className="table-form-label-err mb-0">
                                          {this.state.requestTypeErr}
                                        </span>
                                      </Form.Group>
                                    </div>

                                    {isReasonCodeFieldVisible && (
                                      <div className="col-lg-6">
                                        <Form.Group
                                          as={Col}
                                          controlId="authStatus"
                                        >
                                          <Form.Label className="table-form-label mb-0">
                                            Reason Code
                                          </Form.Label>
                                          <Form.Select
                                            className="grid-form-control"
                                            value={
                                              this.state.rejectedReasonCode
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                rejectedReasonCode:
                                                  e.target.value,
                                              })
                                            }
                                          >
                                            <option value="">
                                              {" "}
                                              -- Select Reason Code --{" "}
                                            </option>
                                            {reasonCodes.map(
                                              (reasonCode, key) => {
                                                return (
                                                  <option
                                                    key={key}
                                                    value={reasonCode.code}
                                                  >
                                                    {reasonCode.value}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </Form.Select>
                                        </Form.Group>
                                        <span className="table-form-label-err mb-0">
                                          {this.state.reasonCodeErr}
                                        </span>
                                      </div>
                                    )}
                                  </Row>
                                  <Row>
                                    <div className="col-lg-6 d-flex flex-column">
                                      <label className="table-form-label mb-0">
                                        Requested Date
                                      </label>
                                      <DatePicker
                                        className="date-picker"
                                        showIcon
                                        placeholderText="MM/DD/YYYY"
                                        customInput={
                                          <MaskedTextInput
                                            type="text"
                                            mask={MaskDateInput}
                                          />
                                        }
                                        onChange={(date) =>
                                          this.setState({
                                            requestedDate:
                                              formatDateToCustomISO(date),
                                          })
                                        }
                                        selected={
                                          this.state.requestedDate
                                            ? formatDateToCustomISO(
                                                moment(
                                                  this.state.requestedDate
                                                ).toDate()
                                              )
                                            : null
                                        }
                                      />
                                      <span className="table-form-label-err mb-0 mb-0">
                                        {this.state.requestedDateErr}
                                      </span>
                                    </div>
                                  </Row>
                                  <Row>
                                    <div className="col-lg-6"></div>
                                    <div class="col-lg-6">
                                      <Button
                                        className="form-save-btn btn-primary mt-1 float-end"
                                        type="button"
                                        disabled={
                                          this.state.disabledSaveOrderDetail
                                        }
                                        onClick={() => {
                                          this.saveEncounterOrderDetail();
                                        }}
                                      >
                                        <img
                                          src={require("../../images/save-btn-icon.png")}
                                          className="add-icon"
                                        />
                                        Save
                                      </Button>
                                    </div>
                                  </Row>
                                </div>
                                <div className="col-lg-6">
                                  <Row>
                                    <div className="col-lg-12 d-flex">
                                      <Form.Group
                                        as={Col}
                                        controlId="authStatus"
                                      >
                                        <Form.Label className="table-form-label mb-0">
                                          Notes
                                        </Form.Label>
                                        <Table
                                          bordered
                                          className="table-portal"
                                        >
                                          <thead>
                                            <tr>
                                              <th className="td-first"></th>
                                              <th>Date</th>
                                              <th>Created By</th>
                                              <th>Modified By</th>
                                              <th className="td-last">Note</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {orderDetailsNote.map(
                                              (recordDetail, key) => {
                                                return (
                                                  <>
                                                    <tr
                                                      onClick={(event) =>
                                                        this.onClickHandlerSubTable(
                                                          {
                                                            ...recordDetail,
                                                            isNew: "N",
                                                            btnClick: "N",
                                                          },
                                                          event
                                                        )
                                                      }
                                                    >
                                                      <td className="td-first">
                                                        <i className="fa fa-chevron-down"></i>
                                                      </td>
                                                      <td>
                                                        {recordDetail.createdDate
                                                          ? moment(
                                                              formatDateToCustomISO(
                                                                moment(
                                                                  recordDetail.createdDate
                                                                ).toDate()
                                                              )
                                                            ).format(
                                                              "MM/DD/YYYY"
                                                            )
                                                          : ""}
                                                      </td>
                                                      <td>
                                                        {recordDetail.createdBy}
                                                      </td>
                                                      <td>
                                                        {
                                                          recordDetail.modifiedBy
                                                        }
                                                      </td>
                                                      <td className="td-last">
                                                        {recordDetail.note}
                                                      </td>
                                                    </tr>
                                                    <tr className="sub-table collapse">
                                                      <td
                                                        className="td-first td-last"
                                                        colSpan="5"
                                                      >
                                                        <Form>
                                                          <Row>
                                                            <div class="col-lg-12 col-6">
                                                              <Form.Group
                                                                as={Col}
                                                                controlId=""
                                                              >
                                                                <Form.Label className="table-form-label mb-0">
                                                                  Role
                                                                </Form.Label>
                                                                <Form.Control
                                                                  as="textarea"
                                                                  rows="3"
                                                                  className="grid-form-control grid-form-textarea order-notes"
                                                                  value={
                                                                    this.state
                                                                      .note
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    this.setState(
                                                                      {
                                                                        note: e
                                                                          .target
                                                                          .value,
                                                                      }
                                                                    )
                                                                  }
                                                                />
                                                                <span className="table-form-label-err mb-0">
                                                                  {
                                                                    this.state
                                                                      .noteErr
                                                                  }
                                                                </span>
                                                              </Form.Group>
                                                              <span className="table-form-label-err mb-0"></span>
                                                            </div>
                                                          </Row>
                                                          <Row>
                                                            <div>
                                                              <Button
                                                                className="form-save-btn btn-primary mt-1 float-end"
                                                                type="button"
                                                                disabled={
                                                                  this.state
                                                                    .disabledSaveNote
                                                                }
                                                                onClick={() => {
                                                                  this.saveNote();
                                                                }}
                                                              >
                                                                <img
                                                                  src={require("../../images/save-btn-icon.png")}
                                                                  className="add-icon"
                                                                />
                                                                Save
                                                              </Button>
                                                            </div>
                                                          </Row>
                                                        </Form>
                                                      </td>
                                                    </tr>
                                                  </>
                                                );
                                              }
                                            )}
                                            <tr
                                              onClick={(event) =>
                                                this.onClickHandlerSubTable(
                                                  {
                                                    ...this
                                                      .orderDetailNotesDefault,
                                                    isNew: "Y",
                                                    btnClick: "N",
                                                  },
                                                  event
                                                )
                                              }
                                            >
                                              <td className="td-first">
                                                <i className="fa fa-chevron-down"></i>
                                              </td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td className="td-last"></td>
                                            </tr>
                                            <tr className="sub-table collapse">
                                              <td
                                                className="td-first td-last"
                                                colSpan="5"
                                              >
                                                <Form>
                                                  <Row>
                                                    <div class="col-lg-12">
                                                      <Form.Group
                                                        as={Col}
                                                        controlId=""
                                                      >
                                                        <Form.Control
                                                          as="textarea"
                                                          rows="3"
                                                          className="grid-form-control grid-form-textarea order-notes"
                                                          value={
                                                            this.state.note
                                                          }
                                                          onChange={(e) =>
                                                            this.setState({
                                                              note: e.target
                                                                .value,
                                                            })
                                                          }
                                                        />
                                                        <span className="table-form-label-err mb-0">
                                                          {this.state.noteErr}
                                                        </span>
                                                      </Form.Group>
                                                    </div>
                                                  </Row>
                                                  <Row>
                                                    <div>
                                                      <Button
                                                        className="form-save-btn btn-primary mt-1 float-end"
                                                        type="button"
                                                        disabled={
                                                          this.state
                                                            .disabledSaveNote
                                                        }
                                                        onClick={() => {
                                                          this.saveNote();
                                                        }}
                                                      >
                                                        <img
                                                          src={require("../../images/save-btn-icon.png")}
                                                          className="add-icon"
                                                        />
                                                        Save
                                                      </Button>
                                                    </div>
                                                  </Row>
                                                </Form>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      </Form.Group>
                                    </div>
                                  </Row>
                                </div>
                              </Row>
                              <div style={hideOrderDetailOthers}>
                                <div class="form-main-title mt-3">
                                  <label>Servicing Provider</label>
                                </div>
                                <Table bordered className="table-portal">
                                  <thead>
                                    <tr>
                                      <th className="td-first"></th>
                                      <th>Role</th>
                                      <th>NPI</th>
                                      <th>Name</th>
                                      <th className="td-last">
                                        Speciality/ Taxonomy
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {orderDetailsProviders.map(
                                      (recordDetail, key) => {
                                        return (
                                          <>
                                            <tr
                                              onClick={(event) =>
                                                this.onClickHandlerSubTable(
                                                  {
                                                    ...recordDetail,
                                                    isNew: "N",
                                                    btnClick: "N",
                                                    contactPhoneAttendingProvider:recordDetail.contactPhone,
                                                    contactPhoneExtensionAttendingProvider:recordDetail.contactPhoneExt,
                                                    contactFaxAttendingProvider:recordDetail.contactFax
                                                  },
                                                  event
                                                )
                                              }
                                            >
                                              <td className="td-first">
                                                <i className="fa fa-chevron-down"></i>
                                              </td>
                                              <td>{recordDetail.role}</td>
                                              <td>{recordDetail.npi}</td>
                                              <td>
                                                {recordDetail.role ===
                                                "FACILITY"
                                                  ? recordDetail.name
                                                  : `${recordDetail.firstName} ${recordDetail.lastName}`}
                                              </td>
                                              <td className="td-last">
                                                {recordDetail.specialtyTaxonomy}
                                              </td>
                                            </tr>
                                            <tr className="sub-table collapse">
                                              <td
                                                className="td-first td-last"
                                                colSpan="5"
                                              >
                                                <Form>
                                                  <Row>
                                                    <div class="col-lg-2 col-6">
                                                      <Form.Group
                                                        as={Col}
                                                        controlId=""
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          Role
                                                        </Form.Label>
                                                        <Form.Select
                                                          className="grid-form-control"
                                                          value={
                                                            this.state.role
                                                          }
                                                          onChange={this.selectRole.bind(
                                                            this
                                                          )}
                                                        >
                                                          <option value="RENDERING">
                                                            RENDERING
                                                          </option>
                                                          <option value="FACILITY">
                                                            FACILITY
                                                          </option>
                                                        </Form.Select>
                                                      </Form.Group>
                                                      <span className="table-form-label-err mb-0">
                                                        {this.state.roleErr}
                                                      </span>
                                                    </div>
                                                    <div className="col-lg-2 col-6">
                                                      <Form.Group
                                                        as={Col}
                                                        controlId="accountNumber"
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          Select a Provider
                                                        </Form.Label>
                                                        <div className="d-flex">
                                                          <Form.Select
                                                            className="grid-form-control"
                                                            value={
                                                              this.state
                                                                .providerFacilityId
                                                            }
                                                            onChange={(e) => {
                                                              this.setState({
                                                                providerFacilityId:
                                                                  e.target
                                                                    .value,
                                                              });
                                                              this.selectProvider(
                                                                this.state.role,
                                                                e.target.value
                                                              );
                                                            }}
                                                          >
                                                            <option value="">
                                                              {" "}
                                                              -- Select
                                                              Provider/Facility
                                                              --{" "}
                                                            </option>
                                                            {providerFacility.map(
                                                              (data, key) => {
                                                                return (
                                                                  <option
                                                                    key={key}
                                                                    value={
                                                                      data.id
                                                                    }
                                                                  >
                                                                    {data.name}
                                                                  </option>
                                                                );
                                                              }
                                                            )}
                                                          </Form.Select>
                                                          <a
                                                            className="add-payer-btn"
                                                            onClick={() => {
                                                              if (
                                                                this.state
                                                                  .role ==
                                                                "FACILITY"
                                                              ) {
                                                                this.handleShowCloseFacility(
                                                                  true
                                                                );
                                                              } else {
                                                                this.handleShowCloseProvider(
                                                                  true
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            <i className="fa fa-plus"></i>
                                                          </a>
                                                        </div>
                                                        <span className="table-form-label-err mb-0">
                                                          {
                                                            this.state
                                                              .providerFacilityIdErr
                                                          }
                                                        </span>
                                                      </Form.Group>
                                                    </div>
                                                    <div
                                                      style={
                                                        hideOtherFacilityProvider
                                                      }
                                                      className="col-lg-2 col-6"
                                                    >
                                                      <Form.Group
                                                        as={Col}
                                                        controlId=""
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          Name
                                                        </Form.Label>
                                                        <Form.Control
                                                          className="grid-form-control"
                                                          maxLength={40}
                                                          value={
                                                            this.state.name
                                                          }
                                                          onChange={(e) =>
                                                            this.setState({
                                                              name: e.target
                                                                .value,
                                                            })
                                                          }
                                                        />
                                                      </Form.Group>
                                                      <span className="table-form-label-err mb-0">
                                                        {this.state.nameErr}
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={
                                                        hideOtherAttendingProvider
                                                      }
                                                      className="col-lg-2 col-6"
                                                    >
                                                      <Form.Group
                                                        as={Col}
                                                        controlId=""
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          First Name
                                                        </Form.Label>
                                                        <Form.Control
                                                          className="grid-form-control"
                                                          maxLength={40}
                                                          value={
                                                            this.state.firstName
                                                          }
                                                          onChange={(e) =>
                                                            this.setState({
                                                              firstName:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                      </Form.Group>
                                                      <span className="table-form-label-err mb-0">
                                                        {
                                                          this.state
                                                            .firstNameErr
                                                        }
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={
                                                        hideOtherAttendingProvider
                                                      }
                                                      className="col-lg-2 col-6"
                                                    >
                                                      <Form.Group
                                                        as={Col}
                                                        controlId=""
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          Last Name
                                                        </Form.Label>
                                                        <Form.Control
                                                          className="grid-form-control"
                                                          maxLength={40}
                                                          value={
                                                            this.state.lastName
                                                          }
                                                          onChange={(e) =>
                                                            this.setState({
                                                              lastName:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                      </Form.Group>
                                                      <span className="table-form-label-err mb-0">
                                                        {this.state.lastNameErr}
                                                      </span>
                                                    </div>
                                                    <div className="col-lg-2 col-6">
                                                      <Form.Group
                                                        as={Col}
                                                        controlId=""
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          NPI
                                                        </Form.Label>
                                                        <Form.Control
                                                          className="grid-form-control"
                                                          maxLength={40}
                                                          value={this.state.npi}
                                                          onChange={(e) =>
                                                            this.setState({
                                                              npi: e.target
                                                                .value,
                                                            })
                                                          }
                                                        />
                                                      </Form.Group>
                                                      <span className="table-form-label-err mb-0">
                                                        {this.state.npiErr}
                                                      </span>
                                                    </div>
                                                    <div className="col-lg-2 col-6">
                                                      <Form.Group
                                                        as={Col}
                                                        controlId=""
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          Speciality/ Taxonomy
                                                        </Form.Label>
                                                        <Form.Control
                                                          className="grid-form-control"
                                                          maxLength={40}
                                                          value={
                                                            this.state
                                                              .specialtyTaxonomy
                                                          }
                                                          onChange={(e) =>
                                                            this.setState({
                                                              specialtyTaxonomy:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                      </Form.Group>
                                                      <span className="table-form-label-err mb-0">
                                                        {
                                                          this.state
                                                            .specialtyTaxonomyErr
                                                        }
                                                      </span>
                                                    </div>
                                                  </Row>
                                                  <Row>
                                                    <div className="col-lg-4">
                                                      <Form.Group
                                                        as={Col}
                                                        controlId=""
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          Address Line 1
                                                        </Form.Label>
                                                        <Form.Control
                                                          className="grid-form-control"
                                                          maxLength={40}
                                                          value={
                                                            this.state.address1
                                                          }
                                                          onChange={(e) =>
                                                            this.setState({
                                                              address1:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                      </Form.Group>
                                                      <span className="table-form-label-err mb-0">
                                                        {this.state.address1Err}
                                                      </span>
                                                    </div>
                                                    <div className="col-lg-2">
                                                      <Form.Group
                                                        as={Col}
                                                        controlId=""
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          City
                                                        </Form.Label>
                                                        <Form.Control
                                                          className="grid-form-control"
                                                          maxLength={40}
                                                          value={
                                                            this.state.city
                                                          }
                                                          onChange={(e) =>
                                                            this.setState({
                                                              city: e.target
                                                                .value,
                                                            })
                                                          }
                                                        />
                                                      </Form.Group>
                                                      <span className="table-form-label-err mb-0">
                                                        {this.state.cityErr}
                                                      </span>
                                                    </div>
                                                    <div className="col-lg-2">
                                                      <Form.Group
                                                        as={Col}
                                                        controlId=""
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          State
                                                        </Form.Label>
                                                        <Form.Select
                                                          className="grid-form-control"
                                                          value={
                                                            this.state.state
                                                          }
                                                          onChange={(e) =>
                                                            this.setState({
                                                              state:
                                                                e.target.value,
                                                            })
                                                          }
                                                        >
                                                          <option value="">
                                                            {" "}
                                                            -- Select State --{" "}
                                                          </option>
                                                          {USStates.map(
                                                            (USState, key) => {
                                                              return (
                                                                <option
                                                                  key={key}
                                                                  value={
                                                                    USState.abbreviation
                                                                  }
                                                                >
                                                                  {USState.name}
                                                                </option>
                                                              );
                                                            }
                                                          )}
                                                        </Form.Select>
                                                      </Form.Group>
                                                      <span className="table-form-label-err mb-0">
                                                        {this.state.stateErr}
                                                      </span>
                                                    </div>
                                                    <div className="col-lg-1">
                                                      <Form.Group
                                                        as={Col}
                                                        controlId=""
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          Zip
                                                        </Form.Label>
                                                        <Form.Control
                                                          className="grid-form-control"
                                                          maxLength={10}
                                                          value={this.state.zip}
                                                          onChange={(e) =>
                                                            this.setState({
                                                              zip: e.target
                                                                .value,
                                                            })
                                                          }
                                                        />
                                                      </Form.Group>
                                                      <span className="table-form-label-err mb-0">
                                                        {this.state.zipErr}
                                                      </span>
                                                    </div>
                                                  </Row>
                                                  <Row>
                                                    <div className="col-lg-2">
                                                      <Form.Group
                                                        as={Col}
                                                        controlId=""
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          Phone
                                                        </Form.Label>
                                                        <Form.Control
                                                          className="grid-form-control"
                                                          maxLength={15}
                                                          value={
                                                            this.state.contactPhoneAttendingProvider
                                                          }
                                                          onChange={(e) => {
                                                            let phone = this.addDashes(e.target.value);
                                                            this.setState({ contactPhoneAttendingProvider: phone });
                                                          }}
                                                        />
                                                      </Form.Group>
                                                      <span className="table-form-label-err mb-0">
                                                        {this.state.contactPhoneAttendingProviderErr}
                                                      </span>
                                                    </div>
                                                    <div className="col-lg-2">
                                                      <Form.Group
                                                        as={Col}
                                                        controlId=""
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          Extension
                                                        </Form.Label>
                                                        <Form.Control
                                                          className="grid-form-control"
                                                          maxLength={12}
                                                          value={
                                                            this.state.contactPhoneExtensionAttendingProvider
                                                          }
                                                          onChange={(e) =>
                                                            this.setState({
                                                              contactPhoneExtensionAttendingProvider: e.target
                                                                .value,
                                                            })
                                                          }
                                                        />
                                                      </Form.Group>
                                                      <span className="table-form-label-err mb-0">
                                                        {this.state.contactPhoneExtensionAttendingProviderErr}
                                                      </span>
                                                    </div>
                                                    <div className="col-lg-2">
                                                      <Form.Group
                                                        as={Col}
                                                        controlId=""
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          Fax
                                                        </Form.Label>
                                                        <Form.Control
                                                          className="grid-form-control"
                                                          maxLength={15}
                                                          value={this.state.contactFaxAttendingProvider}
                                                          onChange={(e) => {
                                                            let fax = this.addDashes(e.target.value);
                                                            this.setState({ contactFaxAttendingProvider: fax });
                                                          }}
                                                        />
                                                      </Form.Group>
                                                      <span className="table-form-label-err mb-0">
                                                        {this.state.contactFaxAttendingProviderErr}
                                                      </span>
                                                    </div>
                                                  </Row>
                                                  <Row>
                                                    <div>
                                                      <Button
                                                        className="form-save-btn btn-primary mt-1 float-end"
                                                        type="button"
                                                        disabled={
                                                          this.state
                                                            .disabledSaveAttendingProvider
                                                        }
                                                        onClick={() => {
                                                          this.saveAttendingProvider();
                                                        }}
                                                      >
                                                        <img
                                                          src={require("../../images/save-btn-icon.png")}
                                                          className="add-icon"
                                                        />
                                                        Save
                                                      </Button>
                                                    </div>
                                                  </Row>
                                                </Form>
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      }
                                    )}
                                    <tr
                                      onClick={(event) =>
                                        this.onClickHandlerSubTable(
                                          {
                                            ...this
                                              .orderDetailAttendingProviderDefault,
                                            isNew: "Y",
                                            btnClick: "N",
                                          },
                                          event
                                        )
                                      }
                                    >
                                      <td className="td-first">
                                        <i className="fa fa-chevron-down"></i>
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className="td-last"></td>
                                    </tr>
                                    <tr className="sub-table collapse">
                                      <td
                                        className="td-first td-last"
                                        colSpan="5"
                                      >
                                        <Form>
                                          <Row>
                                            <div class="col-lg-2 col-6">
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">
                                                  Role
                                                </Form.Label>
                                                <Form.Select
                                                  className="grid-form-control"
                                                  value={this.state.role}
                                                  onChange={this.selectRole.bind(
                                                    this
                                                  )}
                                                >
                                                  <option value="RENDERING">
                                                    RENDERING
                                                  </option>
                                                  <option value="FACILITY">
                                                    FACILITY
                                                  </option>
                                                </Form.Select>
                                              </Form.Group>
                                            </div>
                                            <div className="col-lg-2 col-6">
                                              <Form.Group
                                                as={Col}
                                                controlId="accountNumber"
                                              >
                                                <Form.Label className="table-form-label mb-0">
                                                  Select a Provider
                                                </Form.Label>
                                                <div className="d-flex">
                                                  <Form.Select
                                                    className="grid-form-control"
                                                    value={
                                                      this.state
                                                        .providerFacilityId
                                                    }
                                                    onChange={(e) => {
                                                      this.setState({
                                                        providerFacilityId:
                                                          e.target.value,
                                                      });
                                                      this.selectProvider(
                                                        this.state.role,
                                                        e.target.value
                                                      );
                                                    }}
                                                  >
                                                    <option value="">
                                                      {" "}
                                                      -- Select
                                                      Provider/Facility --{" "}
                                                    </option>
                                                    {providerFacility.map(
                                                      (data, key) => {
                                                        return (
                                                          <option
                                                            key={key}
                                                            value={data.id}
                                                          >
                                                            {data.name}
                                                          </option>
                                                        );
                                                      }
                                                    )}
                                                  </Form.Select>
                                                  <a
                                                    className="add-payer-btn"
                                                    onClick={() => {
                                                      if (
                                                        this.state.role ==
                                                        "FACILITY"
                                                      ) {
                                                        this.handleShowCloseFacility(
                                                          true
                                                        );
                                                      } else {
                                                        this.handleShowCloseProvider(
                                                          true
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <i className="fa fa-plus"></i>
                                                  </a>
                                                </div>
                                                <span className="table-form-label-err mb-0">
                                                  {
                                                    this.state
                                                      .providerFacilityIdErr
                                                  }
                                                </span>
                                              </Form.Group>
                                            </div>
                                            <div
                                              style={hideOtherFacilityProvider}
                                              className="col-lg-2 col-6"
                                            >
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">
                                                  Name
                                                </Form.Label>
                                                <Form.Control
                                                  className="grid-form-control"
                                                  maxLength={40}
                                                  value={this.state.name}
                                                  onChange={(e) =>
                                                    this.setState({
                                                      name: e.target.value,
                                                    })
                                                  }
                                                />
                                              </Form.Group>
                                              <span className="table-form-label-err mb-0">
                                                {this.state.nameErr}
                                              </span>
                                            </div>
                                            <div
                                              style={hideOtherAttendingProvider}
                                              className="col-lg-2 col-6"
                                            >
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">
                                                  First Name
                                                </Form.Label>
                                                <Form.Control
                                                  className="grid-form-control"
                                                  maxLength={40}
                                                  value={this.state.firstName}
                                                  onChange={(e) =>
                                                    this.setState({
                                                      firstName: e.target.value,
                                                    })
                                                  }
                                                />
                                              </Form.Group>
                                              <span className="table-form-label-err mb-0">
                                                {this.state.firstNameErr}
                                              </span>
                                            </div>
                                            <div
                                              style={hideOtherAttendingProvider}
                                              className="col-lg-2 col-6"
                                            >
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">
                                                  Last Name
                                                </Form.Label>
                                                <Form.Control
                                                  className="grid-form-control"
                                                  maxLength={40}
                                                  value={this.state.lastName}
                                                  onChange={(e) =>
                                                    this.setState({
                                                      lastName: e.target.value,
                                                    })
                                                  }
                                                />
                                              </Form.Group>
                                              <span className="table-form-label-err mb-0">
                                                {this.state.lastNameErr}
                                              </span>
                                            </div>
                                            <div className="col-lg-2 col-6">
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">
                                                  NPI
                                                </Form.Label>
                                                <Form.Control
                                                  className="grid-form-control"
                                                  maxLength={40}
                                                  value={this.state.npi}
                                                  onChange={(e) =>
                                                    this.setState({
                                                      npi: e.target.value,
                                                    })
                                                  }
                                                />
                                              </Form.Group>
                                              <span className="table-form-label-err mb-0">
                                                {this.state.npiErr}
                                              </span>
                                            </div>
                                            <div className="col-lg-2 col-6">
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">
                                                  Speciality/ Taxonomy
                                                </Form.Label>
                                                <Form.Control
                                                  className="grid-form-control"
                                                  maxLength={40}
                                                  value={
                                                    this.state.specialtyTaxonomy
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      specialtyTaxonomy:
                                                        e.target.value,
                                                    })
                                                  }
                                                />
                                              </Form.Group>
                                              <span className="table-form-label-err mb-0">
                                                {
                                                  this.state
                                                    .specialtyTaxonomyErr
                                                }
                                              </span>
                                            </div>
                                          </Row>
                                          <Row>
                                            <div className="col-lg-4">
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">
                                                  Address Line 1
                                                </Form.Label>
                                                <Form.Control
                                                  className="grid-form-control"
                                                  maxLength={40}
                                                  value={this.state.address1}
                                                  onChange={(e) =>
                                                    this.setState({
                                                      address1: e.target.value,
                                                    })
                                                  }
                                                />
                                              </Form.Group>
                                              <span className="table-form-label-err mb-0">
                                                {this.state.address1Err}
                                              </span>
                                            </div>
                                            <div className="col-lg-2">
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">
                                                  City
                                                </Form.Label>
                                                <Form.Control
                                                  className="grid-form-control"
                                                  maxLength={40}
                                                  value={this.state.city}
                                                  onChange={(e) =>
                                                    this.setState({
                                                      city: e.target.value,
                                                    })
                                                  }
                                                />
                                              </Form.Group>
                                              <span className="table-form-label-err mb-0">
                                                {this.state.cityErr}
                                              </span>
                                            </div>
                                            <div className="col-lg-2">
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">
                                                  State
                                                </Form.Label>
                                                <Form.Select
                                                  className="grid-form-control"
                                                  value={this.state.state}
                                                  onChange={(e) =>
                                                    this.setState({
                                                      state: e.target.value,
                                                    })
                                                  }
                                                >
                                                  <option value="">
                                                    {" "}
                                                    -- Select State --{" "}
                                                  </option>
                                                  {USStates.map(
                                                    (USState, key) => {
                                                      return (
                                                        <option
                                                          key={key}
                                                          value={
                                                            USState.abbreviation
                                                          }
                                                        >
                                                          {USState.name}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </Form.Select>
                                              </Form.Group>
                                              <span className="table-form-label-err mb-0">
                                                {this.state.stateErr}
                                              </span>
                                            </div>
                                            <div className="col-lg-1">
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">
                                                  Zip
                                                </Form.Label>
                                                <Form.Control
                                                  className="grid-form-control"
                                                  maxLength={10}
                                                  value={this.state.zip}
                                                  onChange={(e) =>
                                                    this.setState({
                                                      zip: e.target.value,
                                                    })
                                                  }
                                                />
                                              </Form.Group>
                                              <span className="table-form-label-err mb-0">
                                                {this.state.zipErr}
                                              </span>
                                            </div>
                                          </Row>
                                          <Row>
                                              <div className="col-lg-2">
                                                <Form.Group
                                                  as={Col}
                                                  controlId=""
                                                >
                                                  <Form.Label className="table-form-label mb-0">
                                                    Phone
                                                  </Form.Label>
                                                  <Form.Control
                                                    className="grid-form-control"
                                                    maxLength={15}
                                                    value={
                                                      this.state.contactPhoneAttendingProvider
                                                    }
                                                    onChange={(e) => {
                                                        let phone = this.addDashes(e.target.value);
                                                        this.setState({ contactPhoneAttendingProvider: phone });
                                                      }}
                                                  />
                                                </Form.Group>
                                                <span className="table-form-label-err mb-0">
                                                  {this.state.contactPhoneAttendingProviderErr}
                                                </span>
                                              </div>
                                              <div className="col-lg-2">
                                                <Form.Group
                                                  as={Col}
                                                  controlId=""
                                                >
                                                  <Form.Label className="table-form-label mb-0">
                                                    Extension
                                                  </Form.Label>
                                                  <Form.Control
                                                    className="grid-form-control"
                                                    maxLength={12}
                                                    value={
                                                      this.state.contactPhoneExtensionAttendingProvider
                                                    }
                                                    onChange={(e) =>
                                                      this.setState({
                                                        contactPhoneExtensionAttendingProvider: e.target
                                                          .value,
                                                      })
                                                    }
                                                  />
                                                </Form.Group>
                                                <span className="table-form-label-err mb-0">
                                                  {this.state.contactPhoneExtensionAttendingProviderErr}
                                                </span>
                                              </div>
                                              <div className="col-lg-2">
                                                <Form.Group
                                                  as={Col}
                                                  controlId=""
                                                >
                                                  <Form.Label className="table-form-label mb-0">
                                                    Fax
                                                  </Form.Label>
                                                  <Form.Control
                                                    className="grid-form-control"
                                                    maxLength={15}
                                                    value={this.state.contactFaxAttendingProvider}
                                                    onChange={(e) => {
                                                      let fax = this.addDashes(e.target.value);
                                                      this.setState({ contactFaxAttendingProvider: fax });
                                                    }}
                                                  />
                                                </Form.Group>
                                                <span className="table-form-label-err mb-0">
                                                  {this.state.contactFaxAttendingProviderErr}
                                                </span>
                                              </div>
                                            </Row>
                                          <Row>
                                            <div>
                                              <Button
                                                className="form-save-btn btn-primary mt-1 float-end"
                                                type="button"
                                                disabled={
                                                  this.state
                                                    .disabledSaveAttendingProvider
                                                }
                                                onClick={() => {
                                                  this.saveAttendingProvider();
                                                }}
                                              >
                                                <img
                                                  src={require("../../images/save-btn-icon.png")}
                                                  className="add-icon"
                                                />
                                                Save
                                              </Button>
                                            </div>
                                          </Row>
                                        </Form>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                                <div className="row">
                                  <div className="col-4">
                                    <div class="form-main-title mt-2">
                                      <label>Diagnosis</label>
                                    </div>
                                    <Table bordered className="table-portal">
                                      <thead>
                                        <tr>
                                          <th className="td-first"></th>
                                          <th>Diagnosis Code</th>
                                          <th>Diagnosis Description</th>
                                          <th className="td-last">Valid</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {orderDetailsDiagnosis.map(
                                          (recordDetail, key) => {
                                            return (
                                              <>
                                                <tr
                                                  onClick={(event) =>
                                                    this.onClickHandlerSubTable(
                                                      {
                                                        ...recordDetail,
                                                        isNew: "N",
                                                        btnClick: "N",
                                                      },
                                                      event
                                                    )
                                                  }
                                                >
                                                  <td className="td-first">
                                                    <i className="fa fa-chevron-down"></i>
                                                  </td>
                                                  <td>
                                                    {recordDetail.diagnosisCode}
                                                  </td>
                                                  <td>
                                                    {recordDetail.description}
                                                  </td>
                                                  <td className="td-last">
                                                    {recordDetail.valid
                                                      ? recordDetail.valid ==
                                                        "Y"
                                                        ? "Yes"
                                                        : "No"
                                                      : ""}
                                                  </td>
                                                </tr>
                                                <tr className="sub-table collapse">
                                                  <td
                                                    className="td-first td-last"
                                                    colSpan="4"
                                                  >
                                                    <Form>
                                                      <Row>
                                                        <div class="col-lg-6 col-6">
                                                          <Form.Group
                                                            as={Col}
                                                            controlId=""
                                                          >
                                                            <Form.Label className="table-form-label mb-0">
                                                              Diagnosis Code
                                                            </Form.Label>
                                                            <Form.Control
                                                              className="grid-form-control"
                                                              value={
                                                                this.state
                                                                  .diagnosisCode
                                                              }
                                                              onChange={(e) =>
                                                                this.setState({
                                                                  diagnosisCode:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }
                                                              onBlur={async (
                                                                e
                                                              ) => {
                                                                await this.props.validateIcd10cmCode(
                                                                  e.target.value
                                                                );
                                                                if (
                                                                  this.props
                                                                    .validateIcd10cmCodeData
                                                                    .valid ===
                                                                  "VALID"
                                                                ) {
                                                                  console.log(
                                                                    this.props
                                                                      .validateIcd10cmCodeData
                                                                  );
                                                                  this.setState(
                                                                    {
                                                                      description:
                                                                        this
                                                                          .props
                                                                          .validateIcd10cmCodeData
                                                                          .master
                                                                          .description,
                                                                      diagnosisCodeErr:
                                                                        "",
                                                                    }
                                                                  );
                                                                } else {
                                                                  this.setState(
                                                                    {
                                                                      description:
                                                                        "",
                                                                      diagnosisCodeErr:
                                                                        "Diagnosis Code not found.",
                                                                    }
                                                                  );
                                                                  alert(
                                                                    "The diagnosis code is not found in our database. You can still continue and save your diagnosis code."
                                                                  );
                                                                }
                                                              }}
                                                            />
                                                          </Form.Group>
                                                          <span className="table-form-label-err mb-0">
                                                            {
                                                              this.state
                                                                .diagnosisCodeErr
                                                            }
                                                          </span>
                                                        </div>
                                                        <div className="col-lg-6 col-6">
                                                          <Form.Group
                                                            as={Col}
                                                            controlId=""
                                                          >
                                                            <Form.Label className="table-form-label mb-0">
                                                              Diagnosis
                                                              Description
                                                            </Form.Label>
                                                            <Form.Control
                                                              className="grid-form-control"
                                                              value={
                                                                this.state
                                                                  .description
                                                              }
                                                              onChange={(e) =>
                                                                this.setState({
                                                                  description:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }
                                                            />
                                                          </Form.Group>
                                                          <span className="table-form-label-err mb-0">
                                                            {
                                                              this.state
                                                                .descriptionErr
                                                            }
                                                          </span>
                                                        </div>
                                                      </Row>
                                                      <Row>
                                                        <div>
                                                          <Button
                                                            className="form-save-btn btn-primary mt-1 float-end"
                                                            type="button"
                                                            disabled={
                                                              this.state
                                                                .disabledSaveDiagnosis
                                                            }
                                                            onClick={() => {
                                                              this.saveDiagnosis();
                                                            }}
                                                          >
                                                            <img
                                                              src={require("../../images/save-btn-icon.png")}
                                                              className="add-icon"
                                                            />
                                                            Save
                                                          </Button>
                                                        </div>
                                                      </Row>
                                                    </Form>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )}
                                        <tr
                                          onClick={(event) =>
                                            this.onClickHandlerSubTable(
                                              {
                                                ...this
                                                  .orderDetailDiagnosisDefault,
                                                isNew: "Y",
                                                btnClick: "N",
                                              },
                                              event
                                            )
                                          }
                                        >
                                          <td className="td-first">
                                            <i className="fa fa-chevron-down"></i>
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td className="td-last"></td>
                                        </tr>
                                        <tr className="sub-table collapse">
                                          <td
                                            className="td-first td-last"
                                            colSpan="4"
                                          >
                                            <Form>
                                              <Row>
                                                <div class="col-lg-6 col-6">
                                                  <Form.Group
                                                    as={Col}
                                                    controlId=""
                                                  >
                                                    <Form.Label className="table-form-label mb-0">
                                                      Diagnosis Code
                                                    </Form.Label>
                                                    <Form.Control
                                                      className="grid-form-control"
                                                      value={
                                                        this.state.diagnosisCode
                                                      }
                                                      onChange={(e) =>
                                                        this.setState({
                                                          diagnosisCode:
                                                            e.target.value,
                                                        })
                                                      }
                                                      onBlur={async (e) => {
                                                        await this.props.validateIcd10cmCode(
                                                          e.target.value
                                                        );
                                                        if (
                                                          this.props
                                                            .validateIcd10cmCodeData
                                                            .valid === "VALID"
                                                        ) {
                                                          console.log(
                                                            this.props
                                                              .validateIcd10cmCodeData
                                                          );
                                                          this.setState({
                                                            description:
                                                              this.props
                                                                .validateIcd10cmCodeData
                                                                .master
                                                                .description,
                                                            diagnosisCodeErr:
                                                              "",
                                                          });
                                                        } else {
                                                          this.setState({
                                                            description: "",
                                                            diagnosisCodeErr:
                                                              "Diagnosis Code not found.",
                                                          });
                                                          alert(
                                                            "The diagnosis code is not found in our database. You can still continue and save your diagnosis code."
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </Form.Group>
                                                  <span className="table-form-label-err mb-0">
                                                    {
                                                      this.state
                                                        .diagnosisCodeErr
                                                    }
                                                  </span>
                                                </div>
                                                <div className="col-lg-6 col-6">
                                                  <Form.Group
                                                    as={Col}
                                                    controlId=""
                                                  >
                                                    <Form.Label className="table-form-label mb-0">
                                                      Diagnosis Description
                                                    </Form.Label>
                                                    <Form.Control
                                                      className="grid-form-control"
                                                      value={
                                                        this.state.description
                                                      }
                                                      onChange={(e) =>
                                                        this.setState({
                                                          description:
                                                            e.target.value,
                                                        })
                                                      }
                                                    />
                                                  </Form.Group>
                                                  <span className="table-form-label-err mb-0">
                                                    {this.state.descriptionErr}
                                                  </span>
                                                </div>
                                              </Row>
                                              <Row>
                                                <div>
                                                  <Button
                                                    className="form-save-btn btn-primary mt-1 float-end"
                                                    type="button"
                                                    disabled={
                                                      this.state
                                                        .disabledSaveDiagnosis
                                                    }
                                                    onClick={() => {
                                                      this.saveDiagnosis();
                                                    }}
                                                  >
                                                    <img
                                                      src={require("../../images/save-btn-icon.png")}
                                                      className="add-icon"
                                                    />
                                                    Save
                                                  </Button>
                                                </div>
                                              </Row>
                                            </Form>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </div>
                                  <div className="col-8">
                                    <div class="form-main-title mt-2">
                                      <label>Procedures</label>
                                    </div>
                                    <Table bordered className="table-portal">
                                      <thead>
                                        <tr>
                                          <th className="td-first"></th>
                                          <th nowrap="nowrap">
                                            Procedure Code
                                          </th>
                                          <th>Procedure Description</th>
                                          <th nowrap="nowrap">From Date</th>
                                          <th nowrap="nowrap">Valid</th>
                                          <th className="td-last">
                                            Authorization Required
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {orderDetailsProcedure.map(
                                          (recordDetail, key) => {
                                            return (
                                              <>
                                                <tr
                                                  onClick={(event) =>
                                                    this.onClickHandlerSubTable(
                                                      {
                                                        ...recordDetail,
                                                        isNew: "N",
                                                        btnClick: "N",
                                                      },
                                                      event
                                                    )
                                                  }
                                                >
                                                  <td className="td-first">
                                                    <i className="fa fa-chevron-down"></i>
                                                  </td>
                                                  <td>
                                                    {recordDetail.procedureCode}
                                                  </td>
                                                  <td>
                                                    {
                                                      recordDetail.procedureDescription
                                                    }
                                                  </td>
                                                  <td>
                                                    {recordDetail.fromDate
                                                      ? moment(
                                                          formatDateToCustomISO(
                                                            moment(
                                                              recordDetail.fromDate
                                                            ).toDate()
                                                          )
                                                        ).format("MM/DD/YYYY")
                                                      : ""}
                                                  </td>
                                                  <td>
                                                    {recordDetail.valid
                                                      ? recordDetail.valid ==
                                                        "Y"
                                                        ? "Yes"
                                                        : "No"
                                                      : ""}
                                                  </td>
                                                  <td className="td-last">
                                                    {
                                                      recordDetail.isAuthorizationRequired
                                                    }
                                                  </td>
                                                </tr>
                                                <tr className="sub-table collapse">
                                                  <td
                                                    className="td-first td-last p-0"
                                                    colSpan="6"
                                                  >
                                                    <Form>
                                                      <div className="p-2">
                                                        <Row>
                                                          <div class="col-lg-4">
                                                            <Form.Group
                                                              as={Col}
                                                              controlId=""
                                                            >
                                                              <Form.Label className="table-form-label mb-0">
                                                                Procedure Code
                                                              </Form.Label>
                                                              <Form.Control
                                                                className="grid-form-control"
                                                                maxLength={40}
                                                                value={
                                                                  this.state
                                                                    .procedureCode
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  this.setState(
                                                                    {
                                                                      procedureCode:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  );
                                                                  this.setCPTCrossWalk(
                                                                    e.target
                                                                      .value
                                                                  );
                                                                }}
                                                                onBlur={async (
                                                                  e
                                                                ) => {
                                                                  await this.props.getCptDescription(
                                                                    addedPatient.clientId,
                                                                    e.target
                                                                      .value
                                                                  );

                                                                  if (
                                                                    this.props
                                                                      .cptDescription
                                                                  ) {
                                                                    this.setState(
                                                                      {
                                                                        procedureDescription:
                                                                          this
                                                                            .props
                                                                            .cptDescription
                                                                            .cptDescription,
                                                                      }
                                                                    );
                                                                  } else {
                                                                    this.setState(
                                                                      {
                                                                        procedureDescription:
                                                                          "",
                                                                      }
                                                                    );
                                                                  }
                                                                }}
                                                              />
                                                            </Form.Group>
                                                            <span className="table-form-label-err mb-0">
                                                              {
                                                                this.state
                                                                  .procedureCodeErr
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="col-lg-8">
                                                            <Form.Group
                                                              as={Col}
                                                              controlId="visitDate"
                                                            >
                                                              <Form.Label className="table-form-label mb-0">
                                                                Service Type
                                                              </Form.Label>
                                                              <Form.Select
                                                                className="grid-form-control"
                                                                value={
                                                                  this.state
                                                                    .serviceType
                                                                }
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      serviceType:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                              >
                                                                <option value="">
                                                                  {" "}
                                                                  -- Select
                                                                  Service Type
                                                                  --{" "}
                                                                </option>
                                                                {this.props.serviceTypes.map(
                                                                  (
                                                                    serviceType,
                                                                    key
                                                                  ) => {
                                                                    return (
                                                                      <option
                                                                        key={
                                                                          key
                                                                        }
                                                                        value={
                                                                          serviceType.code
                                                                        }
                                                                      >
                                                                        {serviceType.code +
                                                                          " - " +
                                                                          serviceType.description}
                                                                      </option>
                                                                    );
                                                                  }
                                                                )}
                                                              </Form.Select>
                                                              <span className="table-form-label-err mb-0">
                                                                {
                                                                  this.state
                                                                    .serviceTypeErr
                                                                }
                                                              </span>
                                                            </Form.Group>
                                                          </div>
                                                        </Row>
                                                        <Row>
                                                          <div class="col-lg-4">
                                                            <Form.Group
                                                              as={Col}
                                                              controlId=""
                                                            >
                                                              <Form.Label className="table-form-label mb-0">
                                                                Place of Service
                                                              </Form.Label>
                                                              <Form.Select
                                                                className="grid-form-control"
                                                                value={
                                                                  this.state
                                                                    .placeOfService
                                                                }
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      placeOfService:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                              >
                                                                <option value="">
                                                                  {" "}
                                                                  -- Select
                                                                  Place Of
                                                                  Service --{" "}
                                                                </option>
                                                                {placeOfServices.map(
                                                                  (
                                                                    placeOfService,
                                                                    key
                                                                  ) => {
                                                                    return (
                                                                      <option
                                                                        key={
                                                                          key
                                                                        }
                                                                        value={
                                                                          placeOfService.code
                                                                        }
                                                                      >
                                                                        {placeOfService.code +
                                                                          " - " +
                                                                          placeOfService.value}
                                                                      </option>
                                                                    );
                                                                  }
                                                                )}
                                                              </Form.Select>
                                                            </Form.Group>
                                                            <span className="table-form-label-err mb-0">
                                                              {
                                                                this.state
                                                                  .placeOfServiceErr
                                                              }
                                                            </span>
                                                          </div>
                                                          <div class="col-lg-8">
                                                            <Form.Group
                                                              as={Col}
                                                              controlId=""
                                                            >
                                                              <Form.Label className="table-form-label mb-0">
                                                                Procedure
                                                                Description
                                                              </Form.Label>
                                                              <Form.Control
                                                                className="grid-form-control"
                                                                value={
                                                                  this.state
                                                                    .procedureDescription
                                                                }
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      procedureDescription:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                              />
                                                            </Form.Group>
                                                            <span className="table-form-label-err mb-0">
                                                              {
                                                                this.state
                                                                  .procedureDescriptionErr
                                                              }
                                                            </span>
                                                          </div>
                                                        </Row>
                                                        <Row>
                                                          <div className="col-lg-2 col-6 d-flex flex-column">
                                                            <label className="table-form-label mb-0">
                                                              From Date
                                                            </label>
                                                            <DatePicker
                                                              className="date-picker"
                                                              showIcon
                                                              placeholderText="MM/DD/YYYY"
                                                              customInput={
                                                                <MaskedTextInput
                                                                  type="text"
                                                                  mask={
                                                                    MaskDateInput
                                                                  }
                                                                />
                                                              }
                                                              onChange={(
                                                                date
                                                              ) =>
                                                                this.setState({
                                                                  fromDate:
                                                                    formatDateToCustomISO(
                                                                      date
                                                                    ),
                                                                })
                                                              }
                                                              selected={
                                                                this.state
                                                                  .fromDate
                                                                  ? formatDateToCustomISO(
                                                                      moment(
                                                                        this
                                                                          .state
                                                                          .fromDate
                                                                      ).toDate()
                                                                    )
                                                                  : null
                                                              }
                                                            />
                                                            <span className="table-form-label-err mb-0 mb-0">
                                                              {
                                                                this.state
                                                                  .fromDateErr
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="col-lg-2 col-6 d-flex flex-column">
                                                            <label className="table-form-label mb-0">
                                                              To Date
                                                            </label>
                                                            <DatePicker
                                                              className="date-picker"
                                                              showIcon
                                                              placeholderText="MM/DD/YYYY"
                                                              customInput={
                                                                <MaskedTextInput
                                                                  type="text"
                                                                  mask={
                                                                    MaskDateInput
                                                                  }
                                                                />
                                                              }
                                                              onChange={(
                                                                date
                                                              ) =>
                                                                this.setState({
                                                                  toDate:
                                                                    formatDateToCustomISO(
                                                                      date
                                                                    ),
                                                                })
                                                              }
                                                              selected={
                                                                this.state
                                                                  .toDate
                                                                  ? formatDateToCustomISO(
                                                                      moment(
                                                                        this
                                                                          .state
                                                                          .toDate
                                                                      ).toDate()
                                                                    )
                                                                  : null
                                                              }
                                                            />
                                                            <span className="table-form-label-err mb-0 mb-0">
                                                              {
                                                                this.state
                                                                  .toDateErr
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="col-lg-2">
                                                            <Form.Group
                                                              as={Col}
                                                              controlId=""
                                                            >
                                                              <Form.Label className="table-form-label mb-0">
                                                                Quantity
                                                              </Form.Label>
                                                              <Form.Control
                                                                className="grid-form-control"
                                                                maxLength={40}
                                                                value={
                                                                  this.state
                                                                    .quantity
                                                                }
                                                                type="number"
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      quantity:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                              />
                                                            </Form.Group>
                                                            <span className="table-form-label-err mb-0">
                                                              {
                                                                this.state
                                                                  .quantityErr
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="col-lg-2">
                                                            <Form.Group
                                                              as={Col}
                                                              controlId=""
                                                            >
                                                              <Form.Label className="table-form-label mb-0">
                                                                Unit Measure
                                                              </Form.Label>
                                                              <Form.Select
                                                                className="grid-form-control"
                                                                value={
                                                                  this.state
                                                                    .unitMeasure
                                                                }
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      unitMeasure:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                              >
                                                                <option value="">
                                                                  {" "}
                                                                  -- Select --{" "}
                                                                </option>
                                                                <option value="Units">
                                                                  Units
                                                                </option>
                                                                <option value="Visits">
                                                                  Visits
                                                                </option>
                                                                <option value="Days">
                                                                  Days
                                                                </option>
                                                                <option value="Hours">
                                                                  Hours
                                                                </option>
                                                              </Form.Select>
                                                            </Form.Group>
                                                            <span className="table-form-label-err mb-0">
                                                              {
                                                                this.state
                                                                  .unitMeasureErr
                                                              }
                                                            </span>
                                                          </div>
                                                          <div class="col-lg-4">
                                                            <label className="table-form-label mb-0">
                                                              Authorization is
                                                              Required?
                                                            </label>
                                                            <Form.Group
                                                              className="d-flex"
                                                              as={Col}
                                                              controlId=""
                                                            >
                                                              <Form.Check
                                                                disabled
                                                                className="grid-form-control mt-1 w-50"
                                                                label="Yes"
                                                                onChange={
                                                                  this
                                                                    .handleIsAuthorizationRequired
                                                                }
                                                                checked={this.state.isAuthorizationRequiredChecked.includes(
                                                                  "Y"
                                                                )}
                                                              />
                                                            </Form.Group>
                                                          </div>
                                                        </Row>
                                                        <Row className="mb-1">
                                                          <div className="col-lg-3">
                                                            <Form.Group
                                                              as={Col}
                                                              controlId="orderNumber"
                                                            >
                                                              <Form.Label className="table-form-label mb-0">
                                                                Level of Service
                                                              </Form.Label>
                                                              <Form.Select
                                                                className="grid-form-control"
                                                                value={
                                                                  this.state
                                                                    .levelOfService
                                                                }
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      levelOfService:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                              >
                                                                <option value="">
                                                                  {" "}
                                                                  -- Select
                                                                  Level of
                                                                  Service --{" "}
                                                                </option>
                                                                <option value="ELECTIVE">
                                                                  Elective
                                                                </option>
                                                                <option value="EMERGENCY">
                                                                  Emergency
                                                                </option>
                                                              </Form.Select>
                                                            </Form.Group>
                                                            <span className="table-form-label-err mb-0">
                                                              {
                                                                this.state
                                                                  .levelOfServiceErr
                                                              }
                                                            </span>
                                                          </div>

                                                          <div className="col-lg-1">
                                                            <Form.Group
                                                              as={Col}
                                                              controlId="accountNumber"
                                                            >
                                                              <Form.Label className="table-form-label mb-0">
                                                                Frequency
                                                              </Form.Label>
                                                              <Form.Control
                                                                className="grid-form-control"
                                                                maxLength={40}
                                                                value={
                                                                  this.state
                                                                    .frequency
                                                                }
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      frequency:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                              />
                                                            </Form.Group>
                                                            <span className="table-form-label-err mb-0">
                                                              {
                                                                this.state
                                                                  .frequencyErr
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="col-lg-8">
                                                            <Form.Group
                                                              as={Col}
                                                              controlId="authStatus"
                                                            >
                                                              <Form.Label className="table-form-label mb-0">
                                                                Reason for Exam
                                                              </Form.Label>
                                                              <Form.Control
                                                                className="grid-form-control"
                                                                value={
                                                                  this.state
                                                                    .reasonForExam
                                                                }
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      reasonForExam:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                              />
                                                            </Form.Group>
                                                            <span className="table-form-label-err mb-0">
                                                              {
                                                                this.state
                                                                  .reasonForExamErr
                                                              }
                                                            </span>
                                                          </div>
                                                        </Row>
                                                        <Row className="mb-1">
                                                          <div className="col-lg-12">
                                                            <Form.Group
                                                              as={Col}
                                                              controlId="authStatus"
                                                            >
                                                              <Form.Label className="table-form-label mb-0">
                                                                Tracking Number
                                                              </Form.Label>
                                                              <Form.Control
                                                                className="grid-form-control"
                                                                value={
                                                                  this.state
                                                                    .trackingNumber
                                                                }
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      trackingNumber:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                              />
                                                            </Form.Group>
                                                            <span className="table-form-label-err mb-0">
                                                              {
                                                                this.state
                                                                  .trackingNumberErr
                                                              }
                                                            </span>
                                                          </div>
                                                        </Row>
                                                      </div>
                                                      <div className="grid-line-sep"></div>
                                                      <div className="mt-2">
                                                        <label className="form-main-title">
                                                          APPROVAL INFORMATION
                                                        </label>
                                                      </div>
                                                      <div className="p-2">
                                                        <Row className="mb-1">
                                                          <div className="col-lg-3 col-6">
                                                            <Form.Group
                                                              as={Col}
                                                              controlId="authStatus"
                                                            >
                                                              <Form.Label className="table-form-label mb-0">
                                                                Auth Number
                                                              </Form.Label>
                                                              <Form.Control
                                                                className="grid-form-control"
                                                                maxLength={40}
                                                                value={
                                                                  this.state
                                                                    .authNumber
                                                                }
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      authNumber:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                              />
                                                            </Form.Group>
                                                            <span className="table-form-label-err mb-0">
                                                              {
                                                                this.state
                                                                  .authNumberErr
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="col-lg-3 col-6">
                                                            <Form.Group
                                                              as={Col}
                                                              controlId="authStatus"
                                                            >
                                                              <Form.Label className="table-form-label mb-0">
                                                                Approved Units
                                                              </Form.Label>
                                                              <Form.Control
                                                                className="grid-form-control"
                                                                maxLength={40}
                                                                value={
                                                                  this.state
                                                                    .approvedUnits
                                                                }
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      approvedUnits:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                              />
                                                            </Form.Group>
                                                            <span className="table-form-label-err mb-0">
                                                              {
                                                                this.state
                                                                  .approvedUnitsErr
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="col-lg-3 col-6">
                                                            <Form.Group
                                                              as={Col}
                                                              controlId="authStatus"
                                                            >
                                                              <Form.Label className="table-form-label mb-0">
                                                                Approved
                                                                Procedure Codes
                                                              </Form.Label>
                                                              <Form.Control
                                                                className="grid-form-control"
                                                                maxLength={40}
                                                                value={
                                                                  this.state
                                                                    .approvedProcedureCodes
                                                                }
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      approvedProcedureCodes:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                              />
                                                            </Form.Group>
                                                            <span className="table-form-label-err mb-0">
                                                              {
                                                                this.state
                                                                  .approvedProcedureCodesErr
                                                              }
                                                            </span>
                                                          </div>
                                                        </Row>
                                                        <Row className="mb-1 d-flex">
                                                          <div className="col-lg-6">
                                                            <div className="col-lg-10 col-6 d-flex flex-column">
                                                              <label className="table-form-label mb-0">
                                                                From Date
                                                              </label>
                                                              <DatePicker
                                                                className="date-picker"
                                                                showIcon
                                                                placeholderText="MM/DD/YYYY"
                                                                customInput={
                                                                  <MaskedTextInput
                                                                    type="text"
                                                                    mask={
                                                                      MaskDateInput
                                                                    }
                                                                  />
                                                                }
                                                                onChange={(
                                                                  date
                                                                ) =>
                                                                  this.setState(
                                                                    {
                                                                      approvalFromDate:
                                                                        formatDateToCustomISO(
                                                                          date
                                                                        ),
                                                                    }
                                                                  )
                                                                }
                                                                selected={
                                                                  this.state
                                                                    .approvalFromDate
                                                                    ? formatDateToCustomISO(
                                                                        moment(
                                                                          this
                                                                            .state
                                                                            .approvalFromDate
                                                                        ).toDate()
                                                                      )
                                                                    : null
                                                                }
                                                              />
                                                              <span className="table-form-label-err mb-0 mb-0">
                                                                {
                                                                  this.state
                                                                    .approvalFromDateErr
                                                                }
                                                              </span>
                                                            </div>
                                                            <div className="col-lg-10 col-6 d-flex flex-column">
                                                              <label className="table-form-label mb-0">
                                                                To Date
                                                              </label>
                                                              <DatePicker
                                                                className="date-picker"
                                                                showIcon
                                                                placeholderText="MM/DD/YYYY"
                                                                customInput={
                                                                  <MaskedTextInput
                                                                    type="text"
                                                                    mask={
                                                                      MaskDateInput
                                                                    }
                                                                  />
                                                                }
                                                                onChange={(
                                                                  date
                                                                ) =>
                                                                  this.setState(
                                                                    {
                                                                      approvalToDate:
                                                                        formatDateToCustomISO(
                                                                          date
                                                                        ),
                                                                    }
                                                                  )
                                                                }
                                                                selected={
                                                                  this.state
                                                                    .approvalToDate
                                                                    ? formatDateToCustomISO(
                                                                        moment(
                                                                          this
                                                                            .state
                                                                            .approvalToDate
                                                                        ).toDate()
                                                                      )
                                                                    : null
                                                                }
                                                              />
                                                              <span className="table-form-label-err mb-0 mb-0">
                                                                {
                                                                  this.state
                                                                    .approvalToDateErr
                                                                }
                                                              </span>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-6">
                                                            <div className="col-lg-12 d-flex">
                                                              <Form.Group
                                                                as={Col}
                                                                controlId="authStatus"
                                                              >
                                                                <Form.Label className="table-form-label mb-0">
                                                                  Notes
                                                                </Form.Label>
                                                                <Form.Control
                                                                  as="textarea"
                                                                  rows="3"
                                                                  className="grid-form-control app-info-note"
                                                                  value={
                                                                    this.state
                                                                      .procedureNotes
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    this.setState(
                                                                      {
                                                                        procedureNotes:
                                                                          e
                                                                            .target
                                                                            .value,
                                                                      }
                                                                    )
                                                                  }
                                                                />
                                                                <span className="table-form-label-err mb-0">
                                                                  {
                                                                    this.state
                                                                      .procedureNotesErr
                                                                  }
                                                                </span>
                                                              </Form.Group>
                                                            </div>
                                                          </div>
                                                        </Row>
                                                        <Row className="d-flex">
                                                          <div className="float-start w-50">
                                                            <Button
                                                              className="med-req-btn"
                                                              variant="primary"
                                                              type="button"
                                                              onClick={(e) =>
                                                                this.openDocumentationReq()
                                                              }
                                                            >
                                                              <img
                                                                src={require("../../images/doc-req-icon.png")}
                                                                className="add-icon"
                                                              />
                                                              Documentation REQ
                                                            </Button>
                                                            <Button
                                                              className="med-req-btn"
                                                              variant="primary"
                                                              type="button"
                                                              onClick={() =>
                                                                this.openMedicalNecessity(
                                                                  record.id
                                                                )
                                                              }
                                                            >
                                                              <img
                                                                src={require("../../images/nec-req-icon.png")}
                                                                className="add-icon"
                                                              />
                                                              Medical Necessity
                                                              REQ
                                                            </Button>
                                                          </div>
                                                          <div className="float-end w-50">
                                                            <Button
                                                              className="form-save-btn btn-primary mt-1 float-end"
                                                              type="button"
                                                              disabled={
                                                                this.state
                                                                  .disabledSaveProcedure
                                                              }
                                                              onClick={() => {
                                                                this.saveProcedure();
                                                              }}
                                                            >
                                                              <img
                                                                src={require("../../images/save-btn-icon.png")}
                                                                className="add-icon"
                                                              />
                                                              Save
                                                            </Button>
                                                          </div>
                                                        </Row>
                                                      </div>
                                                    </Form>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )}
                                        <tr
                                          onClick={(event) =>
                                            this.onClickHandlerSubTable(
                                              {
                                                ...this
                                                  .orderDetailProcedureDefault,
                                                isNew: "Y",
                                                btnClick: "N",
                                              },
                                              event
                                            )
                                          }
                                        >
                                          <td className="td-first">
                                            <i className="fa fa-chevron-down"></i>
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td className="td-last"></td>
                                        </tr>
                                        <tr className="sub-table collapse">
                                          <td
                                            className="td-first td-last"
                                            colSpan="6"
                                          >
                                            <Form>
                                              <div className="p-2">
                                                <Row>
                                                  <div class="col-lg-4">
                                                    <Form.Group
                                                      as={Col}
                                                      controlId=""
                                                    >
                                                      <Form.Label className="table-form-label mb-0">
                                                        Procedure Code
                                                      </Form.Label>
                                                      <Form.Control
                                                        className="grid-form-control"
                                                        maxLength={40}
                                                        value={
                                                          this.state
                                                            .procedureCode
                                                        }
                                                        onChange={(e) => {
                                                          this.setState({
                                                            procedureCode:
                                                              e.target.value,
                                                          });
                                                          this.setCPTCrossWalk(
                                                            e.target.value
                                                          );
                                                        }}
                                                        onBlur={async (e) => {
                                                          await this.props.getCptDescription(
                                                            addedPatient.clientId,
                                                            e.target.value
                                                          );

                                                          if (
                                                            this.props
                                                              .cptDescription
                                                          ) {
                                                            this.setState({
                                                              procedureDescription:
                                                                this.props
                                                                  .cptDescription
                                                                  .cptDescription,
                                                            });
                                                          } else {
                                                            this.setState({
                                                              procedureDescription:
                                                                "",
                                                            });
                                                          }
                                                        }}
                                                      />
                                                    </Form.Group>
                                                    <span className="table-form-label-err mb-0">
                                                      {
                                                        this.state
                                                          .procedureCodeErr
                                                      }
                                                    </span>
                                                  </div>
                                                  <div className="col-lg-8">
                                                    <Form.Group
                                                      as={Col}
                                                      controlId="visitDate"
                                                    >
                                                      <Form.Label className="table-form-label mb-0">
                                                        Service Type
                                                      </Form.Label>
                                                      <Form.Select
                                                        className="grid-form-control"
                                                        value={
                                                          this.state.serviceType
                                                        }
                                                        onChange={(e) =>
                                                          this.setState({
                                                            serviceType:
                                                              e.target.value,
                                                          })
                                                        }
                                                      >
                                                        <option value="">
                                                          {" "}
                                                          -- Select Service Type
                                                          --{" "}
                                                        </option>
                                                        {this.props.serviceTypes.map(
                                                          (
                                                            serviceType,
                                                            key
                                                          ) => {
                                                            return (
                                                              <option
                                                                key={key}
                                                                value={
                                                                  serviceType.code
                                                                }
                                                              >
                                                                {serviceType.code +
                                                                  " - " +
                                                                  serviceType.description}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                      </Form.Select>
                                                      <span className="table-form-label-err mb-0">
                                                        {
                                                          this.state
                                                            .serviceTypeErr
                                                        }
                                                      </span>
                                                    </Form.Group>
                                                  </div>
                                                </Row>
                                                <Row>
                                                  <div class="col-lg-4">
                                                    <Form.Group
                                                      as={Col}
                                                      controlId=""
                                                    >
                                                      <Form.Label className="table-form-label mb-0">
                                                        Place of Service
                                                      </Form.Label>
                                                      <Form.Select
                                                        className="grid-form-control"
                                                        value={
                                                          this.state
                                                            .placeOfService
                                                        }
                                                        onChange={(e) =>
                                                          this.setState({
                                                            placeOfService:
                                                              e.target.value,
                                                          })
                                                        }
                                                      >
                                                        <option value="">
                                                          {" "}
                                                          -- Select Place Of
                                                          Service --{" "}
                                                        </option>
                                                        {placeOfServices.map(
                                                          (
                                                            placeOfService,
                                                            key
                                                          ) => {
                                                            return (
                                                              <option
                                                                key={key}
                                                                value={
                                                                  placeOfService.code
                                                                }
                                                              >
                                                                {placeOfService.code +
                                                                  " - " +
                                                                  placeOfService.value}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                      </Form.Select>
                                                    </Form.Group>
                                                    <span className="table-form-label-err mb-0">
                                                      {
                                                        this.state
                                                          .placeOfServiceErr
                                                      }
                                                    </span>
                                                  </div>
                                                  <div class="col-lg-8">
                                                    <Form.Group
                                                      as={Col}
                                                      controlId=""
                                                    >
                                                      <Form.Label className="table-form-label mb-0">
                                                        Procedure Description
                                                      </Form.Label>
                                                      <Form.Control
                                                        className="grid-form-control"
                                                        value={
                                                          this.state
                                                            .procedureDescription
                                                        }
                                                        onChange={(e) =>
                                                          this.setState({
                                                            procedureDescription:
                                                              e.target.value,
                                                          })
                                                        }
                                                      />
                                                    </Form.Group>
                                                    <span className="table-form-label-err mb-0">
                                                      {
                                                        this.state
                                                          .procedureDescriptionErr
                                                      }
                                                    </span>
                                                  </div>
                                                </Row>
                                                <Row>
                                                  <div className="col-lg-2 col-6 d-flex flex-column">
                                                    <label className="table-form-label mb-0">
                                                      From Date
                                                    </label>
                                                    <DatePicker
                                                      className="date-picker"
                                                      showIcon
                                                      placeholderText="MM/DD/YYYY"
                                                      customInput={
                                                        <MaskedTextInput
                                                          type="text"
                                                          mask={MaskDateInput}
                                                        />
                                                      }
                                                      onChange={(date) =>
                                                        this.setState({
                                                          fromDate:
                                                            formatDateToCustomISO(
                                                              date
                                                            ),
                                                        })
                                                      }
                                                      selected={
                                                        this.state.fromDate
                                                          ? formatDateToCustomISO(
                                                              moment(
                                                                this.state
                                                                  .fromDate
                                                              ).toDate()
                                                            )
                                                          : null
                                                      }
                                                    />
                                                    <span className="table-form-label-err mb-0 mb-0">
                                                      {this.state.fromDateErr}
                                                    </span>
                                                  </div>
                                                  <div className="col-lg-2 col-6 d-flex flex-column">
                                                    <label className="table-form-label mb-0">
                                                      To Date
                                                    </label>
                                                    <DatePicker
                                                      className="date-picker"
                                                      showIcon
                                                      placeholderText="MM/DD/YYYY"
                                                      customInput={
                                                        <MaskedTextInput
                                                          type="text"
                                                          mask={MaskDateInput}
                                                        />
                                                      }
                                                      onChange={(date) =>
                                                        this.setState({
                                                          toDate:
                                                            formatDateToCustomISO(
                                                              date
                                                            ),
                                                        })
                                                      }
                                                      selected={
                                                        this.state.toDate
                                                          ? formatDateToCustomISO(
                                                              moment(
                                                                this.state
                                                                  .toDate
                                                              ).toDate()
                                                            )
                                                          : null
                                                      }
                                                    />
                                                    <span className="table-form-label-err mb-0 mb-0">
                                                      {this.state.toDateErr}
                                                    </span>
                                                  </div>
                                                  <div className="col-lg-2">
                                                    <Form.Group
                                                      as={Col}
                                                      controlId=""
                                                    >
                                                      <Form.Label className="table-form-label mb-0">
                                                        Quantity
                                                      </Form.Label>
                                                      <Form.Control
                                                        className="grid-form-control"
                                                        maxLength={40}
                                                        value={
                                                          this.state.quantity
                                                        }
                                                        type="number"
                                                        onChange={(e) =>
                                                          this.setState({
                                                            quantity:
                                                              e.target.value,
                                                          })
                                                        }
                                                      />
                                                    </Form.Group>
                                                    <span className="table-form-label-err mb-0">
                                                      {this.state.quantityErr}
                                                    </span>
                                                  </div>
                                                  <div className="col-lg-2">
                                                    <Form.Group
                                                      as={Col}
                                                      controlId=""
                                                    >
                                                      <Form.Label className="table-form-label mb-0">
                                                        Unit Measure
                                                      </Form.Label>
                                                      <Form.Select
                                                        className="grid-form-control"
                                                        value={
                                                          this.state.unitMeasure
                                                        }
                                                        onChange={(e) =>
                                                          this.setState({
                                                            unitMeasure:
                                                              e.target.value,
                                                          })
                                                        }
                                                      >
                                                        <option value="">
                                                          {" "}
                                                          -- Select --{" "}
                                                        </option>
                                                        <option value="Units">
                                                          Units
                                                        </option>
                                                        <option value="Visits">
                                                          Visits
                                                        </option>
                                                        <option value="Days">
                                                          Days
                                                        </option>
                                                        <option value="Hours">
                                                          Hours
                                                        </option>
                                                      </Form.Select>
                                                    </Form.Group>
                                                    <span className="table-form-label-err mb-0">
                                                      {
                                                        this.state
                                                          .unitMeasureErr
                                                      }
                                                    </span>
                                                  </div>
                                                  <div class="col-lg-4">
                                                    <label className="table-form-label mb-0">
                                                      Authorization is Required?
                                                    </label>
                                                    <Form.Group
                                                      className="d-flex"
                                                      as={Col}
                                                      controlId=""
                                                    >
                                                      <Form.Check
                                                        disabled
                                                        className="grid-form-control mt-1 w-50"
                                                        label="Yes"
                                                        onChange={
                                                          this
                                                            .handleIsAuthorizationRequired
                                                        }
                                                        checked={this.state.isAuthorizationRequiredChecked.includes(
                                                          "Y"
                                                        )}
                                                      />
                                                    </Form.Group>
                                                  </div>
                                                </Row>
                                                <Row className="mb-1">
                                                  <div className="col-lg-3">
                                                    <Form.Group
                                                      as={Col}
                                                      controlId="orderNumber"
                                                    >
                                                      <Form.Label className="table-form-label mb-0">
                                                        Level of Service
                                                      </Form.Label>
                                                      <Form.Select
                                                        className="grid-form-control"
                                                        value={
                                                          this.state
                                                            .levelOfService
                                                        }
                                                        onChange={(e) =>
                                                          this.setState({
                                                            levelOfService:
                                                              e.target.value,
                                                          })
                                                        }
                                                      >
                                                        <option value="">
                                                          {" "}
                                                          -- Select Level of
                                                          Service --{" "}
                                                        </option>
                                                        <option value="ELECTIVE">
                                                          Elective
                                                        </option>
                                                        <option value="EMERGENCY">
                                                          Emergency
                                                        </option>
                                                      </Form.Select>
                                                    </Form.Group>
                                                    <span className="table-form-label-err mb-0">
                                                      {
                                                        this.state
                                                          .levelOfServiceErr
                                                      }
                                                    </span>
                                                  </div>

                                                  <div className="col-lg-1">
                                                    <Form.Group
                                                      as={Col}
                                                      controlId="accountNumber"
                                                    >
                                                      <Form.Label className="table-form-label mb-0">
                                                        Frequency
                                                      </Form.Label>
                                                      <Form.Control
                                                        className="grid-form-control"
                                                        maxLength={40}
                                                        value={
                                                          this.state.frequency
                                                        }
                                                        onChange={(e) =>
                                                          this.setState({
                                                            frequency:
                                                              e.target.value,
                                                          })
                                                        }
                                                      />
                                                    </Form.Group>
                                                    <span className="table-form-label-err mb-0">
                                                      {this.state.frequencyErr}
                                                    </span>
                                                  </div>
                                                  <div className="col-lg-8">
                                                    <Form.Group
                                                      as={Col}
                                                      controlId="authStatus"
                                                    >
                                                      <Form.Label className="table-form-label mb-0">
                                                        Reason for Exam
                                                      </Form.Label>
                                                      <Form.Control
                                                        className="grid-form-control"
                                                        value={
                                                          this.state
                                                            .reasonForExam
                                                        }
                                                        onChange={(e) =>
                                                          this.setState({
                                                            reasonForExam:
                                                              e.target.value,
                                                          })
                                                        }
                                                      />
                                                    </Form.Group>
                                                    <span className="table-form-label-err mb-0">
                                                      {
                                                        this.state
                                                          .reasonForExamErr
                                                      }
                                                    </span>
                                                  </div>
                                                </Row>
                                                <Row className="mb-1">
                                                  <div className="col-lg-12">
                                                    <Form.Group
                                                      as={Col}
                                                      controlId="authStatus"
                                                    >
                                                      <Form.Label className="table-form-label mb-0">
                                                        Tracking Number
                                                      </Form.Label>
                                                      <Form.Control
                                                        className="grid-form-control"
                                                        value={
                                                          this.state
                                                            .trackingNumber
                                                        }
                                                        onChange={(e) =>
                                                          this.setState({
                                                            trackingNumber:
                                                              e.target.value,
                                                          })
                                                        }
                                                      />
                                                    </Form.Group>
                                                    <span className="table-form-label-err mb-0">
                                                      {
                                                        this.state
                                                          .trackingNumberErr
                                                      }
                                                    </span>
                                                  </div>
                                                </Row>
                                              </div>
                                              <div className="grid-line-sep"></div>
                                              <div className="mt-2">
                                                <label className="form-main-title">
                                                  APPROVAL INFORMATION
                                                </label>
                                              </div>
                                              <div className="p-2">
                                                <Row className="mb-1">
                                                  <div className="col-lg-3 col-6">
                                                    <Form.Group
                                                      as={Col}
                                                      controlId="authStatus"
                                                    >
                                                      <Form.Label className="table-form-label mb-0">
                                                        Auth Number
                                                      </Form.Label>
                                                      <Form.Control
                                                        className="grid-form-control"
                                                        maxLength={40}
                                                        value={
                                                          this.state.authNumber
                                                        }
                                                        onChange={(e) =>
                                                          this.setState({
                                                            authNumber:
                                                              e.target.value,
                                                          })
                                                        }
                                                      />
                                                    </Form.Group>
                                                    <span className="table-form-label-err mb-0">
                                                      {this.state.authNumberErr}
                                                    </span>
                                                  </div>
                                                  <div className="col-lg-3 col-6">
                                                    <Form.Group
                                                      as={Col}
                                                      controlId="authStatus"
                                                    >
                                                      <Form.Label className="table-form-label mb-0">
                                                        Approved Units
                                                      </Form.Label>
                                                      <Form.Control
                                                        className="grid-form-control"
                                                        maxLength={40}
                                                        value={
                                                          this.state
                                                            .approvedUnits
                                                        }
                                                        onChange={(e) =>
                                                          this.setState({
                                                            approvedUnits:
                                                              e.target.value,
                                                          })
                                                        }
                                                      />
                                                    </Form.Group>
                                                    <span className="table-form-label-err mb-0">
                                                      {
                                                        this.state
                                                          .approvedUnitsErr
                                                      }
                                                    </span>
                                                  </div>
                                                  <div className="col-lg-3 col-6">
                                                    <Form.Group
                                                      as={Col}
                                                      controlId="authStatus"
                                                    >
                                                      <Form.Label className="table-form-label mb-0">
                                                        Approved Procedure Codes
                                                      </Form.Label>
                                                      <Form.Control
                                                        className="grid-form-control"
                                                        maxLength={40}
                                                        value={
                                                          this.state
                                                            .approvedProcedureCodes
                                                        }
                                                        onChange={(e) =>
                                                          this.setState({
                                                            approvedProcedureCodes:
                                                              e.target.value,
                                                          })
                                                        }
                                                      />
                                                    </Form.Group>
                                                    <span className="table-form-label-err mb-0">
                                                      {
                                                        this.state
                                                          .approvedProcedureCodesErr
                                                      }
                                                    </span>
                                                  </div>
                                                </Row>
                                                <Row className="mb-1 d-flex">
                                                  <div className="col-lg-6">
                                                    <div className="col-lg-10 col-6 d-flex flex-column">
                                                      <label className="table-form-label mb-0">
                                                        From Date
                                                      </label>
                                                      <DatePicker
                                                        className="date-picker"
                                                        showIcon
                                                        placeholderText="MM/DD/YYYY"
                                                        customInput={
                                                          <MaskedTextInput
                                                            type="text"
                                                            mask={MaskDateInput}
                                                          />
                                                        }
                                                        onChange={(date) =>
                                                          this.setState({
                                                            approvalFromDate:
                                                              formatDateToCustomISO(
                                                                date
                                                              ),
                                                          })
                                                        }
                                                        selected={
                                                          this.state
                                                            .approvalFromDate
                                                            ? formatDateToCustomISO(
                                                                moment(
                                                                  this.state
                                                                    .approvalFromDate
                                                                ).toDate()
                                                              )
                                                            : null
                                                        }
                                                      />
                                                      <span className="table-form-label-err mb-0 mb-0">
                                                        {
                                                          this.state
                                                            .approvalFromDateErr
                                                        }
                                                      </span>
                                                    </div>
                                                    <div className="col-lg-10 col-6 d-flex flex-column">
                                                      <label className="table-form-label mb-0">
                                                        To Date
                                                      </label>
                                                      <DatePicker
                                                        className="date-picker"
                                                        showIcon
                                                        placeholderText="MM/DD/YYYY"
                                                        customInput={
                                                          <MaskedTextInput
                                                            type="text"
                                                            mask={MaskDateInput}
                                                          />
                                                        }
                                                        onChange={(date) =>
                                                          this.setState({
                                                            approvalToDate:
                                                              formatDateToCustomISO(
                                                                date
                                                              ),
                                                          })
                                                        }
                                                        selected={
                                                          this.state
                                                            .approvalToDate
                                                            ? formatDateToCustomISO(
                                                                moment(
                                                                  this.state
                                                                    .approvalToDate
                                                                ).toDate()
                                                              )
                                                            : null
                                                        }
                                                      />
                                                      <span className="table-form-label-err mb-0 mb-0">
                                                        {
                                                          this.state
                                                            .approvalToDateErr
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-6">
                                                    <div className="col-lg-12 d-flex">
                                                      <Form.Group
                                                        as={Col}
                                                        controlId="authStatus"
                                                      >
                                                        <Form.Label className="table-form-label mb-0">
                                                          Notes
                                                        </Form.Label>
                                                        <Form.Control
                                                          as="textarea"
                                                          rows="3"
                                                          className="grid-form-control app-info-note"
                                                          value={
                                                            this.state
                                                              .procedureNotes
                                                          }
                                                          onChange={(e) =>
                                                            this.setState({
                                                              procedureNotes:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                        <span className="table-form-label-err mb-0">
                                                          {
                                                            this.state
                                                              .procedureNotesErr
                                                          }
                                                        </span>
                                                      </Form.Group>
                                                    </div>
                                                  </div>
                                                </Row>
                                                <Row>
                                                  <div>
                                                    <Button
                                                      className="form-save-btn btn-primary mt-1 float-end"
                                                      type="button"
                                                      disabled={
                                                        this.state
                                                          .disabledSaveProcedure
                                                      }
                                                      onClick={() => {
                                                        this.saveProcedure();
                                                      }}
                                                    >
                                                      <img
                                                        src={require("../../images/save-btn-icon.png")}
                                                        className="add-icon"
                                                      />
                                                      Save
                                                    </Button>
                                                  </div>
                                                </Row>
                                              </div>
                                            </Form>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                    <div style={show278Result}>
                                        <div className="d-flex">
                                              <div className="d-flex">
                                                <label className="elig-info d-flex">278&nbsp;Result:</label>
                                              </div>
                                        </div>
                                        <div className="d-flex">
                                            {result278}
                                        </div>
                                        <div className="float-start">
                                          {submit278Btn}
                                        </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="float-end d-flex">
                                    <Button
                                      className="view-doc-btn"
                                      variant="primary"
                                      type="button"
                                      onClick={async () => {
                                        await this.props.patientDocumentationClear();
                                        await this.props.authorizationDocumentationClear();
                                        await this.props.getAuthorizationDocumentation(
                                          record.id
                                        );
                                        await this.props.getPatientDocumentation(
                                          record.patientId
                                        );
                                        this.setDefaultCheckedDocuments();
                                        this.setState({
                                          selectedAuthorization: record,
                                        });
                                        this.handleShowCloseDocumentation(true);
                                      }}
                                    >
                                      <img
                                        src={require("../../images/doc-icon.png")}
                                        className="add-icon"
                                      />
                                      Documentation
                                    </Button>
                                  </div>
                                </div>

                                <div class="mt-5">
                                  <div class="form-main-title mt-3">
                                    <label>Order History</label>
                                  </div>
                                  <Table bordered className="table-portal">
                                    <thead>
                                      <tr>
                                        <th className="td-first-none-collapse">
                                          Order No.
                                        </th>
                                        <th>Status From</th>
                                        <th>Status To</th>
                                        <th>Disposition From</th>
                                        <th>Disposition To</th>
                                        <th>Modified Date</th>
                                        <th className="td-last">Modified By</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {orderDetailsHistory.map(
                                        (recordDetail, key) => {
                                          return (
                                            <>
                                              <tr>
                                                <td className="td-first-none-collapse">
                                                  {recordDetail.orderNo}
                                                </td>
                                                <td>
                                                  {recordDetail.fromStatus}
                                                </td>
                                                <td>{recordDetail.toStatus}</td>
                                                <td>
                                                  {recordDetail.fromDisposition}
                                                </td>
                                                <td>
                                                  {recordDetail.toDisposition}
                                                </td>
                                                <td>
                                                  {recordDetail.modifiedDate
                                                    ? moment(
                                                        formatDateToCustomISO(
                                                          moment(
                                                            recordDetail.modifiedDate
                                                          ).toDate()
                                                        )
                                                      ).format("MM/DD/YYYY")
                                                    : ""}
                                                </td>
                                                <td className="td-last">
                                                  {recordDetail.modifiedBy}
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </Form>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUpdateEncounter: bindActionCreators(addUpdateEncounter, dispatch),
    addUpdateEncounterSelected: bindActionCreators(
      addUpdateEncounterSelected,
      dispatch
    ),
    addUpdateOrder: bindActionCreators(addUpdateAuthorization, dispatch),
    addUpdateOrderSelected: bindActionCreators(
      addUpdateAuthorizationSelected,
      dispatch
    ),
    addUpdateAuthorization278: bindActionCreators(
        addUpdateAuthorization278,
        dispatch
    ),
    getAuthorization278: bindActionCreators(
      getAuthorization278,
      dispatch
    ),
    addUpdateAuthorization278Selected: bindActionCreators(
        addUpdateAuthorization278Selected,
        dispatch
    ),
    addUpdateAuthorizationEligibility: bindActionCreators(
      addUpdateAuthorizationEligibility,
      dispatch
    ),
    getAuthorizationEligibility: bindActionCreators(
      getAuthorizationEligibility,
      dispatch
    ),
    addUpdateAuthorizationEligibilitySelected: bindActionCreators(
      addUpdateAuthorizationEligibilitySelected,
      dispatch
    ),
    getPayersFindAll: bindActionCreators(getPayersFindAll, dispatch),
    getOrderDetails: bindActionCreators(getAuthorizationDetail, dispatch),
    getOrderDetailsSelected: bindActionCreators(
      getAuthorizationDetailSelected,
      dispatch
    ),
    getOrderDuplicates: bindActionCreators(
      getAuthorizationDuplicates,
      dispatch
    ),
    addUpdateOrderDetail: bindActionCreators(
      addUpdateAuthorizationDetail,
      dispatch
    ),
    addUpdateOrderDetailSelected: bindActionCreators(
      addUpdateAuthorizationDetailSelected,
      dispatch
    ),
    addUpdateOrderProvider: bindActionCreators(
      addUpdateAuthorizationProvider,
      dispatch
    ),
    addUpdateOrderNote: bindActionCreators(
      addUpdateAuthorizationNote,
      dispatch
    ),
    addUpdateOrderDiagnosis: bindActionCreators(
      addUpdateAuthorizationDiagnosis,
      dispatch
    ),
    addUpdateOrderProcedure: bindActionCreators(
      addUpdateAuthorizationProcedure,
      dispatch
    ),
    addUpdateOrderRequestingProvider: bindActionCreators(
      addUpdateAuthorizationRequestingProvider,
      dispatch
    ),
    addUpdateOrderRequestingProviderSelected: bindActionCreators(
      addUpdateAuthorizationRequestingProviderSelected,
      dispatch
    ),
    getSystemsCode: bindActionCreators(getSystemsCode, dispatch),
    getOrderProvider: bindActionCreators(getAuthorizationProvider, dispatch),
    getOrderNote: bindActionCreators(getAuthorizationNote, dispatch),
    getOrderDiagnosis: bindActionCreators(getAuthorizationDiagnosis, dispatch),
    getOrderProcedure: bindActionCreators(getAuthorizationProcedure, dispatch),
    getOrderHistory: bindActionCreators(getAuthorizationHistory, dispatch),
    getOrderProviderSelected: bindActionCreators(
      getAuthorizationProviderSelected,
      dispatch
    ),
    getOrderNoteSelected: bindActionCreators(
      getAuthorizationNoteSelected,
      dispatch
    ),
    getOrderDiagnosisSelected: bindActionCreators(
      getAuthorizationDiagnosisSelected,
      dispatch
    ),
    getOrderProcedureSelected: bindActionCreators(
      getAuthorizationProcedureSelected,
      dispatch
    ),
    getOrderHistorySelected: bindActionCreators(
      getAuthorizationHistorySelected,
      dispatch
    ),
    getProvider: bindActionCreators(getProvider, dispatch),
    getFacilities: bindActionCreators(getFacilities, dispatch),

    getDocumentationDownloadFile: bindActionCreators(
      getDocumentationDownloadFile,
      dispatch
    ),
    documentationDownloadFileClear: bindActionCreators(
      documentationDownloadFileClear,
      dispatch
    ),
    getMedicalNecessity: bindActionCreators(getMedicalNecessity, dispatch),
    medicalNecessityClear: bindActionCreators(medicalNecessityClear, dispatch),
    getPatientDocumentation: bindActionCreators(
      getPatientDocumentation,
      dispatch
    ),
    patientDocumentationClear: bindActionCreators(
      patientDocumentationClear,
      dispatch
    ),
    getAuthorizationDocumentation: bindActionCreators(
      getAuthorizationDocumentation,
      dispatch
    ),
    getAddUpdateAuthorizationDocumentationByStatus: bindActionCreators(
      getAddUpdateAuthorizationDocumentationByStatus,
      dispatch
    ),
    authorizationDocumentationClear: bindActionCreators(
      authorizationDocumentationClear,
      dispatch
    ),
    addUpdateDocumentation: bindActionCreators(
      addUpdateDocumentation,
      dispatch
    ),
    getPatientInsurance: bindActionCreators(getPatientInsurance, dispatch),
    getServiceTypes: bindActionCreators(getServiceTypes, dispatch),
    validateIcd10cmCode: bindActionCreators(validateIcd10cmCode, dispatch),
    addUpdateInsurance: bindActionCreators(addUpdateInsurance, dispatch),
    patientInsuranceClear: bindActionCreators(patientInsuranceClear, dispatch),
    getPayerDto: bindActionCreators(getPayerDto, dispatch),
    getMedicalReq: bindActionCreators(getMedicalReq, dispatch),
    validateIcdCpt: bindActionCreators(validateIcdCpt, dispatch),
    getAuthorizationById: bindActionCreators(getAuthorizationById, dispatch),
    authorizationByIdSelected: bindActionCreators(
      authorizationByIdSelected,
      dispatch
    ),
    getCptDescription: bindActionCreators(getCptDescription, dispatch),
    addUpdatePatientSelected: bindActionCreators(
      addUpdatePatientSelected,
      dispatch
    ),

    addUpdateFacility: bindActionCreators(addUpdateFacility, dispatch),
    addUpdateProvider: bindActionCreators(addUpdateProvider, dispatch),
    getCignaFax: bindActionCreators(getCignaFax, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    addedPatient: state.addUpdatePatient.data,
    selectedEncounter: state.addUpdateEncounter.data,
    selectedOrder: state.addUpdateAuthorization.data,
    selectedOrderEligibility: state.addUpdateAuthorizationEligibility.data,
    selectedOrderDetail278: state.addUpdateAuthorization278.data,
    serviceTypes: state.getSystemsCode.serviceTypes,
    statusCodes: state.getSystemsCode.statusCodes,
    dispositionCodes: state.getSystemsCode.dispositionCodes,
    reasonCodes: state.getSystemsCode.reasonCodes,
    placeOfServices: state.getSystemsCode.placeOfService,
    payers: state.getPayersAll.data,
    orderDetails: state.getAuthorizationDetail.data,
    selectedOrderRequestingProvider:
      state.addUpdateAuthorizationRequestingProvider.data,
    orderDetailsProviders: state.getAuthorizationProvider.data,
    orderDetailsNote: state.getAuthorizationNote.data,
    orderDetailsDiagnosis: state.getAuthorizationDiagnosis.data,
    orderDetailsProcedure: state.getAuthorizationProcedure.data,
    orderDetailsHistory: state.getAuthorizationHistory.data,
    providers: state.getProvider.data,
    facilities: state.getFacilities.data,
    selectedOrderDetail: state.addUpdateAuthorizationDetail.data,

    medicalNecessity: state.medicalNecessity.data,
    documentationDownloadFile: state.documentationDownloadFile.data,
    documentationList: state.getPatientDocumentation.data,
    authorizationDocumentationList: state.getAuthorizationDocumentation.data,
    addedAuthorizationDocumentation:
      state.addUpdateAuthorizationDocumentationByStatus.data,
    insuranceList: state.getPatientInsurance.data,
    authentication: state.authentication,
    cptServiceTypes: state.getServiceTypes.data,
    validateIcd10cmCodeData: state.validateIcd10cmCodeReducer.data,
    payerDto: state.getPayerDtoReducer.data,
    relation: state.getSystemsCode.relation,
    savedProcedure: state.addUpdateAuthorizationProcedure.data,
    savedDiagnosis: state.addUpdateAuthorizationDiagnosis.data,
    medicalReq: state.getMedicalReq.data,
    authorizationById: state.getAuthorizationById.data,
    cptDescription: state.getCptDescription.data,
    orderDuplicates: state.getAuthorizationDuplicates.data,

    menu: state.menu.data,
    cignaFax: state.getFaxReducer.data,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter(Encounter));
