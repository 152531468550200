import React, { Component } from "react";
import "../../App.css";
import "./Eligibility.css";
import { Button, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import moment from "moment";
import { formatDateToCustomISO } from "./../common/UtilsDate";

class Eligibility extends Component {
  state = {
    payerName: null,
    payerId: null,
    patientMemberId: null,
    patientFirstName: null,
    patientLastName: null,
    patientDob: null,
    groupNumber: null,
    provider: null,
    npi: null,
    planType: null,
    dateOfService: null,
  };

  render() {
    const {
      payerName,
      payerId,
      patientMemberId,
      patientFirstName,
      patientLastName,
      patientDob,
      groupNumber,
      provider,
      npi,
      planType,
      dateOfService,
    } = this.state;

    var MaskDateInput = [
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    return (
      <div className="w-100">
        <Form className="main-form">
          <h1>Eligibility Form</h1>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="payerName">
                <Form.Label className="table-form-label mb-0">
                  Payer Name
                </Form.Label>
                <Form.Control
                  maxLength={40}
                  className="grid-form-control"
                  placeholder="Payer Name"
                  value={payerName}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="payerId">
                <Form.Label className="table-form-label mb-0">
                  Payer ID
                </Form.Label>
                <Form.Control
                  maxLength={40}
                  className="grid-form-control"
                  placeholder="Payer ID"
                  value={payerId}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="payerMemberId">
                <Form.Label className="table-form-label mb-0">
                  Patient Member ID
                </Form.Label>
                <Form.Control
                  maxLength={40}
                  className="grid-form-control"
                  placeholder="Payer Member ID"
                  value={patientMemberId}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="firstName">
                <Form.Label className="table-form-label mb-0">
                  Patient First Name
                </Form.Label>
                <Form.Control
                  maxLength={40}
                  className="grid-form-control"
                  placeholder="First Name"
                  value={patientFirstName}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="lastName">
                <Form.Label className="table-form-label mb-0">
                  Patient Last Name
                </Form.Label>
                <Form.Control
                  maxLength={40}
                  className="grid-form-control"
                  placeholder="Last Name"
                  value={patientLastName}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="dob">
                <Form.Label className="table-form-label mb-0">
                  Patient Date of Birth
                </Form.Label>
                <DatePicker
                  className="date-picker"
                  wrapperClassName="date-picker-eligibility"
                  showIcon
                  placeholderText="MM/DD/YYYY"
                  value={patientDob}
                  customInput={
                    <MaskedTextInput type="text" mask={MaskDateInput} />
                  }
                  onChange={(date) =>
                    this.setState({
                      patientDob: formatDateToCustomISO(date),
                    })
                  }
                  selected={
                    this.state.patientDob
                      ? formatDateToCustomISO(
                          moment(this.state.patientDob).toDate()
                        )
                      : null
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="groupNumber">
                <Form.Label className="table-form-label mb-0">
                  Group Number
                </Form.Label>
                <Form.Control
                  maxLength={40}
                  className="grid-form-control"
                  placeholder="Group Number"
                  value={groupNumber}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="provider">
                <Form.Label className="table-form-label mb-0">
                  Provider
                </Form.Label>
                <Form.Control
                  maxLength={40}
                  className="grid-form-control"
                  placeholder="Provider"
                  value={provider}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="npi">
                <Form.Label className="table-form-label mb-0">NPI</Form.Label>
                <Form.Control
                  maxLength={40}
                  className="grid-form-control"
                  placeholder="NPI"
                  value={npi}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="planType">
                <Form.Label className="table-form-label mb-0">
                  Plan Type
                </Form.Label>
                <Form.Control
                  maxLength={40}
                  className="grid-form-control"
                  placeholder="Plan Type"
                  value={planType}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="dos">
                <Form.Label className="table-form-label mb-0">
                  Date of Service
                </Form.Label>
                <DatePicker
                  className="date-picker"
                  wrapperClassName="date-picker-eligibility"
                  showIcon
                  placeholderText="MM/DD/YYYY"
                  value={dateOfService}
                  customInput={
                    <MaskedTextInput type="text" mask={MaskDateInput} />
                  }
                  onChange={(date) =>
                    this.setState({
                      dateOfService: formatDateToCustomISO(date),
                    })
                  }
                  selected={
                    this.state.dateOfService
                      ? formatDateToCustomISO(
                          moment(this.state.dateOfService).toDate()
                        )
                      : null
                  }
                />
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <div className="d-flex justify-content-center mt-5">
            <Button
              className="btn btn-primary grid-btn"
              variant="primary"
              type="button"
            >
              Run Eligibility
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default Eligibility;
