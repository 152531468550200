import React, { useState, Component } from "react";
import {connect} from "react-redux";
import {WithRouter} from "../common/WithRouter";
class RefreshClients extends Component {

    state = {
      redirect: false
    };


    componentDidMount()  {
        this.setState({ redirect: true });
        this.redirect();
    }

    redirect = () => {
       const {redirect} = this.state;
       if(redirect == true){
            this.props.navigate('/clients');
       }
    }

    render() {

        return (
            <div>
                {this.redirect()}
            </div>
        );

    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(RefreshClients))