import React, { useState, Component } from "react";
import "./AddGuarantor.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { Table, Tab, Tabs, Modal } from "react-bootstrap";
import "font-awesome/css/font-awesome.min.css";
import {
  addUpdateGuarantor,
  addUpdateGuarantorSelected,
} from "../../../redux/actions/api/guarantor/AddUpdateGuarantorApiActions";
import { addUpdatePatientSelected } from "../../../redux/actions/api/patient/AddUpdatePatientApiActions";
import { guarantorAddressClear } from "../../../redux/actions/api/address/GetGuarantorAddressApiActions";
import { guarantorPhoneEmailClear } from "../../../redux/actions/api/phoneEmail/GetGuarantorPhoneEmailApiActions";
import { patientAddressClear } from "../../../redux/actions/api/address/GetPatientAddressApiActions";
import { patientPhoneEmailClear } from "../../../redux/actions/api/phoneEmail/GetPatientPhoneEmailApiActions";
import { patientInsuranceClear } from "../../../redux/actions/api/insurance/GetPatientInsuranceApiActions";
import { patientEncounterClear } from "../../../redux/actions/api/encounter/GetPatientEncounterApiActions";
import { documentationDownloadFileClear } from "../../../redux/actions/api/documentation/DocumentationDownloadFileApiActions";
import { guarantorPatientsClear } from "../../../redux/actions/api/patient/GetGuarantorPatientsApiActions";
import { addUpdatePatient } from "../../../redux/actions/api/patient/AddUpdatePatientApiActions";
import { addUpdatePhoneEmail } from "../../../redux/actions/api/phoneEmail/AddUpdatePhoneEmailApiActions";
import { getGuarantorPhoneEmail } from "../../../redux/actions/api/phoneEmail/GetGuarantorPhoneEmailApiActions";
import { getGuarantorPatients } from "../../../redux/actions/api/patient/GetGuarantorPatientsApiActions";
import { getGuarantorAddress } from "../../../redux/actions/api/address/GetGuarantorAddressApiActions";
import { addUpdateAddress } from "../../../redux/actions/api/address/AddUpdateAddressApiActions";
import { guarantorPatientsSearch } from "../../../redux/actions/api/guarantor-patients-search/GuarantorPatientsSearchApiActions";

import getClientsByOrgId from "../../../redux/actions/api/client/GetClientsByOrgIdApiActions";
import getFacilities from "../../../redux/actions/api/facility/GetFacilitiesApiActions";

import {
  guarantorPatientSearchDuplicate,
  guarantorPatientSearchDuplicateClear,
} from "../../../redux/actions/api/guarantor-patients-search/GuarantorPatientSearchDuplicateApiActions";

import { WithRouter } from "../../common/WithRouter";
import moment from "moment";
import USStates from "../../common/USStates";

import { useNavigate } from "react-router-dom";
import MaskedFormControl from "react-bootstrap-maskedinput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from "react-maskedinput";
import { formatDateToCustomISO } from "../../common/UtilsDate";
import { updateGuarandorsSearchResult } from "../../../redux/actions/api/guarantor-patients-search/GuarantorSearchApiActions";

class AddGuarantors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchGuaPatDupModal: false,

      key: 1,
      id: "",
      parentId: "",

      orgId: "",
      clientId: "",
      clientIdErr: "",
      facilityId: "",
      facilityIdErr: "",
      lastName: "",
      lastNameErr: "",
      firstName: "",
      firstNameErr: "",
      middleName: "",
      middleNameErr: "",
      mrn: "",
      dob: "",
      dobErr: "",
      ssn: "",
      ssnErr: "",
      gender: "",

      type: "",
      subtype: "MOBILE",
      numberEmail: "",
      numberEmailErr: "",
      sms: "Y",
      status: "ACTIVE",

      address1: "",
      address1Err: "",
      address2: "",
      city: "",
      cityErr: "",
      state: "",
      stateErr: "",
      zip: "",
      zipErr: "",
      country: "US",
      isPrimary: "Y",

      patientId: "",

      relation: "",
      provider: "",
      phoneNumber: "",
      groupName: "",
      groupId: "",
      policyNumber: "",
      plan: "",
      insBegin: "",
      insEnd: "",

      isNew: "Y",
      btnClick: "N",

      disabledSaveGuarantor: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  guarantorDefault = {
    clientId: "",
    clientIdErr: "",
    facilityId: "",
    facilityIdErr: "",
    lastName: "",
    lastNameErr: "",
    firstName: "",
    firstNameErr: "",
    middleName: "",
    middleNameErr: "",
    mrn: "",
    dob: "",
    dobErr: "",

    ssn: "",
    ssnErr: "",
    parentId: "",
  };

  phoneEmailDefault = {
    id: "",
    type: "HOME",
    subtype: "MOBILE",
    numberEmail: "",
    numberEmailErr: "",
    isPrimary: "Y",
    sms: "Y",
    status: "ACTIVE",
    btnClick: "Y",
  };

  addressDefault = {
    id: "",
    type: "HOME",
    address1: "",
    address1Err: "",
    address2: "",
    city: "",
    cityErr: "",
    state: "",
    stateErr: "",
    zip: "",
    zipErr: "",
    country: "US",
    isPrimary: "Y",
  };

  insuranceDefault = {
    patientId: "",
    type: "PRIMARY",
    relation: "",
    provider: "MEDICAID",
    address1: "",
    address2: "",
    city: "",
    state: "PENNSYLVANIA",
    zip: "",
    phoneNumber: "",
    groupName: "",
    groupId: "",
    policyNumber: "",
    plan: "",
    insBegin: "",
    insEnd: "",
  };

  selectClient = (e) => {
    let clientId = e.target.value;
    console.log(clientId);
    this.setState({ clientId: clientId, facilityId: "" });
    //this.setState({selectedClient: selectedClient, clientName: selectedClient.name});
    this.props.getFacilities(clientId);
  };

  handleSelect(key) {
    console.log("selected" + key);
    this.setState({ key: key });
  }

  clearGuarantorErrors = () => {
    this.setState({
      clientIdErr: "",
      facilityIdErr: "",
      lastNameErr: "",
      firstNameErr: "",
      middleNameErr: "",
      dobErr: "",
      ssnErr: "",
    });
  };

  saveGuarantor = async () => {
    this.setState({ disabledSaveGuarantor: true });
    var error = false;

    if (!this.state.clientId) {
      this.setState({ clientIdErr: "Client is required." });
      error = true;
    } else {
      this.setState({ clientIdErr: "" });
    }
    console.log("this.state.facilityId" + this.state.facilityId);
    if (!this.state.facilityId) {
      this.setState({ facilityIdErr: "Facility is required." });
      error = true;
    } else {
      this.setState({ facilityIdErr: "" });
    }

    if (!this.state.lastName) {
      this.setState({ lastNameErr: "Last Name is required." });
      error = true;
    } else {
      this.setState({ lastNameErr: "" });
    }

    if (!this.state.firstName) {
      this.setState({ firstNameErr: "First Name is required." });
      error = true;
    } else {
      this.setState({ firstNameErr: "" });
    }

    if (!this.state.dob) {
      this.setState({ dobErr: "Date of Birth is required." });
      error = true;
    } else {
      this.setState({ dobErr: "" });
    }

    if (!this.state.ssn) {
      this.setState({ ssnErr: "SSN is required." });
      error = true;
    } else {
      this.setState({ ssnErr: "" });
    }

    if (error == false) {
      const { addedGuarantor, guarantorPatientSearchDuplicateResults } =
        this.props;
      var guarantor = { ...this.state, id: addedGuarantor.id, type: "G" };

      var hasDuplicate = true;

      if (guarantorPatientSearchDuplicateResults == "CLEARED") {
        await this.searchGuaPatDup();
        this.saveGuarantor();
      } else {
        console.log(
          "guarantorPatientSearchDuplicateResults",
          guarantorPatientSearchDuplicateResults
        );
        if (!guarantorPatientSearchDuplicateResults) {
          hasDuplicate = false;
        } else {
          if (guarantorPatientSearchDuplicateResults.length != 0) {
            this.setState({ searchGuaPatDupModal: true });
          } else {
            hasDuplicate = false;
          }
        }
      }

      console.log("hasDuplicate=" + hasDuplicate);
      if (hasDuplicate == false) {
        this.clearGuarantorErrors();
        this.props.updateGuarandorsSearchResult(guarantor);
        await this.props.addUpdateGuarantor(guarantor);
      }
    }

    this.setState({ disabledSaveGuarantor: false });
  };

  clearPhoneEmailErrors = () => {
    this.setState({
      numberEmailErr: "",
    });
  };

  savePhoneEmail = async () => {
    var error = false;

    if (!this.state.numberEmail) {
      this.setState({ numberEmailErr: "Number/ Email is required." });
      error = true;
    } else {
      this.setState({ numberEmailErr: "" });
    }

    if (error == false) {
      console.log("phoneEmial", phoneEmail);
      const { isNew } = this.state;
      const { addedGuarantor } = this.props;

      var phoneEmail = { ...this.state, guarantorId: addedGuarantor.id };
      console.log("phoneEmial", phoneEmail);
      await this.props.addUpdatePhoneEmail(phoneEmail);
      await this.props.getGuarantorPhoneEmail(addedGuarantor.id);
      console.log("I am here 4");
      if (isNew == "Y") {
        this.setState(this.phoneEmailDefault);
      }
      console.log("addedGuarantor.id" + addedGuarantor.id);
      this.clearPhoneEmailErrors();
    }
  };

  badStatusPhoneEmail = async (record) => {
    console.log("I am here 1", record);
    await this.setState(record);
    this.savePhoneEmail();
    //this.setState({btnClick:'Y'});
  };

  closeSearchGuaPatDupDialog = () => {
    this.props.guarantorPatientSearchDuplicateClear();
    this.setState({ searchGuaPatDupModal: false });
  };

  showSearchGuaPatDupDialog = () => {
    console.log("test");
    this.setState({ searchGuaPatDupModal: true });
  };

  searchGuaPatDup = async () => {
    var { orgId, clientId, facilityId, mrn, ssn, lastName, firstName, dob } =
      this.state;
    const { addedGuarantor } = this.props;

    var id = 0;

    if (addedGuarantor.id) {
      id = addedGuarantor.id;
    }

    if (!orgId) {
      orgId = 0;
    }

    if (!clientId) {
      clientId = 0;
    }

    if (!facilityId) {
      facilityId = 0;
    }

    dob = formatDateToCustomISO(moment(dob).toDate());
    dob = moment(dob).format("MM/DD/YYYY");

    await this.props.guarantorPatientSearchDuplicate(
      id,
      orgId,
      clientId,
      facilityId,
      "G",
      mrn,
      ssn,
      lastName,
      firstName,
      dob
    );
  };

  clearAddressErrors = () => {
    this.setState({
      address1Err: "",
      cityErr: "",
      stateErr: "",
      zipErr: "",
    });
  };

  saveAddress = async () => {
    var error = false;

    if (!this.state.address1) {
      this.setState({ address1Err: "Address1 is required." });
      error = true;
    } else {
      this.setState({ address1Err: "" });
    }

    if (!this.state.city) {
      this.setState({ cityErr: "City is required." });
      error = true;
    } else {
      this.setState({ cityErr: "" });
    }

    if (!this.state.state) {
      this.setState({ stateErr: "State is required." });
      error = true;
    } else {
      this.setState({ stateErr: "" });
    }

    if (!this.state.zip) {
      this.setState({ zipErr: "Zip is required." });
      error = true;
    } else {
      this.setState({ zipErr: "" });
    }

    if (error == false) {
      const { isNew } = this.state;
      const { addedGuarantor } = this.props;

      var address = { ...this.state, guarantorId: addedGuarantor.id };

      await this.props.addUpdateAddress(address);
      await this.props.getGuarantorAddress(addedGuarantor.id);
      if (isNew == "Y") {
        this.setState(this.addressDefault);
      }
      console.log("addedGuarantor.id" + addedGuarantor.id);
      this.clearAddressErrors();
    }
  };

  searchGuarantor = () => {
    const { searchMrn, searchLastName, searchFirstName, searchDob } =
      this.state;
    let dob = undefined;
    if (!searchMrn && !searchLastName && !searchFirstName && !searchDob) return;
    let lastName = searchLastName
      ? searchLastName.toString().toUpperCase()
      : null;
    let firstName = searchFirstName
      ? searchFirstName.toString().toUpperCase()
      : null;
    try {
      if (searchDob) {
        dob = moment(searchDob).format("MM/DD/YYYY");
      } else {
        dob = undefined;
      }
      this.props.guarantorPatientsSearch(
        searchMrn,
        lastName,
        firstName,
        dob,
        "G"
      );
    } catch (err) {
      console.log(err);
    }
  };

  clearGuarantorList = () => {
    /*this.props.addUpdatePatientSelected({});*/
    this.props.addUpdateGuarantorSelected({});

    this.props.guarantorAddressClear();
    this.props.guarantorPhoneEmailClear();
    this.props.patientInsuranceClear();
    this.props.patientEncounterClear();
    this.props.documentationDownloadFileClear();
    this.props.patientAddressClear();
    this.props.patientPhoneEmailClear();
    this.props.guarantorPatientsClear();
    this.setState(this.guarantorDefault);
  };

  clearPatientList = () => {
    this.props.addUpdatePatientSelected({});

    this.props.patientInsuranceClear();
    this.props.patientAddressClear();
    this.props.patientPhoneEmailClear();
    this.props.patientEncounterClear();
    this.props.documentationDownloadFileClear();
  };

  addNewPatientClick = () => {
    this.clearPatientList();
    this.props.navigate("/accounts/add-patients");
  };

  onClickPatientRow = (record) => {
    console.log("record", record);
    this.clearPatientList();
    this.props.addUpdatePatientSelected(record);
    this.props.navigate("/accounts/add-patients");
  };

  addNewGuarantorClick = () => {
    this.props.guarantorPatientSearchDuplicateClear();
    this.clearGuarantorList();
  };

  async componentDidMount() {
    const { user, getClients, getFacilities, addedGuarantor } = this.props;

    this.setState({ orgId: user.orgId });
    await getClients(user.orgId);

    if (addedGuarantor.id) {
      console.log("I am here 5");
      this.props.getGuarantorPhoneEmail(addedGuarantor.id);
      this.props.getGuarantorAddress(addedGuarantor.id);
      this.props.getGuarantorPatients(addedGuarantor.id);
      await getFacilities(addedGuarantor.clientId);
      this.setState({ ...addedGuarantor });
      console.log("addedGuarantor", addedGuarantor);
    } else {
      this.props.guarantorAddressClear();
      this.props.guarantorPhoneEmailClear();
      this.props.guarantorPatientsClear();
      await getFacilities(0);
    }

    this.props.guarantorPatientSearchDuplicateClear();
  }

  onClickHandler(record, e) {
    this.clearPhoneEmailErrors();
    this.clearAddressErrors();

    console.log("I am here 2");
    var { btnClick } = this.state;

    console.log("test " + btnClick);

    if (btnClick == "N") {
      console.log("I am here 3");

      //
    }
    console.log(record);
    this.setState(record);

    const elementsShow = document.querySelectorAll("tr.show");
    const hiddenElement = e.currentTarget.nextSibling;

    /*Reset all show details*/
    elementsShow.forEach((element) => {
      if (hiddenElement != element) {
        element.classList.remove("show");
      }
    });

    /*Set selected row*/
    const selected = e.currentTarget;
    const elementsSelected = document.querySelectorAll("tr.selected-table-row");

    elementsSelected.forEach((element) => {
      if (selected != element) {
        element.classList.remove("selected-table-row");
      }
    });

    selected.classList.add("selected-table-row");

    /*Reset all up arrow*/
    const elementsArrow = document.querySelectorAll(".fa-chevron-up");
    elementsArrow.forEach((element) => {
      element.classList.remove("fa-chevron-up");
      element.classList.add("fa-chevron-down");
    });

    /*set up and show details on selected row*/
    const arrowSelected = document.querySelectorAll(".selected-table-row .fa");

    if (hiddenElement.className.indexOf("collapse show") > -1) {
      hiddenElement.classList.remove("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });
    } else {
      hiddenElement.classList.add("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-down");
        element.classList.add("fa-chevron-up");
      });
    }
    //}

    //this.setState({btnClick:'N'});
  }

  render() {
    var {
      addedPatient,
      addedGuarantor,
      patientList,
      phoneEmailList,
      addressList,
      insuranceList,
      menu,
      clients,
      facilities,
      guarantorPatientSearchDuplicateResults,
    } = this.props;
    var { searchGuaPatDupModal } = this.state;

    if (!phoneEmailList) {
      phoneEmailList = [];
    }

    if (!addressList) {
      addressList = [];
    }

    if (!insuranceList) {
      insuranceList = [];
    }

    if (!addedPatient) {
      addedPatient = {};
    }

    if (!patientList) {
      patientList = [];
    }

    if (!clients) {
      clients = [];
    }

    if (!facilities) {
      facilities = [];
    }

    if (
      guarantorPatientSearchDuplicateResults == "CLEARED" ||
      !guarantorPatientSearchDuplicateResults
    ) {
      guarantorPatientSearchDuplicateResults = [];
    }

    var addGuarantorToPatient;

    if (menu == "Patients") {
      if (addedGuarantor.id) {
        addGuarantorToPatient = (
          <div>
            <Button
              className="btn btn-success grid-btn grid-btn mt-1"
              variant="primary"
              type="button"
              onClick={() => {
                this.props.navigate("/accounts/add-patients");
              }}
            >
              Add Guarantor to Patient
            </Button>
          </div>
        );
      }
    }

    var addNewGuarantorButton;
    var cancelNavigate = "/accounts/add-patients";
    if (menu == "Guarantors") {
      cancelNavigate = "/guarantor";
    }

    addNewGuarantorButton = (
      <div>
        <Button
          className="add-patients-btn btn-primary mx-1 mb-1 float-end m-0"
          variant="primary"
          type="button"
          onClick={this.addNewGuarantorClick}
        >
          <img
            src={require("../../../images/add-icon.png")}
            className="add-icon"
          />
          Add New Guarantor
        </Button>
      </div>
    );

    console.log("cancelNavigate" + cancelNavigate);

    var cancelBtn = (
      <Button
        className="btn back-btn"
        variant="primary"
        type="button"
        onClick={() => {
          this.props.navigate(cancelNavigate);
        }}
      >
        <img
          src={require("../../../images/back-icon.png")}
          className="add-icon"
        />
        Back
      </Button>
    );

    var addNewPatientButton;

    if (menu == "Guarantors" && addedGuarantor.id) {
      addNewPatientButton = (
        <Button
          className="add-patients-btn btn-primary mb-1 ml-2 float-end m-0"
          variant="primary"
          type="button"
          onClick={this.addNewPatientClick}
        >
          <img
            src={require("../../../images/add-icon.png")}
            className="add-icon"
          />
          Add New Patient
        </Button>
      );
    }

    var addedGuarantorDetails;

    if (addedGuarantor.id) {
      addedGuarantorDetails = (
        <div>
          <div>
            <div className="row">
              <div className="col-6">
                <div className="form-main-title mt-2">
                  <label>Phone and Email</label>
                </div>
                <Table bordered className="table-portal table table-bordered">
                  <thead>
                    <tr>
                      <th className="td-first"></th>
                      <th>Type</th>
                      <th>Number/ Email</th>
                      <th className="td-last">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {phoneEmailList.map((record, key) => {
                      return (
                        <>
                          <tr
                            onClick={(event) =>
                              this.onClickHandler(
                                { ...record, isNew: "N", btnClick: "N" },
                                event
                              )
                            }
                          >
                            <td className="td-first">
                              <i className="fa fa-chevron-down"></i>
                            </td>
                            <td>{record.type}</td>
                            <td>{record.numberEmail}</td>
                            <td className="td-last">{record.status}</td>
                          </tr>
                          <tr className="sub-table collapse">
                            <td className="td-first td-last" colSpan="4">
                              <Form>
                                <Row>
                                  <div class="col-lg-3 col-6">
                                    <Form.Group
                                      as={Col}
                                      controlId="guarantorFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Type
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.type}
                                        onChange={(e) =>
                                          this.setState({
                                            type: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="HOME">HOME</option>
                                        <option value="WORK">WORK</option>
                                        <option value="MOBILE">MOBILE</option>
                                        <option value="LANDLINE">
                                          LANDLINE
                                        </option>
                                        <option value="EMAIL">EMAIL</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>

                                  <div class="col-lg-6 col-6">
                                    <Form.Group
                                      as={Col}
                                      controlId="formGridPassword"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Number/ Email
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        value={this.state.numberEmail}
                                        onChange={(e) =>
                                          this.setState({
                                            numberEmail: e.target.value,
                                          })
                                        }
                                      />
                                      <span className="table-form-label-err mb-0">
                                        {this.state.numberEmailErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Status
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.status}
                                        onChange={(e) =>
                                          this.setState({
                                            status: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="ACTIVE">ACTIVE</option>
                                        <option value="INACTIVE">
                                          INACTIVE
                                        </option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-2">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Primary
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.isPrimary}
                                        onChange={(e) =>
                                          this.setState({
                                            isPrimary: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-2">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        SMS
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.sms}
                                        onChange={(e) =>
                                          this.setState({ sms: e.target.value })
                                        }
                                      >
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row>
                                  <div>
                                    <Button
                                      className="form-save-btn btn-primary mt-1"
                                      type="button"
                                      onClick={() => {
                                        this.savePhoneEmail();
                                      }}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </Row>
                              </Form>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    <tr
                      onClick={(event) =>
                        this.onClickHandler(
                          {
                            ...this.phoneEmailDefault,
                            isNew: "Y",
                            btnClick: "N",
                          },
                          event
                        )
                      }
                    >
                      <td className="td-first">
                        <i className="fa fa-chevron-down"></i>
                      </td>
                      <td></td>
                      <td></td>
                      <td className="td-last"></td>
                    </tr>
                    <tr className="sub-table collapse">
                      <td className="td-first td-last" colSpan="4">
                        <Form>
                          <Row>
                            <div class="col-lg-3 col-6">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  Type
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.type}
                                  onChange={(e) =>
                                    this.setState({ type: e.target.value })
                                  }
                                >
                                  <option value="HOME">HOME</option>
                                  <option value="WORK">WORK</option>
                                  <option value="MOBILE">MOBILE</option>
                                  <option value="LANDLINE">LANDLINE</option>
                                  <option value="EMAIL">EMAIL</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div class="col-lg-6 col-6">
                              <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label className="table-form-label mb-0">
                                  Number/ Email
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  value={this.state.numberEmail}
                                  onChange={(e) =>
                                    this.setState({
                                      numberEmail: e.target.value,
                                    })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.numberEmailErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3 col-6">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  Status
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.status}
                                  onChange={(e) =>
                                    this.setState({ status: e.target.value })
                                  }
                                >
                                  <option value="ACTIVE">ACTIVE</option>
                                  <option value="INACTIVE">INACTIVE</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3 col-6 mt-2">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  Primary
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.isPrimary}
                                  onChange={(e) =>
                                    this.setState({ isPrimary: e.target.value })
                                  }
                                >
                                  <option value="Y">Yes</option>
                                  <option value="N">No</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3 col-6 mt-2">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  SMS
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.sms}
                                  onChange={(e) =>
                                    this.setState({ sms: e.target.value })
                                  }
                                >
                                  <option value="Y">Yes</option>
                                  <option value="N">No</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                          </Row>
                          <Row>
                            <div>
                              <Button
                                className="form-save-btn btn-primary mt-1"
                                type="button"
                                onClick={() => {
                                  this.savePhoneEmail();
                                }}
                              >
                                Save
                              </Button>
                            </div>
                          </Row>
                        </Form>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-6">
                <div className="form-main-title mt-2">
                  <label>Address</label>
                </div>
                <Table bordered className="table-portal table table-bordered">
                  <thead>
                    <tr>
                      <th className="td-first"></th>
                      <th>Type</th>
                      <th>Address</th>
                      <th>City</th>
                      <th className="td-last">State</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addressList.map((record, key) => {
                      return (
                        <>
                          <tr
                            onClick={(event) =>
                              this.onClickHandler(
                                { ...record, isNew: "N", btnClick: "N" },
                                event
                              )
                            }
                          >
                            <td className="td-first">
                              <i className="fa fa-chevron-down"></i>
                            </td>
                            <td>{record.type}</td>
                            <td>{record.address1}</td>
                            <td>{record.city}</td>
                            <td className="td-last">{record.state}</td>
                          </tr>
                          <tr className="sub-table collapse">
                            <td className="td-first td-last" colSpan="6">
                              <Form>
                                <Row>
                                  <div class="col-lg-3 col-6">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        TYPE
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.type}
                                        onChange={(e) =>
                                          this.setState({
                                            type: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="HOME">HOME</option>
                                        <option value="WORK">WORK</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row>
                                  <div class="col-lg-6 col-6">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Address 1
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        maxLength={40}
                                        value={this.state.address1}
                                        onChange={(e) =>
                                          this.setState({
                                            address1: e.target.value,
                                          })
                                        }
                                      />
                                      <span className="table-form-label-err mb-0">
                                        {this.state.address1Err}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-6 col-6">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Address 2
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        maxLength={40}
                                        value={this.state.address2}
                                        onChange={(e) =>
                                          this.setState({
                                            address2: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        City
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        value={this.state.city}
                                        onChange={(e) =>
                                          this.setState({
                                            city: e.target.value,
                                          })
                                        }
                                      />
                                      <span className="table-form-label-err mb-0">
                                        {this.state.cityErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        State
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.state}
                                        onChange={(e) =>
                                          this.setState({
                                            state: e.target.value,
                                          })
                                        }
                                      >
                                        <option selected disabled value="">
                                          {" "}
                                          -- Select State --{" "}
                                        </option>
                                        {USStates.map((USState, key) => {
                                          return (
                                            <option
                                              key={key}
                                              value={USState.abbreviation}
                                            >
                                              {USState.name}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                      <span className="table-form-label-err mb-0">
                                        {this.state.stateErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Country
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.country}
                                        onChange={(e) =>
                                          this.setState({
                                            country: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="US">US</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Zip
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        maxLength={12}
                                        value={this.state.zip}
                                        onChange={(e) =>
                                          this.setState({ zip: e.target.value })
                                        }
                                      />
                                      <span className="table-form-label-err mb-0">
                                        {this.state.zipErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Status
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.status}
                                        onChange={(e) =>
                                          this.setState({
                                            status: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="ACTIVE">ACTIVE</option>
                                        <option value="INACTIVE">
                                          INACTIVE
                                        </option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Primary
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.isPrimary}
                                        onChange={(e) =>
                                          this.setState({
                                            isPrimary: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row>
                                  <div>
                                    <Button
                                      className="form-save-btn btn-primary mt-1"
                                      type="button"
                                      onClick={() => {
                                        this.saveAddress();
                                      }}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </Row>
                              </Form>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    <tr
                      onClick={(event) =>
                        this.onClickHandler(
                          { ...this.addressDefault, isNew: "Y", btnClick: "N" },
                          event
                        )
                      }
                    >
                      <td className="td-first">
                        <i className="fa fa-chevron-down"></i>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="td-last"></td>
                    </tr>
                    <tr className="sub-table collapse">
                      <td className="td-first td-last" colSpan="6">
                        <Form>
                          <Row>
                            <div class="col-lg-3 col-6">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  TYPE
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.type}
                                  onChange={(e) =>
                                    this.setState({ type: e.target.value })
                                  }
                                >
                                  <option value="HOME">HOME</option>
                                  <option value="WORK">WORK</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                          </Row>
                          <Row>
                            <div class="col-lg-6 col-6">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Address 1
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={40}
                                  value={this.state.address1}
                                  onChange={(e) =>
                                    this.setState({ address1: e.target.value })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.address1Err}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-6 col-6">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Address 2
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={40}
                                  value={this.state.address2}
                                  onChange={(e) =>
                                    this.setState({ address2: e.target.value })
                                  }
                                />
                              </Form.Group>
                            </div>
                          </Row>
                          <Row>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  City
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  value={this.state.city}
                                  onChange={(e) =>
                                    this.setState({ city: e.target.value })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.cityErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  State
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.state}
                                  onChange={(e) =>
                                    this.setState({ state: e.target.value })
                                  }
                                >
                                  <option selected disabled value="">
                                    {" "}
                                    -- Select State --{" "}
                                  </option>
                                  {USStates.map((USState, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={USState.abbreviation}
                                      >
                                        {USState.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                                <span className="table-form-label-err mb-0">
                                  {this.state.stateErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Country
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.country}
                                  onChange={(e) =>
                                    this.setState({ country: e.target.value })
                                  }
                                >
                                  <option value="US">US</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Zip
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={12}
                                  value={this.state.zip}
                                  onChange={(e) =>
                                    this.setState({ zip: e.target.value })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.zipErr}
                                </span>
                              </Form.Group>
                            </div>
                          </Row>
                          <Row>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  Status
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.status}
                                  onChange={(e) =>
                                    this.setState({ status: e.target.value })
                                  }
                                >
                                  <option value="ACTIVE">ACTIVE</option>
                                  <option value="INACTIVE">INACTIVE</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  Primary
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.isPrimary}
                                  onChange={(e) =>
                                    this.setState({ isPrimary: e.target.value })
                                  }
                                >
                                  <option value="Y">Yes</option>
                                  <option value="N">No</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                          </Row>
                          <Row>
                            <div>
                              <Button
                                className="form-save-btn btn-primary mt-1"
                                type="button"
                                onClick={() => {
                                  this.saveAddress();
                                }}
                              >
                                Save
                              </Button>
                            </div>
                          </Row>
                        </Form>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div class="form-secondary-title d-flex flex-row">
            <div class="col-lg-6">
              <label className="form-main-title">Patient List</label>
            </div>
            <div class="float-end col-lg-6">{addNewPatientButton}</div>
          </div>
          <Table bordered className="table-portal table table-bordered">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Middle Name</th>
                <th>Last Name</th>
                <th>Date of Birth</th>
                <th className="td-last">MRN</th>
              </tr>
            </thead>
            <tbody>
              {patientList.map((record, key) => {
                return (
                  <>
                    <tr
                      key={key}
                      className={key % 2 == 1 ? "even-row" : ""}
                      onClick={() => {
                        this.onClickPatientRow(record);
                      }}
                    >
                      <td className="td-right-border">{record.firstName}</td>
                      <td>{record.middleName}</td>
                      <td>{record.lastName}</td>
                      <td>
                        {record.dob
                          ? moment(
                              formatDateToCustomISO(moment(record.dob).toDate())
                            ).format("MM/DD/YYYY")
                          : ""}
                      </td>
                      <td className="td-last">{record.mrn}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      );
    }

    return (
      <div class="m-2">
        <Modal show={searchGuaPatDupModal} size="lg" centered>
          <Modal.Header>
            <label>Guarantor Already Exist!</label>
          </Modal.Header>
          <Modal.Body>
            <Table bordered className="table-main">
              <thead>
                <tr>
                  <th className="td-first">MRN</th>
                  <th className="td-first">SSN</th>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th className="td-last">Date of Birth</th>
                </tr>
              </thead>
              <tbody>
                {guarantorPatientSearchDuplicateResults.map(
                  (guarantor, key) => {
                    return (
                      <>
                        <tr
                          key={key}
                          onClick={() => {
                            this.props.addUpdateGuarantorSelected(guarantor);

                            this.props.getGuarantorPhoneEmail(guarantor.id);
                            this.props.getGuarantorAddress(guarantor.id);
                            this.props.getGuarantorPatients(guarantor.id);

                            this.setState({ ...guarantor });

                            this.props.guarantorPatientSearchDuplicateClear();
                            this.closeSearchGuaPatDupDialog();
                            console.log("I am here 3");
                          }}
                        >
                          <td className="td-first">{guarantor.mrn}</td>
                          <td>{guarantor.ssn}</td>
                          <td>{guarantor.lastName}</td>
                          <td>{guarantor.firstName}</td>
                          <td className="td-last">{guarantor.dob}</td>
                        </tr>
                      </>
                    );
                  }
                )}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn grid-btn"
              variant="secondary"
              onClick={this.closeSearchGuaPatDupDialog.bind(this)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div class="d-flex">
          <div class="col-lg-5 col-6 d-flex">
            <div>
              <label className="patient-title">Guarantor</label>
            </div>
          </div>
          <div class="col-lg-7 col-6">
            <div className="d-flex float-end m-0 mt-1">
              {cancelBtn}
              {addNewGuarantorButton}
            </div>
          </div>
        </div>
        <div class="d-flex">
          <label className="patient-info-header">Reference Number : </label>
          <label className="patient-info-header">{addedGuarantor.id}</label>
        </div>
        <div class="line-sep mt-2"></div>
        <Row className="mb-1 mt-2">
          <div class="col-lg-4 col-6">
            <Form.Group as={Col} controlId="clientName">
              <Form.Label className="table-form-label mb-0">
                Client Name
              </Form.Label>
              <Form.Select
                className="grid-form-control-select"
                placeholder="Select Client"
                value={this.state.clientId}
                onChange={(e) => {
                  //console.log(e)
                  //this.setState({clientName: e.target.value, selectedClient: e.target.selectedIndex})
                  this.selectClient(e);
                }}
              >
                <option value=""> -- Select Client -- </option>
                {clients.map((client, key) => {
                  return (
                    <option value={client.id} key={key}>
                      {client.name}
                    </option>
                  );
                })}
              </Form.Select>
              <span className="table-form-label-err mb-0">
                {this.state.clientIdErr}
              </span>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-6">
            <Form.Group as={Col} controlId="facility">
              <Form.Label className="table-form-label mb-0">
                Facility
              </Form.Label>
              <Form.Select
                className="grid-form-control-select"
                placeholder="Select Facility"
                onChange={(e) => this.setState({ facilityId: e.target.value })}
                value={this.state.facilityId}
              >
                <option value=""> -- Select Facility --</option>
                {facilities.map((facility, key) => {
                  return (
                    <option value={facility.id} key={key}>
                      {facility.name}
                    </option>
                  );
                })}
              </Form.Select>
              <span className="table-form-label-err mb-0">
                {this.state.facilityIdErr}
              </span>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-6">
            <Form.Group as={Col} controlId="">
              <Form.Label className="table-form-label mb-0">SSN</Form.Label>
              <MaskedFormControl
                className="grid-form-control"
                type="text"
                maxLength={40}
                value={this.state.ssn}
                onChange={(e) => this.setState({ ssn: e.target.value })}
                mask="111-11-1111"
              />
              <span className="table-form-label-err mb-0">
                {this.state.ssnErr}
              </span>
            </Form.Group>
          </div>
          <div class="col-lg-4 col-6">
            <Form.Group as={Col} controlId="">
              <Form.Label className="table-form-label mb-0">
                First Name
              </Form.Label>
              <Form.Control
                className="grid-form-control"
                maxLength={40}
                value={this.state.firstName}
                onChange={(e) => this.setState({ firstName: e.target.value })}
              />
              <span className="table-form-label-err mb-0">
                {this.state.firstNameErr}
              </span>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-6">
            <Form.Group as={Col} controlId="">
              <Form.Label className="table-form-label mb-0">
                Middle Name
              </Form.Label>
              <Form.Control
                className="grid-form-control"
                value={this.state.middleName}
                maxLength={40}
                onChange={(e) => this.setState({ middleName: e.target.value })}
              />
              <span className="table-form-label-err mb-0">
                {this.state.middleNameErr}
              </span>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-6">
            <Form.Group as={Col} controlId="">
              <Form.Label className="table-form-label mb-0">
                Last Name
              </Form.Label>
              <Form.Control
                className="grid-form-control"
                value={this.state.lastName}
                maxLength={40}
                onChange={(e) => this.setState({ lastName: e.target.value })}
              />
              <span className="table-form-label-err mb-0">
                {this.state.lastNameErr}
              </span>
            </Form.Group>
          </div>
          <div className="col-lg-2 col-6 d-flex flex-column">
            <label className="date-picker-label mb-0">Date of Birth</label>
            <DatePicker
              className="date-picker"
              showIcon
              customInput={
                <MaskedInput mask="11/11/1111" placeholder="MM/DD/YYYY" />
              }
              onChange={(date) =>
                this.setState({ dob: formatDateToCustomISO(date) })
              }
              selected={
                this.state.dob
                  ? formatDateToCustomISO(moment(this.state.dob).toDate())
                  : null
              }
            />
            <span className="table-form-label-err mb-0 mb-0">
              {this.state.dobErr}
            </span>
          </div>
          <div className="col-lg-2 col-6">
            <Form.Label className="table-form-label mb-0">Gender:</Form.Label>
            <Form.Select
              className="grid-form-control"
              value={this.state.gender}
              onChange={(e) => this.setState({ gender: e.target.value })}
            >
              <option value="">--Select Gender --</option>
              <option value="M">MALE</option>
              <option value="F">FEMALE</option>
            </Form.Select>
          </div>
          <div className="col-lg-4 col-6">
            <Form.Group as={Col} controlId="">
              <Form.Label className="table-form-label mb-0">MRN</Form.Label>
              <Form.Control
                className="grid-form-control"
                value={this.state.mrn}
                maxLength={40}
                onChange={(e) => this.setState({ mrn: e.target.value })}
              />
            </Form.Group>
          </div>
          <div className="col-lg-4 col-6">
            <div className="d-flex float-end mt-4">
              <Button
                className="form-save-btn ml-2"
                variant="primary"
                disabled={this.state.disabledSaveGuarantor}
                type="button"
                onClick={() => {
                  this.saveGuarantor();
                }}
              >
                <img
                  src={require("../../../images/save-btn-icon.png")}
                  className="add-icon"
                />
                Save
              </Button>
            </div>
          </div>
        </Row>
        {addedGuarantorDetails}
        {addGuarantorToPatient}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    guarantorPatientsSearch: bindActionCreators(
      guarantorPatientsSearch,
      dispatch
    ),
    getGuarantorPatients: bindActionCreators(getGuarantorPatients, dispatch),
    addUpdateGuarantorSelected: bindActionCreators(
      addUpdateGuarantorSelected,
      dispatch
    ),
    addUpdatePatientSelected: bindActionCreators(
      addUpdatePatientSelected,
      dispatch
    ),
    addUpdateGuarantor: bindActionCreators(addUpdateGuarantor, dispatch),
    updateGuarandorsSearchResult: bindActionCreators(
      updateGuarandorsSearchResult,
      dispatch
    ),
    addUpdatePatient: bindActionCreators(addUpdatePatient, dispatch),
    addUpdatePhoneEmail: bindActionCreators(addUpdatePhoneEmail, dispatch),
    addUpdateAddress: bindActionCreators(addUpdateAddress, dispatch),
    getGuarantorPhoneEmail: bindActionCreators(
      getGuarantorPhoneEmail,
      dispatch
    ),
    getGuarantorAddress: bindActionCreators(getGuarantorAddress, dispatch),
    guarantorAddressClear: bindActionCreators(guarantorAddressClear, dispatch),
    guarantorPhoneEmailClear: bindActionCreators(
      guarantorPhoneEmailClear,
      dispatch
    ),
    patientAddressClear: bindActionCreators(patientAddressClear, dispatch),
    patientPhoneEmailClear: bindActionCreators(
      patientPhoneEmailClear,
      dispatch
    ),
    patientInsuranceClear: bindActionCreators(patientInsuranceClear, dispatch),
    patientEncounterClear: bindActionCreators(patientEncounterClear, dispatch),
    documentationDownloadFileClear: bindActionCreators(
      documentationDownloadFileClear,
      dispatch
    ),
    guarantorPatientsClear: bindActionCreators(
      guarantorPatientsClear,
      dispatch
    ),
    getClients: bindActionCreators(getClientsByOrgId, dispatch),
    getFacilities: bindActionCreators(getFacilities, dispatch),
    guarantorPatientSearchDuplicate: bindActionCreators(
      guarantorPatientSearchDuplicate,
      dispatch
    ),
    guarantorPatientSearchDuplicateClear: bindActionCreators(
      guarantorPatientSearchDuplicateClear,
      dispatch
    ),
  };
};

const mapStateToProps = (state) => {
  return {
    addedGuarantor: state.addUpdateGuarantor.data,
    addedPatient: state.addUpdatePatient.data,
    addedPhoneEmail: state.addUpdatePhoneEmail.data,
    addedAddress: state.addUpdateAddress.data,
    patientList: state.getGuarantorPatients.data,
    phoneEmailList: state.getGuarantorPhoneEmail.data,
    addressList: state.getGuarantorAddress.data,
    guarantorPatientsSearchResults:
      state.guarantorPatientsSearch.guarantorPatientsSearchResults,
    menu: state.menu.data,
    clients: state.getClientsByOrgId.data,
    facilities: state.getFacilities.data,
    user: state.currentUser.data,
    guarantorPatientSearchDuplicateResults:
      state.guarantorPatientSearchDuplicate.data,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter(AddGuarantors));
