import * as GetSystemsCodeApiActionConstants from "../../redux/actions/api/systemsCode/GetSystemsCodeApiActionConstants";

const initialState = {
  data: "",
  serviceTypes: [],
  statusCodes: [],
  dispositionCodes: [],
  reasonCodes: [],
  placeOfService: [],
  relation: [],
  financialClass: [],
};

export default function getSystemsCodeReducer(state = initialState, action) {
  switch (action.type) {
    case GetSystemsCodeApiActionConstants.GET_SYSTEMS_CODE_REQUEST:
      return {
        ...state,
      };
    case GetSystemsCodeApiActionConstants.GET_SYSTEMS_CODE_SUCCESS: {
      let serviceTypes = [];
      let statusCodes = [];
      let dispositionCodes = [];
      let reasonCodes = [];
      let placeOfService = [];
      let relation = [];
      let financialClass = [];

      for (let code of action.data) {
        if (code.type == "SERVICE TYPE") serviceTypes.push(code);
        if (code.type == "STATUS CODE") statusCodes.push(code);
        if (code.type == "DISPOSITION CODE") dispositionCodes.push(code);
        if (code.type == "REASON CODE") reasonCodes.push(code);
        if (code.type == "PLACE_OF_SERVICE") placeOfService.push(code);
        if (code.type == "RELATION") relation.push(code);
        if (code.type == "FINANCIAL CLASS") financialClass.push(code);
      }

      serviceTypes.sort(compare);

      return {
        ...state,
        data: action.data,
        serviceTypes: serviceTypes,
        statusCodes: statusCodes,
        dispositionCodes: dispositionCodes,
        reasonCodes: reasonCodes,
        placeOfService: placeOfService,
        relation: relation,
        financialClass: financialClass,
      };
    }
    case GetSystemsCodeApiActionConstants.GET_SYSTEMS_CODE_FAILED:
      return {
        ...state,
        data: "",
      };
    case GetSystemsCodeApiActionConstants.GET_SYSTEMS_CODE_CLEAR:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
}

function compare(a, b) {
  if (a.sortOrder < b.sortOrder) {
    return -1;
  }
  if (a.sortOrder > b.sortOrder) {
    return 1;
  }
  return 0;
}
