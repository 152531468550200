import React, { useState, Component } from "react";
import { Table, Tab, Tabs, Modal } from "react-bootstrap";
import "../../../App.css";
import "../Encounter.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import LeftPanel from "../../common/leftPanel/LeftPanel";
import EncounterInfo from "../encounterInfo/EncounterInfo";
import RightPanel from "../../common/leftPanel/RightPanel";
import LeftPanelMobile from "../../common/leftPanel/LeftPanelMobile";
import PatientInfo from "../common/PatientInfo";
import moment from "moment";

import { Routes, Route } from "react-router-dom";

import {
  addUpdateEncounter,
  addUpdateEncounterSelected,
} from "../../../redux/actions/api/encounter/AddUpdateEncounterApiActions";
import {
  addUpdateAuthorization,
  addUpdateAuthorizationSelected,
} from "../../../redux/actions/api/authorization/AddUpdateAuthorizationApiActions";
import { addUpdateAuthorizationDetail } from "../../../redux/actions/api/authorization/AddUpdateAuthorizationDetailApiActions";

import {
  getAuthorizationDetail,
  getAuthorizationDetailSelected,
} from "../../../redux/actions/api/authorization/GetAuthorizationDetailApiActions";

import getPayersFindAll from "../../../redux/actions/api/payer/GetPayersApiActions";

import { getSystemsCode } from "../../../redux/actions/api/systemsCode/GetSystemsCodeApiActions";
import getUser from "../../../redux/actions/api/authentication/GetUserApiActions";
import { addUpdateEmployee } from "../../../redux/actions/api/employee/AddUpdateEmployeeApiActions";
import { WithRouter } from "../../common/WithRouter";
import ReactDatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import { formatDateToCustomISO } from "../../common/UtilsDate";

class Encounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      orgId: "",
      clientId: "",

      accountNumber: "",
      visitDate: "",
      visitTime: "",
      orderNumber: "",

      requestedDate: "",
      preAuthStatus: "NEW",
      serviceType: "",
      quantity: "",
      frequency: "",
      levelOfService: "",
      serviceDate: "",
      reasonForExam: "",
      payerId: "",
    };
  }

  ninetyDaysChangePassword = async () => {
    let userInfo = this.props.user;
    if (!userInfo.passwordChanged) {
      userInfo.passwordChanged = new Date();
      await this.props.addUpdateEmployee(userInfo);
      await this.props.getUser(userInfo.userId);
    } else {
      console.log("iamhere");
      let passwordChanged = new Date(userInfo.passwordChanged);
      let ninetyDaysFromNow = new Date();
      ninetyDaysFromNow.setDate(ninetyDaysFromNow.getDate() - 90);
      if (passwordChanged < ninetyDaysFromNow) {
        alert("Your password has expired. Please create a new password.");
        this.props.navigate("/change-password");
      }
    }
  };

  async componentDidMount() {
    if (!this.props.user) {
      let userId = sessionStorage.getItem("userId");
      await this.props.getUser(userId);
    }

    await this.ninetyDaysChangePassword();

    const { selectedEncounter, selectedOrder } = this.props;

    if (selectedEncounter) {
      this.setState({ ...selectedEncounter });
    }

    if (selectedOrder) {
      this.setState({ ...selectedOrder });
      this.props.getOrderDetails(selectedOrder.id);
      console.log("I am here !!!! selectedOrder.id", selectedOrder);
    }

    await this.props.getPayersFindAll();
    await this.props.getSystemsCode();
  }

  encounterDefault = {
    id: "",
    orgId: "",
    clientId: "",

    idEncounter: "",
    accountNumber: "",
    visitDate: "",
    visitTime: "",
    orderNumber: "",
  };

  orderDefault = {
    requestedDate: "",
    preAuthStatus: "NEW",
    serviceType: "",
    quantity: "",
    frequency: "",
    levelOfService: "",
    serviceDate: "",
    reasonForExam: "",
    payerId: "",
  };

  clearEncounterList = () => {
    this.setState(this.encounterDefault);
    this.setState(this.orderDefault);
  };

  addNewEncounterClick = () => {
    this.props.addUpdateEncounterSelected({});
    this.props.addUpdateOrderSelected({});
    this.props.getOrderDetailsSelected([]);
    this.clearEncounterList();
  };

  saveEncounter = async () => {
    const { addedPatient, selectedEncounter } = this.props;

    var encounter = {
      ...this.state,
      orgId: addedPatient.orgId,
      clientId: addedPatient.clientId,
      id: selectedEncounter.id,
      patientId: addedPatient.id,
    };
    console.log(encounter);
    await this.props.addUpdateEncounter(encounter);
  };

  saveOrder = async () => {
    var { addedPatient, selectedEncounter, selectedOrder } = this.props;
    console.log("selectedOrder", selectedOrder);
    if (!selectedOrder) {
      selectedOrder = {};
    }
    var order = {
      ...this.state,
      orgId: addedPatient.orgId,
      clientId: addedPatient.clientId,
      id: selectedOrder.id,
      encounterId: selectedEncounter.id,
      patientId: addedPatient.id,
      type: "ORDER",
    };
    console.log("order", order);
    await this.props.addUpdateOrder(order);

    this.saveOrderDetail(order);
  };

  saveOrderDetail = async (order) => {
    const { selectedOrder, orderDetails } = this.props;
    console.log("selectedOrder.id", selectedOrder.id);
    if (!selectedOrder.id || selectedOrder.id == undefined) {
      this.saveOrderDetail(order);
    }

    if (!orderDetails || orderDetails.length == 0) {
      console.log(orderDetails);
      var orderDetail = {
        ...order,
        id: "",
        parentId: selectedOrder.id,
        type: "ORDER_DETAIL",
      };
      console.log(order);
      await this.props.addUpdateOrderDetail(orderDetail);
      await this.props.getOrderDetails(selectedOrder.id);
    }
  };

  onClickHandler(record, e) {
    console.log("I am here 2");
    var { btnClick } = this.state;

    console.log("test " + btnClick);

    if (btnClick == "N") {
      console.log("I am here 3");

      //
    }
    console.log(record);
    this.setState(record);

    const elementsShow = document.querySelectorAll("tr.main-table");
    const hiddenElement = e.currentTarget.nextSibling;

    /*Reset all show details*/
    elementsShow.forEach((element) => {
      if (hiddenElement != element) {
        element.classList.remove("show");
      }
    });

    /*Set selected row*/
    const selected = e.currentTarget;
    const elementsSelected = document.querySelectorAll("tr.selected-table-row");

    elementsSelected.forEach((element) => {
      if (selected != element) {
        element.classList.remove("selected-table-row");
      }
    });

    selected.classList.add("selected-table-row");

    /*Reset all up arrow*/
    const elementsArrow = document.querySelectorAll(".fa-chevron-up");
    elementsArrow.forEach((element) => {
      element.classList.remove("fa-chevron-up");
      element.classList.add("fa-chevron-down");
    });

    /*set up and show details on selected row*/
    const arrowSelected = document.querySelectorAll(".selected-table-row .fa");

    if (hiddenElement.className.indexOf("collapse show") > -1) {
      hiddenElement.classList.remove("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });
    } else {
      hiddenElement.classList.add("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-down");
        element.classList.add("fa-chevron-up");
      });
    }
    //}

    //this.setState({btnClick:'N'});
  }

  onClickHandlerSubTable(record, e) {
    console.log("I am here 2");
    var { btnClick } = this.state;

    console.log("test " + btnClick);

    if (btnClick == "N") {
      console.log("I am here 3");

      //
    }
    console.log(record);
    //this.setState(record);

    const elementsShow = document.querySelectorAll("tr.sub-table");
    const hiddenElement = e.currentTarget.nextSibling;

    /*Reset all show details*/
    elementsShow.forEach((element) => {
      if (hiddenElement != element) {
        element.classList.remove("show");
      }
    });

    /*Set selected row*/
    const selected = e.currentTarget;
    const elementsSelected = document.querySelectorAll("tr.selected-table-row");

    elementsSelected.forEach((element) => {
      if (selected != element) {
        element.classList.remove("selected-table-row");
      }
    });

    selected.classList.add("selected-table-row");

    /*Reset all up arrow*/
    const elementsArrow = document.querySelectorAll(".fa-chevron-up");
    elementsArrow.forEach((element) => {
      element.classList.remove("fa-chevron-up");
      element.classList.add("fa-chevron-down");
    });

    /*set up and show details on selected row*/
    const arrowSelected = document.querySelectorAll(".selected-table-row .fa");

    if (hiddenElement.className.indexOf("collapse show") > -1) {
      hiddenElement.classList.remove("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });
    } else {
      hiddenElement.classList.add("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-down");
        element.classList.add("fa-chevron-up");
      });
    }
    //}

    //this.setState({btnClick:'N'});
  }

  render() {
    var { orderDetails, selectedEncounter, selectedOrder } = this.props;

    if (!orderDetails) {
      orderDetails = [];
    }
    var hideOrder = { display: "block" };
    if (!selectedEncounter || selectedEncounter.id == undefined) {
      hideOrder = { display: "none" };
    }

    var hideOrderDetail = { display: "block" };
    if (!selectedOrder || selectedOrder.id == undefined) {
      hideOrderDetail = { display: "none" };
    }

    var MaskDateInput = [
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    return (
      <div className="w-100">
        <div className="auth-content-header d-flex justify-content-between">
          <div className="d-flex">
            <h5>
              <img alt="#" src={require("../../../images/AuthoFiLogo.jpg")} />
            </h5>
          </div>
          <div className="d-flex justify-content-end">
            <RightPanel />
          </div>
        </div>
        <div className="auth-content-main d-flex align-items-center justify-content-center">
          <div className="auth-content-content p-2">
            <div>
              <Form>
                <Row>
                  <div className="col-lg-3 col-6">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Order Number
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        value={this.state.orderNumber}
                        onChange={(e) =>
                          this.setState({ orderNumber: e.target.value })
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-3 col-6 d-flex">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Requested Date
                      </Form.Label>
                      <ReactDatePicker
                        className="date-picker"
                        calendarIconClassname="date-picker-icon"
                        placeholderText="MM/DD/YYYY"
                        showIcon
                        // value={moment(this.state.requestedDate).format(
                        //   "YYYY-MM-DD"
                        // )}
                        onChange={(date) =>
                          this.setState({
                            requestedDate: formatDateToCustomISO(date),
                          })
                        }
                        selected={
                          this.state.requestedDate
                            ? formatDateToCustomISO(
                                moment(this.state.requestedDate).toDate()
                              )
                            : null
                        }
                        customInput={
                          <MaskedTextInput type="text" mask={MaskDateInput} />
                        }
                      />
                    </Form.Group>
                  </div>
                  <div class="col-lg-3 col-6">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Pre-Auth Status
                      </Form.Label>
                      <Form.Select className="grid-form-control">
                        <option disabled selected value>
                          {" "}
                          -- Select Status --{" "}
                        </option>
                        <option value="New">NEW</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-lg-3 col-6">
                    <Form.Group as={Col} controlId="authStatus">
                      <Form.Label className="table-form-label mb-0">
                        Disposition Code
                      </Form.Label>
                      <Form.Control className="grid-form-control" />
                    </Form.Group>
                  </div>
                </Row>
                <Row>
                  <div className="col-lg-3 col-6">
                    <Form.Group as={Col} controlId="accountNumber">
                      <Form.Label className="table-form-label mb-0">
                        Payer
                      </Form.Label>
                      <Form.Select
                        className="grid-form-control"
                        value={this.state.payerId}
                        onChange={(e) =>
                          this.setState({ payerId: e.target.value })
                        }
                      >
                        <option value=""> -- Select Payer -- </option>
                        {this.props.payers.map((payer, key) => {
                          return (
                            <option key={key} value={payer.id}>
                              {payer.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-lg-3 col-6">
                    <Form.Group as={Col} controlId="authStatus">
                      <Form.Label className="table-form-label mb-0">
                        Member ID
                      </Form.Label>
                      <Form.Control className="grid-form-control" />
                    </Form.Group>
                  </div>
                  <div className="col-lg-3 col-6">
                    <Form.Group as={Col} controlId="authStatus">
                      <Form.Label className="table-form-label mb-0">
                        Relationship to Subscriber
                      </Form.Label>
                      <Form.Control className="grid-form-control" />
                    </Form.Group>
                  </div>
                </Row>
                <Row className="mt-2">
                  <div class="col-lg-3 col-6 d-flex">
                    <label className="encounter-form-label mb-0">
                      Request Type
                    </label>
                    <Form.Group as={Col} controlId="">
                      <Form.Select className="grid-form-control">
                        <option disabled selected value>
                          {" "}
                          -- Select Type --{" "}
                        </option>
                        <option value="IN-PATIENT">IN-PATIENT</option>
                        <option value="OUT-PATIENT">OUT-PATIENT</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Row>
                <Row>
                  <div class="col-lg-2 col-6">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Requesting Provider
                      </Form.Label>
                      <Form.Select className="grid-form-control">
                        <option disabled selected value>
                          {" "}
                          -- Select Provider --{" "}
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-lg-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        NPI
                      </Form.Label>
                      <Form.Control className="grid-form-control" />
                    </Form.Group>
                  </div>
                  <div className="col-lg-2 col-6">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Speciality/ Taxonomy
                      </Form.Label>
                      <Form.Control className="grid-form-control" />
                    </Form.Group>
                  </div>
                  <div className="col-lg-2 col-6">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact First Name
                      </Form.Label>
                      <Form.Control className="grid-form-control" />
                    </Form.Group>
                  </div>
                  <div className="col-lg-2">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact Last Name
                      </Form.Label>
                      <Form.Control className="grid-form-control" />
                    </Form.Group>
                  </div>
                  <div className="col-lg-2 col-6 ">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact Phone
                      </Form.Label>
                      <Form.Control className="grid-form-control" />
                    </Form.Group>
                  </div>
                  <div className="col-lg-1 col-6">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact Fax
                      </Form.Label>
                      <Form.Control className="grid-form-control" />
                    </Form.Group>
                  </div>
                </Row>
                <Row>
                  <div>
                    <Button
                      className="form-save-btn btn-primary mt-2"
                      type="button"
                      onClick={() => {
                        this.saveOrder();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </Row>
              </Form>
            </div>
            <div>
              <Table bordered className="table-main mt-3">
                <thead>
                  <tr>
                    <th className="td-first"></th>
                    <th>Requested Date</th>
                    <th>Order Status</th>
                    <th>Reason for Exam</th>
                    <th>Service Date</th>
                    <th className="td-last">Level of Service</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails.map((record, key) => {
                    return (
                      <>
                        <tr
                          onClick={(event) =>
                            this.onClickHandler(
                              { ...record, isNew: "N", btnClick: "N" },
                              event
                            )
                          }
                        >
                          <td className="td-first">
                            <i className="fa fa-chevron-down"></i>
                          </td>
                          <td>
                            {moment(record.requestedDate).format("MM/DD/YYYY")}
                          </td>
                          <td>{record.preAuthStatus}</td>
                          <td>{record.reasonForExam}</td>
                          <td>
                            {moment(record.serviceDate).format("MM/DD/YYYY")}
                          </td>
                          <td className="td-last">{record.levelOfService}</td>
                        </tr>
                        <tr className="main-table collapse">
                          <td className="td-first td-last" colSpan="6">
                            <Form>
                              <Row className="mb-1">
                                <div className="col-lg-3 col-6">
                                  <Form.Group
                                    as={Col}
                                    controlId="accountNumber"
                                  >
                                    <Form.Label className="table-form-label mb-0">
                                      Requested Date
                                    </Form.Label>
                                    <ReactDatePicker
                                      className="date-picker"
                                      calendarIconClassname="date-picker-icon"
                                      placeholderText="MM/DD/YYYY"
                                      showIcon
                                      // value={moment(
                                      //   this.state.requestedDate
                                      // ).format("YYYY-MM-DD")}
                                      onChange={(date) =>
                                        this.setState({
                                          requestedDate:
                                            formatDateToCustomISO(date),
                                        })
                                      }
                                      selected={
                                        this.state.requestedDate
                                          ? formatDateToCustomISO(
                                              moment(
                                                this.state.requestedDate
                                              ).toDate()
                                            )
                                          : null
                                      }
                                      customInput={
                                        <MaskedTextInput
                                          type="text"
                                          mask={MaskDateInput}
                                        />
                                      }
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-lg-3 col-6">
                                  <Form.Group as={Col} controlId="orderNumber">
                                    <Form.Label className="table-form-label mb-0">
                                      Order Status
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control-select"
                                      placeholder="Order Status"
                                      value={this.state.preAuthStatus}
                                    >
                                      <option value="NEW">NEW</option>
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                                <div className="col-lg-3 col-6">
                                  <Form.Group as={Col} controlId="visitDate">
                                    <Form.Label className="table-form-label mb-0">
                                      Service Type
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.serviceType}
                                    >
                                      <option value="">
                                        {" "}
                                        -- Select Service Type --{" "}
                                      </option>
                                      {this.props.serviceTypes.map(
                                        (serviceType, key) => {
                                          return (
                                            <option
                                              key={key}
                                              value={serviceType.code}
                                            >
                                              {serviceType.code +
                                                " - " +
                                                serviceType.description}
                                            </option>
                                          );
                                        }
                                      )}
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                                <div className="col-lg-3 col-6">
                                  <Form.Group as={Col} controlId="authStatus">
                                    <Form.Label className="table-form-label mb-0">
                                      Quantity
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      value={this.state.quantity}
                                    />
                                  </Form.Group>
                                </div>
                              </Row>
                              <Row className="mb-1">
                                <div className="col-lg-3 col-6">
                                  <Form.Group
                                    as={Col}
                                    controlId="accountNumber"
                                  >
                                    <Form.Label className="table-form-label mb-0">
                                      Frequency
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      value={this.state.frequency}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-lg-3 col-6">
                                  <Form.Group as={Col} controlId="orderNumber">
                                    <Form.Label className="table-form-label mb-0">
                                      Level of Service
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.levelOfService}
                                    >
                                      <option value="">
                                        {" "}
                                        -- Select Level of Service --{" "}
                                      </option>
                                      <option value="ELECTIVE">Elective</option>
                                      <option value="EMERGENCY">
                                        Emergency
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                                <div className="col-lg-3 col-6">
                                  <Form.Group as={Col} controlId="visitDate">
                                    <Form.Label className="table-form-label mb-0">
                                      Service Date
                                    </Form.Label>
                                    <ReactDatePicker
                                      className="date-picker"
                                      calendarIconClassname="date-picker-icon"
                                      placeholderText="MM/DD/YYYY"
                                      showIcon
                                      // value={moment(
                                      //   this.state.requestedDate
                                      // ).format("YYYY-MM-DD")}
                                      onChange={(date) =>
                                        this.setState({
                                          requestedDate:
                                            formatDateToCustomISO(date),
                                        })
                                      }
                                      selected={
                                        this.state.requestedDate
                                          ? formatDateToCustomISO(
                                              moment(
                                                this.state.requestedDate
                                              ).toDate()
                                            )
                                          : null
                                      }
                                      customInput={
                                        <MaskedTextInput
                                          type="text"
                                          mask={MaskDateInput}
                                        />
                                      }
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-lg-3 col-6">
                                  <Form.Group as={Col} controlId="authStatus">
                                    <Form.Label className="table-form-label mb-0">
                                      Reason for Exam
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      value={this.state.reasonForExam}
                                    />
                                  </Form.Group>
                                </div>
                              </Row>
                              <Row className="mb-1">
                                <div className="col-lg-3 col-6">
                                  <Form.Group
                                    as={Col}
                                    controlId="accountNumber"
                                  >
                                    <Form.Label className="table-form-label mb-0">
                                      Payer
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.payerId}
                                    >
                                      <option value="">
                                        {" "}
                                        -- Select Payer --{" "}
                                      </option>
                                      {this.props.payers.map((payer, key) => {
                                        return (
                                          <option key={key} value={payer.id}>
                                            {payer.name}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                              </Row>

                              <Row>
                                <div>
                                  <Button
                                    className="form-save-btn btn-primary mt-1"
                                    type="button"
                                    onClick={() => {
                                      this.saveOrder();
                                    }}
                                  >
                                    Save
                                  </Button>
                                </div>
                              </Row>
                            </Form>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  <tr
                    onClick={(event) =>
                      this.onClickHandler(
                        { ...this.insuranceDefault, isNew: "Y", btnClick: "N" },
                        event
                      )
                    }
                  >
                    <td className="td-first">
                      <i className="fa fa-chevron-down"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="main-table collapse">
                    <td className="td-first td-last" colSpan="6">
                      <Form>
                        <Row>
                          <div className="col-lg-3 col-6">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Order Number
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                value={this.state.orderNumber}
                                onChange={(e) =>
                                  this.setState({ orderNumber: e.target.value })
                                }
                              />
                            </Form.Group>
                          </div>
                          <div className="col-lg-3 col-6 d-flex">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Requested Date
                              </Form.Label>
                              <ReactDatePicker
                                className="date-picker"
                                calendarIconClassname="date-picker-icon"
                                placeholderText="MM/DD/YYYY"
                                showIcon
                                // value={moment(
                                //   this.state.requestedDate
                                // ).format("YYYY-MM-DD")}
                                onChange={(date) =>
                                  this.setState({
                                    requestedDate: formatDateToCustomISO(date),
                                  })
                                }
                                selected={
                                  this.state.requestedDate
                                    ? formatDateToCustomISO(
                                        moment(
                                          this.state.requestedDate
                                        ).toDate()
                                      )
                                    : null
                                }
                                customInput={
                                  <MaskedTextInput
                                    type="text"
                                    mask={MaskDateInput}
                                  />
                                }
                              />
                            </Form.Group>
                          </div>
                          <div class="col-lg-3 col-6">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Pre-Auth Status
                              </Form.Label>
                              <Form.Select className="grid-form-control">
                                <option disabled selected value>
                                  {" "}
                                  -- Select Status --{" "}
                                </option>
                                <option value="New">NEW</option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3 col-6">
                            <Form.Group as={Col} controlId="authStatus">
                              <Form.Label className="table-form-label mb-0">
                                Disposition Code
                              </Form.Label>
                              <Form.Control className="grid-form-control" />
                            </Form.Group>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-lg-3 col-6">
                            <Form.Group as={Col} controlId="accountNumber">
                              <Form.Label className="table-form-label mb-0">
                                Payer
                              </Form.Label>
                              <Form.Select
                                className="grid-form-control"
                                value={this.state.payerId}
                                onChange={(e) =>
                                  this.setState({ payerId: e.target.value })
                                }
                              >
                                <option value=""> -- Select Payer -- </option>
                                {this.props.payers.map((payer, key) => {
                                  return (
                                    <option key={key} value={payer.id}>
                                      {payer.name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3 col-6">
                            <Form.Group as={Col} controlId="authStatus">
                              <Form.Label className="table-form-label mb-0">
                                Member ID
                              </Form.Label>
                              <Form.Control className="grid-form-control" />
                            </Form.Group>
                          </div>
                          <div className="col-lg-3 col-6">
                            <Form.Group as={Col} controlId="authStatus">
                              <Form.Label className="table-form-label mb-0">
                                Relationship to Subscriber
                              </Form.Label>
                              <Form.Control className="grid-form-control" />
                            </Form.Group>
                          </div>
                        </Row>
                        <Row>
                          <div>
                            <Button
                              className="form-save-btn btn-primary mt-1"
                              type="button"
                              onClick={() => {
                                this.saveOrder();
                              }}
                            >
                              Save
                            </Button>
                          </div>
                        </Row>
                        <div class="form-title-label mt-3">
                          <label className="border-bottom">
                            Attending Provider
                          </label>
                        </div>
                        <Table bordered className="mt-2 table-sub">
                          <thead>
                            <tr>
                              <th className="td-first"></th>
                              <th>Role</th>
                              <th>Payer</th>
                              <th>Name</th>
                              <th className="td-last">Speciality/ Taxonomy</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              onClick={(event) =>
                                this.onClickHandlerSubTable(
                                  { isNew: "N", btnClick: "N" },
                                  event
                                )
                              }
                            >
                              <td className="td-first">
                                <i className="fa fa-chevron-down"></i>
                              </td>
                              <td>Test</td>
                              <td>Test</td>
                              <td>Test</td>
                              <td className="td-last">Test</td>
                            </tr>
                            <tr className="sub-table collapse">
                              <td className="td-first td-last" colSpan="5">
                                <Form>
                                  <Row>
                                    <div class="col-lg-2 col-6">
                                      <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">
                                          Role
                                        </Form.Label>
                                        <Form.Select className="grid-form-control">
                                          <option disabled selected value>
                                            {" "}
                                            -- Select Role --{" "}
                                          </option>
                                        </Form.Select>
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-2 col-6">
                                      <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">
                                          Payer
                                        </Form.Label>
                                        <Form.Select
                                          className="grid-form-control"
                                          value={this.state.payerId}
                                          onChange={(e) =>
                                            this.setState({
                                              payerId: e.target.value,
                                            })
                                          }
                                        >
                                          <option value="">
                                            {" "}
                                            -- Select Payer --{" "}
                                          </option>
                                          {this.props.payers.map(
                                            (payer, key) => {
                                              return (
                                                <option
                                                  key={key}
                                                  value={payer.id}
                                                >
                                                  {payer.name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </Form.Select>
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-2 col-6">
                                      <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">
                                          First Name
                                        </Form.Label>
                                        <Form.Control className="grid-form-control" />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-2 col-6">
                                      <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">
                                          Last Name
                                        </Form.Label>
                                        <Form.Control className="grid-form-control" />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-2 col-6">
                                      <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">
                                          NPI
                                        </Form.Label>
                                        <Form.Control className="grid-form-control" />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-2 col-6">
                                      <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">
                                          Speciality/ Taxonomy
                                        </Form.Label>
                                        <Form.Control className="grid-form-control" />
                                      </Form.Group>
                                    </div>
                                  </Row>
                                </Form>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <div className="row">
                          <div className="col-4">
                            <div class="form-title-label mt-2">
                              <label className="border-bottom">Diagnosis</label>
                            </div>
                            <Table bordered className="mt-2 table-sub">
                              <thead>
                                <tr>
                                  <th className="td-first"></th>
                                  <th>Diagnosis Code</th>
                                  <th className="td-last">
                                    Diagnosis Description
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  onClick={(event) =>
                                    this.onClickHandlerSubTable(
                                      { isNew: "N", btnClick: "N" },
                                      event
                                    )
                                  }
                                >
                                  <td className="td-first">
                                    <i className="fa fa-chevron-down"></i>
                                  </td>
                                  <td>Test</td>
                                  <td className="td-last">Test</td>
                                </tr>
                                <tr className="sub-table collapse">
                                  <td className="td-first td-last" colSpan="3">
                                    <Form>
                                      <Row>
                                        <div class="col-lg-6 col-6">
                                          <Form.Group as={Col} controlId="">
                                            <Form.Label className="table-form-label mb-0">
                                              Diagnosis Code
                                            </Form.Label>
                                            <Form.Select className="grid-form-control">
                                              <option disabled selected value>
                                                {" "}
                                                -- Select Code --{" "}
                                              </option>
                                            </Form.Select>
                                          </Form.Group>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                          <Form.Group as={Col} controlId="">
                                            <Form.Label className="table-form-label mb-0">
                                              Diagnosis Description
                                            </Form.Label>
                                            <Form.Control className="grid-form-control" />
                                          </Form.Group>
                                        </div>
                                      </Row>
                                    </Form>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                          <div className="col-8">
                            <div class="form-title-label mt-2">
                              <label className="border-bottom">
                                Procedures
                              </label>
                            </div>
                            <Table bordered className="mt-2 table-sub">
                              <thead>
                                <tr>
                                  <th className="td-first"></th>
                                  <th>Service Type</th>
                                  <th className="td-last">Place of Service</th>
                                  <th className="td-last">From Date</th>
                                  <th className="td-last">To Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  onClick={(event) =>
                                    this.onClickHandlerSubTable(
                                      { isNew: "N", btnClick: "N" },
                                      event
                                    )
                                  }
                                >
                                  <td className="td-first">
                                    <i className="fa fa-chevron-down"></i>
                                  </td>
                                  <td>Test</td>
                                  <td>Test</td>
                                  <td>Test</td>
                                  <td className="td-last">Test</td>
                                </tr>
                                <tr className="sub-table collapse">
                                  <td className="td-first td-last" colSpan="5">
                                    <Form>
                                      <Row>
                                        <div className="col-lg-3 col-6">
                                          <Form.Group
                                            as={Col}
                                            controlId="visitDate"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              Service Type
                                            </Form.Label>
                                            <Form.Select
                                              className="grid-form-control"
                                              value={this.state.serviceType}
                                              onChange={(e) =>
                                                this.setState({
                                                  serviceType: e.target.value,
                                                })
                                              }
                                            >
                                              <option value="">
                                                {" "}
                                                -- Select Service Type --{" "}
                                              </option>
                                              {this.props.serviceTypes.map(
                                                (serviceType, key) => {
                                                  return (
                                                    <option
                                                      key={key}
                                                      value={serviceType.code}
                                                    >
                                                      {serviceType.code +
                                                        " - " +
                                                        serviceType.description}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </Form.Select>
                                          </Form.Group>
                                        </div>
                                        <div class="col-lg-3 col-6">
                                          <Form.Group as={Col} controlId="">
                                            <Form.Label className="table-form-label mb-0">
                                              Place of Service
                                            </Form.Label>
                                            <Form.Select className="grid-form-control">
                                              <option disabled selected value>
                                                {" "}
                                                -- Select Service --{" "}
                                              </option>
                                            </Form.Select>
                                          </Form.Group>
                                        </div>
                                        <div class="col-lg-3">
                                          <label className="table-form-label mb-0">
                                            Authentication is Required
                                          </label>
                                          <Form.Group
                                            className="d-flex"
                                            as={Col}
                                            controlId=""
                                          >
                                            <Form.Check
                                              className="grid-form-control mt-1 w-50"
                                              label="Yes"
                                            />
                                            <Form.Check
                                              className="grid-form-control mt-1 w-50"
                                              label="No"
                                            />
                                          </Form.Group>
                                        </div>
                                      </Row>
                                      <Row>
                                        <div className="col-lg-3 col-6">
                                          <Form.Group
                                            as={Col}
                                            controlId="visitDate"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              From Date
                                            </Form.Label>
                                            <Form.Control
                                              className="grid-form-control"
                                              placeholder="Requested Date"
                                              type="Date"
                                            />
                                          </Form.Group>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                          <Form.Group
                                            as={Col}
                                            controlId="visitDate"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              To Date
                                            </Form.Label>
                                            <Form.Control
                                              className="grid-form-control"
                                              placeholder="Requested Date"
                                              type="Date"
                                            />
                                          </Form.Group>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                          <Form.Group as={Col} controlId="">
                                            <Form.Label className="table-form-label mb-0">
                                              Quantity
                                            </Form.Label>
                                            <Form.Control
                                              className="grid-form-control"
                                              value={this.state.quantity}
                                              onChange={(e) =>
                                                this.setState({
                                                  quantity: e.target.value,
                                                })
                                              }
                                            />
                                          </Form.Group>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                          <Form.Group as={Col} controlId="">
                                            <Form.Label className="table-form-label mb-0">
                                              Unit Measure
                                            </Form.Label>
                                            <Form.Control className="grid-form-control" />
                                          </Form.Group>
                                        </div>
                                      </Row>
                                      <Row className="mb-1">
                                        <div className="col-lg-3 col-6">
                                          <Form.Group
                                            as={Col}
                                            controlId="orderNumber"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              Level of Service
                                            </Form.Label>
                                            <Form.Select
                                              className="grid-form-control"
                                              value={this.state.levelOfService}
                                              onChange={(e) =>
                                                this.setState({
                                                  levelOfService:
                                                    e.target.value,
                                                })
                                              }
                                            >
                                              <option value="">
                                                {" "}
                                                -- Select Level of Service --{" "}
                                              </option>
                                              <option value="ELECTIVE">
                                                Elective
                                              </option>
                                              <option value="EMERGENCY">
                                                Emergency
                                              </option>
                                            </Form.Select>
                                          </Form.Group>
                                        </div>

                                        <div className="col-lg-3 col-6">
                                          <Form.Group
                                            as={Col}
                                            controlId="accountNumber"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              Frequency
                                            </Form.Label>
                                            <Form.Control
                                              className="grid-form-control"
                                              value={this.state.frequency}
                                              onChange={(e) =>
                                                this.setState({
                                                  frequency: e.target.value,
                                                })
                                              }
                                            />
                                          </Form.Group>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                          <Form.Group
                                            as={Col}
                                            controlId="authStatus"
                                          >
                                            <Form.Label className="table-form-label mb-0">
                                              Reason for Exam
                                            </Form.Label>
                                            <Form.Control
                                              className="grid-form-control"
                                              value={this.state.reasonForExam}
                                              onChange={(e) =>
                                                this.setState({
                                                  reasonForExam: e.target.value,
                                                })
                                              }
                                            />
                                          </Form.Group>
                                        </div>
                                      </Row>
                                    </Form>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Form>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUpdateEncounter: bindActionCreators(addUpdateEncounter, dispatch),
    addUpdateEncounterSelected: bindActionCreators(
      addUpdateEncounterSelected,
      dispatch
    ),
    addUpdateOrder: bindActionCreators(addUpdateAuthorization, dispatch),
    addUpdateOrderSelected: bindActionCreators(
      addUpdateAuthorizationSelected,
      dispatch
    ),
    getPayersFindAll: bindActionCreators(getPayersFindAll, dispatch),
    getOrderDetails: bindActionCreators(getAuthorizationDetail, dispatch),
    getOrderDetailsSelected: bindActionCreators(
      getAuthorizationDetailSelected,
      dispatch
    ),
    addUpdateOrderDetail: bindActionCreators(
      addUpdateAuthorizationDetail,
      dispatch
    ),
    getSystemsCode: bindActionCreators(getSystemsCode, dispatch),
    getUser: bindActionCreators(getUser, dispatch),
    addUpdateEmployee: bindActionCreators(addUpdateEmployee, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    addedPatient: state.addUpdatePatient.data,
    selectedEncounter: state.addUpdateEncounter.data,
    selectedOrder: state.addUpdateAuthorization.data,
    serviceTypes: state.getSystemsCode.serviceTypes,
    payers: state.getPayersAll.data,
    orderDetails: state.getAuthorizationDetail.data,
    user: state.currentUser.data,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter(Encounter));
