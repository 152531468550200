import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

class PatientInfoModal extends Component {
  state = { result: { isOpen: false } };

  closeModal = () => {
    let result = { isOpen: false };
    this.props.result(result);
  };

  componentDidMount() {
    console.log(this.props.data);
  }

  render() {
    console.log(this.props.data);
    return (
      <Modal show={this.props.data.isOpen} size="lg" className="portal-dialog">
        <Modal.Body>
          <div>
            <div className="portal-modal-header">
              <label>Patient Information</label>
            </div>
            <div className="row mt-2">
              <div className="col-lg-4">
                <Form.Group as={Col} controlId="">
                  <Form.Label className="table-form-label mb-0">
                    First Name
                  </Form.Label>
                  <Form.Control className="grid-form-control" />
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group as={Col} controlId="">
                  <Form.Label className="table-form-label mb-0">
                    Last Name
                  </Form.Label>
                  <Form.Control className="grid-form-control" />
                </Form.Group>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-4">
                <Form.Group as={Col} controlId="">
                  <Form.Label className="table-form-label mb-0">
                    Patient Sex
                  </Form.Label>
                  <Form.Control className="grid-form-control" />
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group as={Col} controlId="">
                  <Form.Label className="table-form-label mb-0">
                    To Date
                  </Form.Label>
                  <Form.Control type="date" className="grid-form-control" />
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group as={Col} controlId="">
                  <Form.Label className="table-form-label mb-0">MRN</Form.Label>
                  <Form.Control className="grid-form-control" />
                </Form.Group>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-4">
                <Form.Group as={Col} controlId="">
                  <Form.Label className="table-form-label mb-0">
                    Insurance Name
                  </Form.Label>
                  <Form.Select className="grid-form-control">
                    <option selected disabled value="">
                      {" "}
                      -- Select Insurance Name --{" "}
                    </option>
                    <option value="SAME AS PARENT">Same as parent</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group as={Col} controlId="">
                  <Form.Label className="table-form-label mb-0">
                    Insurance Id Number
                  </Form.Label>
                  <Form.Control className="grid-form-control" />
                </Form.Group>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-4">
                <Form.Group as={Col} controlId="">
                  <Form.Label className="table-form-label mb-0">
                    Relation to Subscriber
                  </Form.Label>
                  <Form.Control className="grid-form-control" />
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group as={Col} controlId="">
                  <Form.Label className="table-form-label mb-0">
                    Organization
                  </Form.Label>
                  <Form.Control className="grid-form-control" />
                </Form.Group>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            type="button"
            onClick={this.closeModal.bind(this)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PatientInfoModal;
