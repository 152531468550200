import React, { useState, Component, useEffect } from "react";
import "../../App.css";
import "./Reports.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LeftPanel from "../common/leftPanel/LeftPanel";
import RightPanel from "../common/leftPanel/RightPanel";
import LeftPanelMobile from "../common/leftPanel/LeftPanelMobile";
import "font-awesome/css/font-awesome.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Table, Tab, Tabs } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import getUser from "../../redux/actions/api/authentication/GetUserApiActions";
import { addUpdateEmployee } from "../../redux/actions/api/employee/AddUpdateEmployeeApiActions";
import { WithRouter } from "../common/WithRouter";

interface IClient {
  id: number;
  clientName: string;
  prefix: string;
  suffix?: string;
}

const clients: IClient[] = [
  { id: 1, clientName: "Au Health", prefix: "" },
  { id: 2, clientName: "Doctors Hospital", prefix: "", suffix: "" },
];

type CustomToggleProps = {
  children?: React.ReactNode,
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {},
};

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
    <a
      href=""
      className="clientSelection"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </a>
  )
);

type CustomMenuProps = {
  children?: React.ReactNode,
  style?: React.CSSProperties,
  className?: string,
  labeledBy?: string,
};

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  (props: CustomMenuProps, ref: React.Ref<HTMLDivElement>) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={props.style}
        className={props.className}
        aria-labelledby={props.labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(props.children).filter(
            (child: any) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

const authorizations = [
  {
    clientId: 1,
    patientName: "Jonathan Caday",
    authorizationId: "4569663974",
    scheduleDate: "02/18/2023",
    amount: 60.0,
  },
  {
    clientId: 1,
    patientName: "Mary Jane Sainz",
    authorizationId: "4569663975",
    scheduleDate: "02/18/2023",
    amount: 250.0,
  },
  {
    clientId: 2,
    patientName: "John Leal",
    authorizationId: "4569663976",
    scheduleDate: "02/18/2023",
    amount: 100.0,
  },
  {
    clientId: 2,
    patientName: "Wilfredo Gumaru",
    authorizationId: "4569663977",
    scheduleDate: "02/18/2023",
    amount: 90.0,
  },
];

const invoiceSubmitted = [];

const Reports = (props) => {
  const [selectedTab, setSelectedTab] = useState(1);

  useEffect(() => {
    const ninetyDaysChangePassword = async () => {
      if (!props.user) {
        let userId = sessionStorage.getItem("userId");
        await props.getUser(userId);
      }

      let userInfo = props.user;
      if (!userInfo.passwordChanged) {
        userInfo.passwordChanged = new Date();
        await props.addUpdateEmployee(userInfo);
        await props.getUser(userInfo.userId);
      } else {
        let passwordChanged = new Date(userInfo.passwordChanged);
        let ninetyDaysFromNow = new Date();
        ninetyDaysFromNow.setDate(ninetyDaysFromNow.getDate() - 90);
        if (passwordChanged < ninetyDaysFromNow) {
          alert("Your password has expired. Please create a new password.");
          props.navigate("/change-password");
        }
      }
    };

    ninetyDaysChangePassword();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (key) => {
    setSelectedTab(key);
  };

  const [selectedClient, setSelectedClient] = useState(0);
  const theChosenClient = () => {
    const chosenClient: IClient = clients.find((f) => f.id === selectedClient);
    return chosenClient ? chosenClient.clientName : "Add Client";
  };

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const handleSelectAll = (e) => {
    console.log("test");
    setIsCheckAll(!isCheckAll);
    setIsCheck(clientAuthorizations.map((li) => li.authorizationId));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const [invoiceNumber, setInvoiceNumber] = useState(0);
  const [invcNumRunning = 90, setInvcNumRunning] = useState(0);
  var selectedAuthTotAmt = 0;
  var totalAuthorizationAmount = 0,
    invoiceNumPrefix = "AF-2022-";

  console.log("authorizations", authorizations);

  const clientAuthorizations = authorizations.filter(
    (a) => a.clientId == selectedClient
  );

  clientAuthorizations.map((record, key) => {
    selectedAuthTotAmt = 0;
    if (clientAuthorizations.length != 0) {
      totalAuthorizationAmount += record.amount;
      if (isCheck.length != 0) {
        isCheck.map((id) => {
          const authorization = clientAuthorizations.filter(
            (a) => a.authorizationId == id
          );
          if (authorization.length != 0) {
            selectedAuthTotAmt += authorization[0].amount;
          }
        });
      }
    }
  });

  const authorizationsList = clientAuthorizations.map((record, key) => {
    return (
      <>
        <tr key={key}>
          <td>
            <Form.Check
              key={record.authorizationId}
              id={record.authorizationId}
              checked={isCheck.includes(record.authorizationId)}
              onChange={handleClick}
              value={record.authorizationId}
            />
          </td>
          <td>{record.patientName}</td>
          <td>{record.authorizationId}</td>
          <td>{record.scheduleDate}</td>
          <td className="text-end">${record.amount.toFixed(2)}</td>
        </tr>
      </>
    );
  });

  const [selectedClientPayment, setSelectedClientPayment] = useState(0);
  const theChosenClientPayment = () => {
    const theChosenClientPayment: IClient = clients.find(
      (f) => f.id === selectedClientPayment
    );
    return theChosenClientPayment
      ? theChosenClientPayment.clientName
      : "Add Client";
  };

  const [isCheckAllPayment, setIsCheckAllPayment] = useState(false);
  const [isCheckPayment, setIsCheckPayment] = useState([]);

  var invoice = {};
  if (selectedAuthTotAmt != 0) {
    invoice.clientId = selectedClient;
    invoice.invoiceNumber = invoiceNumber;
    invoice.dateOfIssue = "01/18/2023";
    invoice.dueDate = "02/22/2023";
    invoice.amount = selectedAuthTotAmt;
  }
  console.log("selectedClientPayment", invoiceSubmitted);

  const clientInvoice = invoiceSubmitted.filter(
    (a) => a.clientId == selectedClientPayment
  );

  const handleSelectAllPayment = (e) => {
    console.log("test");
    setIsCheckAllPayment(!isCheckAllPayment);
    setIsCheckPayment(invoiceSubmitted.map((li) => li.invoiceNumber));
    if (isCheckAllPayment) {
      setIsCheckPayment([]);
    }
  };

  const handleClickPayment = (e) => {
    const { id, checked } = e.target;
    setIsCheckPayment([...isCheckPayment, id]);
    if (!checked) {
      setIsCheckPayment(isCheckPayment.filter((item) => item !== id));
    }
  };

  const invoiceList = clientInvoice.map((record, key) => {
    return (
      <>
        <tr key={key}>
          <td>
            <Form.Check
              key={record.invoiceNumber}
              id={record.invoiceNumber}
              checked={isCheckPayment.includes(record.invoiceNumber)}
              onChange={handleClickPayment}
              value={record.invoiceNumber}
            />
          </td>
          <td>{record.invoiceNumber}</td>
          <td>{record.dateOfIssue}</td>
          <td>{record.dueDate}</td>
          <td className="text-end">${record.amount.toFixed(2)}</td>
        </tr>
      </>
    );
  });

  var selectedInvcTotAmt = 0;
  clientInvoice.map((record, key) => {
    selectedInvcTotAmt = 0;
    if (clientInvoice.length != 0) {
      if (isCheckPayment.length != 0) {
        isCheckPayment.map((id) => {
          const invoice = clientInvoice.filter((a) => a.invoiceNumber == id);
          if (invoice.length != 0) {
            selectedInvcTotAmt += invoice[0].amount;
          }
        });
      }
    }
  });

  const removedAuthorizations = () => {
    if (authorizations.length != 0) {
      if (isCheck.length != 0) {
        isCheck.map((id) => {
          console.log(id);
          authorizations.splice(
            authorizations.findIndex((a) => a.authorizationId == id),
            1
          );
          clientAuthorizations.splice(
            authorizations.findIndex((a) => a.authorizationId == id),
            1
          );
          console.log(authorizations);
        });
      }
    }
  };

  const [showPayment, setShowPayment] = useState(false);
  const handleClosePayment = () => setShowPayment(false);
  const handleShowPayment = () => setShowPayment(true);

  const removedInvoices = () => {
    clientInvoice.map((record, key) => {
      if (clientInvoice.length != 0) {
        if (isCheckPayment.length != 0) {
          isCheckPayment.map((id) => {
            clientInvoice.splice(
              clientInvoice.findIndex((a) => a.invoiceNumber == id),
              1
            );
            invoiceSubmitted.splice(
              invoiceSubmitted.findIndex((a) => a.invoiceNumber == id),
              1
            );
          });
        }
      }
    });
  };

  return (
    <div className="w-100">
      <div className="auth-content-header d-flex justify-content-between">
        <div className="auth-logo-cont">
          <svg
            width="118"
            height="24"
            viewBox="0 0 118 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.28 0C0.413213 0 0 8.54562 0 8.54562V23.7477C0 23.8803 0.108893 23.988 0.243042 23.988H2.55173C2.68588 23.988 2.79478 23.8803 2.79478 23.7477V13.6394C2.79478 13.5068 2.90367 13.3991 3.03782 13.3991H17.522C17.6562 13.3991 17.7651 13.5068 17.7651 13.6394V23.7477C17.7651 23.8803 17.8739 23.988 18.0081 23.988H20.3168C20.4509 23.988 20.5598 23.8803 20.5598 23.7477V8.54562C20.5598 8.54562 20.1466 0 10.28 0ZM17.7653 10.8523C17.7653 10.9849 17.6564 11.0926 17.5222 11.0926H3.03782C2.90367 11.0926 2.79478 10.9849 2.79478 10.8523V9.0262C2.79478 5.49636 5.68892 2.63499 9.2592 2.63499H11.3006C14.8709 2.63499 17.7651 5.49636 17.7651 9.0262V10.8523H17.7653Z"
              fill="#2DC0D0"
            />
            <path
              d="M37.6368 5.4519V15.287C37.6368 18.7372 34.8078 21.5341 31.3181 21.5341C27.8283 21.5341 24.9994 18.7372 24.9994 15.287V5.4519H22.3423V15.0796C22.3423 19.9806 26.3608 23.9536 31.3179 23.9536C36.275 23.9536 40.2934 19.9806 40.2934 15.0796V5.4519H37.6363H37.6368Z"
              fill="#2DC0D0"
            />
            <path
              d="M49.3089 21.4402C46.6133 21.4402 44.4282 19.2798 44.4282 16.6148V8.38945C44.4282 8.27217 44.5242 8.1772 44.6429 8.1772H50.4349C50.5535 8.1772 50.6496 8.08223 50.6496 7.96495V5.95463C50.6496 5.83735 50.5535 5.74238 50.4349 5.74238H44.6429C44.5242 5.74238 44.4282 5.64741 44.4282 5.53013V0.212249C44.4282 0.0949695 44.3321 0 44.2135 0H42.245C42.1263 0 42.0303 0.0949695 42.0303 0.212249V17.6719C42.0303 20.9915 44.7522 23.6826 48.1098 23.6826H52.379C52.4976 23.6826 52.5937 23.5876 52.5937 23.4704V21.6522C52.5937 21.535 52.4976 21.44 52.379 21.44H49.3089V21.4402Z"
              fill="#2DC0D0"
            />
            <path
              d="M63.3938 5.48613C60.9294 5.48613 58.6971 6.46816 57.0751 8.05767V0H54.418V23.988H57.0751V14.1529C57.0751 10.7027 59.904 7.9058 63.3938 7.9058C66.8835 7.9058 69.7124 10.7027 69.7124 14.1529V23.988H72.3695V14.3602C72.3695 9.45929 68.3511 5.48633 63.394 5.48633L63.3938 5.48613Z"
              fill="#2DC0D0"
            />
            <path
              d="M83.7622 4.90674C78.4294 4.90674 74.106 9.18098 74.106 14.4534C74.106 19.7259 78.4292 24.0001 83.7622 24.0001C89.0953 24.0001 93.4183 19.7259 93.4183 14.4534C93.4183 9.18098 89.0951 4.90674 83.7622 4.90674ZM83.7622 21.5503C79.7978 21.5503 76.584 18.373 76.584 14.4534C76.584 10.5339 79.7978 7.3565 83.7622 7.3565C87.7267 7.3565 90.9404 10.5339 90.9404 14.4534C90.9404 18.373 87.7267 21.5503 83.7622 21.5503Z"
              fill="#2DC0D0"
            />
            <path
              d="M112.778 3.39188V0.212249C112.778 0.0949695 112.682 0 112.563 0H95.2317V23.7757C95.2317 23.893 95.3277 23.988 95.4464 23.988H98.6624C98.781 23.988 98.8771 23.893 98.8771 23.7757V12.8506C98.8771 12.7333 98.9732 12.6383 99.0918 12.6383H109.89C110.009 12.6383 110.105 12.5433 110.105 12.4261V9.24643C110.105 9.12915 110.009 9.03418 109.89 9.03418H99.0918C98.9732 9.03418 98.8771 8.93921 98.8771 8.82193V3.81638C98.8771 3.6991 98.9732 3.60413 99.0918 3.60413H112.563C112.682 3.60413 112.778 3.50916 112.778 3.39188Z"
              fill="#22449C"
            />
            <path
              d="M117.805 5.99707H114.783C114.675 5.99707 114.588 6.08348 114.588 6.19008V9.88059C114.588 9.98718 114.675 10.0736 114.783 10.0736H117.805C117.913 10.0736 118 9.98718 118 9.88059V6.19008C118 6.08348 117.913 5.99707 117.805 5.99707Z"
              fill="#22449C"
            />
            <path
              d="M117.805 11.6594H114.783C114.675 11.6594 114.588 11.7458 114.588 11.8524V23.7852C114.588 23.8918 114.675 23.9782 114.783 23.9782H117.805C117.913 23.9782 118 23.8918 118 23.7852V11.8524C118 11.7458 117.913 11.6594 117.805 11.6594Z"
              fill="#22449C"
            />
          </svg>
        </div>
        <div className="d-flex justify-content-end">
          <RightPanel />
        </div>
      </div>
      <div className="auth-content-main d-flex align-items-center justify-content-center">
        <div className="auth-content-content d-flex p-2">
          <div className="reportsContent">
            <Tabs
              activeKey={selectedTab}
              onSelect={handleSelect}
              id="controlled-tab-example"
            >
              <Tab eventKey={1} title="INVOICE">
                <div className="d-flex justify-content-end mb-2">
                  <Button
                    className="btn btn-success grid-btn m-0"
                    variant="primary"
                    type="button"
                    onClick={(e: string) => {
                      invoiceSubmitted.push(invoice);
                      removedAuthorizations();
                      setInvcNumRunning(invcNumRunning + 1);
                      setInvoiceNumber(
                        invoiceNumPrefix + (invcNumRunning + 1).toString()
                      );
                      setIsCheckAll(false);
                      setIsCheck([]);
                      setIsCheckAllPayment(false);
                      setIsCheckPayment([]);
                    }}
                  >
                    CREATE INVOICE
                  </Button>
                </div>
                <div className="invoice-content p-5">
                  <div className="table-form-label row text-end mb-5">
                    <span>AUTHOFI</span>
                    <span>12341234123</span>
                    <span>32412 N 87th Street</span>
                    <span>Scottsdale, Arizona</span>
                    <span>85266</span>
                    <span>United States</span>
                  </div>
                  <div className="mb-5">
                    <Row>
                      <div class="col">
                        <Form.Group as={Row} controlId="">
                          <Form.Label className="table-form-label-invoice mb-1">
                            Billed To
                          </Form.Label>
                          <Dropdown
                            className="table-form-label"
                            onSelect={(e: string) => {
                              setSelectedClient(Number(e));
                              setInvoiceNumber(
                                invoiceNumPrefix +
                                  (invcNumRunning + 1).toString()
                              );
                              setInvcNumRunning(invcNumRunning + 1);
                              setIsCheckAll(false);
                              setIsCheck([]);
                              setIsCheckAllPayment(false);
                              setIsCheckPayment([]);
                            }}
                          >
                            <Dropdown.Toggle
                              as={CustomToggle}
                              id="dropdown-custom-components"
                            >
                              {theChosenClient()}
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              className="table-form-label"
                              as={CustomMenu}
                            >
                              {clients.map((client) => {
                                return (
                                  <Dropdown.Item
                                    key={client.id}
                                    eventKey={client.id.toString()}
                                  >
                                    {client.clientName}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Form.Group>
                      </div>
                      <div class="col">
                        <Form.Group as={Row} controlId="">
                          <Form.Label className="table-form-label-invoice mb-1">
                            Date of Issue
                          </Form.Label>
                          <Form.Label className="table-form-label">
                            01/18/2023
                          </Form.Label>
                        </Form.Group>
                      </div>
                      <div class="col">
                        <Form.Group as={Row} controlId="">
                          <Form.Label className="table-form-label-invoice mb-1">
                            Due Date
                          </Form.Label>
                          <Form.Label className="table-form-label">
                            02/22/2023
                          </Form.Label>
                        </Form.Group>
                      </div>
                      <div class="col">
                        <Form.Group as={Row} controlId="">
                          <Form.Label className="table-form-label-invoice mb-1">
                            Invoice Number
                          </Form.Label>
                          <Form.Label className="table-form-label">
                            {invoiceNumber}
                          </Form.Label>
                        </Form.Group>
                      </div>
                      <div class="col">
                        <Form.Group as={Row} controlId="">
                          <Form.Label className="table-form-label-invoice mb-1 text-end">
                            Amount Due(USD)
                          </Form.Label>
                          <Form.Label className="table-form-label-due text-end">
                            ${totalAuthorizationAmount.toFixed(2)}
                          </Form.Label>
                        </Form.Group>
                      </div>
                    </Row>
                  </div>
                  <Table className="table-invoice m-10">
                    <thead>
                      <tr className="table-form-label-invoice">
                        <th>
                          <Form.Check
                            name="selectAll"
                            id="selectAll"
                            onChange={handleSelectAll}
                            checked={isCheckAll}
                          />
                        </th>
                        <th>PATIENT NAME</th>
                        <th>AUTHORIZATION ID</th>
                        <th>SCHEDULE DATE</th>
                        <th className="text-end">AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>{authorizationsList}</tbody>
                  </Table>
                  <div class="col">
                    <Form.Group as={Col} className="text-end mb-1">
                      <Form.Label className="table-form-label-selected text-end me-5">
                        Total Selected
                      </Form.Label>
                      <Form.Label className="table-form-label-selected text-end me-2">
                        ${selectedAuthTotAmt.toFixed(2)}
                      </Form.Label>
                    </Form.Group>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={2} title="PAYMENT">
                <div className="d-flex justify-content-end mb-2">
                  <Button
                    className="btn btn-success grid-btn m-0"
                    variant="primary"
                    onClick={handleShowPayment}
                  >
                    MAKE PAYMENT
                  </Button>
                </div>
                <Modal show={showPayment} onHide={handleClosePayment}>
                  <Modal.Header closeButton>
                    <Modal.Title>Payments</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label-invoice mb-1 text-end">
                        Bank Name:
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        placeholder="Bank Name"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label-invoice mb-1 text-end">
                        Check Number:
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        placeholder="Check Number"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label-invoice mb-1 text-end">
                        Amount:
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        placeholder="Amount"
                        value={selectedInvcTotAmt.toFixed(2)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label-invoice mb-1 text-end">
                        Reference Number:
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        placeholder="Reference Number"
                      />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn grid-btn"
                      variant="secondary"
                      onClick={handleClosePayment}
                    >
                      Close
                    </Button>
                    <Button
                      className="btn grid-btn"
                      variant="primary"
                      onClick={() => {
                        removedInvoices();
                        handleClosePayment();
                      }}
                    >
                      SUBMIT
                    </Button>
                  </Modal.Footer>
                </Modal>
                <div className="invoice-content p-5">
                  <div className="table-form-label row text-end mb-5">
                    <span>AUTHOFI</span>
                    <span>12341234123</span>
                    <span>32412 N 87th Street</span>
                    <span>Scottsdale, Arizona</span>
                    <span>85266</span>
                    <span>United States</span>
                  </div>
                  <div className="mb-5">
                    <Row>
                      <div class="col">
                        <Form.Group as={Row} controlId="">
                          <Form.Label className="table-form-label-invoice mb-1">
                            Payment From
                          </Form.Label>
                          <Dropdown
                            className="table-form-label"
                            onSelect={(e: string) => {
                              setSelectedClientPayment(Number(e));
                              setIsCheckAllPayment(false);
                              setIsCheckPayment([]);
                            }}
                          >
                            <Dropdown.Toggle
                              as={CustomToggle}
                              id="dropdown-custom-components"
                            >
                              {theChosenClientPayment()}
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              className="table-form-label"
                              as={CustomMenu}
                            >
                              {clients.map((client) => {
                                return (
                                  <Dropdown.Item
                                    key={client.id}
                                    eventKey={client.id.toString()}
                                  >
                                    {client.clientName}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Form.Group>
                      </div>
                    </Row>
                  </div>
                  <Table className="table-invoice m-10">
                    <thead>
                      <tr className="table-form-label-invoice">
                        <th>
                          <Form.Check
                            name="selectAll"
                            id="selectAll"
                            onChange={handleSelectAllPayment}
                            checked={isCheckAllPayment}
                          />
                        </th>
                        <th>INVOICE NUMBER</th>
                        <th>DATE OF ISSUE</th>
                        <th>DUE DATE</th>
                        <th className="text-end">AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>{invoiceList}</tbody>
                  </Table>
                  <div class="col">
                    <Form.Group as={Col} className="text-end mb-1">
                      <Form.Label className="table-form-label-selected text-end me-5">
                        Total Selected
                      </Form.Label>
                      <Form.Label className="table-form-label-selected text-end me-2">
                        ${selectedInvcTotAmt.toFixed(2)}
                      </Form.Label>
                    </Form.Group>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: bindActionCreators(getUser, dispatch),
    addUpdateEmployee: bindActionCreators(addUpdateEmployee, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.currentUser.data,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter(Reports));
