import * as MenuApiActionConstants from '../../redux/actions/api/menu/MenuApiActionConstants'

const initialState = {
    data: '',
    dataSearch: {},
};

export default function menuReducer(state = initialState, action) {
    switch (action.type) {
        case MenuApiActionConstants.SELECTED_MENU:
            return {
                ...state,
                data: action.val
            };
        case MenuApiActionConstants.SELECTED_MENU_SEARCH:
            return {
                ...state,
                dataSearch: action.val
            };
        default:
            return state
    }
}
