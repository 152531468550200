import { CALL_API } from '../../../middleware/api'
import * as MenuApiActionConstants from "./MenuApiActionConstants"

export function selectedMenu(val) {
     return function (dispatch) {
         dispatch({
             type: MenuApiActionConstants.SELECTED_MENU,
             val: val
         })
     }
}

export function selectedMenuSearch(val) {
     return function (dispatch) {
         dispatch({
             type: MenuApiActionConstants.SELECTED_MENU_SEARCH,
             val: val
         })
     }
}