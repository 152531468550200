import React, { useState, Component } from "react";
import "../../App.css";
import "./Employee.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Table } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LeftPanel from "../common/leftPanel/LeftPanel";
import RightPanel from "../common/leftPanel/RightPanel";
import LeftPanelMobile from "../common/leftPanel/LeftPanelMobile";
import Search from "../common/search/Search";
import {
  addUpdateEmployee,
  addUpdateEmployeeSelected,
} from "../../redux/actions/api/employee/AddUpdateEmployeeApiActions";
import {
  getClientEmployees,
  clientEmployeesClear,
} from "../../redux/actions/api/employee/GetClientEmployeesApiActions";
import getClientsByOrgId from "../../redux/actions/api/client/GetClientsByOrgIdApiActions";
import getOrganization from "../../redux/actions/api/organization/GetOrganizationApiActions";
import getAllOrganization from "../../redux/actions/api/organization/GetAllOrganizationApiActions";
import getEmployeeByOrgId from "../../redux/actions/api/employee/GetEmployeeByOrgIdApiActions";
import getEmployeeByClientId from "../../redux/actions/api/employee/GetEmployeeByClientIdApiActions";
import getEmployeeByFacilityId from "../../redux/actions/api/employee/GetEmployeeByFacilityIdApiActions";
import getFacilities from "../../redux/actions/api/facility/GetFacilitiesApiActions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import { WithRouter } from "../common/WithRouter";
import getUser from "../../redux/actions/api/authentication/GetUserApiActions";

class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      id: "",
      parentId: "",
      parentType: "",
      orgId: "",
      facilityId: "",

      firstName: "",
      middleName: "",
      lastName: "",
      userRole: "USER",
      userId: "",
      userPassword: "",
      userPasswordEncrypted: "",
      status: "ACTIVE",

      isNew: "Y",
      hideEmployeeInfo: true,
    };
  }

  ninetyDaysChangePassword = async () => {
    let userInfo = this.props.user;
    if (!userInfo.passwordChanged) {
      userInfo.passwordChanged = new Date();
      await this.props.addUpdateEmployee(userInfo);
      await this.props.getUser(userInfo.userId);
    } else {
      console.log("iamhere");
      let passwordChanged = new Date(userInfo.passwordChanged);
      let ninetyDaysFromNow = new Date();
      ninetyDaysFromNow.setDate(ninetyDaysFromNow.getDate() - 90);
      if (passwordChanged < ninetyDaysFromNow) {
        alert("Your password has expired. Please create a new password.");
        this.props.navigate("/change-password");
      }
    }
  };

  employeeDefault = {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    userRole: "USER",
    userId: "",
    userPassword: "",
    userPasswordEncrypted: "",
    status: "ACTIVE",
  };

  saveEmployee = async () => {
    const { isNew } = this.state;
    const { menuSearch } = this.props;
    var orgId, clientId, facilityId;
    orgId = menuSearch.empOrgId;
    clientId = menuSearch.empClientId;
    facilityId = menuSearch.empFacilityId;
    //const {addedEmployee} = this.props;

    var employee = {
      ...this.state,
      orgId: orgId,
      clientId: clientId,
      facilityId: facilityId,
    };
    console.log(employee);
    var re =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;

    if (employee.userPassword.match(re)) {
      await this.props.addUpdateEmployee(employee);
      await this.props.getEmployeeByOrgId(orgId);
      await this.props.getEmployeeByClientId(clientId);
      await this.props.getEmployeeByFacilityId(facilityId);
      if (isNew == "Y") {
        this.setState(this.employeeDefault);
      }
    } else {
      alert(
        "Password did not satisfy the rules:\n" +
          "1. Password must be between 8 to 30 characters.\n" +
          "2. Password must contain at least one uppercase or capital letter.\n" +
          "3. Password must contain at least one lowercase letter.\n" +
          "4. Password must contain at least one number.\n" +
          "5. Password must contain at least one special character ($, #, @, !, &, *, (,))."
      );
    }
  };

  handleOrgId(e) {
    const state = this.state;

    var orgId = e.target.value;
    this.setState({ orgId: orgId });

    this.props.getClientsByOrgId(orgId);
    this.props.getEmployeeByOrgId(orgId);
    this.clearEmployeeAndList();
  }

  handleClientId(e) {
    const state = this.state;

    var clientId = e.target.value;
    this.setState({ clientId: clientId });

    this.props.getFacilities(clientId);
    this.props.getEmployeeByClientId(clientId);
  }

  handleFacilityId(e) {
    const state = this.state;

    var facilityId = e.target.value;
    this.setState({ facilityId: facilityId });

    this.props.getEmployeeByFacilityId(facilityId);
  }

  clearEmployeeAndList = async () => {
    this.props.addUpdateEmployeeSelected({});
    this.setState(this.employeeDefault);
    console.log("I am here 123...");
  };

  onClickRow = (record) => {
    this.props.addUpdateEmployeeSelected(record);
    const { addedEmployee } = this.props;

    console.log("addedEmployee", addedEmployee);

    this.props.getClientEmployees(record.id);

    this.setState(record);
  };

  async componentDidMount() {
    if (!this.props.user) {
      let userId = sessionStorage.getItem("userId");
      await this.props.getUser(userId);
    }

    await this.ninetyDaysChangePassword();

    const { menuSearch } = this.props;

    await this.props.getClientsByOrgId(menuSearch.empOrgId);

    await this.props.getFacilities(menuSearch.empClientId);

    await this.props.getEmployeeByOrgId(0);
    this.props.getAllOrganization();

    this.clearEmployeeAndList();

    this.setState({ hideEmployeeInfo: true });
  }

  onClickMainTableHandler(record, e) {
    console.log("record", record);
    const state = this.state;

    this.setState(record);

    /*Reset all show details*/
    const elementsShow = document.querySelectorAll("tr.show");
    const hiddenElement = e.currentTarget.nextSibling;

    /*Reset all show details*/
    elementsShow.forEach((element) => {
      if (hiddenElement != element) {
        element.classList.remove("show");
      }
    });

    /*Set selected row*/
    const selected = e.currentTarget;
    const elementsSelected = document.querySelectorAll("tr.selected-table-row");

    elementsSelected.forEach((element) => {
      if (selected != element) {
        element.classList.remove("selected-table-row");
      }
    });

    selected.classList.add("selected-table-row");

    /*Reset all up arrow*/
    const elementsArrow = document.querySelectorAll(".fa-chevron-up");
    elementsArrow.forEach((element) => {
      element.classList.remove("fa-chevron-up");
      element.classList.add("fa-chevron-down");
    });

    /*set up and show details on selected row*/
    const arrowSelected = document.querySelectorAll(".selected-table-row .fa");

    if (hiddenElement.className.indexOf("collapse show") > -1) {
      hiddenElement.classList.remove("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });
    } else {
      hiddenElement.classList.add("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-down");
        element.classList.add("fa-chevron-up");
      });
    }
  }

  addDashes = (phone) => {
    let x = phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    phone = !x[2] ? x[1] : +x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    return phone;
  };

  render() {
    var {
      addedEmployee,
      employeesOrgList,
      employeesClientList,
      employeesFacilityList,
      organizationList,
      clientList,
      facilityList,
    } = this.props;
    const { orgId, hideEmployeeInfo } = this.state;
    var employeesList = [];
    //console.log("employeesClientList", employeesClientList);
    //console.log("employeesOrgList")
    if (employeesClientList) {
      employeesList = employeesClientList;
    } else {
      if (employeesOrgList) {
        employeesList = employeesOrgList;
      }
    }

    if (!organizationList) {
      organizationList = [];
    }

    if (!clientList) {
      clientList = [];
    }

    if (!employeesFacilityList) {
      employeesFacilityList = [];
    }

    if (!facilityList) {
      facilityList = [];
    }

    var hideEmployee = { display: "none" };
    var hideEmployeeList = { display: "none" };
    if (hideEmployeeInfo == true) {
      hideEmployee = { display: "none" };
      hideEmployeeList = { display: "block" };
    } else {
      hideEmployee = { display: "block" };
      hideEmployeeList = { display: "none" };
    }

    const tooltipNote = () => (
      <Tooltip id="button-tooltip">
        <div>
          <label>
            1. Password must be between 8 to 30 characters. <br />
            2. Password must contain at least one uppercase or capital letter{" "}
            <br />
            3. Password must contain at least one lowercase letter <br />
            4. Password must contain at least one number <br />
            5. Password must contain at least one special character ($, #, @, !,
            &, *, (,)).")
          </label>
        </div>
      </Tooltip>
    );
    return (
      <div className="w-100">
        <div className="auth-content-header d-flex justify-content-between">
          <div className="auth-logo-cont">
            <svg
              width="118"
              height="24"
              viewBox="0 0 118 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.28 0C0.413213 0 0 8.54562 0 8.54562V23.7477C0 23.8803 0.108893 23.988 0.243042 23.988H2.55173C2.68588 23.988 2.79478 23.8803 2.79478 23.7477V13.6394C2.79478 13.5068 2.90367 13.3991 3.03782 13.3991H17.522C17.6562 13.3991 17.7651 13.5068 17.7651 13.6394V23.7477C17.7651 23.8803 17.8739 23.988 18.0081 23.988H20.3168C20.4509 23.988 20.5598 23.8803 20.5598 23.7477V8.54562C20.5598 8.54562 20.1466 0 10.28 0ZM17.7653 10.8523C17.7653 10.9849 17.6564 11.0926 17.5222 11.0926H3.03782C2.90367 11.0926 2.79478 10.9849 2.79478 10.8523V9.0262C2.79478 5.49636 5.68892 2.63499 9.2592 2.63499H11.3006C14.8709 2.63499 17.7651 5.49636 17.7651 9.0262V10.8523H17.7653Z"
                fill="#2DC0D0"
              />
              <path
                d="M37.6368 5.4519V15.287C37.6368 18.7372 34.8078 21.5341 31.3181 21.5341C27.8283 21.5341 24.9994 18.7372 24.9994 15.287V5.4519H22.3423V15.0796C22.3423 19.9806 26.3608 23.9536 31.3179 23.9536C36.275 23.9536 40.2934 19.9806 40.2934 15.0796V5.4519H37.6363H37.6368Z"
                fill="#2DC0D0"
              />
              <path
                d="M49.3089 21.4402C46.6133 21.4402 44.4282 19.2798 44.4282 16.6148V8.38945C44.4282 8.27217 44.5242 8.1772 44.6429 8.1772H50.4349C50.5535 8.1772 50.6496 8.08223 50.6496 7.96495V5.95463C50.6496 5.83735 50.5535 5.74238 50.4349 5.74238H44.6429C44.5242 5.74238 44.4282 5.64741 44.4282 5.53013V0.212249C44.4282 0.0949695 44.3321 0 44.2135 0H42.245C42.1263 0 42.0303 0.0949695 42.0303 0.212249V17.6719C42.0303 20.9915 44.7522 23.6826 48.1098 23.6826H52.379C52.4976 23.6826 52.5937 23.5876 52.5937 23.4704V21.6522C52.5937 21.535 52.4976 21.44 52.379 21.44H49.3089V21.4402Z"
                fill="#2DC0D0"
              />
              <path
                d="M63.3938 5.48613C60.9294 5.48613 58.6971 6.46816 57.0751 8.05767V0H54.418V23.988H57.0751V14.1529C57.0751 10.7027 59.904 7.9058 63.3938 7.9058C66.8835 7.9058 69.7124 10.7027 69.7124 14.1529V23.988H72.3695V14.3602C72.3695 9.45929 68.3511 5.48633 63.394 5.48633L63.3938 5.48613Z"
                fill="#2DC0D0"
              />
              <path
                d="M83.7622 4.90674C78.4294 4.90674 74.106 9.18098 74.106 14.4534C74.106 19.7259 78.4292 24.0001 83.7622 24.0001C89.0953 24.0001 93.4183 19.7259 93.4183 14.4534C93.4183 9.18098 89.0951 4.90674 83.7622 4.90674ZM83.7622 21.5503C79.7978 21.5503 76.584 18.373 76.584 14.4534C76.584 10.5339 79.7978 7.3565 83.7622 7.3565C87.7267 7.3565 90.9404 10.5339 90.9404 14.4534C90.9404 18.373 87.7267 21.5503 83.7622 21.5503Z"
                fill="#2DC0D0"
              />
              <path
                d="M112.778 3.39188V0.212249C112.778 0.0949695 112.682 0 112.563 0H95.2317V23.7757C95.2317 23.893 95.3277 23.988 95.4464 23.988H98.6624C98.781 23.988 98.8771 23.893 98.8771 23.7757V12.8506C98.8771 12.7333 98.9732 12.6383 99.0918 12.6383H109.89C110.009 12.6383 110.105 12.5433 110.105 12.4261V9.24643C110.105 9.12915 110.009 9.03418 109.89 9.03418H99.0918C98.9732 9.03418 98.8771 8.93921 98.8771 8.82193V3.81638C98.8771 3.6991 98.9732 3.60413 99.0918 3.60413H112.563C112.682 3.60413 112.778 3.50916 112.778 3.39188Z"
                fill="#22449C"
              />
              <path
                d="M117.805 5.99707H114.783C114.675 5.99707 114.588 6.08348 114.588 6.19008V9.88059C114.588 9.98718 114.675 10.0736 114.783 10.0736H117.805C117.913 10.0736 118 9.98718 118 9.88059V6.19008C118 6.08348 117.913 5.99707 117.805 5.99707Z"
                fill="#22449C"
              />
              <path
                d="M117.805 11.6594H114.783C114.675 11.6594 114.588 11.7458 114.588 11.8524V23.7852C114.588 23.8918 114.675 23.9782 114.783 23.9782H117.805C117.913 23.9782 118 23.8918 118 23.7852V11.8524C118 11.7458 117.913 11.6594 117.805 11.6594Z"
                fill="#22449C"
              />
            </svg>
          </div>
          <div className="d-flex justify-content-end">
            <Search />
            <RightPanel />
          </div>
        </div>
        <div className="auth-content-main d-flex align-items-center justify-content-center">
          <div className="auth-content-content">
            <div class="row">
              <div className="col-md-8">
                <label className="patient-title">EMPLOYEES</label>
              </div>
              <div className="col-md-4">
                <Button
                  className="add-patients-btn btn-primary mb-1 float-end m-0"
                  variant="primary"
                  type="button"
                  onClick={() => {
                    this.clearEmployeeAndList();
                    this.setState({ hideEmployeeInfo: false });
                  }}
                >
                  <img
                    src={require("../../images/add-icon.png")}
                    className="add-icon"
                  />
                  Add New Employee
                </Button>
                <Button
                  style={hideEmployee}
                  className="btn back-btn mb-1 float-end mr-1"
                  type="button"
                  onClick={() => {
                    this.clearEmployeeAndList();
                    this.setState({ hideEmployeeInfo: true });
                  }}
                >
                  <img
                    src={require("../../images/back-icon.png")}
                    className="add-icon"
                  />
                  Back
                </Button>
              </div>
            </div>
            <div style={hideEmployee} class="main-panel">
              <Row className="mb-1">
                <div class="col-lg-3 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      First Name
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={40}
                      value={this.state.firstName}
                      onChange={(e) =>
                        this.setState({ firstName: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Middle Name
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={40}
                      value={this.state.middleName}
                      onChange={(e) =>
                        this.setState({ middleName: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-3 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={40}
                      value={this.state.lastName}
                      onChange={(e) =>
                        this.setState({ lastName: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={15}
                      value={this.state.phoneNumber}
                      onChange={(e) => {
                        let phone = this.addDashes(e.target.value);
                        this.setState({ phoneNumber: phone });
                      }}
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Email
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={40}
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </Form.Group>
                </div>
              </Row>
              <Row className="mb-1">
                <div class="col-lg-3 col-6 mt-2">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      User Role
                    </Form.Label>
                    <Form.Select
                      className="grid-form-control"
                      value={this.state.userRole}
                      onChange={(e) =>
                        this.setState({ userRole: e.target.value })
                      }
                    >
                      <option value="USER">USER</option>
                      <option value="ADMIN">ADMIN</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6 mt-2">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      User ID
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      maxLength={40}
                      value={this.state.userId}
                      onChange={(e) =>
                        this.setState({ userId: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
                <div class="col-lg-3 col-6 mt-2">
                  <Form.Group as={Col} controlId="">
                    <OverlayTrigger placement="bottom" overlay={tooltipNote()}>
                      <div>
                        <Form.Label className="table-form-label mb-0">
                          User Password
                        </Form.Label>
                        <Form.Control
                          className="grid-form-control"
                          type="password"
                          value={this.state.userPassword}
                          onChange={(e) =>
                            this.setState({ userPassword: e.target.value })
                          }
                        />
                      </div>
                    </OverlayTrigger>
                  </Form.Group>
                </div>
                <div class="col-lg-2 col-6 mt-2">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Status
                    </Form.Label>
                    <Form.Select
                      className="grid-form-control"
                      value={this.state.status}
                      onChange={(e) =>
                        this.setState({ status: e.target.value })
                      }
                    >
                      <option value="ACTIVE">ACTIVE</option>
                      <option value="NOT ACTIVE">NOT ACTIVE</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </Row>
              <Row>
                <div>
                  <Button
                    className="form-save-btn btn-primary float-end mt-1"
                    type="button"
                    onClick={() => {
                      this.saveEmployee();
                    }}
                  >
                    <img
                      src={require("../../images/save-btn-icon.png")}
                      className="add-icon"
                    />
                    Save
                  </Button>
                </div>
              </Row>
            </div>
            <div style={hideEmployeeList}>
              <label className="form-main-title mt-2">Employee List</label>
            </div>
            <div style={hideEmployeeList} className="mb-2">
              <Table bordered hover className="table-portal">
                <thead>
                  <tr>
                    <th className="td-first-none-collapse">First Name</th>
                    <th>Middle Name</th>
                    <th>Last Name</th>
                    <th>User Role</th>
                    <th className="td-last">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {employeesList.map((record, key) => {
                    return (
                      <>
                        <tr
                          key={key}
                          className={key % 2 == 1 ? "even-row" : ""}
                          onClick={() => {
                            this.onClickRow(record);
                            this.setState({ hideEmployeeInfo: false });
                          }}
                        >
                          <td className="td-first-none-collapse">
                            {record.firstName}
                          </td>
                          <td>{record.middleName}</td>
                          <td>{record.lastName}</td>
                          <td>{record.userRole}</td>
                          <td className="td-last">{record.status}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUpdateEmployee: bindActionCreators(addUpdateEmployee, dispatch),
    addUpdateEmployeeSelected: bindActionCreators(
      addUpdateEmployeeSelected,
      dispatch
    ),
    getClientEmployees: bindActionCreators(getClientEmployees, dispatch),
    getAllOrganization: bindActionCreators(getAllOrganization, dispatch),
    getOrganization: bindActionCreators(getOrganization, dispatch),
    getClientsByOrgId: bindActionCreators(getClientsByOrgId, dispatch),
    getEmployeeByOrgId: bindActionCreators(getEmployeeByOrgId, dispatch),
    getEmployeeByClientId: bindActionCreators(getEmployeeByClientId, dispatch),
    getEmployeeByFacilityId: bindActionCreators(
      getEmployeeByFacilityId,
      dispatch
    ),
    getFacilities: bindActionCreators(getFacilities, dispatch),
    getUser: bindActionCreators(getUser, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    addedEmployee: state.addUpdateEmployee.data,
    employeesOrgList: state.getEmployeeByOrgId.data,
    employeesClientList: state.getEmployeeByClientId.data,
    employeesFacilityList: state.getEmployeeByFacilityId.data,
    organizationList: state.getAllOrganization.data,
    clientList: state.getClientsByOrgId.data,
    facilityList: state.getFacilities.data,
    menuSearch: state.menu.dataSearch,
    user: state.currentUser.data,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter(Employee));
